import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { ActOfAcceptanceItemResponse } from '../../services/api';
import { actsOfAcceptanceService } from '../../services/services';

export interface IActOfAcceptanceItems<T> extends IAsyncType<T> {
    actOfAcceptanceItems: T;
    setActOfAcceptanceItems: Dispatch<SetStateAction<T>>;
}

const useActOfAcceptanceItems = (
    id: number,
): IActOfAcceptanceItems<ActOfAcceptanceItemResponse[]> => {
    const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
        ActOfAcceptanceItemResponse[]
    >(actsOfAcceptanceService.getActOfAcceptanceItems, [], [id]);
    return {
        actOfAcceptanceItems: data,
        setActOfAcceptanceItems: setData,
        loading,
        setLoading,
        parameters,
        setParameters
    };
};

export default useActOfAcceptanceItems;
