import { toEndOfDay, toStartOfDay } from '../helpers/date-helper';

import { AMSApi } from '../helpers/openapi-axios-helper';

export class ActsOfAcceptanceService {
  getActsOfAcceptance = async (
    fromDate: Date | null,
    toDate: Date | null,
    receivers?: string,
    legalEntities?: string,
    companies?: string,
    users?: string, 
    orderTypes?: string
  ) => {
    const axios = await AMSApi();
    return await axios.readActsOfAcceptanceV2ActsOfAcceptanceGet(
      fromDate ? toStartOfDay(fromDate) : undefined,
      toDate ? toEndOfDay(toDate) : undefined,
      receivers,
      legalEntities,
      companies,
      users,
      orderTypes
    );
  };

  getActOfAcceptance = async (id:number) => {
    const axios = await AMSApi();
    return await axios.readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(id);
  }

  getActOfAcceptanceItems = async (id:number) => {
    const axios = await AMSApi();
    return await axios.readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(id);
  }
}
