import { IAsyncType, useAsync } from '.';
import { useCallback, useMemo } from 'react';

import { PartnerBonusQuantityResponse } from '../../services/api';
import { promotionsService } from '../../services/services';

export interface IActivePromotions<T> extends IAsyncType<T> {
  promotions: T;
  setPartnerId: (partnerId?: number) => void;
}

const useActivePromotions = (
  partnerId?: number
): IActivePromotions<PartnerBonusQuantityResponse[]> => {
  const today = useMemo(() => new Date(), []);
  const { data, loading, setLoading, parameters, setParameters } = useAsync<
    PartnerBonusQuantityResponse[]
  >(
    promotionsService.getPromotions,
    [],
    partnerId
      ? [undefined, [partnerId], undefined, today, today, undefined]
      : [undefined, undefined, undefined, undefined, undefined, undefined]
  );
  const setPartnerId = useCallback(
    (partnerId?: number) => {
      setParameters(
        partnerId
          ? [undefined, [partnerId], undefined, today, today, undefined]
          : [undefined, undefined, undefined, undefined, undefined, undefined]
      );
    },
    [setParameters, today]
  );
  return {
    promotions: data,
    setPartnerId,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useActivePromotions;
