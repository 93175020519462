import { AMSPdfViewerDialog, AMSViewOnlyTextField } from '../../helpers/ui';
import { Button, CircularProgress, Container, Grid, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import ActOfAcceptanceItemsPanel from './ActOfAcceptanceItemsPanel';
import OutlinedDiv from '../../helpers/ui/OutlinedDiv';
import { getActOfAcceptanceUrl } from '../../helpers/utils';
import { getOrderTypeLabelById } from '../../services/ordersService';
import { parseQuery } from '../../helpers/url';
import { useActOfAcceptance } from '../../helpers/hooks';
import { useStyles } from '../../helpers/hooks';

const ActOfAcceptanceComponent = ({ location }: { location: any }) => {
  const { id } = parseQuery(location.search);

  const classes = useStyles();

  const { actOfAcceptance, loading } = useActOfAcceptance(id);

  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [openActOfAcceptancePreview, setOpenActOfAcceptancePreview] = useState(false);

  return loading ? (
    <CircularProgress />
  ) : (
    <Container maxWidth="xl" className={classes.container}>
      <Grid item lg={12}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            setTitle(actOfAcceptance!.number);
            setUrl(getActOfAcceptanceUrl(actOfAcceptance!.id));
            setOpenActOfAcceptancePreview(true);
          }}
        >
          <Typography component="h5" variant="h5">
            {id && actOfAcceptance!.number ? actOfAcceptance!.number : ''}
          </Typography>
        </Button>
      </Grid>
      <Paper elevation={2} className={classes.root}>
        <OutlinedDiv label="Данни за протокола" disabled={true}>
          <Grid container spacing={1}>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <AMSViewOnlyTextField label="Дата на създаване" value={actOfAcceptance!.createdAt} />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <AMSViewOnlyTextField label="Компания" value={actOfAcceptance!.companyName} />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <AMSViewOnlyTextField label="Получатели" value={actOfAcceptance!.receiverName} />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <AMSViewOnlyTextField
                label="Юридическо лице"
                value={actOfAcceptance!.receiverLegalEntityName ?? ''}
              />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <AMSViewOnlyTextField label="Потребител" value={actOfAcceptance!.createdByName} />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <AMSViewOnlyTextField
                label="Тип на поръчката"
                value={getOrderTypeLabelById(actOfAcceptance!.orderType)}
              />
            </Grid>
          </Grid>
        </OutlinedDiv>
      </Paper>
      <Grid item sm={12} lg={12}>
        <ActOfAcceptanceItemsPanel id={id} />
      </Grid>
      <AMSPdfViewerDialog
        title={title}
        url={url}
        open={openActOfAcceptancePreview}
        onClose={() => setOpenActOfAcceptancePreview(false)}
      />
    </Container>
  );
};

export default ActOfAcceptanceComponent;
