import * as H from 'history';

import { AMSAsyncAutocomplete, AMSAutocomplete, AMSButton, AMSTable } from '../../helpers/ui';
import { Checkbox, Grid, TextField, Typography } from '@material-ui/core';
import Lookup, { partnerToLookup } from '../../models/lookup';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { cookiesService, promotionsService, snackbarService } from '../../services/services';
import { ignoreOffset, isValidDate, toDateString } from '../../helpers/date-helper';
import {
  useFlag,
  usePartners,
  usePermissions,
  usePromotions,
  useStyles
} from '../../helpers/hooks';

import AMSLink from '../../helpers/ui/AMSLink/AMSLink';
import { AccessPermissions } from '../../services/accessManagementService';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import EditQuantityPromotionDialog from './EditQuantityPromotionDialog/EditQuantityPromotionDialog';
import { ItemResponse } from '../../services/api-v3';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { PartnerBonusQuantityResponse } from '../../services/api';
import QuantityPromotionDialog from '../Promotions/QuantityPromotionDialog/QuantityPromotionDialog';
import moment from 'moment';
import { parseFilter } from '../../helpers/url';
import { useHistory } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type PromoOptionKey = 'all' | 'active' | 'upcoming' | 'passed';

interface PromoOption {
  key: PromoOptionKey;
  label: string;
  action: () => void;
}

interface PromotionsComponentProps {
  location: H.Location;
}

const PromotionsComponent = ({ location }: PromotionsComponentProps) => {
  const history = useHistory();
  const classes = useStyles();

  const { fromStartDate, toStartDate, fromEndDate, toEndDate } = useMemo(
    () => parseFilter(location.search),
    [location.search]
  );
  const filter = cookiesService.getPromotionsList();
  const { promotions, setPromotions, setParameters, loading } = usePromotions();
  const [selectedPromotion, setSelectedPromotion] = useState<PartnerBonusQuantityResponse | null>(
    null
  );
  const [openCreateDialog, setOpenCreateDialog] = useFlag(false);
  const [openEditDialog, setOpenEditDialog] = useFlag(false);
  const [promoOptionKey, setPromoOptionKey] = useState<PromoOptionKey | null>(null);

  const [canCreate, canUpdate, canDelete] = usePermissions([
    AccessPermissions.CAN_CREATE_PROMOTION,
    AccessPermissions.CAN_UPDATE_PROMOTION,
    AccessPermissions.CAN_DELETE_PROMOTION
  ]);

  const handleOnSave = useCallback(
    (newPromotions: PartnerBonusQuantityResponse[]) => {
      setPromotions([...promotions, ...newPromotions]);
      setOpenCreateDialog(false);
      snackbarService.setSnackbarOpen(true);
    },
    [promotions, setPromotions, setOpenCreateDialog]
  );

  const handleAdd = useCallback(() => {
    setOpenCreateDialog(true);
  }, [setOpenCreateDialog]);

  const handleEdit = useCallback(
    (promotion: PartnerBonusQuantityResponse) => {
      setSelectedPromotion(promotion);
      setOpenEditDialog(true);
    },
    [setOpenEditDialog]
  );

  const handleUpdate = useCallback(
    (promotion: PartnerBonusQuantityResponse) => {
      setPromotions(promotions.map((p) => (p.id === promotion.id ? promotion : p)));
      setOpenEditDialog(false);
      setSelectedPromotion(null);
      snackbarService.setSnackbarOpen(true);
    },
    [promotions, setPromotions, setOpenEditDialog]
  );

  const handleDelete = useCallback(
    async (promotion: PartnerBonusQuantityResponse) => {
      await promotionsService.deletePromotion(promotion.id);
      setPromotions(promotions.filter((p) => p.id !== promotion.id));
      snackbarService.setSnackbarOpen(true);
    },
    [promotions, setPromotions]
  );

  const { partners } = usePartners();
  const [item, setItem] = useState<ItemResponse | null>(null);
  const [filterPartners, setFilterPartners] = useState<Lookup[]>([]);
  const [filterFromStartDate, setFilterFromStartDate] = useState<Date | null>(
    fromStartDate
      ? fromStartDate
      : filter.filterFromStartDate
      ? ignoreOffset(new Date(filter.filterFromStartDate))
      : null
  );
  const [filterToStartDate, setFilterToStartDate] = useState<Date | null>(
    toStartDate
      ? toStartDate
      : filter.filterToStartDate
      ? ignoreOffset(new Date(filter.filterToStartDate))
      : null
  );
  const [filterFromEndDate, setFilterFromEndDate] = useState<Date | null>(
    fromEndDate
      ? fromEndDate
      : filter.filterFromEndDate
      ? ignoreOffset(new Date(filter.filterFromEndDate))
      : null
  );
  const [filterToEndDate, setFilterToEndDate] = useState<Date | null>(
    toEndDate
      ? toEndDate
      : filter.filterToEndDate
      ? ignoreOffset(new Date(filter.filterToEndDate))
      : null
  );

  const onSearch = useCallback(() => {
    const fromStartDate =
      filterFromStartDate !== null || isValidDate(filterFromStartDate)
        ? toDateString(filterFromStartDate!)
        : undefined;
    const toStartDate =
      filterToStartDate !== null || isValidDate(filterToStartDate)
        ? toDateString(filterToStartDate!)
        : undefined;
    const fromEndDate =
      filterFromEndDate !== null || isValidDate(filterFromEndDate)
        ? toDateString(filterFromEndDate!)
        : undefined;
    const toEndDate =
      filterToEndDate !== null || isValidDate(filterToEndDate)
        ? toDateString(filterToEndDate!)
        : undefined;
    cookiesService.setInternalTransfersList({
      filterFromStartDate: fromStartDate,
      filterToStartDate: toStartDate,
      filterFromEndDate: fromEndDate,
      filterToEndDate: toEndDate
    });
    let params: any = {};
    if (filterPartners?.length > 0) {
      params.partnerIds = filterPartners.map((p) => p.id).join(',');
    }
    if (filterFromStartDate) {
      params.filterFromStartDate = toDateString(filterFromStartDate);
    }
    if (filterToStartDate) {
      params.filterToStartDate = toDateString(filterToStartDate);
    }
    if (filterFromEndDate) {
      params.filterFromEndDate = toDateString(filterFromEndDate);
    }
    if (filterToEndDate) {
      params.filterToEndDate = toDateString(filterToEndDate);
    }
    history.push({
      pathname: '/promotions',
      search: new URLSearchParams(params).toString()
    });
    setParameters([
      item?.id,
      filterPartners.map((p) => p.id),
      filterFromStartDate,
      filterToStartDate,
      filterFromEndDate,
      filterToEndDate
    ]);
  }, [
    history,
    setParameters,
    item,
    filterPartners,
    filterFromStartDate,
    filterToStartDate,
    filterFromEndDate,
    filterToEndDate
  ]);

  const promoOptions: PromoOption[] = useMemo(() => {
    const today = new Date(moment().format('YYYY-MM-DD'));
    const tomorrow = new Date(moment().add(1, 'days').format('YYYY-MM-DD'));
    const yesterday = new Date(moment().subtract(1, 'days').format('YYYY-MM-DD'));
    return [
      {
        key: 'all',
        label: 'Всички',
        action: () => {
          setPromoOptionKey('all');
          setFilterFromEndDate(null);
          setFilterFromStartDate(null);
          setFilterToEndDate(null);
          setFilterToStartDate(null);
        }
      },
      {
        key: 'active',
        label: 'Активни',
        action: () => {
          setPromoOptionKey('active');
          setFilterFromEndDate(today);
          setFilterFromStartDate(null);
          setFilterToEndDate(null);
          setFilterToStartDate(today);
        }
      },
      {
        key: 'upcoming',
        label: 'Предстоящи',
        action: () => {
          setPromoOptionKey('upcoming');
          setFilterFromEndDate(null);
          setFilterFromStartDate(tomorrow);
          setFilterToEndDate(null);
          setFilterToStartDate(null);
        }
      },
      {
        key: 'passed',
        label: 'Отминали',
        action: () => {
          setPromoOptionKey('passed');
          setFilterFromEndDate(null);
          setFilterFromStartDate(null);
          setFilterToEndDate(yesterday);
          setFilterToStartDate(null);
        }
      }
    ];
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <KeyboardDatePicker
            label="От дата на старта"
            value={filterFromStartDate}
            onChange={(value: Date | null) => {
              if (value) {
                if (isValidDate(value)) {
                  setFilterFromStartDate(ignoreOffset(value));
                }
              } else {
                setFilterFromStartDate(null);
              }
              setPromoOptionKey(null);
            }}
            maxDate={filterToStartDate ?? undefined}
            disableToolbar
            autoOk={true}
            variant="inline"
            format="dd/MM/yy"
            margin="dense"
            helperText={''}
            inputVariant="outlined"
            fullWidth
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <KeyboardDatePicker
            label="До дата на старта"
            value={filterToStartDate}
            onChange={(value: Date | null) => {
              if (value) {
                if (isValidDate(value)) {
                  setFilterToStartDate(ignoreOffset(value));
                }
              } else {
                setFilterToStartDate(null);
              }
              setPromoOptionKey(null);
            }}
            minDate={filterFromStartDate ?? undefined}
            disableToolbar
            autoOk={true}
            variant="inline"
            format="dd/MM/yy"
            margin="dense"
            helperText={''}
            inputVariant="outlined"
            fullWidth
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <KeyboardDatePicker
            label="От дата на край"
            value={filterFromEndDate}
            onChange={(value: Date | null) => {
              if (value) {
                if (isValidDate(value)) {
                  setFilterFromEndDate(ignoreOffset(value));
                }
              } else {
                setFilterFromEndDate(null);
              }
              setPromoOptionKey(null);
            }}
            maxDate={filterToEndDate ?? undefined}
            disableToolbar
            autoOk={true}
            variant="inline"
            format="dd/MM/yy"
            margin="dense"
            helperText={''}
            inputVariant="outlined"
            fullWidth
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <KeyboardDatePicker
            label="До дата на край"
            value={filterToEndDate}
            onChange={(value: Date | null) => {
              if (value) {
                if (isValidDate(value)) {
                  setFilterToEndDate(ignoreOffset(value));
                }
              } else {
                setFilterToEndDate(null);
              }
              setPromoOptionKey(null);
            }}
            minDate={filterFromEndDate ?? undefined}
            disableToolbar
            autoOk={true}
            variant="inline"
            format="dd/MM/yy"
            margin="dense"
            helperText={''}
            inputVariant="outlined"
            fullWidth
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <AMSAutocomplete
            multiple
            minChar={0}
            limitTags={10}
            options={partners.map(partnerToLookup)}
            disableCloseOnSelect
            renderOption={(option, { selected }) => (
              <Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  className={classes.checkbox}
                  checked={selected}
                  color="primary"
                />
                {option.value}
              </Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Контрагенти"
                placeholder=""
                margin="dense"
                fullWidth
              />
            )}
            value={filterPartners}
            onChange={setFilterPartners}
            groupBy={(g) => (g.group ? g.group : '')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <AMSAsyncAutocomplete
            label="Артикул"
            value={item}
            onChange={(value: ItemResponse | null) => {
              setItem(value);
            }}
            getItemLabel={(item: any) => `${item.artNo} ${item.name}`}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <Grid container>
            {promoOptions.map((option: PromoOption) => (
              <Grid key={option.key} item xs={12} sm={6} md={6}>
                <Typography
                  onClick={option.action}
                  style={{
                    cursor: 'pointer',
                    textDecoration: promoOptionKey === option.key ? 'underline' : undefined,
                    fontWeight: promoOptionKey === option.key ? 'bold' : undefined
                  }}
                >
                  {option.label}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <AMSButton
            color="primary"
            variant="contained"
            text="Търсене"
            loading={loading}
            disabled={false}
            onClick={onSearch}
            style={{
              marginTop: 8
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AMSTable
            columns={[
              {
                title: 'Артикул',
                field: 'itemName',
                cellStyle: { width: '35%' },
                render: (rowData: any) => (
                  <AMSLink href={`/item?id=${rowData.itemId}`}>{rowData.itemName}</AMSLink>
                )
              },
              {
                title: 'Контрагент',
                field: 'partnerName',
                cellStyle: { width: '35%' },
                render: (rowData: any) => (
                  <AMSLink href={`/partner?id=${rowData.partnerId}`}>{rowData.partnerName}</AMSLink>
                )
              },
              {
                title: 'Основа',
                field: 'baseQuantity',
                cellStyle: { width: '5%' }
              },
              {
                title: 'Бонус',
                field: 'bonusQuantity',
                cellStyle: { width: '5%' }
              },
              {
                title: 'От',
                field: 'startDate',
                type: 'date',
                cellStyle: { width: '10%' }
              },
              {
                title: 'До',
                field: 'endDate',
                type: 'date',
                cellStyle: { width: '10%' }
              }
            ]}
            data={promotions}
            onAdd={canCreate ? handleAdd : undefined}
            onEdit={canUpdate ? handleEdit : undefined}
            onDelete={canDelete ? handleDelete : undefined}
          />
          <QuantityPromotionDialog
            open={openCreateDialog}
            onSave={handleOnSave}
            onClose={() => setOpenCreateDialog(false)}
          />
          {selectedPromotion && (
            <EditQuantityPromotionDialog
              promotion={selectedPromotion}
              open={openEditDialog}
              onSave={handleUpdate}
              onClose={() => setOpenEditDialog(false)}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default PromotionsComponent;
