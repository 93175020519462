import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  createStyles,
  makeStyles
} from '@material-ui/core';
import {
  CreateTransactionRequest,
  TransactionListResponse,
  TreasuryListResponse
} from '../../../services/api-v3';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TransactionType, getTransactionName } from '../../../services/AMSV3Service';
import { accessManagementService, amsV3Service } from '../../../services/services';
import { ignoreOffset, isValidDate } from '../../../helpers/date-helper';

import AMSButton from '../../../helpers/ui/AMSButton/AMSButton';
import AMSLink from '../../../helpers/ui/AMSLink/AMSLink';
import AMSWidget from '../../../helpers/ui/AMSWidget/AMSWidget';
import { AccessPermissions } from '../../../services/accessManagementService';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import AccountBalanceWalletTwoToneIcon from '@material-ui/icons/AccountBalanceWalletTwoTone';
import AddTransactionDialog from '../../OrderComponent/Panels/OrderInvoiceAndTransactionsPanel/Dialog/AddTransactionDialog';
import DateFnsUtils from '@date-io/date-fns';
import MoneyTwoToneIcon from '@material-ui/icons/MoneyTwoTone';
import PaymentTwoToneIcon from '@material-ui/icons/PaymentTwoTone';
import { useTransactions } from '../../../helpers/hooks';

const useStyles = makeStyles(() =>
  createStyles({
    tableBody: {
      minHeight: '30vh !important',
      maxHeight: '30vh !important',
      overflow: 'auto'
    },
    selectedTreasury: {
      backgroundColor: '#3F51B530'
    },
    fullWidth: {
      width: '100%'
    },
    checkbox: {
      marginRight: 8
    },
    treasuryHeaderCell: {
      paddingLeft: 24
    },
    expense: {
      borderLeft: '8px solid #F03901',
      color: '#F03901'
    },
    income: {
      borderLeft: '8px solid #93AB3B',
      color: '#93AB3B'
    },
    saveButton: {
      marginTop: 10,
      marginLeft: 10
    }
  })
);

interface FinanceTransactionsWidgetComponentProps {
  treasuries: TreasuryListResponse[];
  selected: any;
  loading: boolean;
  reloadForm: () => void;
}

const FinanceTransactionsWidgetComponent = ({
  treasuries,
  selected,
  loading,
  reloadForm
}: FinanceTransactionsWidgetComponentProps) => {
  const classes = useStyles();
  const [filterFromDate, setFilterFromDate] = useState<Date | null>(
    new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [filterToDate, setFilterToDate] = useState<Date | null>(new Date());

  const [openTransactionDialog, setOpenTransactionDialog] = useState<boolean>(false);
  const { transactions, setParameters } = useTransactions(
    undefined,
    undefined,
    Object.keys(selected)
      .filter((key: string) => selected[key])
      .map((key: string) => parseInt(key)),
    filterFromDate,
    filterToDate
  );

  const canCreate = useMemo(
    () => accessManagementService.hasPermission(AccessPermissions.CAN_CREATE_TRANSACTION),
    []
  );

  const treasuryNameById = useMemo(() => {
    if (!treasuries) return {};
    return treasuries.reduce(
      (nameById: { [treasuryId: number]: string }, treasury: TreasuryListResponse) => {
        nameById[treasury.id] = treasury.name;
        return nameById;
      },
      {}
    );
  }, [treasuries]);

  const reload = useCallback(() => {
    setParameters([
      undefined,
      undefined,
      Object.keys(selected)
        .filter((key: string) => selected[key])
        .map((key: string) => parseInt(key)),
      filterFromDate,
      filterToDate
    ]);
  }, [selected, filterFromDate, filterToDate, setParameters]);

  useEffect(() => {
    reload();
  }, [reload]);

  return (
    <AMSWidget title={<Typography>{`Трансакции`}</Typography>}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={1} className={classes.fullWidth}>
          <Grid item lg={3}>
            <KeyboardDatePicker
              disableToolbar
              autoOk={true}
              variant="inline"
              format="dd/MM/yy"
              margin="dense"
              label="От"
              helperText={''}
              value={filterFromDate ? filterFromDate : null}
              onChange={(value: Date | null) => {
                if (value) {
                  if (isValidDate(value)) {
                    setFilterFromDate(ignoreOffset(value));
                  }
                } else {
                  setFilterFromDate(null);
                }
              }}
              inputVariant="outlined"
              fullWidth
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              maxDate={filterToDate}
            />
          </Grid>
          <Grid item lg={3}>
            <KeyboardDatePicker
              disableToolbar
              autoOk={true}
              variant="inline"
              format="dd/MM/yy"
              margin="dense"
              label="До"
              helperText={''}
              value={filterToDate ? filterToDate : null}
              onChange={(value: Date | null) => {
                if (value) {
                  if (isValidDate(value)) {
                    setFilterToDate(ignoreOffset(value));
                  }
                } else {
                  setFilterToDate(null);
                }
              }}
              inputVariant="outlined"
              fullWidth
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              minDate={filterFromDate}
              maxDate={new Date()}
            />
          </Grid>
          <Grid item lg={2}>
            {canCreate && (
              <AMSButton
                variant="contained"
                color="primary"
                disabled={false}
                className={classes.saveButton}
                onClick={() => setOpenTransactionDialog(true)}
                text={'Добави'}
                loading={false}
              />
            )}
          </Grid>
          <Grid item lg={12}>
            {loading ? (
              <CircularProgress className={classes.tableBody} />
            ) : (
              <TableContainer className={classes.tableBody}>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell width="8%" className={classes.treasuryHeaderCell}>
                        Тип
                      </TableCell>
                      <TableCell width="47%">Каса</TableCell>
                      <TableCell width="15%">Дата/Час</TableCell>
                      <TableCell width="15%">Основание</TableCell>
                      <TableCell width="15%">Сума</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {transactions?.map((transaction: TransactionListResponse) => {
                      let info;
                      if (transaction.orderInvoiceId) {
                        info = (
                          <AMSLink href={`/invoice?id=${transaction.orderInvoiceId}`}>
                            {transaction.displayInfo}
                          </AMSLink>
                        );
                      }
                      if (transaction.deliveryInvoiceId) {
                        const deliveryId = parseInt(
                          transaction.displayInfo?.split(' / ')[0].replace('ДК', '') ?? ''
                        );
                        info = (
                          <AMSLink href={`/delivery?id=${deliveryId}&tabId=1`}>
                            {transaction.displayInfo}
                          </AMSLink>
                        );
                      }
                      if (!transaction.deliveryInvoiceId && !transaction.orderInvoiceId) {
                        info = <Typography variant="body2">{transaction.displayInfo}</Typography>;
                      }
                      return (
                        <TableRow key={transaction.id}>
                          <TableCell
                            className={
                              transaction.transactionType ===
                                TransactionType.EXPENSE_BANK_ACCOUNT ||
                              transaction.transactionType === TransactionType.EXPENSE_CASH
                                ? classes.expense
                                : classes.income
                            }
                          >
                            {transaction.transactionType === TransactionType.BANK_ACCOUNT && (
                              <Tooltip title={getTransactionName(TransactionType.BANK_ACCOUNT)}>
                                <PaymentTwoToneIcon />
                              </Tooltip>
                            )}
                            {transaction.transactionType === TransactionType.CASH && (
                              <Tooltip title={getTransactionName(TransactionType.CASH)}>
                                <AccountBalanceWalletTwoToneIcon />
                              </Tooltip>
                            )}
                            {transaction.transactionType ===
                              TransactionType.EXPENSE_BANK_ACCOUNT && (
                              <Tooltip
                                title={getTransactionName(TransactionType.EXPENSE_BANK_ACCOUNT)}
                              >
                                <AccountBalanceTwoToneIcon />
                              </Tooltip>
                            )}
                            {transaction.transactionType === TransactionType.EXPENSE_CASH && (
                              <Tooltip title={getTransactionName(TransactionType.EXPENSE_CASH)}>
                                <MoneyTwoToneIcon />
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell>{treasuryNameById[transaction.treasuryId]}</TableCell>
                          <TableCell>
                            {transaction.date.substr(0, 10)} {transaction.date.substr(11, 8)}
                          </TableCell>
                          <TableCell>{info}</TableCell>
                          <TableCell>{transaction.amount} лв.</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
        {openTransactionDialog && (
          <AddTransactionDialog
            open={openTransactionDialog}
            onClose={() => setOpenTransactionDialog(false)}
            onSave={async (transaction: CreateTransactionRequest) => {
              delete transaction.deliveryInvoiceId;
              const resp = await amsV3Service.createTransaction(transaction);
              if (resp) {
                setOpenTransactionDialog(false);
                reload();
                reloadForm();
              }
            }}
          />
        )}
      </MuiPickersUtilsProvider>
    </AMSWidget>
  );
};

export default FinanceTransactionsWidgetComponent;
