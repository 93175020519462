import { AMSApi, AMSV3Api } from '../helpers/openapi-axios-helper';
import { CreateOrderV2Request, ReturnOrderItemV2Request, UpdateOrderV2Request } from './api';

import Lookup from '../models/lookup';

export enum OrderStatusIds {
  PRELIMINARY = 10,
  CANCELLED = 20,
  DELAYED = 30,
  DELAYED_DUE_TO_SUPPLIER = 31,
  DELAYED_DUE_TO_WAREHOUSE_STOCK = 32,
  FORECLOSURE = 35,
  FOR_PICKING = 40,
  PICKING_IN_PROGRESS = 45,
  FOR_CLARIFICATION = 50,
  CLARIFICATION_IN_PROGRESS = 55,
  FOR_INSPECTION = 60,
  INSPECTION_IN_PROGRESS = 65,
  FOR_SHIPPING = 70,
  SHIPPED = 75,
  RECEIVED_WITH_REMARKS = 77,
  DELIVERED = 78,
  AWAITING_PAYMENT = 79,
  FINISHED_SUCCESSFULLY = 80,
  FINISHED_UNSUCCESSFULLY = 85
}

export enum OrderStatusKeys {
  PRELIMINARY = 'PRELIMINARY',
  CANCELLED = 'CANCELLED',
  DELAYED = 'DELAYED',
  DELAYED_DUE_TO_WAREHOUSE_STOCK = 'DELAYED_DUE_TO_WAREHOUSE_STOCK',
  DELAYED_DUE_TO_SUPPLIER = 'DELAYED_DUE_TO_SUPPLIER',
  FORECLOSURE = 'FORECLOSURE',
  FOR_PICKING = 'FOR_PICKING',
  PICKING_IN_PROGRESS = 'PICKING_IN_PROGRESS',
  FOR_CLARIFICATION = 'FOR_CLARIFICATION',
  CLARIFICATION_IN_PROGRESS = 'CLARIFICATION_IN_PROGRESS',
  FOR_INSPECTION = 'FOR_INSPECTION',
  INSPECTION_IN_PROGRESS = 'INSPECTION_IN_PROGRESS',
  FOR_SHIPPING = 'FOR_SHIPPING',
  SHIPPED = 'SHIPPED',
  RECEIVED_WITH_REMARKS = 'RECEIVED_WITH_REMARKS',
  DELIVERED = 'DELIVERED',
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  FINISHED_SUCCESSFULLY = 'FINISHED_SUCCESSFULLY',
  FINISHED_UNSUCCESSFULLY = 'FINISHED_UNSUCCESSFULLY'
}

export enum OrderStatusLabels {
  PRELIMINARY = 'Предварителен',
  CANCELLED = 'Прекратена',
  DELAYED = 'Забавена',
  DELAYED_DUE_TO_WAREHOUSE_STOCK = 'Забавена от вътрешен склад',
  DELAYED_DUE_TO_SUPPLIER = 'Забавена заради доставчик',
  FORECLOSURE = 'Възбрана',
  FOR_PICKING = 'За пикиране',
  PICKING_IN_PROGRESS = 'В процес на пикиране',
  FOR_CLARIFICATION = 'За разяснение',
  CLARIFICATION_IN_PROGRESS = 'В процес на разясняване',
  FOR_INSPECTION = 'За проверка',
  INSPECTION_IN_PROGRESS = 'В процес на проверка',
  FOR_SHIPPING = 'За експедиране',
  SHIPPED = 'Експедирана',
  RECEIVED_WITH_REMARKS = 'Приета със забележки',
  DELIVERED = 'Доставена',
  AWAITING_PAYMENT = 'Очаква плащане',
  FINISHED_UNSUCCESSFULLY = 'Неуспешно приключена',
  FINISHED_SUCCESSFULLY = 'Успешно приключена'
}

export type OrderStatusId =
  | OrderStatusIds.PRELIMINARY
  | OrderStatusIds.CANCELLED
  | OrderStatusIds.DELAYED
  | OrderStatusIds.DELAYED_DUE_TO_WAREHOUSE_STOCK
  | OrderStatusIds.DELAYED_DUE_TO_SUPPLIER
  | OrderStatusIds.FORECLOSURE
  | OrderStatusIds.FOR_PICKING
  | OrderStatusIds.PICKING_IN_PROGRESS
  | OrderStatusIds.FOR_CLARIFICATION
  | OrderStatusIds.CLARIFICATION_IN_PROGRESS
  | OrderStatusIds.FOR_INSPECTION
  | OrderStatusIds.INSPECTION_IN_PROGRESS
  | OrderStatusIds.FOR_SHIPPING
  | OrderStatusIds.SHIPPED
  | OrderStatusIds.RECEIVED_WITH_REMARKS
  | OrderStatusIds.DELIVERED
  | OrderStatusIds.AWAITING_PAYMENT
  | OrderStatusIds.FINISHED_SUCCESSFULLY
  | OrderStatusIds.FINISHED_UNSUCCESSFULLY;

export type OrderStatusKey =
  | OrderStatusKeys.PRELIMINARY
  | OrderStatusKeys.CANCELLED
  | OrderStatusKeys.DELAYED
  | OrderStatusKeys.DELAYED_DUE_TO_WAREHOUSE_STOCK
  | OrderStatusKeys.DELAYED_DUE_TO_SUPPLIER
  | OrderStatusKeys.FORECLOSURE
  | OrderStatusKeys.FOR_PICKING
  | OrderStatusKeys.PICKING_IN_PROGRESS
  | OrderStatusKeys.FOR_CLARIFICATION
  | OrderStatusKeys.CLARIFICATION_IN_PROGRESS
  | OrderStatusKeys.FOR_INSPECTION
  | OrderStatusKeys.INSPECTION_IN_PROGRESS
  | OrderStatusKeys.FOR_SHIPPING
  | OrderStatusKeys.SHIPPED
  | OrderStatusKeys.RECEIVED_WITH_REMARKS
  | OrderStatusKeys.DELIVERED
  | OrderStatusKeys.AWAITING_PAYMENT
  | OrderStatusKeys.FINISHED_SUCCESSFULLY
  | OrderStatusKeys.FINISHED_UNSUCCESSFULLY;

export type OrderStatusLabel =
  | OrderStatusLabels.PRELIMINARY
  | OrderStatusLabels.CANCELLED
  | OrderStatusLabels.DELAYED
  | OrderStatusLabels.DELAYED_DUE_TO_WAREHOUSE_STOCK
  | OrderStatusLabels.DELAYED_DUE_TO_SUPPLIER
  | OrderStatusLabels.FORECLOSURE
  | OrderStatusLabels.FOR_PICKING
  | OrderStatusLabels.PICKING_IN_PROGRESS
  | OrderStatusLabels.FOR_CLARIFICATION
  | OrderStatusLabels.CLARIFICATION_IN_PROGRESS
  | OrderStatusLabels.FOR_INSPECTION
  | OrderStatusLabels.INSPECTION_IN_PROGRESS
  | OrderStatusLabels.FOR_SHIPPING
  | OrderStatusLabels.SHIPPED
  | OrderStatusLabels.RECEIVED_WITH_REMARKS
  | OrderStatusLabels.DELIVERED
  | OrderStatusLabels.AWAITING_PAYMENT
  | OrderStatusLabels.FINISHED_SUCCESSFULLY
  | OrderStatusLabels.FINISHED_UNSUCCESSFULLY;

export class OrderStatus {
  static readonly PRELIMINARY = new OrderStatus(
    OrderStatusIds.PRELIMINARY,
    OrderStatusKeys.PRELIMINARY,
    OrderStatusLabels.PRELIMINARY,
    '#52FFFF'
  );
  static readonly CANCELLED = new OrderStatus(
    OrderStatusIds.CANCELLED,
    OrderStatusKeys.CANCELLED,
    OrderStatusLabels.CANCELLED,
    '#FF3300'
  );
  static readonly DELAYED = new OrderStatus(
    OrderStatusIds.DELAYED,
    OrderStatusKeys.DELAYED,
    OrderStatusLabels.DELAYED,
    '#52A8FF'
  );
  static readonly DELAYED_DUE_TO_WAREHOUSE_STOCK = new OrderStatus(
    OrderStatusIds.DELAYED_DUE_TO_WAREHOUSE_STOCK,
    OrderStatusKeys.DELAYED_DUE_TO_WAREHOUSE_STOCK,
    OrderStatusLabels.DELAYED_DUE_TO_WAREHOUSE_STOCK,
    '#52A8FF'
  );
  static readonly DELAYED_DUE_TO_SUPPLIER = new OrderStatus(
    OrderStatusIds.DELAYED_DUE_TO_SUPPLIER,
    OrderStatusKeys.DELAYED_DUE_TO_SUPPLIER,
    OrderStatusLabels.DELAYED_DUE_TO_SUPPLIER,
    '#52A8FF'
  );
  static readonly FORECLOSURE = new OrderStatus(
    OrderStatusIds.FORECLOSURE,
    OrderStatusKeys.FORECLOSURE,
    OrderStatusLabels.FORECLOSURE,
    '#FF3300'
  );
  static readonly FOR_PICKING = new OrderStatus(
    OrderStatusIds.FOR_PICKING,
    OrderStatusKeys.FOR_PICKING,
    OrderStatusLabels.FOR_PICKING,
    '#FFD452'
  );
  static readonly PICKING_IN_PROGRESS = new OrderStatus(
    OrderStatusIds.PICKING_IN_PROGRESS,
    OrderStatusKeys.PICKING_IN_PROGRESS,
    OrderStatusLabels.PICKING_IN_PROGRESS,
    '#FFD452'
  );
  static readonly FOR_CLARIFICATION = new OrderStatus(
    OrderStatusIds.FOR_CLARIFICATION,
    OrderStatusKeys.FOR_CLARIFICATION,
    OrderStatusLabels.FOR_CLARIFICATION,
    '#00CC33'
  );
  static readonly CLARIFICATION_IN_PROGRESS = new OrderStatus(
    OrderStatusIds.CLARIFICATION_IN_PROGRESS,
    OrderStatusKeys.CLARIFICATION_IN_PROGRESS,
    OrderStatusLabels.CLARIFICATION_IN_PROGRESS,
    '#00CC33'
  );
  static readonly FOR_INSPECTION = new OrderStatus(
    OrderStatusIds.FOR_INSPECTION,
    OrderStatusKeys.FOR_INSPECTION,
    OrderStatusLabels.FOR_INSPECTION,
    '#00CC00'
  );
  static readonly INSPECTION_IN_PROGRESS = new OrderStatus(
    OrderStatusIds.INSPECTION_IN_PROGRESS,
    OrderStatusKeys.INSPECTION_IN_PROGRESS,
    OrderStatusLabels.INSPECTION_IN_PROGRESS,
    '#00CC00'
  );
  static readonly FOR_SHIPPING = new OrderStatus(
    OrderStatusIds.FOR_SHIPPING,
    OrderStatusKeys.FOR_SHIPPING,
    OrderStatusLabels.FOR_SHIPPING,
    '#00CC66'
  );
  static readonly SHIPPED = new OrderStatus(
    OrderStatusIds.SHIPPED,
    OrderStatusKeys.SHIPPED,
    OrderStatusLabels.SHIPPED,
    '#00CC66'
  );
  static readonly RECEIVED_WITH_REMARKS = new OrderStatus(
    OrderStatusIds.RECEIVED_WITH_REMARKS,
    OrderStatusKeys.RECEIVED_WITH_REMARKS,
    OrderStatusLabels.RECEIVED_WITH_REMARKS,
    '#00BB00'
  );
  static readonly DELIVERED = new OrderStatus(
    OrderStatusIds.DELIVERED,
    OrderStatusKeys.DELIVERED,
    OrderStatusLabels.DELIVERED,
    '#00BB00'
  );
  static readonly AWAITING_PAYMENT = new OrderStatus(
    OrderStatusIds.AWAITING_PAYMENT,
    OrderStatusKeys.AWAITING_PAYMENT,
    OrderStatusLabels.AWAITING_PAYMENT,
    '#00АА00'
  );
  static readonly FINISHED_SUCCESSFULLY = new OrderStatus(
    OrderStatusIds.FINISHED_SUCCESSFULLY,
    OrderStatusKeys.FINISHED_SUCCESSFULLY,
    OrderStatusLabels.FINISHED_SUCCESSFULLY,
    '#008800'
  );
  static readonly FINISHED_UNSUCCESSFULLY = new OrderStatus(
    OrderStatusIds.FINISHED_UNSUCCESSFULLY,
    OrderStatusKeys.FINISHED_UNSUCCESSFULLY,
    OrderStatusLabels.FINISHED_UNSUCCESSFULLY,
    '#FF3300'
  );

  private constructor(
    public readonly id: OrderStatusId,
    public readonly key: OrderStatusKey,
    public readonly label: OrderStatusLabel,
    public readonly color: string
  ) {}

  toString() {
    return this.key + ' ' + this.label;
  }
}

export const orderStatuses: OrderStatus[] = [
  OrderStatus.PRELIMINARY,
  OrderStatus.CANCELLED,
  OrderStatus.DELAYED,
  OrderStatus.DELAYED_DUE_TO_WAREHOUSE_STOCK,
  OrderStatus.DELAYED_DUE_TO_SUPPLIER,
  OrderStatus.FORECLOSURE,
  OrderStatus.FOR_PICKING,
  OrderStatus.PICKING_IN_PROGRESS,
  OrderStatus.FOR_CLARIFICATION,
  OrderStatus.CLARIFICATION_IN_PROGRESS,
  OrderStatus.FOR_INSPECTION,
  OrderStatus.INSPECTION_IN_PROGRESS,
  OrderStatus.FOR_SHIPPING,
  OrderStatus.SHIPPED,
  OrderStatus.RECEIVED_WITH_REMARKS,
  OrderStatus.DELIVERED,
  OrderStatus.AWAITING_PAYMENT,
  OrderStatus.FINISHED_SUCCESSFULLY,
  OrderStatus.FINISHED_UNSUCCESSFULLY
];

const invalidStatus = {
  id: 0 as OrderStatusId,
  key: 'INVALID_STATUS' as OrderStatusKey,
  label: '<НЕВАЛИДЕН СТАТУС>',
  color: '#BBBBBB'
};

const invalidType = {
  id: 0 as OrderTypeId,
  key: 'INVALID_TYPE' as OrderTypeKey,
  label: '<НЕВАЛИДЕН ТИП>'
};

const getOrderStatusById = (id: OrderStatusId) =>
  orderStatuses.find((status: OrderStatus) => status.id === id) || invalidStatus;

export const getOrderStatusByKey = (key: OrderStatusKey) =>
  orderStatuses.find((status: OrderStatus) => status.key === key) || invalidStatus;

export const getOrderTypeById = (id: OrderTypeId) =>
  orderTypes.find((type: OrderType) => type.id === id) || invalidType;

export const getOrderStatusKeyById = (id: OrderStatusId) => getOrderStatusById(id).key;

export const getOrderStatusIdByKey = (key: OrderStatusKey) => getOrderStatusByKey(key).id;

export const getOrderStatusLabelByKey = (key: OrderStatusKey) => getOrderStatusByKey(key).label;

export const getOrderTypeLabelById = (id: OrderTypeId) => getOrderTypeById(id).label;

export enum OrderTypeIds {
  INTERNAL = 0,
  B2B = 1,
  B2C = 2
}

export enum OrderTypeKeys {
  INTERNAL = 'INTERNAL',
  B2B = 'EXTERNAL_B2B',
  B2C = 'EXTERNAL_B2C'
}

export enum OrderTypeLabels {
  INTERNAL = 'Вътрешен трансфер',
  B2B = 'B2B поръчка',
  B2C = 'B2C поръчка'
}

export enum OrderTypes {
  INTERNAL = 'INTERNAL',
  EXTERNAL_B2B = 'EXTERNAL_B2B',
  EXTERNAL_B2C = 'EXTERNAL_B2C'
}

export type OrderTypeId = OrderTypeIds.INTERNAL | OrderTypeIds.B2B | OrderTypeIds.B2C;

export type OrderTypeKey = OrderTypeKeys.INTERNAL | OrderTypeKeys.B2B | OrderTypeKeys.B2C;

export type OrderTypeLabel = OrderTypeLabels.INTERNAL | OrderTypeLabels.B2B | OrderTypeLabels.B2C;

export class OrderType {
  static readonly INTERNAL = new OrderType(
    OrderTypeIds.INTERNAL,
    OrderTypeKeys.INTERNAL,
    OrderTypeLabels.INTERNAL
  );
  static readonly B2B = new OrderType(OrderTypeIds.B2B, OrderTypeKeys.B2B, OrderTypeLabels.B2B);
  static readonly B2C = new OrderType(OrderTypeIds.B2C, OrderTypeKeys.B2C, OrderTypeLabels.B2C);

  private constructor(
    public readonly id: OrderTypeId,
    public readonly key: OrderTypeKey,
    public readonly label: OrderTypeLabel
  ) {}

  toString() {
    return this.key + ' ' + this.label;
  }
}

export const orderTypes: OrderType[] = [OrderType.INTERNAL, OrderType.B2B, OrderType.B2C];

export const orderTypeLookups: Lookup[] = [
  { id: OrderTypeIds.INTERNAL, value: OrderTypeLabels.INTERNAL },
  { id: OrderTypeIds.B2B, value: OrderTypeLabels.B2B },
  { id: OrderTypeIds.B2C, value: OrderTypeLabels.B2C }
];

export const documentTypeAliasMap = [
  'ПРИЕМО-ПРЕДАВАТЕЛЕН ПРОТОКОЛ',
  'ФАКТУРА',
  'КРЕДИТНО ИЗВЕСТИЕ'
];

export enum DocumentTypes {
  ACT_OF_ACCEPTANCE = 0,
  INVOICE = 1,
  CREDIT_NOTE = 2
}

export class OrdersService {
  createOrder = async (order: CreateOrderV2Request) => {
    const axios = await AMSApi();
    return await axios.createOrderV2OrdersPost(order);
  };

  getOrders = async (
    statusKeys?: string[],
    companyIds?: number[],
    warehouseIds?: number[],
    fromDate?: string,
    toDate?: string,
    noInvoice: boolean = false,
    withExecutionPercentage: boolean = false,
    withItems: boolean = false
  ) => {
    const axios = await AMSApi();
    return await axios.listOrdersV2OrdersGet(
      statusKeys?.join(','),
      companyIds?.join(','),
      warehouseIds?.join(','),
      fromDate,
      toDate,
      noInvoice,
      withExecutionPercentage,
      withItems
    );
  };

  getB2COrders = async (
    statusKeys?: string[],
    companyIds?: number[],
    warehouseIds?: number[],
    receiverIds?: number[],
    legalEntitiyIds?: number[],
    fromDeliveryDate?: string,
    toDeliveryDate?: string,
    withExecutionPercentage: boolean = false
  ) => {
    const axios = await AMSV3Api();
    return await axios.getB2cOrdersOrdersB2cOrdersGet(
      [],
      statusKeys,
      companyIds,
      warehouseIds,
      receiverIds,
      legalEntitiyIds,
      fromDeliveryDate,
      toDeliveryDate,
      1,
      10000
    );
  };

  getInternalOrders = async (
    statusKeys?: string[],
    companyIds?: number[],
    warehouseIds?: number[],
    receiverIds?: number[],
    fromDeliveryDate?: string,
    toDeliveryDate?: string
  ) => {
    const axios = await AMSV3Api();
    return await axios.getInternalTransfersOrdersInternalTransfersGet(
      [],
      statusKeys,
      companyIds,
      warehouseIds,
      receiverIds,
      fromDeliveryDate,
      toDeliveryDate,
      1,
      10000
    );
  };

  getOrdersWithExecutionPercentage = async (companyIds?: number[], warehouseIds?: number[]) => {
    const axios = await AMSApi();
    return await axios.listOrdersV2OrdersGet(
      undefined,
      companyIds?.join(','),
      warehouseIds?.join(','),
      undefined,
      undefined,
      undefined,
      true
    );
  };

  getOrder = async (id: number, withLivePrices?: boolean) => {
    const axios = await AMSApi();
    return await axios.readOrderByOrderIdV2OrdersOrderIdGet(id, withLivePrices);
  };

  getOrderDocuments = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readOrderDocumentsV2OrdersOrderIdDocumentsGet(id);
  };

  getOrderDocumentTypeAlias = (id: number) => documentTypeAliasMap[id];

  updateOrder = async (id: number, order: UpdateOrderV2Request) => {
    const axios = await AMSApi();
    return await axios.updateOrderV2OrdersOrderIdPatch(id, order);
  };

  updateOrderStatus = async (orderId: number, statusKey: OrderStatusKeys) => {
    const axios = await AMSApi();
    return await axios.updateOrderStatusV2OrdersStatusPut({ orderId, statusKey });
  };

  returnItemsForOrder = async (orderId: number, requests: ReturnOrderItemV2Request[]) => {
    const axios = await AMSApi();
    return await axios.returnItemsV2OrdersOrderIdReturnItemsPut(orderId, requests);
  };

  cancelOrderInvoice = async (orderId: number) => {
    const axios = await AMSApi();
    return await axios.cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete(orderId);
  };

  getOrderStatusLogs = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readOrderDocumentsV2OrdersOrderIdStatusLogsGet(id);
  };
}
