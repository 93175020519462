import MaterialTable, { Action, Icons } from 'material-table';
import { Paper, createStyles, makeStyles } from '@material-ui/core';
import React, { forwardRef } from 'react';

import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useWindowDimensions } from '../../hooks';

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      color: '#252525 !important'
    },
    paper: {
      padding: 20
    }
  })
);

interface AMSTableProps {
  title?: string | React.ReactElement<any>;
  columns: any[];
  data?: any[];
  onAdd?: (value?: any) => void;
  onView?: (value: any) => void;
  onViewText?: string;
  onEdit?: (value: any, oldValue?: any) => void;
  onDelete?: (value: any) => void;
  onCopy?: (value: any) => void;
  onCopyText?: string;
  copyIcon?: any;
  options?: any;
  inlineActions?: boolean;
  pageSize?: number;
  paging?: boolean;
  autoPageSize?: boolean;
  search?: boolean;
  components?: any;
  isLoading?: boolean;
  onSearchChange?: (searchText: string) => void;
  overflowY?: 'visible' | 'hidden' | 'scroll' | 'auto' | 'initial' | 'inherit';
  maxBodyHeight?: number | string;
  minBodyHeight?: number | string;
  addRowPosition?: 'first' | 'last';
  exportButton?: boolean | { csv?: boolean; pdf?: boolean };
  actions?: (Action<any> | ((rowData: any) => Action<any>))[];
  deleteText?: string;
  deleteTooltip?: string;
  deleteIcon?: any;
  isDeletable?: (row: any) => boolean;
  parentChildData?: (row: any, rows: any) => any;
  customAction?: Action<any> | ((rowData: any) => Action<any>);
  rowStyle?:
    | React.CSSProperties
    | ((data: any, index: number, level: number) => React.CSSProperties);
  selection?: boolean;
  onSelectionChange?: (data: any[], rowData?: any) => void;
}

const AMSTable = (props: AMSTableProps) => {
  const {
    title,
    columns,
    data = [],
    onAdd,
    onView,
    onViewText,
    onEdit,
    onDelete,
    onCopy,
    onCopyText,
    copyIcon,
    inlineActions,
    pageSize = 12,
    paging = true,
    autoPageSize = false,
    search = true,
    components,
    isLoading = false,
    onSearchChange,
    overflowY,
    maxBodyHeight,
    minBodyHeight,
    addRowPosition = 'last',
    exportButton = { csv: true },
    actions = [],
    deleteText = 'Наистина ли искате да изтриете този запис?',
    deleteTooltip = 'Изтриване',
    deleteIcon,
    isDeletable,
    parentChildData,
    customAction,
    rowStyle,
    selection,
    onSelectionChange
  } = props;

  const classes = useStyles();

  const { height, width } = useWindowDimensions();

  let ps = 12;
  if (height <= 1080 || width <= 1920) {
    ps = 10;
  }
  if (height <= 768 || width <= 1366) {
    ps = 8;
  }

  const editable: any = {};

  if (onAdd) {
    if (inlineActions) {
      editable.onRowAdd = (newData: any) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            if (onAdd) {
              onAdd(newData);
            }
            resolve(newData);
          }, 1000);
        });
    } else {
      actions.push({
        icon: () => <AddCircleOutlinedIcon className={classes.icon} />,
        tooltip: 'Добавяне',
        isFreeAction: true,
        onClick: () => onAdd()
      });
    }
  }

  if (onEdit) {
    if (inlineActions) {
      editable.onRowUpdate = (newData: any, oldData: any) =>
        new Promise((resolve, reject) => {
          onEdit(newData, oldData);
          resolve(newData);
        });
    } else {
      actions.push({
        icon: () => <EditIcon className={classes.icon} />,
        tooltip: 'Редактиране',
        onClick: (event: Event, rowData: any) => {
          if (onEdit) {
            onEdit(rowData);
          }
        }
      });
    }
  }

  if (onDelete) {
    editable.onRowDelete = (oldData: any) =>
      new Promise((resolve, reject) => {
        if (oldData && onDelete) {
          onDelete(oldData);
        }
        resolve(oldData);
      });

    editable.isDeletable = isDeletable;
  }

  if (onView) {
    actions.splice(0, 0, {
      icon: () => <VisibilityIcon className={classes.icon} />,
      tooltip: onViewText ? onViewText : 'Преглед',
      onClick: (event: Event, rowData: any) => onView(rowData)
    });
  }
  if (customAction) {
    actions.splice(0, 0, customAction);
  }

  if (onCopy) {
    actions.splice(0, 0, {
      icon: copyIcon ? copyIcon : () => <FileCopyIcon className={classes.icon} />,
      tooltip: onCopyText ? onCopyText : 'Копиране',
      onClick: (event: Event, rowData: any) => onCopy(rowData)
    });
  }

  const icons: Icons = {
    Add: forwardRef((props, ref) => <AddCircleOutlinedIcon {...props} ref={ref} />)
  };

  if (deleteIcon) {
    icons.Delete = deleteIcon;
  }

  return (
    <MaterialTable
      title={title}
      columns={columns.filter((c) => !!c)}
      data={data}
      icons={icons}
      localization={{
        body: {
          emptyDataSourceMessage: 'Не са намерени записи',
          editRow: {
            saveTooltip: 'Потвърждение',
            cancelTooltip: 'Отказ',
            deleteText
          },
          addTooltip: 'Добавяне',
          editTooltip: 'Редактиране',
          deleteTooltip
        },
        header: { actions: 'Действия' },
        toolbar: {
          searchPlaceholder: 'Търсене',
          searchTooltip: 'Търсене',
          exportTitle: 'Експорт',
          exportCSVName: 'Експорт CSV',
          nRowsSelected: (rowCount) => `${rowCount} избран(и)`
        }
      }}
      components={{
        Container: (props: any) => <Paper {...props} elevation={2} className={classes.paper} />,
        ...components
      }}
      options={{
        search,
        toolbar: !!title || !!onAdd,
        showTitle: !!title,
        sorting: true,
        pageSize: autoPageSize ? ps : pageSize,
        pageSizeOptions: [],
        paging,
        actionsColumnIndex: -1,
        actionsCellStyle: {
          alignContent: 'right'
        },
        overflowY,
        maxBodyHeight,
        minBodyHeight,
        addRowPosition,
        exportButton,
        exportDelimiter: ';',
        exportAllData: true,
        rowStyle,
        selection
      }}
      isLoading={isLoading}
      editable={editable}
      onSearchChange={onSearchChange}
      actions={actions}
      parentChildData={parentChildData}
      onSelectionChange={onSelectionChange}
    />
  );
};
export default AMSTable;
