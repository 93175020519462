/* tslint:disable */
/* eslint-disable */
/**
 * AMS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActOfAcceptanceItemResponse
 */
export interface ActOfAcceptanceItemResponse {
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceItemResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceItemResponse
     */
    'orderItemId': number;
    /**
     * 
     * @type {boolean}
     * @memberof ActOfAcceptanceItemResponse
     */
    'itemTracksDeliveryBatches': boolean;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceItemResponse
     */
    'itemArtNo': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceItemResponse
     */
    'itemName': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceItemResponse
     */
    'itemBarcodeValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceItemResponse
     */
    'itemUnitName': string;
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceItemResponse
     */
    'itemPackageQuantityDelivery': number;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceItemResponse
     */
    'itemVatPercent': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceItemResponse
     */
    'orderItemNetPriceForOne': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceItemResponse
     */
    'orderItemNetTotal': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceItemResponse
     */
    'orderItemVatTotal': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceItemResponse
     */
    'orderItemTotalIncludingVat': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceItemResponse
     */
    'deliveryItemBatchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceItemResponse
     */
    'deliveryItemExpirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceItemResponse
     */
    'deliveryItemCountry': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceItemResponse
     */
    'quantity': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceItemResponse
     */
    'netTotal': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceItemResponse
     */
    'vatTotal': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceItemResponse
     */
    'totalIncludingVat': string;
}
/**
 * 
 * @export
 * @interface ActOfAcceptanceResponse
 */
export interface ActOfAcceptanceResponse {
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceResponse
     */
    'type': number;
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceResponse
     */
    'orderType': number;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceResponse
     */
    'number': string;
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceResponse
     */
    'companyId': number;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceResponse
     */
    'companyName': string;
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceResponse
     */
    'receiverId': number;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceResponse
     */
    'receiverName': string;
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceResponse
     */
    'receiverLegalEntityId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceResponse
     */
    'receiverLegalEntityName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceResponse
     */
    'totalIncludingVat': number;
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceResponse
     */
    'netTotal': number;
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceResponse
     */
    'vatTotal': number;
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceResponse
     */
    'createdById': number;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceResponse
     */
    'createdByName': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceResponse
     */
    'orderPaymentOption'?: string;
}
/**
 * 
 * @export
 * @interface BackendAppApiV1SchemasGroupUserSimpleResponse
 */
export interface BackendAppApiV1SchemasGroupUserSimpleResponse {
    /**
     * 
     * @type {number}
     * @memberof BackendAppApiV1SchemasGroupUserSimpleResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BackendAppApiV1SchemasGroupUserSimpleResponse
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof BackendAppApiV1SchemasGroupUserSimpleResponse
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface BackendAppApiV1SchemasRoleUserSimpleResponse
 */
export interface BackendAppApiV1SchemasRoleUserSimpleResponse {
    /**
     * 
     * @type {number}
     * @memberof BackendAppApiV1SchemasRoleUserSimpleResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BackendAppApiV1SchemasRoleUserSimpleResponse
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof BackendAppApiV1SchemasRoleUserSimpleResponse
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface BatchOrderItemV2Response
 */
export interface BatchOrderItemV2Response {
    /**
     * 
     * @type {number}
     * @memberof BatchOrderItemV2Response
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof BatchOrderItemV2Response
     */
    'orderItemId': number;
    /**
     * 
     * @type {string}
     * @memberof BatchOrderItemV2Response
     */
    'artNo': string;
    /**
     * 
     * @type {string}
     * @memberof BatchOrderItemV2Response
     */
    'itemName': string;
    /**
     * 
     * @type {string}
     * @memberof BatchOrderItemV2Response
     */
    'batchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof BatchOrderItemV2Response
     */
    'expirationDate': string;
    /**
     * 
     * @type {number}
     * @memberof BatchOrderItemV2Response
     */
    'collected': number;
    /**
     * 
     * @type {number}
     * @memberof BatchOrderItemV2Response
     */
    'warehouseId': number;
    /**
     * 
     * @type {string}
     * @memberof BatchOrderItemV2Response
     */
    'warehouseName': string;
    /**
     * 
     * @type {number}
     * @memberof BatchOrderItemV2Response
     */
    'stockId': number;
}
/**
 * 
 * @export
 * @interface BulkCreatePartnerBonusQuantityRequest
 */
export interface BulkCreatePartnerBonusQuantityRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof BulkCreatePartnerBonusQuantityRequest
     */
    'partnerIds': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof BulkCreatePartnerBonusQuantityRequest
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof BulkCreatePartnerBonusQuantityRequest
     */
    'baseQuantity': string;
    /**
     * 
     * @type {string}
     * @memberof BulkCreatePartnerBonusQuantityRequest
     */
    'bonusQuantity': string;
    /**
     * 
     * @type {string}
     * @memberof BulkCreatePartnerBonusQuantityRequest
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof BulkCreatePartnerBonusQuantityRequest
     */
    'endDate': string;
}
/**
 * 
 * @export
 * @interface CompanyCategoryBase
 */
export interface CompanyCategoryBase {
    /**
     * 
     * @type {string}
     * @memberof CompanyCategoryBase
     */
    'name': string;
    /**
     * 
     * @type {Array<CompanyCategoryBase>}
     * @memberof CompanyCategoryBase
     */
    'children'?: Array<CompanyCategoryBase>;
}
/**
 * 
 * @export
 * @interface CompanyCategoryResponse
 */
export interface CompanyCategoryResponse {
    /**
     * 
     * @type {string}
     * @memberof CompanyCategoryResponse
     */
    'name': string;
    /**
     * 
     * @type {Array<CompanyCategoryResponse>}
     * @memberof CompanyCategoryResponse
     */
    'children'?: Array<CompanyCategoryResponse>;
    /**
     * 
     * @type {number}
     * @memberof CompanyCategoryResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyCategoryResponse
     */
    'parentId'?: number;
}
/**
 * 
 * @export
 * @interface CompanyInfo
 */
export interface CompanyInfo {
    /**
     * 
     * @type {boolean}
     * @memberof CompanyInfo
     */
    'valid': boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyInfo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyInfo
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyInfo
     */
    'vatNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyInfo
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyInfo
     */
    'errorMessage'?: string;
}
/**
 * 
 * @export
 * @interface ContactResponse
 */
export interface ContactResponse {
    /**
     * 
     * @type {string}
     * @memberof ContactResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ContactResponse
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactResponse
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface CreateCompanyCategoryRequest
 */
export interface CreateCompanyCategoryRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyCategoryRequest
     */
    'name': string;
    /**
     * 
     * @type {Array<CompanyCategoryBase>}
     * @memberof CreateCompanyCategoryRequest
     */
    'children'?: Array<CompanyCategoryBase>;
    /**
     * 
     * @type {number}
     * @memberof CreateCompanyCategoryRequest
     */
    'parentId'?: number;
}
/**
 * 
 * @export
 * @interface CreateDeliveryCreditNoteItem
 */
export interface CreateDeliveryCreditNoteItem {
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryCreditNoteItem
     */
    'deliveryItemId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryCreditNoteItem
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface CreateDeliveryDocumentRequest
 */
export interface CreateDeliveryDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryDocumentRequest
     */
    'invoiceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryDocumentRequest
     */
    'invoiceDate': string;
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryDocumentRequest
     */
    'invoiceTotal': number;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryDocumentRequest
     */
    'paymentDue': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryDocumentRequest
     */
    'paymentInfo': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryDocumentRequest
     */
    'tradeDocumentNumber': string;
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryDocumentRequest
     */
    'deliveryId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryDocumentRequest
     */
    'deliveryDocumentType': number;
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryDocumentRequest
     */
    'proformaId'?: number;
    /**
     * 
     * @type {Array<CreateDeliveryCreditNoteItem>}
     * @memberof CreateDeliveryDocumentRequest
     */
    'creditNoteItems'?: Array<CreateDeliveryCreditNoteItem>;
}
/**
 * 
 * @export
 * @interface CreateDeliveryItemRequest
 */
export interface CreateDeliveryItemRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryItemRequest
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryItemRequest
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryItemRequest
     */
    'batchNumber': string;
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryItemRequest
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryItemRequest
     */
    'country': string;
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryItemRequest
     */
    'price': number;
}
/**
 * 
 * @export
 * @interface CreateDeliveryRequest
 */
export interface CreateDeliveryRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryRequest
     */
    'warehouseId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryRequest
     */
    'supplierId': number;
    /**
     * 
     * @type {Array<CreateDeliveryItemRequest>}
     * @memberof CreateDeliveryRequest
     */
    'deliveryItems'?: Array<CreateDeliveryItemRequest>;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryRequest
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryRequest
     */
    'deliveryRequestId'?: number;
}
/**
 * 
 * @export
 * @interface CreateDeliveryRequestItem
 */
export interface CreateDeliveryRequestItem {
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryRequestItem
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryRequestItem
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface CreateDeliveryRequestRequest
 */
export interface CreateDeliveryRequestRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryRequestRequest
     */
    'warehouseId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryRequestRequest
     */
    'supplierId': number;
    /**
     * 
     * @type {Array<CreateDeliveryRequestItem>}
     * @memberof CreateDeliveryRequestRequest
     */
    'items': Array<CreateDeliveryRequestItem>;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryRequestRequest
     */
    'deliveryDate': string;
}
/**
 * 
 * @export
 * @interface CreateInvoiceRequest
 */
export interface CreateInvoiceRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateInvoiceRequest
     */
    'orderIds': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CreateInvoiceRequest
     */
    'paymentOptionId': number;
}
/**
 * 
 * @export
 * @interface CreateItemCategoryRequest
 */
export interface CreateItemCategoryRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateItemCategoryRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemCategoryRequest
     */
    'aliasId'?: string;
    /**
     * 
     * @type {Array<ItemCategoryBase>}
     * @memberof CreateItemCategoryRequest
     */
    'children'?: Array<ItemCategoryBase>;
    /**
     * 
     * @type {number}
     * @memberof CreateItemCategoryRequest
     */
    'parentId'?: number;
}
/**
 * 
 * @export
 * @interface CreateItemRequest
 */
export interface CreateItemRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'artNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'currency': string;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'depth'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'unitId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'deliveryRequestUnit'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'packageQty'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'packageQtyDelivery'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'barcodeStandardId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'barcodeValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'additionalBarcodeValue'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'basePrice': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'costPrice': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'vat': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemRequest
     */
    'deliveryOnDemand'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemRequest
     */
    'noStorage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemRequest
     */
    'promo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemRequest
     */
    'mlm'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemRequest
     */
    'stockSelectable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemRequest
     */
    'isOwnedByUs'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'itemCategoryId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'supplierId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'manufacturerId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemRequest
     */
    'maintainStock'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'minimumAllowedDaysToExpiration': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'factoryDaysToExpiration': number;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'minimumRequiredPartOfFactoryDaysOfExpirationForDelivery': string;
}
/**
 * 
 * @export
 * @interface CreateOrderItemV2Request
 */
export interface CreateOrderItemV2Request {
    /**
     * 
     * @type {number}
     * @memberof CreateOrderItemV2Request
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderItemV2Request
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderItemV2Request
     */
    'orderedQuantity'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderItemV2Request
     */
    'bonusQuantity'?: string;
}
/**
 * 
 * @export
 * @interface CreateOrderV2Request
 */
export interface CreateOrderV2Request {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderV2Request
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {Array<CreateOrderItemV2Request>}
     * @memberof CreateOrderV2Request
     */
    'items'?: Array<CreateOrderItemV2Request>;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderV2Request
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderV2Request
     */
    'orderPriority'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderV2Request
     */
    'orderType': number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderV2Request
     */
    'receiverId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderV2Request
     */
    'userResponsibleId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderV2Request
     */
    'warehouseId': number;
}
/**
 * 
 * @export
 * @interface CreatePricingPolicyRequest
 */
export interface CreatePricingPolicyRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePricingPolicyRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreatePricingPolicyRequest
     */
    'companyId': number;
    /**
     * 
     * @type {Array<PricingPolicyItemCategoryRequest>}
     * @memberof CreatePricingPolicyRequest
     */
    'itemCategories': Array<PricingPolicyItemCategoryRequest>;
    /**
     * 
     * @type {Array<PricingPolicyItemExceptionRequest>}
     * @memberof CreatePricingPolicyRequest
     */
    'itemExceptions'?: Array<PricingPolicyItemExceptionRequest>;
}
/**
 * 
 * @export
 * @interface CreditNoteSimpleResponse
 */
export interface CreditNoteSimpleResponse {
    /**
     * 
     * @type {number}
     * @memberof CreditNoteSimpleResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CreditNoteSimpleResponse
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreditNoteSimpleResponse
     */
    'paymentDue': string;
    /**
     * 
     * @type {string}
     * @memberof CreditNoteSimpleResponse
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof CreditNoteSimpleResponse
     */
    'netTotal': string;
    /**
     * 
     * @type {string}
     * @memberof CreditNoteSimpleResponse
     */
    'vatTotal': string;
    /**
     * 
     * @type {string}
     * @memberof CreditNoteSimpleResponse
     */
    'totalIncludingVat': string;
    /**
     * 
     * @type {string}
     * @memberof CreditNoteSimpleResponse
     */
    'remainingAmount': string;
    /**
     * 
     * @type {Array<TransactionSimpleResponse>}
     * @memberof CreditNoteSimpleResponse
     */
    'transactions': Array<TransactionSimpleResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof CreditNoteSimpleResponse
     */
    'isPaidInFull': boolean;
}
/**
 * 
 * @export
 * @interface DeliveryCreditNoteItemResponse
 */
export interface DeliveryCreditNoteItemResponse {
    /**
     * 
     * @type {number}
     * @memberof DeliveryCreditNoteItemResponse
     */
    'deliveryItemId': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryCreditNoteItemResponse
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryCreditNoteItemResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryCreditNoteItemResponse
     */
    'itemName': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryCreditNoteItemResponse
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryCreditNoteItemResponse
     */
    'batchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryCreditNoteItemResponse
     */
    'country': string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryCreditNoteItemResponse
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryCreditNoteItemResponse
     */
    'priceIncludingVat': number;
}
/**
 * 
 * @export
 * @interface DeliveryDocumentResponse
 */
export interface DeliveryDocumentResponse {
    /**
     * 
     * @type {string}
     * @memberof DeliveryDocumentResponse
     */
    'invoiceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDocumentResponse
     */
    'invoiceDate': string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryDocumentResponse
     */
    'invoiceTotal': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDocumentResponse
     */
    'paymentDue': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDocumentResponse
     */
    'paymentInfo': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDocumentResponse
     */
    'tradeDocumentNumber': string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryDocumentResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryDocumentResponse
     */
    'remainingAmount': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryDocumentResponse
     */
    'deliveryDocumentType': number;
    /**
     * 
     * @type {Array<DeliveryCreditNoteItemResponse>}
     * @memberof DeliveryDocumentResponse
     */
    'creditNoteItems'?: Array<DeliveryCreditNoteItemResponse>;
}
/**
 * 
 * @export
 * @interface DeliveryItemResponse
 */
export interface DeliveryItemResponse {
    /**
     * 
     * @type {number}
     * @memberof DeliveryItemResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryItemResponse
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryItemResponse
     */
    'batchNumber': string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryItemResponse
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryItemResponse
     */
    'country': string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryItemResponse
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryItemResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryItemResponse
     */
    'itemName': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryItemResponse
     */
    'itemBarcodeValue'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryItemResponse
     */
    'itemVat': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryItemResponse
     */
    'warehouseId': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryItemResponse
     */
    'warehouseName': string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryItemResponse
     */
    'availableQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryItemResponse
     */
    'priceIncludingVat': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryItemResponse
     */
    'deliveryDate': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryItemResponse
     */
    'totalIncludingVat': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryItemResponse
     */
    'totalWithoutVat': string;
}
/**
 * 
 * @export
 * @interface DeliveryRequestItemCountingTaskResponse
 */
export interface DeliveryRequestItemCountingTaskResponse {
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestItemCountingTaskResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestItemCountingTaskResponse
     */
    'dataSourceType': string;
}
/**
 * 
 * @export
 * @interface DeliveryRequestItemResponse
 */
export interface DeliveryRequestItemResponse {
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestItemResponse
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestItemResponse
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestItemResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestItemResponse
     */
    'itemArtNo': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestItemResponse
     */
    'itemName': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestItemResponse
     */
    'itemBarcodeValue'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestItemResponse
     */
    'itemVat': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestItemResponse
     */
    'itemLastDeliveryPrice'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryRequestItemResponse
     */
    'itemShouldTrackBatches': boolean;
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestItemResponse
     */
    'itemMinimumRequiredDaysOfExpirationForDelivery': number;
}
/**
 * 
 * @export
 * @interface DeliveryRequestResponse
 */
export interface DeliveryRequestResponse {
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestResponse
     */
    'warehouseId': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestResponse
     */
    'supplierId': number;
    /**
     * 
     * @type {Array<DeliveryRequestItemResponse>}
     * @memberof DeliveryRequestResponse
     */
    'items'?: Array<DeliveryRequestItemResponse>;
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestResponse
     */
    'createdById': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestResponse
     */
    'createdByName': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestResponse
     */
    'warehouseName': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestResponse
     */
    'supplierName': string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestResponse
     */
    'companyId': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestResponse
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestResponse
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestResponse
     */
    'deliveryDate': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestResponse
     */
    'status': string;
    /**
     * 
     * @type {Array<DeliveryRequestItemCountingTaskResponse>}
     * @memberof DeliveryRequestResponse
     */
    'tasks'?: Array<DeliveryRequestItemCountingTaskResponse>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeliveryRequestResponse
     */
    'allowedSubsequentStatuses': Array<string>;
}
/**
 * 
 * @export
 * @interface DeliveryRequestTaskItemResponse
 */
export interface DeliveryRequestTaskItemResponse {
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestTaskItemResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestTaskItemResponse
     */
    'deliveryRequestTaskId': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestTaskItemResponse
     */
    'taskId': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestTaskItemResponse
     */
    'lastModifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestTaskItemResponse
     */
    'quantity'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestTaskItemResponse
     */
    'batchNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestTaskItemResponse
     */
    'expirationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestTaskItemResponse
     */
    'countryOfOrigin'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestTaskItemResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestTaskItemResponse
     */
    'itemArtNo': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestTaskItemResponse
     */
    'itemName': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestTaskItemResponse
     */
    'itemUnitName': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestTaskItemResponse
     */
    'itemBarcodeValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestTaskItemResponse
     */
    'itemAdditionalBarcodeValue'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryRequestTaskItemResponse
     */
    'itemShouldTrackBatches': boolean;
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestTaskItemResponse
     */
    'itemMinimumRequiredDaysOfExpirationForDelivery': number;
}
/**
 * 
 * @export
 * @interface DeliveryRequestTaskResponse
 */
export interface DeliveryRequestTaskResponse {
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestTaskResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestTaskResponse
     */
    'status': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeliveryRequestTaskResponse
     */
    'allowedSubsequentStatuses': Array<string>;
    /**
     * 
     * @type {Array<DeliveryRequestTaskItemResponse>}
     * @memberof DeliveryRequestTaskResponse
     */
    'items': Array<DeliveryRequestTaskItemResponse>;
}
/**
 * 
 * @export
 * @interface DeliveryResponse
 */
export interface DeliveryResponse {
    /**
     * 
     * @type {number}
     * @memberof DeliveryResponse
     */
    'warehouseId': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryResponse
     */
    'supplierId': number;
    /**
     * 
     * @type {Array<DeliveryItemResponse>}
     * @memberof DeliveryResponse
     */
    'deliveryItems'?: Array<DeliveryItemResponse>;
    /**
     * 
     * @type {string}
     * @memberof DeliveryResponse
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryResponse
     */
    'warehouseName': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryResponse
     */
    'supplierName': string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryResponse
     */
    'companyId': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryResponse
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryResponse
     */
    'createdByName': string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryResponse
     */
    'totalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryResponse
     */
    'invoiceBalance': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryResponse
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {Array<DeliveryDocumentResponse>}
     * @memberof DeliveryResponse
     */
    'documents'?: Array<DeliveryDocumentResponse>;
    /**
     * 
     * @type {Array<TransactionResponse>}
     * @memberof DeliveryResponse
     */
    'transactions'?: Array<TransactionResponse>;
    /**
     * 
     * @type {string}
     * @memberof DeliveryResponse
     */
    'supplierPaymentInfo'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryResponse
     */
    'remainingAmount': number;
}
/**
 * 
 * @export
 * @interface GroupResponse
 */
export interface GroupResponse {
    /**
     * 
     * @type {string}
     * @memberof GroupResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof GroupResponse
     */
    'id': number;
    /**
     * 
     * @type {Array<BackendAppApiV1SchemasGroupUserSimpleResponse>}
     * @memberof GroupResponse
     */
    'users'?: Array<BackendAppApiV1SchemasGroupUserSimpleResponse>;
    /**
     * 
     * @type {Array<SupplierSimpleResponse>}
     * @memberof GroupResponse
     */
    'suppliers'?: Array<SupplierSimpleResponse>;
    /**
     * 
     * @type {Array<WarehouseSimpleResponse>}
     * @memberof GroupResponse
     */
    'warehouses'?: Array<WarehouseSimpleResponse>;
    /**
     * 
     * @type {Array<PartnerSimpleResponse>}
     * @memberof GroupResponse
     */
    'partners'?: Array<PartnerSimpleResponse>;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface InvoiceResponse
 */
export interface InvoiceResponse {
    /**
     * 
     * @type {number}
     * @memberof InvoiceResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'paymentDue': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'number': string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceResponse
     */
    'companyId': number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'receiverName': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'receiverPartnerName': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'netTotal': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'vatTotal': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'totalIncludingVat': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'paymentOption': string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceResponse
     */
    'remainingAmount': number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceResponse
     */
    'invoiceId'?: number;
    /**
     * 
     * @type {Array<OrderSimpleResponse>}
     * @memberof InvoiceResponse
     */
    'orders'?: Array<OrderSimpleResponse>;
    /**
     * 
     * @type {Array<CreditNoteSimpleResponse>}
     * @memberof InvoiceResponse
     */
    'creditNotes'?: Array<CreditNoteSimpleResponse>;
    /**
     * 
     * @type {Array<TransactionSimpleResponse>}
     * @memberof InvoiceResponse
     */
    'transactions'?: Array<TransactionSimpleResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceResponse
     */
    'isCancelled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceResponse
     */
    'canBeCancelled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceResponse
     */
    'isPaidInFull': boolean;
}
/**
 * 
 * @export
 * @interface InvoiceV2Response
 */
export interface InvoiceV2Response {
    /**
     * 
     * @type {number}
     * @memberof InvoiceV2Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceV2Response
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceV2Response
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceV2Response
     */
    'paymentDue': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceV2Response
     */
    'number': string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceV2Response
     */
    'companyId': number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceV2Response
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceV2Response
     */
    'receiverName': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceV2Response
     */
    'receiverPartnerName': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceV2Response
     */
    'netTotal': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceV2Response
     */
    'vatTotal': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceV2Response
     */
    'totalIncludingVat': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceV2Response
     */
    'paymentOption': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceV2Response
     */
    'remainingAmount': string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceV2Response
     */
    'invoiceId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceV2Response
     */
    'isCancelled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceV2Response
     */
    'canBeCancelled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceV2Response
     */
    'isPaidInFull': boolean;
}
/**
 * 
 * @export
 * @interface ItemCategoryBase
 */
export interface ItemCategoryBase {
    /**
     * 
     * @type {string}
     * @memberof ItemCategoryBase
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ItemCategoryBase
     */
    'aliasId'?: string;
    /**
     * 
     * @type {Array<ItemCategoryBase>}
     * @memberof ItemCategoryBase
     */
    'children'?: Array<ItemCategoryBase>;
}
/**
 * 
 * @export
 * @interface ItemCategoryResponse
 */
export interface ItemCategoryResponse {
    /**
     * 
     * @type {string}
     * @memberof ItemCategoryResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ItemCategoryResponse
     */
    'aliasId'?: string;
    /**
     * 
     * @type {Array<ItemCategoryResponse>}
     * @memberof ItemCategoryResponse
     */
    'children'?: Array<ItemCategoryResponse>;
    /**
     * 
     * @type {number}
     * @memberof ItemCategoryResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ItemCategoryResponse
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemCategoryResponse
     */
    'parentName'?: string;
}
/**
 * 
 * @export
 * @interface ItemManufacturerResponse
 */
export interface ItemManufacturerResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemManufacturerResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ItemManufacturerResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ItemManufacturerResponse
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface ItemMovementBalance
 */
export interface ItemMovementBalance {
    /**
     * 
     * @type {number}
     * @memberof ItemMovementBalance
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof ItemMovementBalance
     */
    'itemArtNo': string;
    /**
     * 
     * @type {string}
     * @memberof ItemMovementBalance
     */
    'itemName': string;
    /**
     * 
     * @type {string}
     * @memberof ItemMovementBalance
     */
    'quantity': string;
    /**
     * 
     * @type {string}
     * @memberof ItemMovementBalance
     */
    'itemNetPrice': string;
}
/**
 * 
 * @export
 * @interface ItemResponse
 */
export interface ItemResponse {
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'artNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'currency': string;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'depth'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'unitId': number;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'deliveryRequestUnit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'packageQty'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'packageQtyDelivery'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'barcodeStandardId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'barcodeValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'additionalBarcodeValue'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'basePrice': number;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'costPrice': number;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'vat': number;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'deliveryOnDemand'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'noStorage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'promo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'mlm'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'stockSelectable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'isOwnedByUs'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'itemCategoryId': number;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'supplierId': number;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'manufacturerId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'maintainStock'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'id': number;
    /**
     * 
     * @type {UnitResponse}
     * @memberof ItemResponse
     */
    'unit': UnitResponse;
    /**
     * 
     * @type {ItemCategoryResponse}
     * @memberof ItemResponse
     */
    'itemCategory'?: ItemCategoryResponse;
    /**
     * 
     * @type {SupplierResponse}
     * @memberof ItemResponse
     */
    'supplier'?: SupplierResponse;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'lastDeliveryPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'lastDeliveryCountry'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'activeOrderItems'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'isActive': boolean;
    /**
     * 
     * @type {ItemManufacturerResponse}
     * @memberof ItemResponse
     */
    'manufacturer'?: ItemManufacturerResponse;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'minimumAllowedDaysToExpiration': number;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'factoryDaysToExpiration': number;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'minimumRequiredPartOfFactoryDaysOfExpirationForDelivery': string;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'lastKnownPrice': string;
}
/**
 * 
 * @export
 * @interface LegalEntityOrdersForCollectionAmountResponse
 */
export interface LegalEntityOrdersForCollectionAmountResponse {
    /**
     * 
     * @type {string}
     * @memberof LegalEntityOrdersForCollectionAmountResponse
     */
    'amount': string;
}
/**
 * 
 * @export
 * @interface LegalEntityOrdersForDistributionAmountResponse
 */
export interface LegalEntityOrdersForDistributionAmountResponse {
    /**
     * 
     * @type {string}
     * @memberof LegalEntityOrdersForDistributionAmountResponse
     */
    'amount': string;
}
/**
 * 
 * @export
 * @interface LegalEntityOverduePaymentsAmountResponse
 */
export interface LegalEntityOverduePaymentsAmountResponse {
    /**
     * 
     * @type {string}
     * @memberof LegalEntityOverduePaymentsAmountResponse
     */
    'amount': string;
}
/**
 * 
 * @export
 * @interface LegalEntityPendingPaymentsAmountResponse
 */
export interface LegalEntityPendingPaymentsAmountResponse {
    /**
     * 
     * @type {string}
     * @memberof LegalEntityPendingPaymentsAmountResponse
     */
    'amount': string;
}
/**
 * 
 * @export
 * @interface LinkedItemAssociationResponse
 */
export interface LinkedItemAssociationResponse {
    /**
     * 
     * @type {number}
     * @memberof LinkedItemAssociationResponse
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof LinkedItemAssociationResponse
     */
    'linkedItemId': number;
    /**
     * 
     * @type {string}
     * @memberof LinkedItemAssociationResponse
     */
    'linkedItemName': string;
}
/**
 * 
 * @export
 * @interface OrderDocumentV2Response
 */
export interface OrderDocumentV2Response {
    /**
     * 
     * @type {number}
     * @memberof OrderDocumentV2Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OrderDocumentV2Response
     */
    'number': string;
    /**
     * 
     * @type {number}
     * @memberof OrderDocumentV2Response
     */
    'type': number;
    /**
     * 
     * @type {string}
     * @memberof OrderDocumentV2Response
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDocumentV2Response
     */
    'documentPath': string;
    /**
     * 
     * @type {number}
     * @memberof OrderDocumentV2Response
     */
    'totalAmount': number;
}
/**
 * 
 * @export
 * @interface OrderItemRequest
 */
export interface OrderItemRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderItemRequest
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemRequest
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemRequest
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemRequest
     */
    'priceOverride'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemRequest
     */
    'orderedQuantity'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemRequest
     */
    'bonusQuantity'?: string;
}
/**
 * 
 * @export
 * @interface OrderItemV2Response
 */
export interface OrderItemV2Response {
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemV2Response
     */
    'orderedQuantity': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemV2Response
     */
    'bonusQuantity'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemV2Response
     */
    'collected': string;
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'receivedQuantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemV2Response
     */
    'artNo': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemV2Response
     */
    'barcodeValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemV2Response
     */
    'additionalBarcodeValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemV2Response
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemV2Response
     */
    'unitName': string;
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'packageQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'weight': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'totalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'livePrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'liveTotalPrice'?: number;
    /**
     * 
     * @type {Array<BatchOrderItemV2Response>}
     * @memberof OrderItemV2Response
     */
    'batchItems': Array<BatchOrderItemV2Response>;
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'availableQuantity'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemV2Response
     */
    'stockSelectable': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemV2Response
     */
    'isOwnedByUs': boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'minimumAllowedDaysToExpiration': number;
}
/**
 * 
 * @export
 * @interface OrderSimpleResponse
 */
export interface OrderSimpleResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderSimpleResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OrderSimpleResponse
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderSimpleResponse
     */
    'statusKey': string;
    /**
     * 
     * @type {string}
     * @memberof OrderSimpleResponse
     */
    'receiverName': string;
    /**
     * 
     * @type {number}
     * @memberof OrderSimpleResponse
     */
    'totalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof OrderSimpleResponse
     */
    'taxableTotalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof OrderSimpleResponse
     */
    'nonTaxableTotalPrice': number;
}
/**
 * 
 * @export
 * @interface OrderStatusLogResponse
 */
export interface OrderStatusLogResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderStatusLogResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof OrderStatusLogResponse
     */
    'orderId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderStatusLogResponse
     */
    'statusKey': string;
    /**
     * 
     * @type {string}
     * @memberof OrderStatusLogResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof OrderStatusLogResponse
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderStatusLogResponse
     */
    'userName': string;
}
/**
 * 
 * @export
 * @interface OrderStatusUpdateRequest
 */
export interface OrderStatusUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderStatusUpdateRequest
     */
    'orderId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderStatusUpdateRequest
     */
    'statusKey': string;
}
/**
 * 
 * @export
 * @interface OrderTransactionResponse
 */
export interface OrderTransactionResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderTransactionResponse
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof OrderTransactionResponse
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof OrderTransactionResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OrderTransactionResponse
     */
    'invoiceNumber': string;
    /**
     * 
     * @type {number}
     * @memberof OrderTransactionResponse
     */
    'transactionType': number;
    /**
     * 
     * @type {string}
     * @memberof OrderTransactionResponse
     */
    'treasuryName': string;
}
/**
 * 
 * @export
 * @interface OrderV2ListResponse
 */
export interface OrderV2ListResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderV2ListResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OrderV2ListResponse
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof OrderV2ListResponse
     */
    'priority': string;
    /**
     * 
     * @type {string}
     * @memberof OrderV2ListResponse
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof OrderV2ListResponse
     */
    'packageQty'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderV2ListResponse
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderV2ListResponse
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderV2ListResponse
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderV2ListResponse
     */
    'companyNote'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderV2ListResponse
     */
    'warehouseId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderV2ListResponse
     */
    'warehouseName'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderV2ListResponse
     */
    'receiverId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderV2ListResponse
     */
    'receiverName'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderV2ListResponse
     */
    'actOfAcceptanceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderV2ListResponse
     */
    'actOfAcceptanceNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderV2ListResponse
     */
    'invoiceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderV2ListResponse
     */
    'invoiceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderV2ListResponse
     */
    'billableNetTotal'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderV2ListResponse
     */
    'executionPercentage'?: string;
}
/**
 * 
 * @export
 * @interface OrderV2Response
 */
export interface OrderV2Response {
    /**
     * 
     * @type {number}
     * @memberof OrderV2Response
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof OrderV2Response
     */
    'orderType': number;
    /**
     * 
     * @type {number}
     * @memberof OrderV2Response
     */
    'orderPriority': number;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'orderTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'statusKey': string;
    /**
     * 
     * @type {Array<OrderItemV2Response>}
     * @memberof OrderV2Response
     */
    'items'?: Array<OrderItemV2Response>;
    /**
     * 
     * @type {number}
     * @memberof OrderV2Response
     */
    'totalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof OrderV2Response
     */
    'packageQty'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'note'?: string;
    /**
     * 
     * @type {UserV2Response}
     * @memberof OrderV2Response
     */
    'userResponsible': UserV2Response;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'addressTextField'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderV2Response
     */
    'allowedSubsequentStatuses': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof OrderV2Response
     */
    'inProgress'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderV2Response
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'companyNote'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderV2Response
     */
    'warehouseId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'warehouseName'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderV2Response
     */
    'receiverId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'receiverName'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderV2Response
     */
    'receiverPaymentOptionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'receiverPaymentOptionInfo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderV2Response
     */
    'receiverShouldPayOnDelivery'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'invoiceNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderV2Response
     */
    'isInternal'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'executionPercentage'?: string;
    /**
     * 
     * @type {Array<OrderTransactionResponse>}
     * @memberof OrderV2Response
     */
    'transactions'?: Array<OrderTransactionResponse>;
    /**
     * 
     * @type {number}
     * @memberof OrderV2Response
     */
    'invoiceRemainingAmount': number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderV2Response
     */
    'shouldCollectOnDeliveryDate': boolean;
}
/**
 * 
 * @export
 * @interface OrderV2SimpleResponse
 */
export interface OrderV2SimpleResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderV2SimpleResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OrderV2SimpleResponse
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderV2SimpleResponse
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof OrderV2SimpleResponse
     */
    'receiverName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderV2SimpleResponse
     */
    'totalPrice': string;
    /**
     * 
     * @type {string}
     * @memberof OrderV2SimpleResponse
     */
    'taxableTotalPrice': string;
    /**
     * 
     * @type {string}
     * @memberof OrderV2SimpleResponse
     */
    'nonTaxableTotalPrice': string;
}
/**
 * 
 * @export
 * @interface OrderingPolicyResponse
 */
export interface OrderingPolicyResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderingPolicyResponse
     */
    'allowedWeekdays': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrderingPolicyResponse
     */
    'nextDayDeliveryCutoff': string;
}
/**
 * 
 * @export
 * @interface PartnerBonusQuantityResponse
 */
export interface PartnerBonusQuantityResponse {
    /**
     * 
     * @type {number}
     * @memberof PartnerBonusQuantityResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PartnerBonusQuantityResponse
     */
    'partnerId': number;
    /**
     * 
     * @type {string}
     * @memberof PartnerBonusQuantityResponse
     */
    'partnerName': string;
    /**
     * 
     * @type {number}
     * @memberof PartnerBonusQuantityResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof PartnerBonusQuantityResponse
     */
    'itemName': string;
    /**
     * 
     * @type {string}
     * @memberof PartnerBonusQuantityResponse
     */
    'baseQuantity': string;
    /**
     * 
     * @type {string}
     * @memberof PartnerBonusQuantityResponse
     */
    'bonusQuantity': string;
    /**
     * 
     * @type {string}
     * @memberof PartnerBonusQuantityResponse
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof PartnerBonusQuantityResponse
     */
    'endDate': string;
}
/**
 * 
 * @export
 * @interface PartnerItemCategoryResponse
 */
export interface PartnerItemCategoryResponse {
    /**
     * 
     * @type {number}
     * @memberof PartnerItemCategoryResponse
     */
    'partnerId': number;
    /**
     * 
     * @type {number}
     * @memberof PartnerItemCategoryResponse
     */
    'itemCategoryId': number;
    /**
     * 
     * @type {number}
     * @memberof PartnerItemCategoryResponse
     */
    'itemCategoryParentId'?: number;
}
/**
 * 
 * @export
 * @interface PartnerSimpleResponse
 */
export interface PartnerSimpleResponse {
    /**
     * 
     * @type {number}
     * @memberof PartnerSimpleResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PartnerSimpleResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PermissionResponse
 */
export interface PermissionResponse {
    /**
     * 
     * @type {string}
     * @memberof PermissionResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PermissionResponse
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface PickOrderItemRequest
 */
export interface PickOrderItemRequest {
    /**
     * 
     * @type {number}
     * @memberof PickOrderItemRequest
     */
    'collected': number;
    /**
     * 
     * @type {number}
     * @memberof PickOrderItemRequest
     */
    'stockId': number;
}
/**
 * 
 * @export
 * @interface PricingPolicyItemCategoryRequest
 */
export interface PricingPolicyItemCategoryRequest {
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyItemCategoryRequest
     */
    'itemCategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyItemCategoryRequest
     */
    'multiplier': string;
}
/**
 * 
 * @export
 * @interface PricingPolicyItemCategoryResponse
 */
export interface PricingPolicyItemCategoryResponse {
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyItemCategoryResponse
     */
    'itemCategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyItemCategoryResponse
     */
    'multiplier': string;
}
/**
 * 
 * @export
 * @interface PricingPolicyItemExceptionRequest
 */
export interface PricingPolicyItemExceptionRequest {
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyItemExceptionRequest
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyItemExceptionRequest
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyItemExceptionRequest
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface PricingPolicyItemExceptionResponse
 */
export interface PricingPolicyItemExceptionResponse {
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyItemExceptionResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyItemExceptionResponse
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyItemExceptionResponse
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface PricingPolicyResponse
 */
export interface PricingPolicyResponse {
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyResponse
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyResponse
     */
    'assignedPartnersNames'?: string;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyResponse
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyResponse
     */
    'companyNote'?: string;
}
/**
 * 
 * @export
 * @interface PrintDocumentQueueResponse
 */
export interface PrintDocumentQueueResponse {
    /**
     * 
     * @type {number}
     * @memberof PrintDocumentQueueResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PrintDocumentQueueResponse
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof PrintDocumentQueueResponse
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof PrintDocumentQueueResponse
     */
    'printer': string;
    /**
     * 
     * @type {number}
     * @memberof PrintDocumentQueueResponse
     */
    'createdById': number;
    /**
     * 
     * @type {string}
     * @memberof PrintDocumentQueueResponse
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof PrintDocumentQueueResponse
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface ReportDeliveryDocumentResponse
 */
export interface ReportDeliveryDocumentResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryDocumentResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryDocumentResponse
     */
    'deliveryId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryDocumentResponse
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryDocumentResponse
     */
    'paymentDue': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryDocumentResponse
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryDocumentResponse
     */
    'senderName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryDocumentResponse
     */
    'receiverName': string;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryDocumentResponse
     */
    'totalIncludingVat': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryDocumentResponse
     */
    'paymentOption': string;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryDocumentResponse
     */
    'remainingAmount': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryDocumentResponse
     */
    'deliveryDocumentType': number;
}
/**
 * 
 * @export
 * @interface ReportDeliveryItemByOrdersResponse
 */
export interface ReportDeliveryItemByOrdersResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'itemArtNo': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'itemName': string;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'itemAvailableQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'orderRequiredQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'stockBalance': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'supplierId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'supplierSubscriptionEmail'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'supplierHasSubscription': boolean;
}
/**
 * 
 * @export
 * @interface ReportDeliveryItemExtendedResponse
 */
export interface ReportDeliveryItemExtendedResponse {
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'companyNote': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'warehouseName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemArtNo': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemBarcodeValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemAdditionalBarcodeValue'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemPrice': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemExpirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemBatchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemCountry': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemAvailableQuantity': string;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'deliveryId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'deliveryDate': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'supplierName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'deliveryNote'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemQuantity': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemCategoryName'?: string;
}
/**
 * 
 * @export
 * @interface ReportDeliveryItemResponse
 */
export interface ReportDeliveryItemResponse {
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemResponse
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemResponse
     */
    'companyNote': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemResponse
     */
    'warehouseName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemResponse
     */
    'itemName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemResponse
     */
    'itemArtNo': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemResponse
     */
    'itemBarcodeValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemResponse
     */
    'itemAdditionalBarcodeValue'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemResponse
     */
    'itemPrice': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemResponse
     */
    'itemExpirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemResponse
     */
    'itemBatchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemResponse
     */
    'itemCountry': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemResponse
     */
    'itemAvailableQuantity': string;
}
/**
 * 
 * @export
 * @interface ReportDepletedAmountWithPrices
 */
export interface ReportDepletedAmountWithPrices {
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPrices
     */
    'orderId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDepletedAmountWithPrices
     */
    'orderDate': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDepletedAmountWithPrices
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPrices
     */
    'receiverId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDepletedAmountWithPrices
     */
    'receiverName': string;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPrices
     */
    'companyId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPrices
     */
    'warehouseId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDepletedAmountWithPrices
     */
    'warehouseName': string;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPrices
     */
    'deliveryId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPrices
     */
    'supplierId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDepletedAmountWithPrices
     */
    'supplierName': string;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPrices
     */
    'itemCategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDepletedAmountWithPrices
     */
    'itemCategoryName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDepletedAmountWithPrices
     */
    'itemName': string;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPrices
     */
    'itemQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPrices
     */
    'deliveryItemPrice': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPrices
     */
    'profitRatio': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPrices
     */
    'orderItemPrice': number;
}
/**
 * 
 * @export
 * @interface ReportOrderBalanceResponse
 */
export interface ReportOrderBalanceResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportOrderBalanceResponse
     */
    'orderType': number;
    /**
     * 
     * @type {number}
     * @memberof ReportOrderBalanceResponse
     */
    'receiverId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportOrderBalanceResponse
     */
    'receiverName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportOrderBalanceResponse
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportOrderBalanceResponse
     */
    'companyName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportOrderBalanceResponse
     */
    'warehouseId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportOrderBalanceResponse
     */
    'warehouseName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportOrderBalanceResponse
     */
    'totalAmount': number;
}
/**
 * 
 * @export
 * @interface ReportOrderItemExtendedResponse
 */
export interface ReportOrderItemExtendedResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportOrderItemExtendedResponse
     */
    'orderId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportOrderItemExtendedResponse
     */
    'orderDate': string;
    /**
     * 
     * @type {string}
     * @memberof ReportOrderItemExtendedResponse
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportOrderItemExtendedResponse
     */
    'receiverId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportOrderItemExtendedResponse
     */
    'receiverName': string;
    /**
     * 
     * @type {number}
     * @memberof ReportOrderItemExtendedResponse
     */
    'companyId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportOrderItemExtendedResponse
     */
    'warehouseId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportOrderItemExtendedResponse
     */
    'warehouseName': string;
    /**
     * 
     * @type {number}
     * @memberof ReportOrderItemExtendedResponse
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportOrderItemExtendedResponse
     */
    'itemCategoryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportOrderItemExtendedResponse
     */
    'itemCategoryName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportOrderItemExtendedResponse
     */
    'itemPrice': number;
    /**
     * 
     * @type {string}
     * @memberof ReportOrderItemExtendedResponse
     */
    'itemName': string;
    /**
     * 
     * @type {number}
     * @memberof ReportOrderItemExtendedResponse
     */
    'itemQuantity': number;
    /**
     * 
     * @type {string}
     * @memberof ReportOrderItemExtendedResponse
     */
    'itemExpirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof ReportOrderItemExtendedResponse
     */
    'itemBatchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ReportOrderItemExtendedResponse
     */
    'itemCountry': string;
}
/**
 * 
 * @export
 * @interface ReportPricingPolicyItemCategoryMultipliersListResponse
 */
export interface ReportPricingPolicyItemCategoryMultipliersListResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportPricingPolicyItemCategoryMultipliersListResponse
     */
    'pricingPolicyIds': Array<number>;
    /**
     * 
     * @type {Array<ReportPricingPolicyItemCategoryMultipliersResponse>}
     * @memberof ReportPricingPolicyItemCategoryMultipliersListResponse
     */
    'values': Array<ReportPricingPolicyItemCategoryMultipliersResponse>;
}
/**
 * 
 * @export
 * @interface ReportPricingPolicyItemCategoryMultipliersResponse
 */
export interface ReportPricingPolicyItemCategoryMultipliersResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportPricingPolicyItemCategoryMultipliersResponse
     */
    'itemCategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportPricingPolicyItemCategoryMultipliersResponse
     */
    'itemCategoryName': string;
    /**
     * 
     * @type {Array<ReportPricingPolicyValueResponse>}
     * @memberof ReportPricingPolicyItemCategoryMultipliersResponse
     */
    'values': Array<ReportPricingPolicyValueResponse>;
}
/**
 * 
 * @export
 * @interface ReportPricingPolicyItemPricesListResponse
 */
export interface ReportPricingPolicyItemPricesListResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportPricingPolicyItemPricesListResponse
     */
    'pricingPolicyIds': Array<number>;
    /**
     * 
     * @type {Array<ReportPricingPolicyItemPricesResponse>}
     * @memberof ReportPricingPolicyItemPricesListResponse
     */
    'values': Array<ReportPricingPolicyItemPricesResponse>;
}
/**
 * 
 * @export
 * @interface ReportPricingPolicyItemPricesResponse
 */
export interface ReportPricingPolicyItemPricesResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportPricingPolicyItemPricesResponse
     */
    'itemId': number;
    /**
     * 
     * @type {Array<ReportPricingPolicyValueResponse>}
     * @memberof ReportPricingPolicyItemPricesResponse
     */
    'values': Array<ReportPricingPolicyValueResponse>;
}
/**
 * 
 * @export
 * @interface ReportPricingPolicyValueResponse
 */
export interface ReportPricingPolicyValueResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportPricingPolicyValueResponse
     */
    'pricingPolicyId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportPricingPolicyValueResponse
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface ReportWarehouseReserveItemResponse
 */
export interface ReportWarehouseReserveItemResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportWarehouseReserveItemResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportWarehouseReserveItemResponse
     */
    'itemArtNo': string;
    /**
     * 
     * @type {string}
     * @memberof ReportWarehouseReserveItemResponse
     */
    'itemName': string;
    /**
     * 
     * @type {number}
     * @memberof ReportWarehouseReserveItemResponse
     */
    'availableQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof ReportWarehouseReserveItemResponse
     */
    'requiredQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof ReportWarehouseReserveItemResponse
     */
    'deliveryRequestQuantity': number;
}
/**
 * 
 * @export
 * @interface ReportWarehouseReserveResponse
 */
export interface ReportWarehouseReserveResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportWarehouseReserveResponse
     */
    'supplierId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportWarehouseReserveResponse
     */
    'supplierSubscriptionEmail': string;
    /**
     * 
     * @type {Array<ReportWarehouseReserveItemResponse>}
     * @memberof ReportWarehouseReserveResponse
     */
    'items': Array<ReportWarehouseReserveItemResponse>;
}
/**
 * 
 * @export
 * @interface Request
 */
export interface Request {
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    'requestType'?: RequestRequestTypeEnum;
    /**
     * 
     * @type {Array<SetPricesItemRequest>}
     * @memberof Request
     */
    'items': Array<SetPricesItemRequest>;
}

export const RequestRequestTypeEnum = {
    SetPrices: 'set-prices'
} as const;

export type RequestRequestTypeEnum = typeof RequestRequestTypeEnum[keyof typeof RequestRequestTypeEnum];

/**
 * 
 * @export
 * @interface ReturnOrderItemV2Request
 */
export interface ReturnOrderItemV2Request {
    /**
     * 
     * @type {number}
     * @memberof ReturnOrderItemV2Request
     */
    'orderItemId': number;
    /**
     * 
     * @type {number}
     * @memberof ReturnOrderItemV2Request
     */
    'depletedAmountId': number;
    /**
     * 
     * @type {number}
     * @memberof ReturnOrderItemV2Request
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface ReturnOrderItemsV2Response
 */
export interface ReturnOrderItemsV2Response {
    /**
     * 
     * @type {number}
     * @memberof ReturnOrderItemsV2Response
     */
    'orderId': number;
    /**
     * 
     * @type {number}
     * @memberof ReturnOrderItemsV2Response
     */
    'creditNoteId'?: number;
}
/**
 * 
 * @export
 * @interface RoleResponse
 */
export interface RoleResponse {
    /**
     * 
     * @type {string}
     * @memberof RoleResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof RoleResponse
     */
    'id': number;
    /**
     * 
     * @type {Array<BackendAppApiV1SchemasRoleUserSimpleResponse>}
     * @memberof RoleResponse
     */
    'users'?: Array<BackendAppApiV1SchemasRoleUserSimpleResponse>;
    /**
     * 
     * @type {Array<PermissionResponse>}
     * @memberof RoleResponse
     */
    'permissions'?: Array<PermissionResponse>;
}
/**
 * 
 * @export
 * @interface SetOrderingPolicyRequest
 */
export interface SetOrderingPolicyRequest {
    /**
     * 
     * @type {string}
     * @memberof SetOrderingPolicyRequest
     */
    'allowedWeekdays': string;
    /**
     * 
     * @type {string}
     * @memberof SetOrderingPolicyRequest
     */
    'nextDayDeliveryCutoff': string;
}
/**
 * 
 * @export
 * @interface SetPricesItemRequest
 */
export interface SetPricesItemRequest {
    /**
     * 
     * @type {number}
     * @memberof SetPricesItemRequest
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SetPricesItemRequest
     */
    'price': string;
    /**
     * 
     * @type {string}
     * @memberof SetPricesItemRequest
     */
    'finalQuantity': string;
}
/**
 * 
 * @export
 * @interface SetPricesRequest
 */
export interface SetPricesRequest {
    /**
     * 
     * @type {string}
     * @memberof SetPricesRequest
     */
    'requestType'?: SetPricesRequestRequestTypeEnum;
    /**
     * 
     * @type {Array<SetPricesItemRequest>}
     * @memberof SetPricesRequest
     */
    'items': Array<SetPricesItemRequest>;
}

export const SetPricesRequestRequestTypeEnum = {
    SetPrices: 'set-prices'
} as const;

export type SetPricesRequestRequestTypeEnum = typeof SetPricesRequestRequestTypeEnum[keyof typeof SetPricesRequestRequestTypeEnum];

/**
 * 
 * @export
 * @interface SimilarItemAssociationResponse
 */
export interface SimilarItemAssociationResponse {
    /**
     * 
     * @type {number}
     * @memberof SimilarItemAssociationResponse
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof SimilarItemAssociationResponse
     */
    'similarItemId': number;
    /**
     * 
     * @type {string}
     * @memberof SimilarItemAssociationResponse
     */
    'similarItemName': string;
}
/**
 * 
 * @export
 * @interface StartStockReceivingProcessItemRequest
 */
export interface StartStockReceivingProcessItemRequest {
    /**
     * 
     * @type {string}
     * @memberof StartStockReceivingProcessItemRequest
     */
    'quantity': string;
    /**
     * 
     * @type {string}
     * @memberof StartStockReceivingProcessItemRequest
     */
    'batchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof StartStockReceivingProcessItemRequest
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof StartStockReceivingProcessItemRequest
     */
    'countryOfOrigin': string;
    /**
     * 
     * @type {number}
     * @memberof StartStockReceivingProcessItemRequest
     */
    'itemId': number;
}
/**
 * 
 * @export
 * @interface StartStockReceivingProcessRequest
 */
export interface StartStockReceivingProcessRequest {
    /**
     * 
     * @type {number}
     * @memberof StartStockReceivingProcessRequest
     */
    'counterUserId': number;
    /**
     * 
     * @type {Array<StartStockReceivingProcessItemRequest>}
     * @memberof StartStockReceivingProcessRequest
     */
    'items': Array<StartStockReceivingProcessItemRequest>;
}
/**
 * 
 * @export
 * @interface StockReceivingProcessItemResponse
 */
export interface StockReceivingProcessItemResponse {
    /**
     * 
     * @type {number}
     * @memberof StockReceivingProcessItemResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof StockReceivingProcessItemResponse
     */
    'documentQuantity': string;
    /**
     * 
     * @type {string}
     * @memberof StockReceivingProcessItemResponse
     */
    'countedQuantity'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockReceivingProcessItemResponse
     */
    'verifiedQuantity'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockReceivingProcessItemResponse
     */
    'finalQuantity'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockReceivingProcessItemResponse
     */
    'price'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockReceivingProcessItemResponse
     */
    'batchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof StockReceivingProcessItemResponse
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof StockReceivingProcessItemResponse
     */
    'countryOfOrigin': string;
    /**
     * 
     * @type {number}
     * @memberof StockReceivingProcessItemResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof StockReceivingProcessItemResponse
     */
    'itemArtNo': string;
    /**
     * 
     * @type {string}
     * @memberof StockReceivingProcessItemResponse
     */
    'itemName': string;
    /**
     * 
     * @type {boolean}
     * @memberof StockReceivingProcessItemResponse
     */
    'itemShouldTrackBatches': boolean;
    /**
     * 
     * @type {number}
     * @memberof StockReceivingProcessItemResponse
     */
    'itemMinimumRequiredDaysOfExpirationForDelivery': number;
}
/**
 * 
 * @export
 * @interface StockReceivingProcessResponse
 */
export interface StockReceivingProcessResponse {
    /**
     * 
     * @type {number}
     * @memberof StockReceivingProcessResponse
     */
    'deliveryRequestId': number;
    /**
     * 
     * @type {number}
     * @memberof StockReceivingProcessResponse
     */
    'deliveryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof StockReceivingProcessResponse
     */
    'documentProcessingUserId': number;
    /**
     * 
     * @type {string}
     * @memberof StockReceivingProcessResponse
     */
    'documentProcessingUserName': string;
    /**
     * 
     * @type {number}
     * @memberof StockReceivingProcessResponse
     */
    'counterUserId': number;
    /**
     * 
     * @type {string}
     * @memberof StockReceivingProcessResponse
     */
    'counterUserName': string;
    /**
     * 
     * @type {number}
     * @memberof StockReceivingProcessResponse
     */
    'verifierUserId'?: number;
    /**
     * 
     * @type {string}
     * @memberof StockReceivingProcessResponse
     */
    'verifierUserName'?: string;
    /**
     * 
     * @type {number}
     * @memberof StockReceivingProcessResponse
     */
    'finalizerUserId'?: number;
    /**
     * 
     * @type {string}
     * @memberof StockReceivingProcessResponse
     */
    'finalizerUserName'?: string;
    /**
     * 
     * @type {Array<StockReceivingProcessItemResponse>}
     * @memberof StockReceivingProcessResponse
     */
    'items': Array<StockReceivingProcessItemResponse>;
}
/**
 * 
 * @export
 * @interface StockTakingReportItemResponse
 */
export interface StockTakingReportItemResponse {
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportItemResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportItemResponse
     */
    'stockTakingReportId': number;
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportItemResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof StockTakingReportItemResponse
     */
    'itemName': string;
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportItemResponse
     */
    'expectedQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportItemResponse
     */
    'countedQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportItemResponse
     */
    'confirmedQuantity': number;
}
/**
 * 
 * @export
 * @interface StockTakingReportResponse
 */
export interface StockTakingReportResponse {
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportResponse
     */
    'stockTakingId': number;
    /**
     * 
     * @type {string}
     * @memberof StockTakingReportResponse
     */
    'executionDate': string;
    /**
     * 
     * @type {string}
     * @memberof StockTakingReportResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportResponse
     */
    'userResponsibleId': number;
    /**
     * 
     * @type {string}
     * @memberof StockTakingReportResponse
     */
    'userResponsibleName': string;
    /**
     * 
     * @type {Array<StockTakingReportItemResponse>}
     * @memberof StockTakingReportResponse
     */
    'items'?: Array<StockTakingReportItemResponse>;
}
/**
 * 
 * @export
 * @interface StockTakingTaskItemResponse
 */
export interface StockTakingTaskItemResponse {
    /**
     * 
     * @type {number}
     * @memberof StockTakingTaskItemResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof StockTakingTaskItemResponse
     */
    'stockTakingTaskId': number;
    /**
     * 
     * @type {number}
     * @memberof StockTakingTaskItemResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskItemResponse
     */
    'lastModifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskItemResponse
     */
    'artNo': string;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskItemResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskItemResponse
     */
    'unitName': string;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskItemResponse
     */
    'barcodeValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskItemResponse
     */
    'additionalBarcodeValue'?: string;
    /**
     * 
     * @type {number}
     * @memberof StockTakingTaskItemResponse
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface StockTakingTaskResponse
 */
export interface StockTakingTaskResponse {
    /**
     * 
     * @type {number}
     * @memberof StockTakingTaskResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskResponse
     */
    'status': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StockTakingTaskResponse
     */
    'allowedSubsequentStatuses': Array<string>;
    /**
     * 
     * @type {Array<StockTakingTaskItemResponse>}
     * @memberof StockTakingTaskResponse
     */
    'items': Array<StockTakingTaskItemResponse>;
    /**
     * 
     * @type {number}
     * @memberof StockTakingTaskResponse
     */
    'userResponsibleId': number;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskResponse
     */
    'userResponsibleName': string;
}
/**
 * 
 * @export
 * @interface StocktakingItemResponse
 */
export interface StocktakingItemResponse {
    /**
     * 
     * @type {number}
     * @memberof StocktakingItemResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof StocktakingItemResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof StocktakingItemResponse
     */
    'itemName': string;
    /**
     * 
     * @type {string}
     * @memberof StocktakingItemResponse
     */
    'itemArtNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof StocktakingItemResponse
     */
    'itemUnitName'?: string;
}
/**
 * 
 * @export
 * @interface StocktakingRequest
 */
export interface StocktakingRequest {
    /**
     * 
     * @type {number}
     * @memberof StocktakingRequest
     */
    'warehouseId': number;
    /**
     * 
     * @type {number}
     * @memberof StocktakingRequest
     */
    'userId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof StocktakingRequest
     */
    'items': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof StocktakingRequest
     */
    'executionDate': string;
}
/**
 * 
 * @export
 * @interface StocktakingResponse
 */
export interface StocktakingResponse {
    /**
     * 
     * @type {number}
     * @memberof StocktakingResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof StocktakingResponse
     */
    'warehouseId': number;
    /**
     * 
     * @type {string}
     * @memberof StocktakingResponse
     */
    'warehouseName': string;
    /**
     * 
     * @type {Array<StocktakingItemResponse>}
     * @memberof StocktakingResponse
     */
    'items': Array<StocktakingItemResponse>;
    /**
     * 
     * @type {string}
     * @memberof StocktakingResponse
     */
    'executionDate': string;
}
/**
 * 
 * @export
 * @interface SupplierResponse
 */
export interface SupplierResponse {
    /**
     * 
     * @type {string}
     * @memberof SupplierResponse
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof SupplierResponse
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof SupplierResponse
     */
    'addressLine': string;
    /**
     * 
     * @type {string}
     * @memberof SupplierResponse
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierResponse
     */
    'plusCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierResponse
     */
    'fullAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SupplierResponse
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierResponse
     */
    'paymentInfo': string;
    /**
     * 
     * @type {number}
     * @memberof SupplierResponse
     */
    'companyCategoryId': number;
    /**
     * 
     * @type {Array<ContactResponse>}
     * @memberof SupplierResponse
     */
    'contacts'?: Array<ContactResponse>;
    /**
     * 
     * @type {string}
     * @memberof SupplierResponse
     */
    'subscriptionEmail'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierResponse
     */
    'hasSubscription'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierResponse
     */
    'hasStockSubscription'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SupplierResponse
     */
    'daysForDelivery': number;
    /**
     * 
     * @type {number}
     * @memberof SupplierResponse
     */
    'id': number;
    /**
     * 
     * @type {CompanyCategoryResponse}
     * @memberof SupplierResponse
     */
    'companyCategory': CompanyCategoryResponse;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierResponse
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof SupplierResponse
     */
    'deliveryRequestThreshold': string;
}
/**
 * 
 * @export
 * @interface SupplierSimpleResponse
 */
export interface SupplierSimpleResponse {
    /**
     * 
     * @type {number}
     * @memberof SupplierSimpleResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SupplierSimpleResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TestConnectionDetailsResponse
 */
export interface TestConnectionDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof TestConnectionDetailsResponse
     */
    'result': string;
    /**
     * 
     * @type {string}
     * @memberof TestConnectionDetailsResponse
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface TransactionResponse
 */
export interface TransactionResponse {
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof TransactionResponse
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionResponse
     */
    'transactionType': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionResponse
     */
    'treasuryId': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionResponse
     */
    'orderInvoiceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionResponse
     */
    'deliveryInvoiceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'invoiceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'treasuryName': string;
    /**
     * 
     * @type {number}
     * @memberof TransactionResponse
     */
    'deliveryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'parentInvoiceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'displayInfo'?: string;
}
/**
 * 
 * @export
 * @interface TransactionSimpleResponse
 */
export interface TransactionSimpleResponse {
    /**
     * 
     * @type {number}
     * @memberof TransactionSimpleResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSimpleResponse
     */
    'transactionType': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSimpleResponse
     */
    'treasuryId': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSimpleResponse
     */
    'orderInvoiceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionSimpleResponse
     */
    'invoiceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSimpleResponse
     */
    'parentInvoiceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSimpleResponse
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof TransactionSimpleResponse
     */
    'amount': number;
}
/**
 * 
 * @export
 * @interface TransactionSimpleV2Response
 */
export interface TransactionSimpleV2Response {
    /**
     * 
     * @type {number}
     * @memberof TransactionSimpleV2Response
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSimpleV2Response
     */
    'transactionType': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSimpleV2Response
     */
    'treasuryId': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSimpleV2Response
     */
    'orderInvoiceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionSimpleV2Response
     */
    'invoiceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSimpleV2Response
     */
    'parentInvoiceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSimpleV2Response
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSimpleV2Response
     */
    'amount': string;
}
/**
 * 
 * @export
 * @interface UnitResponse
 */
export interface UnitResponse {
    /**
     * 
     * @type {number}
     * @memberof UnitResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UnitResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateBatchesItemRequest
 */
export interface UpdateBatchesItemRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateBatchesItemRequest
     */
    'quantity': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBatchesItemRequest
     */
    'batchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBatchesItemRequest
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBatchesItemRequest
     */
    'countryOfOrigin': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBatchesItemRequest
     */
    'itemId': number;
}
/**
 * 
 * @export
 * @interface UpdateBatchesRequest
 */
export interface UpdateBatchesRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateBatchesRequest
     */
    'requestType'?: UpdateBatchesRequestRequestTypeEnum;
    /**
     * 
     * @type {Array<UpdateBatchesItemRequest>}
     * @memberof UpdateBatchesRequest
     */
    'items': Array<UpdateBatchesItemRequest>;
}

export const UpdateBatchesRequestRequestTypeEnum = {
    UpdateBatches: 'update-batches'
} as const;

export type UpdateBatchesRequestRequestTypeEnum = typeof UpdateBatchesRequestRequestTypeEnum[keyof typeof UpdateBatchesRequestRequestTypeEnum];

/**
 * 
 * @export
 * @interface UpdateCompanyCategoryRequest
 */
export interface UpdateCompanyCategoryRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyCategoryRequest
     */
    'name'?: string;
    /**
     * 
     * @type {Array<UpdateCompanyCategoryRequest>}
     * @memberof UpdateCompanyCategoryRequest
     */
    'children'?: Array<UpdateCompanyCategoryRequest>;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyCategoryRequest
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyCategoryRequest
     */
    'parentId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateDeliveryDocumentRequest
 */
export interface UpdateDeliveryDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliveryDocumentRequest
     */
    'invoiceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliveryDocumentRequest
     */
    'invoiceDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDeliveryDocumentRequest
     */
    'invoiceTotal'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliveryDocumentRequest
     */
    'paymentDue'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliveryDocumentRequest
     */
    'paymentInfo'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliveryDocumentRequest
     */
    'tradeDocumentNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDeliveryDocumentRequest
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDeliveryDocumentRequest
     */
    'deliveryDocumentType'?: number;
}
/**
 * 
 * @export
 * @interface UpdateDeliveryRequestRequest
 */
export interface UpdateDeliveryRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliveryRequestRequest
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDeliveryRequestTaskItemRequest
 */
export interface UpdateDeliveryRequestTaskItemRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliveryRequestTaskItemRequest
     */
    'quantity': string;
}
/**
 * 
 * @export
 * @interface UpdateDeliveryRequestTaskRequest
 */
export interface UpdateDeliveryRequestTaskRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliveryRequestTaskRequest
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface UpdateItemCategoryRequest
 */
export interface UpdateItemCategoryRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateItemCategoryRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateItemCategoryRequest
     */
    'aliasId'?: string;
    /**
     * 
     * @type {Array<ItemCategoryBase>}
     * @memberof UpdateItemCategoryRequest
     */
    'children'?: Array<ItemCategoryBase>;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemCategoryRequest
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemCategoryRequest
     */
    'parentId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateItemRequest
 */
export interface UpdateItemRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateItemRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateItemRequest
     */
    'artNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateItemRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateItemRequest
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemRequest
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemRequest
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemRequest
     */
    'depth'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemRequest
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemRequest
     */
    'unitId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemRequest
     */
    'deliveryRequestUnit'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemRequest
     */
    'packageQty'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemRequest
     */
    'packageQtyDelivery'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemRequest
     */
    'barcodeStandardId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateItemRequest
     */
    'barcodeValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateItemRequest
     */
    'additionalBarcodeValue'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemRequest
     */
    'basePrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemRequest
     */
    'costPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemRequest
     */
    'vat'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateItemRequest
     */
    'deliveryOnDemand'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateItemRequest
     */
    'noStorage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateItemRequest
     */
    'promo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateItemRequest
     */
    'mlm'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateItemRequest
     */
    'stockSelectable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateItemRequest
     */
    'isOwnedByUs'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemRequest
     */
    'itemCategoryId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemRequest
     */
    'supplierId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemRequest
     */
    'manufacturerId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateItemRequest
     */
    'maintainStock'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemRequest
     */
    'minimumAllowedDaysToExpiration'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemRequest
     */
    'factoryDaysToExpiration'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateItemRequest
     */
    'minimumRequiredPartOfFactoryDaysOfExpirationForDelivery'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemRequest
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateItemRequest
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateOrderV2Request
 */
export interface UpdateOrderV2Request {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderV2Request
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderV2Request
     */
    'packageQty'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderV2Request
     */
    'userResponsibleId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderV2Request
     */
    'orderPriority'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderV2Request
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderV2Request
     */
    'statusKey'?: string;
    /**
     * 
     * @type {Array<OrderItemRequest>}
     * @memberof UpdateOrderV2Request
     */
    'items'?: Array<OrderItemRequest>;
}
/**
 * 
 * @export
 * @interface UpdatePartnerBonusQuantityRequest
 */
export interface UpdatePartnerBonusQuantityRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePartnerBonusQuantityRequest
     */
    'baseQuantity'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePartnerBonusQuantityRequest
     */
    'bonusQuantity'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePartnerBonusQuantityRequest
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePartnerBonusQuantityRequest
     */
    'endDate'?: string;
}
/**
 * 
 * @export
 * @interface UpdatePricingPolicyRequest
 */
export interface UpdatePricingPolicyRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePricingPolicyRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UpdatePricingPolicyRequest
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePricingPolicyRequest
     */
    'id': number;
    /**
     * 
     * @type {Array<PricingPolicyItemCategoryRequest>}
     * @memberof UpdatePricingPolicyRequest
     */
    'itemCategories': Array<PricingPolicyItemCategoryRequest>;
    /**
     * 
     * @type {Array<PricingPolicyItemExceptionRequest>}
     * @memberof UpdatePricingPolicyRequest
     */
    'itemExceptions'?: Array<PricingPolicyItemExceptionRequest>;
}
/**
 * 
 * @export
 * @interface UpdatePrintDocumentQueueRequest
 */
export interface UpdatePrintDocumentQueueRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePrintDocumentQueueRequest
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePrintDocumentQueueRequest
     */
    'printer'?: string;
}
/**
 * 
 * @export
 * @interface UpdateStockTakingTaskItemRequest
 */
export interface UpdateStockTakingTaskItemRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateStockTakingTaskItemRequest
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface UpdateStockTakingTaskRequest
 */
export interface UpdateStockTakingTaskRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateStockTakingTaskRequest
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface UserAccessResponse
 */
export interface UserAccessResponse {
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    'lastName': string;
    /**
     * 
     * @type {number}
     * @memberof UserAccessResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAccessResponse
     */
    'groups'?: Array<string>;
    /**
     * 
     * @type {Array<RoleResponse>}
     * @memberof UserAccessResponse
     */
    'roles'?: Array<RoleResponse>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAccessResponse
     */
    'permissions'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UserAccessResponse
     */
    'companyId': number;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    'companyNote': string;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'lastName': string;
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'email'?: string;
    /**
     * 
     * @type {Array<GroupResponse>}
     * @memberof UserResponse
     */
    'groups'?: Array<GroupResponse>;
    /**
     * 
     * @type {Array<RoleResponse>}
     * @memberof UserResponse
     */
    'roles'?: Array<RoleResponse>;
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'companyNote'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface UserV2Response
 */
export interface UserV2Response {
    /**
     * 
     * @type {number}
     * @memberof UserV2Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserV2Response
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserV2Response
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationError
     */
    'loc': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface VerifyQuantitiesItemRequest
 */
export interface VerifyQuantitiesItemRequest {
    /**
     * 
     * @type {number}
     * @memberof VerifyQuantitiesItemRequest
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof VerifyQuantitiesItemRequest
     */
    'verifiedQuantity': string;
}
/**
 * 
 * @export
 * @interface VerifyQuantitiesRequest
 */
export interface VerifyQuantitiesRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyQuantitiesRequest
     */
    'requestType'?: VerifyQuantitiesRequestRequestTypeEnum;
    /**
     * 
     * @type {Array<VerifyQuantitiesItemRequest>}
     * @memberof VerifyQuantitiesRequest
     */
    'items': Array<VerifyQuantitiesItemRequest>;
}

export const VerifyQuantitiesRequestRequestTypeEnum = {
    VerifyQuantities: 'verify-quantities'
} as const;

export type VerifyQuantitiesRequestRequestTypeEnum = typeof VerifyQuantitiesRequestRequestTypeEnum[keyof typeof VerifyQuantitiesRequestRequestTypeEnum];

/**
 * 
 * @export
 * @interface WarehouseSimpleResponse
 */
export interface WarehouseSimpleResponse {
    /**
     * 
     * @type {number}
     * @memberof WarehouseSimpleResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseSimpleResponse
     */
    'name': string;
}

/**
 * AMSApi - axios parameter creator
 * @export
 */
export const AMSApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Bulk Create Partner Bonus Quantity Promotions
         * @param {BulkCreatePartnerBonusQuantityRequest} bulkCreatePartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCreatePartnerBonusQuantityPromotionsV2PromotionsBonusQuantityPost: async (bulkCreatePartnerBonusQuantityRequest: BulkCreatePartnerBonusQuantityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkCreatePartnerBonusQuantityRequest' is not null or undefined
            assertParamExists('bulkCreatePartnerBonusQuantityPromotionsV2PromotionsBonusQuantityPost', 'bulkCreatePartnerBonusQuantityRequest', bulkCreatePartnerBonusQuantityRequest)
            const localVarPath = `/v2/promotions/bonus-quantity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkCreatePartnerBonusQuantityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel Invoice By Invoice Id
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        cancelInvoiceByInvoiceIdV2InvoicesInvoiceIdPatch: async (invoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('cancelInvoiceByInvoiceIdV2InvoicesInvoiceIdPatch', 'invoiceId', invoiceId)
            const localVarPath = `/v2/invoices/{invoice_id}`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel Invoice Document
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/invoice`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create And Send Email
         * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndSendEmailV1DeliveryRequestsSendEmailPost: async (createDeliveryRequestRequest: CreateDeliveryRequestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDeliveryRequestRequest' is not null or undefined
            assertParamExists('createAndSendEmailV1DeliveryRequestsSendEmailPost', 'createDeliveryRequestRequest', createDeliveryRequestRequest)
            const localVarPath = `/v1/delivery-requests/send-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeliveryRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Delivery Document
         * @param {CreateDeliveryDocumentRequest} createDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryDocumentV1DeliveriesDeliveryDocumentPost: async (createDeliveryDocumentRequest: CreateDeliveryDocumentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDeliveryDocumentRequest' is not null or undefined
            assertParamExists('createDeliveryDocumentV1DeliveriesDeliveryDocumentPost', 'createDeliveryDocumentRequest', createDeliveryDocumentRequest)
            const localVarPath = `/v1/deliveries/delivery-document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeliveryDocumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderV2Request} createOrderV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderV2OrdersPost: async (createOrderV2Request: CreateOrderV2Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderV2Request' is not null or undefined
            assertParamExists('createOrderV2OrdersPost', 'createOrderV2Request', createOrderV2Request)
            const localVarPath = `/v2/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderV2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Print Document Queue
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/documents/print`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Stocktaking
         * @param {StocktakingRequest} stocktakingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStocktakingV2StocktakingsPost: async (stocktakingRequest: StocktakingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stocktakingRequest' is not null or undefined
            assertParamExists('createStocktakingV2StocktakingsPost', 'stocktakingRequest', stocktakingRequest)
            const localVarPath = `/v2/stocktakings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stocktakingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1CompanyCategoriesPost: async (createCompanyCategoryRequest: CreateCompanyCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyCategoryRequest' is not null or undefined
            assertParamExists('createV1CompanyCategoriesPost', 'createCompanyCategoryRequest', createCompanyCategoryRequest)
            const localVarPath = `/v1/company-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {CreateDeliveryRequest} createDeliveryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1DeliveriesPost: async (createDeliveryRequest: CreateDeliveryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDeliveryRequest' is not null or undefined
            assertParamExists('createV1DeliveriesPost', 'createDeliveryRequest', createDeliveryRequest)
            const localVarPath = `/v1/deliveries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeliveryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1DeliveryRequestsPost: async (createDeliveryRequestRequest: CreateDeliveryRequestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDeliveryRequestRequest' is not null or undefined
            assertParamExists('createV1DeliveryRequestsPost', 'createDeliveryRequestRequest', createDeliveryRequestRequest)
            const localVarPath = `/v1/delivery-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeliveryRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {CreateItemCategoryRequest} createItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1ItemCategoriesPost: async (createItemCategoryRequest: CreateItemCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createItemCategoryRequest' is not null or undefined
            assertParamExists('createV1ItemCategoriesPost', 'createItemCategoryRequest', createItemCategoryRequest)
            const localVarPath = `/v1/item-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createItemCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1ItemsItemIdLinkedItemAssociationLinkedItemIdPost: async (itemId: number, linkedItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('createV1ItemsItemIdLinkedItemAssociationLinkedItemIdPost', 'itemId', itemId)
            // verify required parameter 'linkedItemId' is not null or undefined
            assertParamExists('createV1ItemsItemIdLinkedItemAssociationLinkedItemIdPost', 'linkedItemId', linkedItemId)
            const localVarPath = `/v1/items/{item_id}/linked-item-association/{linked_item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"linked_item_id"}}`, encodeURIComponent(String(linkedItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1ItemsItemIdSimilarItemAssociationSimilarItemIdPost: async (itemId: number, similarItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('createV1ItemsItemIdSimilarItemAssociationSimilarItemIdPost', 'itemId', itemId)
            // verify required parameter 'similarItemId' is not null or undefined
            assertParamExists('createV1ItemsItemIdSimilarItemAssociationSimilarItemIdPost', 'similarItemId', similarItemId)
            const localVarPath = `/v1/items/{item_id}/similar-item-association/{similar_item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"similar_item_id"}}`, encodeURIComponent(String(similarItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {CreateItemRequest} createItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1ItemsPost: async (createItemRequest: CreateItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createItemRequest' is not null or undefined
            assertParamExists('createV1ItemsPost', 'createItemRequest', createItemRequest)
            const localVarPath = `/v1/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {CreatePricingPolicyRequest} createPricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1PricingPoliciesPost: async (createPricingPolicyRequest: CreatePricingPolicyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPricingPolicyRequest' is not null or undefined
            assertParamExists('createV1PricingPoliciesPost', 'createPricingPolicyRequest', createPricingPolicyRequest)
            const localVarPath = `/v1/pricing-policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPricingPolicyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {CreateInvoiceRequest} createInvoiceRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createV2InvoicesPost: async (createInvoiceRequest: CreateInvoiceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createInvoiceRequest' is not null or undefined
            assertParamExists('createV2InvoicesPost', 'createInvoiceRequest', createInvoiceRequest)
            const localVarPath = `/v2/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInvoiceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Partner Bonus Quantity
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerBonusQuantityV2PromotionsBonusQuantityIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePartnerBonusQuantityV2PromotionsBonusQuantityIdDelete', 'id', id)
            const localVarPath = `/v2/promotions/bonus-quantity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Picked Order Item
         * @param {number} pickedOrderItemId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete: async (pickedOrderItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pickedOrderItemId' is not null or undefined
            assertParamExists('deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete', 'pickedOrderItemId', pickedOrderItemId)
            const localVarPath = `/v2/orders/picked-order-item/{picked_order_item_id}`
                .replace(`{${"picked_order_item_id"}}`, encodeURIComponent(String(pickedOrderItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1CompanyCategoriesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteV1CompanyCategoriesIdDelete', 'id', id)
            const localVarPath = `/v1/company-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1DeliveriesDeliveryDocumentIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteV1DeliveriesDeliveryDocumentIdDelete', 'id', id)
            const localVarPath = `/v1/deliveries/delivery-document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1DeliveriesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteV1DeliveriesIdDelete', 'id', id)
            const localVarPath = `/v1/deliveries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1DeliveryRequestsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteV1DeliveryRequestsIdDelete', 'id', id)
            const localVarPath = `/v1/delivery-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1ItemCategoriesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteV1ItemCategoriesIdDelete', 'id', id)
            const localVarPath = `/v1/item-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1ItemsItemIdLinkedItemAssociationLinkedItemIdDelete: async (itemId: number, linkedItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('deleteV1ItemsItemIdLinkedItemAssociationLinkedItemIdDelete', 'itemId', itemId)
            // verify required parameter 'linkedItemId' is not null or undefined
            assertParamExists('deleteV1ItemsItemIdLinkedItemAssociationLinkedItemIdDelete', 'linkedItemId', linkedItemId)
            const localVarPath = `/v1/items/{item_id}/linked-item-association/{linked_item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"linked_item_id"}}`, encodeURIComponent(String(linkedItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1ItemsItemIdSimilarItemAssociationSimilarItemIdDelete: async (itemId: number, similarItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('deleteV1ItemsItemIdSimilarItemAssociationSimilarItemIdDelete', 'itemId', itemId)
            // verify required parameter 'similarItemId' is not null or undefined
            assertParamExists('deleteV1ItemsItemIdSimilarItemAssociationSimilarItemIdDelete', 'similarItemId', similarItemId)
            const localVarPath = `/v1/items/{item_id}/similar-item-association/{similar_item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"similar_item_id"}}`, encodeURIComponent(String(similarItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1PricingPoliciesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteV1PricingPoliciesIdDelete', 'id', id)
            const localVarPath = `/v1/pricing-policies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Aggregated Item Movements
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [partners] 
         * @param {Array<number>} [warehouses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatedItemMovementsV2ReportsItemMovementsBalanceGet: async (fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/reports/item-movements-balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (partners) {
                localVarQueryParameter['partners'] = partners;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get B2B Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getB2bOrdersV2OrdersB2bOrdersGet: async (status?: string, companies?: string, warehouses?: string, receivers?: string, legalEntities?: string, fromDeliveryDate?: string, toDeliveryDate?: string, withExecutionPercentage?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orders/b2b-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (receivers !== undefined) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (legalEntities !== undefined) {
                localVarQueryParameter['legal_entities'] = legalEntities;
            }

            if (fromDeliveryDate !== undefined) {
                localVarQueryParameter['from_delivery_date'] = (fromDeliveryDate as any instanceof Date) ?
                    (fromDeliveryDate as any).toISOString() :
                    fromDeliveryDate;
            }

            if (toDeliveryDate !== undefined) {
                localVarQueryParameter['to_delivery_date'] = (toDeliveryDate as any instanceof Date) ?
                    (toDeliveryDate as any).toISOString() :
                    toDeliveryDate;
            }

            if (withExecutionPercentage !== undefined) {
                localVarQueryParameter['with_execution_percentage'] = withExecutionPercentage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Internal Transfers
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [receivers] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getInternalTransfersV2OrdersInternalTransfersGet: async (status?: string, companies?: string, warehouses?: string, receivers?: string, fromDeliveryDate?: string, toDeliveryDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orders/internal-transfers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (receivers !== undefined) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (fromDeliveryDate !== undefined) {
                localVarQueryParameter['from_delivery_date'] = (fromDeliveryDate as any instanceof Date) ?
                    (fromDeliveryDate as any).toISOString() :
                    fromDeliveryDate;
            }

            if (toDeliveryDate !== undefined) {
                localVarQueryParameter['to_delivery_date'] = (toDeliveryDate as any instanceof Date) ?
                    (toDeliveryDate as any).toISOString() :
                    toDeliveryDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMeV1MeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Orders For Collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet', 'id', id)
            const localVarPath = `/v2/legal-entities/{id}/orders-for-collection/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Orders For Distribution Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet', 'id', id)
            const localVarPath = `/v2/legal-entities/{id}/orders-for-distribution/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Overdue Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet', 'id', id)
            const localVarPath = `/v2/legal-entities/{id}/overdue-payments/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Pending Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet', 'id', id)
            const localVarPath = `/v2/legal-entities/{id}/pending-payments/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        healthCheckV1HealthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {boolean} [withItems] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        listOrdersV2OrdersGet: async (status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (noInvoiceOnly !== undefined) {
                localVarQueryParameter['no_invoice_only'] = noInvoiceOnly;
            }

            if (withExecutionPercentage !== undefined) {
                localVarQueryParameter['with_execution_percentage'] = withExecutionPercentage;
            }

            if (withItems !== undefined) {
                localVarQueryParameter['with_items'] = withItems;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Stock Taking Report Items
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStockTakingReportItemsV2StockTakingReportItemsGet: async (itemId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/stock-taking-report-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Stock Taking Reports
         * @param {string} [fromExecutionDate] 
         * @param {string} [toExecutionDate] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStockTakingReportsV2StockTakingReportsGet: async (fromExecutionDate?: string, toExecutionDate?: string, userId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/stock-taking-reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromExecutionDate !== undefined) {
                localVarQueryParameter['from_execution_date'] = fromExecutionDate;
            }

            if (toExecutionDate !== undefined) {
                localVarQueryParameter['to_execution_date'] = toExecutionDate;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Load Info
         * @param {string} vatNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadInfoV1LegalEntitiesLoadInfoPost: async (vatNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vatNumber' is not null or undefined
            assertParamExists('loadInfoV1LegalEntitiesLoadInfoPost', 'vatNumber', vatNumber)
            const localVarPath = `/v1/legal-entities/load-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (vatNumber !== undefined) {
                localVarQueryParameter['vat_number'] = vatNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Partner Bonus Quantity
         * @param {number} id 
         * @param {UpdatePartnerBonusQuantityRequest} updatePartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPartnerBonusQuantityV2PromotionsBonusQuantityIdPatch: async (id: number, updatePartnerBonusQuantityRequest: UpdatePartnerBonusQuantityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchPartnerBonusQuantityV2PromotionsBonusQuantityIdPatch', 'id', id)
            // verify required parameter 'updatePartnerBonusQuantityRequest' is not null or undefined
            assertParamExists('patchPartnerBonusQuantityV2PromotionsBonusQuantityIdPatch', 'updatePartnerBonusQuantityRequest', updatePartnerBonusQuantityRequest)
            const localVarPath = `/v2/promotions/bonus-quantity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePartnerBonusQuantityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pick Order Item
         * @param {number} orderItemId 
         * @param {PickOrderItemRequest} pickOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        pickOrderItemV2OrdersOrderItemOrderItemIdPut: async (orderItemId: number, pickOrderItemRequest: PickOrderItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderItemId' is not null or undefined
            assertParamExists('pickOrderItemV2OrdersOrderItemOrderItemIdPut', 'orderItemId', orderItemId)
            // verify required parameter 'pickOrderItemRequest' is not null or undefined
            assertParamExists('pickOrderItemV2OrdersOrderItemOrderItemIdPut', 'pickOrderItemRequest', pickOrderItemRequest)
            const localVarPath = `/v2/orders/order-item/{order_item_id}`
                .replace(`{${"order_item_id"}}`, encodeURIComponent(String(orderItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pickOrderItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Act Of Acceptance By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet', 'id', id)
            const localVarPath = `/v2/acts-of-acceptance/{id}/document`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Act Of Acceptance Items
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet: async (actOfAcceptanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actOfAcceptanceId' is not null or undefined
            assertParamExists('readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet', 'actOfAcceptanceId', actOfAcceptanceId)
            const localVarPath = `/v2/acts-of-acceptance/{act_of_acceptance_id}/items`
                .replace(`{${"act_of_acceptance_id"}}`, encodeURIComponent(String(actOfAcceptanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet: async (actOfAcceptanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actOfAcceptanceId' is not null or undefined
            assertParamExists('readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet', 'actOfAcceptanceId', actOfAcceptanceId)
            const localVarPath = `/v2/acts-of-acceptance/{act_of_acceptance_id}`
                .replace(`{${"act_of_acceptance_id"}}`, encodeURIComponent(String(actOfAcceptanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/documents/act-of-acceptance`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Acts Of Acceptance
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {string} [users] 
         * @param {string} [orderTypes] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActsOfAcceptanceV2ActsOfAcceptanceGet: async (fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, users?: string, orderTypes?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/acts-of-acceptance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (receivers !== undefined) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (legalEntities !== undefined) {
                localVarQueryParameter['legal_entities'] = legalEntities;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (users !== undefined) {
                localVarQueryParameter['users'] = users;
            }

            if (orderTypes !== undefined) {
                localVarQueryParameter['order_types'] = orderTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All Flat
         * @param {boolean} [leafOnly] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllFlatV1ItemCategoriesFlatGet: async (leafOnly?: boolean, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/item-categories/flat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (leafOnly !== undefined) {
                localVarQueryParameter['leaf_only'] = leafOnly;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All Items
         * @param {number} [itemId] 
         * @param {number} [warehouseId] 
         * @param {boolean} [skipDepleted] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAllItemsV1DeliveriesItemsGet: async (itemId?: number, warehouseId?: number, skipDepleted?: boolean, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/deliveries/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouse_id'] = warehouseId;
            }

            if (skipDepleted !== undefined) {
                localVarQueryParameter['skip_depleted'] = skipDepleted;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All Units
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllUnitsV1ItemsUnitsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/items/units`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllV1CompanyCategoriesGet: async (skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/company-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [simple] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllV1ItemCategoriesGet: async (skip?: number, limit?: number, simple?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/item-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (simple !== undefined) {
                localVarQueryParameter['simple'] = simple;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllV1ItemsItemIdLinkedItemAssociationGet: async (itemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('readAllV1ItemsItemIdLinkedItemAssociationGet', 'itemId', itemId)
            const localVarPath = `/v1/items/{item_id}/linked-item-association`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllV1ItemsItemIdSimilarItemAssociationGet: async (itemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('readAllV1ItemsItemIdSimilarItemAssociationGet', 'itemId', itemId)
            const localVarPath = `/v1/items/{item_id}/similar-item-association`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All
         * @param {boolean} [showInactive] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAllV1UsersGet: async (showInactive?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Assigned Orders For Picker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAssignedOrdersForPickerV2OrdersPickerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orders/picker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/documents/delivery-batch`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Documents
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDocumentsV1DeliveriesDeliveryDocumentGet: async (deliveryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryId' is not null or undefined
            assertParamExists('readDeliveryDocumentsV1DeliveriesDeliveryDocumentGet', 'deliveryId', deliveryId)
            const localVarPath = `/v1/deliveries/delivery-document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (deliveryId !== undefined) {
                localVarQueryParameter['delivery_id'] = deliveryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {boolean} [hidePaid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryInvoicesV1ReportsDeliveryInvoiceGet: async (fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/delivery-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (suppliers !== undefined) {
                localVarQueryParameter['suppliers'] = suppliers;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (hidePaid !== undefined) {
                localVarQueryParameter['hide_paid'] = hidePaid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [supplierId] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryItemsV1ReportsDeliveryItemsGet: async (fromDate?: string, toDate?: string, supplierId?: number, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/delivery-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (supplierId !== undefined) {
                localVarQueryParameter['supplier_id'] = supplierId;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (itemCategories !== undefined) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Request Task
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDeliveryRequestTaskV2DeliveryRequestTasksIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readDeliveryRequestTaskV2DeliveryRequestTasksIdGet', 'id', id)
            const localVarPath = `/v2/delivery-request-tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Request Tasks By User
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDeliveryRequestTasksByUserV2DeliveryRequestTasksGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/delivery-request-tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Depleted Amounts With Prices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receviers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet: async (fromDate?: string, toDate?: string, receviers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/depleted-amounts-with-prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (receviers !== undefined) {
                localVarQueryParameter['receviers'] = receviers;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (itemCategories !== undefined) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoice Credit Notes
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceCreditNotesV2InvoicesInvoiceIdCreditNotesGet: async (invoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('readInvoiceCreditNotesV2InvoicesInvoiceIdCreditNotesGet', 'invoiceId', invoiceId)
            const localVarPath = `/v2/invoices/{invoice_id}/credit-notes`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoice Document
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceDocumentV2InvoicesInvoiceIdDocumentGet: async (invoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('readInvoiceDocumentV2InvoicesInvoiceIdDocumentGet', 'invoiceId', invoiceId)
            const localVarPath = `/v2/invoices/{invoice_id}/document`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoice Document
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/documents/invoice`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoice Orders
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceOrdersV2InvoicesInvoiceIdOrdersGet: async (invoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('readInvoiceOrdersV2InvoicesInvoiceIdOrdersGet', 'invoiceId', invoiceId)
            const localVarPath = `/v2/invoices/{invoice_id}/orders`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoice Transactions
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceTransactionsV2InvoicesInvoiceIdTransactionsGet: async (invoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('readInvoiceTransactionsV2InvoicesInvoiceIdTransactionsGet', 'invoiceId', invoiceId)
            const localVarPath = `/v2/invoices/{invoice_id}/transactions`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoice
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceV2InvoicesInvoiceIdGet: async (invoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('readInvoiceV2InvoicesInvoiceIdGet', 'invoiceId', invoiceId)
            const localVarPath = `/v2/invoices/{invoice_id}`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {boolean} [hideCancelled] 
         * @param {boolean} [hidePaidInFull] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoicesV2InvoicesGet: async (fromDate?: string, toDate?: string, legalEntities?: string, companies?: string, hideCancelled?: boolean, hidePaidInFull?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (legalEntities !== undefined) {
                localVarQueryParameter['legal_entities'] = legalEntities;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (hideCancelled !== undefined) {
                localVarQueryParameter['hide_cancelled'] = hideCancelled;
            }

            if (hidePaidInFull !== undefined) {
                localVarQueryParameter['hide_paid_in_full'] = hidePaidInFull;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [hidePaid] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readListV1DeliveriesGet: async (search?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, hidePaid?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/deliveries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (hidePaid !== undefined) {
                localVarQueryParameter['hide_paid'] = hidePaid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListV1DeliveryRequestsGet: async (search?: string, suppliers?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/delivery-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (suppliers !== undefined) {
                localVarQueryParameter['suppliers'] = suppliers;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read List
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListV1PermissionsGet: async (skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListV1PricingPoliciesGet: async (search?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/pricing-policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order By Order Id
         * @param {number} orderId 
         * @param {boolean} [withLivePrices] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderByOrderIdV2OrdersOrderIdGet: async (orderId: number, withLivePrices?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderByOrderIdV2OrdersOrderIdGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (withLivePrices !== undefined) {
                localVarQueryParameter['with_live_prices'] = withLivePrices;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdDocumentsGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderDocumentsV2OrdersOrderIdDocumentsGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/documents`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdStatusLogsGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderDocumentsV2OrdersOrderIdStatusLogsGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/status-logs`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Items
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderItemsV1ReportsOrderItemsGet: async (fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/order-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (receivers !== undefined) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (legalEntities !== undefined) {
                localVarQueryParameter['legal_entities'] = legalEntities;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (itemCategories !== undefined) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Transactions
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderTransactionsV2OrdersOrderIdTransactionsGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderTransactionsV2OrdersOrderIdTransactionsGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/transactions`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet', 'id', id)
            const localVarPath = `/v2/legal-entities/{id}/ordering-policy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Orders Balance
         * @param {string} [statusKeys] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrdersBalanceV1ReportsOrdersBalanceGet: async (statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/orders-balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (statusKeys !== undefined) {
                localVarQueryParameter['status_keys'] = statusKeys;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (noInvoiceOnly !== undefined) {
                localVarQueryParameter['no_invoice_only'] = noInvoiceOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Orders For Inspection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrdersForInspectionV2OrdersInspectorGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orders/inspector`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Partner Bonus Quantities
         * @param {number} [itemId] 
         * @param {string} [partnerIds] 
         * @param {string} [fromStartDate] 
         * @param {string} [toStartDate] 
         * @param {string} [fromEndDate] 
         * @param {string} [toEndDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnerBonusQuantitiesV2PromotionsBonusQuantityGet: async (itemId?: number, partnerIds?: string, fromStartDate?: string, toStartDate?: string, fromEndDate?: string, toEndDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/promotions/bonus-quantity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (partnerIds !== undefined) {
                localVarQueryParameter['partner_ids'] = partnerIds;
            }

            if (fromStartDate !== undefined) {
                localVarQueryParameter['from_start_date'] = (fromStartDate as any instanceof Date) ?
                    (fromStartDate as any).toISOString().substr(0,10) :
                    fromStartDate;
            }

            if (toStartDate !== undefined) {
                localVarQueryParameter['to_start_date'] = (toStartDate as any instanceof Date) ?
                    (toStartDate as any).toISOString().substr(0,10) :
                    toStartDate;
            }

            if (fromEndDate !== undefined) {
                localVarQueryParameter['from_end_date'] = (fromEndDate as any instanceof Date) ?
                    (fromEndDate as any).toISOString().substr(0,10) :
                    fromEndDate;
            }

            if (toEndDate !== undefined) {
                localVarQueryParameter['to_end_date'] = (toEndDate as any instanceof Date) ?
                    (toEndDate as any).toISOString().substr(0,10) :
                    toEndDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Categories
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet', 'id', id)
            const localVarPath = `/v1/pricing-policies/{id}/item-categories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Category Multipliers
         * @param {string} itemCategories 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet: async (itemCategories: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemCategories' is not null or undefined
            assertParamExists('readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet', 'itemCategories', itemCategories)
            const localVarPath = `/v1/reports/pricing-policy-item-category-multipliers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (itemCategories !== undefined) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Exceptions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet', 'id', id)
            const localVarPath = `/v1/pricing-policies/{id}/item-exceptions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Prices
         * @param {string} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet: async (items: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'items' is not null or undefined
            assertParamExists('readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet', 'items', items)
            const localVarPath = `/v1/reports/pricing-policy-item-prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Print Document Queue
         * @param {string} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPrintDocumentQueueV2PrintDocumentQueueGet: async (state?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/print-document-queue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Quantities To Fill Up Warehouse Reserve
         * @param {number} warehouseId 
         * @param {number} [baseDays] 
         * @param {number} [reserveDays] 
         * @param {number} [supplierId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet: async (warehouseId: number, baseDays?: number, reserveDays?: number, supplierId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet', 'warehouseId', warehouseId)
            const localVarPath = `/v1/reports/warehouse-reserve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouse_id'] = warehouseId;
            }

            if (baseDays !== undefined) {
                localVarQueryParameter['base_days'] = baseDays;
            }

            if (reserveDays !== undefined) {
                localVarQueryParameter['reserve_days'] = reserveDays;
            }

            if (supplierId !== undefined) {
                localVarQueryParameter['supplier_id'] = supplierId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stock Receiving Process By Delivery Request Id
         * @param {number} deliveryRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet: async (deliveryRequestId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryRequestId' is not null or undefined
            assertParamExists('readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet', 'deliveryRequestId', deliveryRequestId)
            const localVarPath = `/v2/delivery-requests/{delivery_request_id}/process`
                .replace(`{${"delivery_request_id"}}`, encodeURIComponent(String(deliveryRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stock Taking Report
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockTakingReportV2StockTakingReportsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readStockTakingReportV2StockTakingReportsIdGet', 'id', id)
            const localVarPath = `/v2/stock-taking-reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stock Taking Tasks By User
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readStockTakingTasksByUserV2StockTakingTaskGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/stock-taking/task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stocktaking Tasks
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStocktakingTasksV2StocktakingsIdTasksGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readStocktakingTasksV2StocktakingsIdTasksGet', 'id', id)
            const localVarPath = `/v2/stocktakings/{id}/tasks`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stocktaking
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStocktakingV2StocktakingsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readStocktakingV2StocktakingsIdGet', 'id', id)
            const localVarPath = `/v2/stocktakings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stocktakings
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [warehouses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStocktakingsV2StocktakingsGet: async (fromDate?: string, toDate?: string, warehouses?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/stocktakings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readV1CompanyCategoriesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readV1CompanyCategoriesIdGet', 'id', id)
            const localVarPath = `/v1/company-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readV1DeliveriesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readV1DeliveriesIdGet', 'id', id)
            const localVarPath = `/v1/deliveries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readV1DeliveryRequestsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readV1DeliveryRequestsIdGet', 'id', id)
            const localVarPath = `/v1/delivery-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readV1ItemCategoriesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readV1ItemCategoriesIdGet', 'id', id)
            const localVarPath = `/v1/item-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readV1PartnersPartnerIdItemCategoryAssociationGet: async (partnerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('readV1PartnersPartnerIdItemCategoryAssociationGet', 'partnerId', partnerId)
            const localVarPath = `/v1/partners/{partner_id}/item-category-association`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readV1PricingPoliciesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readV1PricingPoliciesIdGet', 'id', id)
            const localVarPath = `/v1/pricing-policies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Warehouse Stock By Orders
         * @param {number} warehouseId 
         * @param {string} toDate 
         * @param {boolean} [hideOrdersWithoutDeliveryDate] 
         * @param {boolean} [hideAvailable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehouseStockByOrdersV1ReportsStockByOrdersGet: async (warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('readWarehouseStockByOrdersV1ReportsStockByOrdersGet', 'warehouseId', warehouseId)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('readWarehouseStockByOrdersV1ReportsStockByOrdersGet', 'toDate', toDate)
            const localVarPath = `/v1/reports/stock-by-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouse_id'] = warehouseId;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (hideOrdersWithoutDeliveryDate !== undefined) {
                localVarQueryParameter['hide_orders_without_delivery_date'] = hideOrdersWithoutDeliveryDate;
            }

            if (hideAvailable !== undefined) {
                localVarQueryParameter['hide_available'] = hideAvailable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Warehouse Stock
         * @param {number} [companyId] 
         * @param {number} [warehouseId] 
         * @param {number} [itemId] 
         * @param {number} [manufacturerId] 
         * @param {string} [suppliers] 
         * @param {string} [itemCategories] 
         * @param {boolean} [hideDepleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehouseStockV1ReportsStockGet: async (companyId?: number, warehouseId?: number, itemId?: number, manufacturerId?: number, suppliers?: string, itemCategories?: string, hideDepleted?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/stock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['company_id'] = companyId;
            }

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouse_id'] = warehouseId;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturer_id'] = manufacturerId;
            }

            if (suppliers !== undefined) {
                localVarQueryParameter['suppliers'] = suppliers;
            }

            if (itemCategories !== undefined) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }

            if (hideDepleted !== undefined) {
                localVarQueryParameter['hide_depleted'] = hideDepleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return Items
         * @param {number} orderId 
         * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnItemsV2OrdersOrderIdReturnItemsPut: async (orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('returnItemsV2OrdersOrderIdReturnItemsPut', 'orderId', orderId)
            // verify required parameter 'returnOrderItemV2Request' is not null or undefined
            assertParamExists('returnItemsV2OrdersOrderIdReturnItemsPut', 'returnOrderItemV2Request', returnOrderItemV2Request)
            const localVarPath = `/v2/orders/{order_id}/return-items`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(returnOrderItemV2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut: async (id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut', 'id', id)
            // verify required parameter 'setOrderingPolicyRequest' is not null or undefined
            assertParamExists('setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut', 'setOrderingPolicyRequest', setOrderingPolicyRequest)
            const localVarPath = `/v2/legal-entities/{id}/ordering-policy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setOrderingPolicyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {StartStockReceivingProcessRequest} startStockReceivingProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost: async (deliveryRequestId: number, startStockReceivingProcessRequest: StartStockReceivingProcessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryRequestId' is not null or undefined
            assertParamExists('startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost', 'deliveryRequestId', deliveryRequestId)
            // verify required parameter 'startStockReceivingProcessRequest' is not null or undefined
            assertParamExists('startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost', 'startStockReceivingProcessRequest', startStockReceivingProcessRequest)
            const localVarPath = `/v2/delivery-requests/{delivery_request_id}/process`
                .replace(`{${"delivery_request_id"}}`, encodeURIComponent(String(deliveryRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startStockReceivingProcessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test Barsy Connection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testBarsyConnectionV1PartnersIdTestBarsyConnectionPost: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('testBarsyConnectionV1PartnersIdTestBarsyConnectionPost', 'id', id)
            const localVarPath = `/v1/partners/{id}/test-barsy-connection`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Delivery Document
         * @param {UpdateDeliveryDocumentRequest} updateDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryDocumentV1DeliveriesDeliveryDocumentPut: async (updateDeliveryDocumentRequest: UpdateDeliveryDocumentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDeliveryDocumentRequest' is not null or undefined
            assertParamExists('updateDeliveryDocumentV1DeliveriesDeliveryDocumentPut', 'updateDeliveryDocumentRequest', updateDeliveryDocumentRequest)
            const localVarPath = `/v1/deliveries/delivery-document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeliveryDocumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Delivery Request Task Item
         * @param {number} deliveryRequestTaskItemId 
         * @param {UpdateDeliveryRequestTaskItemRequest} updateDeliveryRequestTaskItemRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDeliveryRequestTaskItemV2DeliveryRequestTasksItemDeliveryRequestTaskItemIdPatch: async (deliveryRequestTaskItemId: number, updateDeliveryRequestTaskItemRequest: UpdateDeliveryRequestTaskItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryRequestTaskItemId' is not null or undefined
            assertParamExists('updateDeliveryRequestTaskItemV2DeliveryRequestTasksItemDeliveryRequestTaskItemIdPatch', 'deliveryRequestTaskItemId', deliveryRequestTaskItemId)
            // verify required parameter 'updateDeliveryRequestTaskItemRequest' is not null or undefined
            assertParamExists('updateDeliveryRequestTaskItemV2DeliveryRequestTasksItemDeliveryRequestTaskItemIdPatch', 'updateDeliveryRequestTaskItemRequest', updateDeliveryRequestTaskItemRequest)
            const localVarPath = `/v2/delivery-request-tasks/item/{delivery_request_task_item_id}`
                .replace(`{${"delivery_request_task_item_id"}}`, encodeURIComponent(String(deliveryRequestTaskItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeliveryRequestTaskItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Delivery Request Task
         * @param {number} deliveryRequestTaskId 
         * @param {UpdateDeliveryRequestTaskRequest} updateDeliveryRequestTaskRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDeliveryRequestTaskV2DeliveryRequestTasksDeliveryRequestTaskIdPatch: async (deliveryRequestTaskId: number, updateDeliveryRequestTaskRequest: UpdateDeliveryRequestTaskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryRequestTaskId' is not null or undefined
            assertParamExists('updateDeliveryRequestTaskV2DeliveryRequestTasksDeliveryRequestTaskIdPatch', 'deliveryRequestTaskId', deliveryRequestTaskId)
            // verify required parameter 'updateDeliveryRequestTaskRequest' is not null or undefined
            assertParamExists('updateDeliveryRequestTaskV2DeliveryRequestTasksDeliveryRequestTaskIdPatch', 'updateDeliveryRequestTaskRequest', updateDeliveryRequestTaskRequest)
            const localVarPath = `/v2/delivery-request-tasks/{delivery_request_task_id}`
                .replace(`{${"delivery_request_task_id"}}`, encodeURIComponent(String(deliveryRequestTaskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeliveryRequestTaskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Delivery Request
         * @param {number} deliveryRequestId 
         * @param {UpdateDeliveryRequestRequest} updateDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryRequestV1DeliveryRequestsDeliveryRequestIdPatch: async (deliveryRequestId: number, updateDeliveryRequestRequest: UpdateDeliveryRequestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryRequestId' is not null or undefined
            assertParamExists('updateDeliveryRequestV1DeliveryRequestsDeliveryRequestIdPatch', 'deliveryRequestId', deliveryRequestId)
            // verify required parameter 'updateDeliveryRequestRequest' is not null or undefined
            assertParamExists('updateDeliveryRequestV1DeliveryRequestsDeliveryRequestIdPatch', 'updateDeliveryRequestRequest', updateDeliveryRequestRequest)
            const localVarPath = `/v1/delivery-requests/{delivery_request_id}`
                .replace(`{${"delivery_request_id"}}`, encodeURIComponent(String(deliveryRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeliveryRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Order Status
         * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderStatusV2OrdersStatusPut: async (orderStatusUpdateRequest: OrderStatusUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderStatusUpdateRequest' is not null or undefined
            assertParamExists('updateOrderStatusV2OrdersStatusPut', 'orderStatusUpdateRequest', orderStatusUpdateRequest)
            const localVarPath = `/v2/orders/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderStatusUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Order
         * @param {number} orderId 
         * @param {UpdateOrderV2Request} updateOrderV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderV2OrdersOrderIdPatch: async (orderId: number, updateOrderV2Request: UpdateOrderV2Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('updateOrderV2OrdersOrderIdPatch', 'orderId', orderId)
            // verify required parameter 'updateOrderV2Request' is not null or undefined
            assertParamExists('updateOrderV2OrdersOrderIdPatch', 'updateOrderV2Request', updateOrderV2Request)
            const localVarPath = `/v2/orders/{order_id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderV2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Print Document Queue
         * @param {number} id 
         * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrintDocumentQueueV2PrintDocumentQueueIdPatch: async (id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePrintDocumentQueueV2PrintDocumentQueueIdPatch', 'id', id)
            // verify required parameter 'updatePrintDocumentQueueRequest' is not null or undefined
            assertParamExists('updatePrintDocumentQueueV2PrintDocumentQueueIdPatch', 'updatePrintDocumentQueueRequest', updatePrintDocumentQueueRequest)
            const localVarPath = `/v2/print-document-queue/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePrintDocumentQueueRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {Request} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch: async (deliveryRequestId: number, request: Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryRequestId' is not null or undefined
            assertParamExists('updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch', 'deliveryRequestId', deliveryRequestId)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch', 'request', request)
            const localVarPath = `/v2/delivery-requests/{delivery_request_id}/process`
                .replace(`{${"delivery_request_id"}}`, encodeURIComponent(String(deliveryRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Stock Taking Task Item
         * @param {number} stockTakingTaskItemId 
         * @param {UpdateStockTakingTaskItemRequest} updateStockTakingTaskItemRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateStockTakingTaskItemV2StockTakingTaskItemStockTakingTaskItemIdPatch: async (stockTakingTaskItemId: number, updateStockTakingTaskItemRequest: UpdateStockTakingTaskItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stockTakingTaskItemId' is not null or undefined
            assertParamExists('updateStockTakingTaskItemV2StockTakingTaskItemStockTakingTaskItemIdPatch', 'stockTakingTaskItemId', stockTakingTaskItemId)
            // verify required parameter 'updateStockTakingTaskItemRequest' is not null or undefined
            assertParamExists('updateStockTakingTaskItemV2StockTakingTaskItemStockTakingTaskItemIdPatch', 'updateStockTakingTaskItemRequest', updateStockTakingTaskItemRequest)
            const localVarPath = `/v2/stock-taking/task-item/{stock_taking_task_item_id}`
                .replace(`{${"stock_taking_task_item_id"}}`, encodeURIComponent(String(stockTakingTaskItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStockTakingTaskItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Stock Taking Task
         * @param {number} stockTakingTaskId 
         * @param {UpdateStockTakingTaskRequest} updateStockTakingTaskRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateStockTakingTaskV2StockTakingTaskStockTakingTaskIdPatch: async (stockTakingTaskId: number, updateStockTakingTaskRequest: UpdateStockTakingTaskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stockTakingTaskId' is not null or undefined
            assertParamExists('updateStockTakingTaskV2StockTakingTaskStockTakingTaskIdPatch', 'stockTakingTaskId', stockTakingTaskId)
            // verify required parameter 'updateStockTakingTaskRequest' is not null or undefined
            assertParamExists('updateStockTakingTaskV2StockTakingTaskStockTakingTaskIdPatch', 'updateStockTakingTaskRequest', updateStockTakingTaskRequest)
            const localVarPath = `/v2/stock-taking/task/{stock_taking_task_id}`
                .replace(`{${"stock_taking_task_id"}}`, encodeURIComponent(String(stockTakingTaskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStockTakingTaskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateV1CompanyCategoriesPut: async (updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCompanyCategoryRequest' is not null or undefined
            assertParamExists('updateV1CompanyCategoriesPut', 'updateCompanyCategoryRequest', updateCompanyCategoryRequest)
            const localVarPath = `/v1/company-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {UpdateItemCategoryRequest} updateItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateV1ItemCategoriesPut: async (updateItemCategoryRequest: UpdateItemCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateItemCategoryRequest' is not null or undefined
            assertParamExists('updateV1ItemCategoriesPut', 'updateItemCategoryRequest', updateItemCategoryRequest)
            const localVarPath = `/v1/item-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateItemCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {UpdateItemRequest} updateItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateV1ItemsPut: async (updateItemRequest: UpdateItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateItemRequest' is not null or undefined
            assertParamExists('updateV1ItemsPut', 'updateItemRequest', updateItemRequest)
            const localVarPath = `/v1/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {number} partnerId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateV1PartnersPartnerIdItemCategoryAssociationPost: async (partnerId: number, requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('updateV1PartnersPartnerIdItemCategoryAssociationPost', 'partnerId', partnerId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateV1PartnersPartnerIdItemCategoryAssociationPost', 'requestBody', requestBody)
            const localVarPath = `/v1/partners/{partner_id}/item-category-association`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {UpdatePricingPolicyRequest} updatePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateV1PricingPoliciesPut: async (updatePricingPolicyRequest: UpdatePricingPolicyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePricingPolicyRequest' is not null or undefined
            assertParamExists('updateV1PricingPoliciesPut', 'updatePricingPolicyRequest', updatePricingPolicyRequest)
            const localVarPath = `/v1/pricing-policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePricingPolicyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AMSApi - functional programming interface
 * @export
 */
export const AMSApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AMSApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Bulk Create Partner Bonus Quantity Promotions
         * @param {BulkCreatePartnerBonusQuantityRequest} bulkCreatePartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkCreatePartnerBonusQuantityPromotionsV2PromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest: BulkCreatePartnerBonusQuantityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerBonusQuantityResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCreatePartnerBonusQuantityPromotionsV2PromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel Invoice By Invoice Id
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async cancelInvoiceByInvoiceIdV2InvoicesInvoiceIdPatch(invoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelInvoiceByInvoiceIdV2InvoicesInvoiceIdPatch(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel Invoice Document
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create And Send Email
         * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAndSendEmailV1DeliveryRequestsSendEmailPost(createDeliveryRequestRequest: CreateDeliveryRequestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAndSendEmailV1DeliveryRequestsSendEmailPost(createDeliveryRequestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Delivery Document
         * @param {CreateDeliveryDocumentRequest} createDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDeliveryDocumentV1DeliveriesDeliveryDocumentPost(createDeliveryDocumentRequest: CreateDeliveryDocumentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDeliveryDocumentV1DeliveriesDeliveryDocumentPost(createDeliveryDocumentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderV2Request} createOrderV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrderV2OrdersPost(createOrderV2Request: CreateOrderV2Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrderV2OrdersPost(createOrderV2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Print Document Queue
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Stocktaking
         * @param {StocktakingRequest} stocktakingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStocktakingV2StocktakingsPost(stocktakingRequest: StocktakingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StocktakingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStocktakingV2StocktakingsPost(stocktakingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createV1CompanyCategoriesPost(createCompanyCategoryRequest: CreateCompanyCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1CompanyCategoriesPost(createCompanyCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {CreateDeliveryRequest} createDeliveryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createV1DeliveriesPost(createDeliveryRequest: CreateDeliveryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1DeliveriesPost(createDeliveryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createV1DeliveryRequestsPost(createDeliveryRequestRequest: CreateDeliveryRequestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1DeliveryRequestsPost(createDeliveryRequestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {CreateItemCategoryRequest} createItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createV1ItemCategoriesPost(createItemCategoryRequest: CreateItemCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1ItemCategoriesPost(createItemCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createV1ItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId: number, linkedItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkedItemAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1ItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId, linkedItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createV1ItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId: number, similarItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimilarItemAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1ItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId, similarItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {CreateItemRequest} createItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createV1ItemsPost(createItemRequest: CreateItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1ItemsPost(createItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {CreatePricingPolicyRequest} createPricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createV1PricingPoliciesPost(createPricingPolicyRequest: CreatePricingPolicyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1PricingPoliciesPost(createPricingPolicyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {CreateInvoiceRequest} createInvoiceRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createV2InvoicesPost(createInvoiceRequest: CreateInvoiceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV2InvoicesPost(createInvoiceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Partner Bonus Quantity
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePartnerBonusQuantityV2PromotionsBonusQuantityIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerBonusQuantityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePartnerBonusQuantityV2PromotionsBonusQuantityIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Picked Order Item
         * @param {number} pickedOrderItemId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete(pickedOrderItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchOrderItemV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete(pickedOrderItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1CompanyCategoriesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1CompanyCategoriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1DeliveriesDeliveryDocumentIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1DeliveriesDeliveryDocumentIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1DeliveriesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1DeliveriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1DeliveryRequestsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1DeliveryRequestsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1ItemCategoriesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1ItemCategoriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1ItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId: number, linkedItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkedItemAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1ItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId, linkedItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1ItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId: number, similarItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimilarItemAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1ItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId, similarItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1PricingPoliciesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1PricingPoliciesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Aggregated Item Movements
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [partners] 
         * @param {Array<number>} [warehouses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ItemMovementBalance>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate, toDate, partners, warehouses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get B2B Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getB2bOrdersV2OrdersB2bOrdersGet(status?: string, companies?: string, warehouses?: string, receivers?: string, legalEntities?: string, fromDeliveryDate?: string, toDeliveryDate?: string, withExecutionPercentage?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderV2ListResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getB2bOrdersV2OrdersB2bOrdersGet(status, companies, warehouses, receivers, legalEntities, fromDeliveryDate, toDeliveryDate, withExecutionPercentage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Internal Transfers
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [receivers] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getInternalTransfersV2OrdersInternalTransfersGet(status?: string, companies?: string, warehouses?: string, receivers?: string, fromDeliveryDate?: string, toDeliveryDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderV2ListResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInternalTransfersV2OrdersInternalTransfersGet(status, companies, warehouses, receivers, fromDeliveryDate, toDeliveryDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getMeV1MeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeV1MeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Orders For Collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntityOrdersForCollectionAmountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Orders For Distribution Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntityOrdersForDistributionAmountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Overdue Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntityOverduePaymentsAmountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Pending Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntityPendingPaymentsAmountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async healthCheckV1HealthGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCheckV1HealthGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {boolean} [withItems] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async listOrdersV2OrdersGet(status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderV2Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOrdersV2OrdersGet(status, companies, warehouses, fromDate, toDate, noInvoiceOnly, withExecutionPercentage, withItems, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Stock Taking Report Items
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStockTakingReportItemsV2StockTakingReportItemsGet(itemId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockTakingReportItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStockTakingReportItemsV2StockTakingReportItemsGet(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Stock Taking Reports
         * @param {string} [fromExecutionDate] 
         * @param {string} [toExecutionDate] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStockTakingReportsV2StockTakingReportsGet(fromExecutionDate?: string, toExecutionDate?: string, userId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockTakingReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStockTakingReportsV2StockTakingReportsGet(fromExecutionDate, toExecutionDate, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Load Info
         * @param {string} vatNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadInfoV1LegalEntitiesLoadInfoPost(vatNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadInfoV1LegalEntitiesLoadInfoPost(vatNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Partner Bonus Quantity
         * @param {number} id 
         * @param {UpdatePartnerBonusQuantityRequest} updatePartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchPartnerBonusQuantityV2PromotionsBonusQuantityIdPatch(id: number, updatePartnerBonusQuantityRequest: UpdatePartnerBonusQuantityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerBonusQuantityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchPartnerBonusQuantityV2PromotionsBonusQuantityIdPatch(id, updatePartnerBonusQuantityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pick Order Item
         * @param {number} orderItemId 
         * @param {PickOrderItemRequest} pickOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async pickOrderItemV2OrdersOrderItemOrderItemIdPut(orderItemId: number, pickOrderItemRequest: PickOrderItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pickOrderItemV2OrdersOrderItemOrderItemIdPut(orderItemId, pickOrderItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Act Of Acceptance By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Act Of Acceptance Items
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActOfAcceptanceItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActOfAcceptanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Acts Of Acceptance
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {string} [users] 
         * @param {string} [orderTypes] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, users?: string, orderTypes?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActOfAcceptanceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate, toDate, receivers, legalEntities, companies, users, orderTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All Flat
         * @param {boolean} [leafOnly] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAllFlatV1ItemCategoriesFlatGet(leafOnly?: boolean, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllFlatV1ItemCategoriesFlatGet(leafOnly, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All Items
         * @param {number} [itemId] 
         * @param {number} [warehouseId] 
         * @param {boolean} [skipDepleted] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readAllItemsV1DeliveriesItemsGet(itemId?: number, warehouseId?: number, skipDepleted?: boolean, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeliveryItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllItemsV1DeliveriesItemsGet(itemId, warehouseId, skipDepleted, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All Units
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAllUnitsV1ItemsUnitsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UnitResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllUnitsV1ItemsUnitsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAllV1CompanyCategoriesGet(skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllV1CompanyCategoriesGet(skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [simple] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAllV1ItemCategoriesGet(skip?: number, limit?: number, simple?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllV1ItemCategoriesGet(skip, limit, simple, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAllV1ItemsItemIdLinkedItemAssociationGet(itemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LinkedItemAssociationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllV1ItemsItemIdLinkedItemAssociationGet(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAllV1ItemsItemIdSimilarItemAssociationGet(itemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SimilarItemAssociationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllV1ItemsItemIdSimilarItemAssociationGet(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All
         * @param {boolean} [showInactive] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readAllV1UsersGet(showInactive?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllV1UsersGet(showInactive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Assigned Orders For Picker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAssignedOrdersForPickerV2OrdersPickerGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderV2Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAssignedOrdersForPickerV2OrdersPickerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Documents
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryDocumentsV1DeliveriesDeliveryDocumentGet(deliveryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeliveryDocumentResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryDocumentsV1DeliveriesDeliveryDocumentGet(deliveryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {boolean} [hidePaid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportDeliveryDocumentResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate, toDate, suppliers, companies, hidePaid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [supplierId] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate?: string, toDate?: string, supplierId?: number, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportDeliveryItemExtendedResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate, toDate, supplierId, companies, warehouses, itemCategories, itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Request Task
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readDeliveryRequestTaskV2DeliveryRequestTasksIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryRequestTaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryRequestTaskV2DeliveryRequestTasksIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Request Tasks By User
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readDeliveryRequestTasksByUserV2DeliveryRequestTasksGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeliveryRequestTaskResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryRequestTasksByUserV2DeliveryRequestTasksGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Depleted Amounts With Prices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receviers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate?: string, toDate?: string, receviers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportDepletedAmountWithPrices>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate, toDate, receviers, companies, warehouses, itemCategories, itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoice Credit Notes
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readInvoiceCreditNotesV2InvoicesInvoiceIdCreditNotesGet(invoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreditNoteSimpleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoiceCreditNotesV2InvoicesInvoiceIdCreditNotesGet(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoice Document
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readInvoiceDocumentV2InvoicesInvoiceIdDocumentGet(invoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoiceDocumentV2InvoicesInvoiceIdDocumentGet(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoice Document
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoice Orders
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readInvoiceOrdersV2InvoicesInvoiceIdOrdersGet(invoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderV2SimpleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoiceOrdersV2InvoicesInvoiceIdOrdersGet(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoice Transactions
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readInvoiceTransactionsV2InvoicesInvoiceIdTransactionsGet(invoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransactionSimpleV2Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoiceTransactionsV2InvoicesInvoiceIdTransactionsGet(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoice
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readInvoiceV2InvoicesInvoiceIdGet(invoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoiceV2InvoicesInvoiceIdGet(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {boolean} [hideCancelled] 
         * @param {boolean} [hidePaidInFull] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readInvoicesV2InvoicesGet(fromDate?: string, toDate?: string, legalEntities?: string, companies?: string, hideCancelled?: boolean, hidePaidInFull?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceV2Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoicesV2InvoicesGet(fromDate, toDate, legalEntities, companies, hideCancelled, hidePaidInFull, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [hidePaid] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readListV1DeliveriesGet(search?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, hidePaid?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeliveryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readListV1DeliveriesGet(search, companies, warehouses, fromDate, toDate, hidePaid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readListV1DeliveryRequestsGet(search?: string, suppliers?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeliveryRequestResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readListV1DeliveryRequestsGet(search, suppliers, companies, warehouses, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read List
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readListV1PermissionsGet(skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PermissionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readListV1PermissionsGet(skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readListV1PricingPoliciesGet(search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PricingPolicyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readListV1PricingPoliciesGet(search, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order By Order Id
         * @param {number} orderId 
         * @param {boolean} [withLivePrices] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderByOrderIdV2OrdersOrderIdGet(orderId: number, withLivePrices?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderByOrderIdV2OrdersOrderIdGet(orderId, withLivePrices, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderDocumentV2Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderStatusLogResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Items
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderItemsV1ReportsOrderItemsGet(fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportOrderItemExtendedResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderItemsV1ReportsOrderItemsGet(fromDate, toDate, receivers, legalEntities, companies, warehouses, itemCategories, itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Transactions
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderTransactionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Orders Balance
         * @param {string} [statusKeys] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportOrderBalanceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys, companies, warehouses, fromDate, toDate, noInvoiceOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Orders For Inspection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrdersForInspectionV2OrdersInspectorGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderV2Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrdersForInspectionV2OrdersInspectorGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Partner Bonus Quantities
         * @param {number} [itemId] 
         * @param {string} [partnerIds] 
         * @param {string} [fromStartDate] 
         * @param {string} [toStartDate] 
         * @param {string} [fromEndDate] 
         * @param {string} [toEndDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPartnerBonusQuantitiesV2PromotionsBonusQuantityGet(itemId?: number, partnerIds?: string, fromStartDate?: string, toStartDate?: string, fromEndDate?: string, toEndDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerBonusQuantityResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPartnerBonusQuantitiesV2PromotionsBonusQuantityGet(itemId, partnerIds, fromStartDate, toStartDate, fromEndDate, toEndDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Categories
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PricingPolicyItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Category Multipliers
         * @param {string} itemCategories 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportPricingPolicyItemCategoryMultipliersListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Exceptions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PricingPolicyItemExceptionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Prices
         * @param {string} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportPricingPolicyItemPricesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Print Document Queue
         * @param {string} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPrintDocumentQueueV2PrintDocumentQueueGet(state?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrintDocumentQueueResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPrintDocumentQueueV2PrintDocumentQueueGet(state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Quantities To Fill Up Warehouse Reserve
         * @param {number} warehouseId 
         * @param {number} [baseDays] 
         * @param {number} [reserveDays] 
         * @param {number} [supplierId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId: number, baseDays?: number, reserveDays?: number, supplierId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportWarehouseReserveResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId, baseDays, reserveDays, supplierId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stock Receiving Process By Delivery Request Id
         * @param {number} deliveryRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet(deliveryRequestId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockReceivingProcessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet(deliveryRequestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stock Taking Report
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStockTakingReportV2StockTakingReportsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockTakingReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStockTakingReportV2StockTakingReportsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stock Taking Tasks By User
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readStockTakingTasksByUserV2StockTakingTaskGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockTakingTaskResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStockTakingTasksByUserV2StockTakingTaskGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stocktaking Tasks
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStocktakingTasksV2StocktakingsIdTasksGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockTakingTaskResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStocktakingTasksV2StocktakingsIdTasksGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stocktaking
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStocktakingV2StocktakingsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StocktakingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStocktakingV2StocktakingsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stocktakings
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [warehouses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStocktakingsV2StocktakingsGet(fromDate?: string, toDate?: string, warehouses?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StocktakingResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStocktakingsV2StocktakingsGet(fromDate, toDate, warehouses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readV1CompanyCategoriesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readV1CompanyCategoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readV1DeliveriesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readV1DeliveriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readV1DeliveryRequestsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readV1DeliveryRequestsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readV1ItemCategoriesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readV1ItemCategoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readV1PricingPoliciesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readV1PricingPoliciesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Warehouse Stock By Orders
         * @param {number} warehouseId 
         * @param {string} toDate 
         * @param {boolean} [hideOrdersWithoutDeliveryDate] 
         * @param {boolean} [hideAvailable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportDeliveryItemByOrdersResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId, toDate, hideOrdersWithoutDeliveryDate, hideAvailable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Warehouse Stock
         * @param {number} [companyId] 
         * @param {number} [warehouseId] 
         * @param {number} [itemId] 
         * @param {number} [manufacturerId] 
         * @param {string} [suppliers] 
         * @param {string} [itemCategories] 
         * @param {boolean} [hideDepleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readWarehouseStockV1ReportsStockGet(companyId?: number, warehouseId?: number, itemId?: number, manufacturerId?: number, suppliers?: string, itemCategories?: string, hideDepleted?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportDeliveryItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readWarehouseStockV1ReportsStockGet(companyId, warehouseId, itemId, manufacturerId, suppliers, itemCategories, hideDepleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return Items
         * @param {number} orderId 
         * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async returnItemsV2OrdersOrderIdReturnItemsPut(orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnOrderItemsV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.returnItemsV2OrdersOrderIdReturnItemsPut(orderId, returnOrderItemV2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id, setOrderingPolicyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {StartStockReceivingProcessRequest} startStockReceivingProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId: number, startStockReceivingProcessRequest: StartStockReceivingProcessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId, startStockReceivingProcessRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Test Barsy Connection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testBarsyConnectionV1PartnersIdTestBarsyConnectionPost(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestConnectionDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testBarsyConnectionV1PartnersIdTestBarsyConnectionPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Delivery Document
         * @param {UpdateDeliveryDocumentRequest} updateDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeliveryDocumentV1DeliveriesDeliveryDocumentPut(updateDeliveryDocumentRequest: UpdateDeliveryDocumentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeliveryDocumentV1DeliveriesDeliveryDocumentPut(updateDeliveryDocumentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Delivery Request Task Item
         * @param {number} deliveryRequestTaskItemId 
         * @param {UpdateDeliveryRequestTaskItemRequest} updateDeliveryRequestTaskItemRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateDeliveryRequestTaskItemV2DeliveryRequestTasksItemDeliveryRequestTaskItemIdPatch(deliveryRequestTaskItemId: number, updateDeliveryRequestTaskItemRequest: UpdateDeliveryRequestTaskItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryRequestTaskItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeliveryRequestTaskItemV2DeliveryRequestTasksItemDeliveryRequestTaskItemIdPatch(deliveryRequestTaskItemId, updateDeliveryRequestTaskItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Delivery Request Task
         * @param {number} deliveryRequestTaskId 
         * @param {UpdateDeliveryRequestTaskRequest} updateDeliveryRequestTaskRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateDeliveryRequestTaskV2DeliveryRequestTasksDeliveryRequestTaskIdPatch(deliveryRequestTaskId: number, updateDeliveryRequestTaskRequest: UpdateDeliveryRequestTaskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryRequestTaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeliveryRequestTaskV2DeliveryRequestTasksDeliveryRequestTaskIdPatch(deliveryRequestTaskId, updateDeliveryRequestTaskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Delivery Request
         * @param {number} deliveryRequestId 
         * @param {UpdateDeliveryRequestRequest} updateDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeliveryRequestV1DeliveryRequestsDeliveryRequestIdPatch(deliveryRequestId: number, updateDeliveryRequestRequest: UpdateDeliveryRequestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeliveryRequestV1DeliveryRequestsDeliveryRequestIdPatch(deliveryRequestId, updateDeliveryRequestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Order Status
         * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest: OrderStatusUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Order
         * @param {number} orderId 
         * @param {UpdateOrderV2Request} updateOrderV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderV2OrdersOrderIdPatch(orderId: number, updateOrderV2Request: UpdateOrderV2Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderV2OrdersOrderIdPatch(orderId, updateOrderV2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Print Document Queue
         * @param {number} id 
         * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrintDocumentQueueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id, updatePrintDocumentQueueRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {Request} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId: number, request: Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockReceivingProcessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Stock Taking Task Item
         * @param {number} stockTakingTaskItemId 
         * @param {UpdateStockTakingTaskItemRequest} updateStockTakingTaskItemRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateStockTakingTaskItemV2StockTakingTaskItemStockTakingTaskItemIdPatch(stockTakingTaskItemId: number, updateStockTakingTaskItemRequest: UpdateStockTakingTaskItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockTakingTaskItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStockTakingTaskItemV2StockTakingTaskItemStockTakingTaskItemIdPatch(stockTakingTaskItemId, updateStockTakingTaskItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Stock Taking Task
         * @param {number} stockTakingTaskId 
         * @param {UpdateStockTakingTaskRequest} updateStockTakingTaskRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateStockTakingTaskV2StockTakingTaskStockTakingTaskIdPatch(stockTakingTaskId: number, updateStockTakingTaskRequest: UpdateStockTakingTaskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockTakingTaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStockTakingTaskV2StockTakingTaskStockTakingTaskIdPatch(stockTakingTaskId, updateStockTakingTaskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateV1CompanyCategoriesPut(updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateV1CompanyCategoriesPut(updateCompanyCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {UpdateItemCategoryRequest} updateItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateV1ItemCategoriesPut(updateItemCategoryRequest: UpdateItemCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateV1ItemCategoriesPut(updateItemCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {UpdateItemRequest} updateItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateV1ItemsPut(updateItemRequest: UpdateItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateV1ItemsPut(updateItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {number} partnerId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId: number, requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {UpdatePricingPolicyRequest} updatePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateV1PricingPoliciesPut(updatePricingPolicyRequest: UpdatePricingPolicyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateV1PricingPoliciesPut(updatePricingPolicyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AMSApi - factory interface
 * @export
 */
export const AMSApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AMSApiFp(configuration)
    return {
        /**
         * 
         * @summary Bulk Create Partner Bonus Quantity Promotions
         * @param {BulkCreatePartnerBonusQuantityRequest} bulkCreatePartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCreatePartnerBonusQuantityPromotionsV2PromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest: BulkCreatePartnerBonusQuantityRequest, options?: any): AxiosPromise<Array<PartnerBonusQuantityResponse>> {
            return localVarFp.bulkCreatePartnerBonusQuantityPromotionsV2PromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel Invoice By Invoice Id
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        cancelInvoiceByInvoiceIdV2InvoicesInvoiceIdPatch(invoiceId: number, options?: any): AxiosPromise<InvoiceV2Response> {
            return localVarFp.cancelInvoiceByInvoiceIdV2InvoicesInvoiceIdPatch(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel Invoice Document
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete(orderId: number, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create And Send Email
         * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndSendEmailV1DeliveryRequestsSendEmailPost(createDeliveryRequestRequest: CreateDeliveryRequestRequest, options?: any): AxiosPromise<DeliveryRequestResponse> {
            return localVarFp.createAndSendEmailV1DeliveryRequestsSendEmailPost(createDeliveryRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Delivery Document
         * @param {CreateDeliveryDocumentRequest} createDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryDocumentV1DeliveriesDeliveryDocumentPost(createDeliveryDocumentRequest: CreateDeliveryDocumentRequest, options?: any): AxiosPromise<DeliveryDocumentResponse> {
            return localVarFp.createDeliveryDocumentV1DeliveriesDeliveryDocumentPost(createDeliveryDocumentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderV2Request} createOrderV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderV2OrdersPost(createOrderV2Request: CreateOrderV2Request, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.createOrderV2OrdersPost(createOrderV2Request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Print Document Queue
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost(orderId: number, options?: any): AxiosPromise<void> {
            return localVarFp.createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Stocktaking
         * @param {StocktakingRequest} stocktakingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStocktakingV2StocktakingsPost(stocktakingRequest: StocktakingRequest, options?: any): AxiosPromise<StocktakingResponse> {
            return localVarFp.createStocktakingV2StocktakingsPost(stocktakingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1CompanyCategoriesPost(createCompanyCategoryRequest: CreateCompanyCategoryRequest, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.createV1CompanyCategoriesPost(createCompanyCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {CreateDeliveryRequest} createDeliveryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1DeliveriesPost(createDeliveryRequest: CreateDeliveryRequest, options?: any): AxiosPromise<DeliveryResponse> {
            return localVarFp.createV1DeliveriesPost(createDeliveryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1DeliveryRequestsPost(createDeliveryRequestRequest: CreateDeliveryRequestRequest, options?: any): AxiosPromise<DeliveryRequestResponse> {
            return localVarFp.createV1DeliveryRequestsPost(createDeliveryRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {CreateItemCategoryRequest} createItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1ItemCategoriesPost(createItemCategoryRequest: CreateItemCategoryRequest, options?: any): AxiosPromise<ItemCategoryResponse> {
            return localVarFp.createV1ItemCategoriesPost(createItemCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1ItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId: number, linkedItemId: number, options?: any): AxiosPromise<LinkedItemAssociationResponse> {
            return localVarFp.createV1ItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId, linkedItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1ItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId: number, similarItemId: number, options?: any): AxiosPromise<SimilarItemAssociationResponse> {
            return localVarFp.createV1ItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId, similarItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {CreateItemRequest} createItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1ItemsPost(createItemRequest: CreateItemRequest, options?: any): AxiosPromise<ItemResponse> {
            return localVarFp.createV1ItemsPost(createItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {CreatePricingPolicyRequest} createPricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1PricingPoliciesPost(createPricingPolicyRequest: CreatePricingPolicyRequest, options?: any): AxiosPromise<PricingPolicyResponse> {
            return localVarFp.createV1PricingPoliciesPost(createPricingPolicyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {CreateInvoiceRequest} createInvoiceRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createV2InvoicesPost(createInvoiceRequest: CreateInvoiceRequest, options?: any): AxiosPromise<InvoiceResponse> {
            return localVarFp.createV2InvoicesPost(createInvoiceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Partner Bonus Quantity
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerBonusQuantityV2PromotionsBonusQuantityIdDelete(id: number, options?: any): AxiosPromise<PartnerBonusQuantityResponse> {
            return localVarFp.deletePartnerBonusQuantityV2PromotionsBonusQuantityIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Picked Order Item
         * @param {number} pickedOrderItemId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete(pickedOrderItemId: number, options?: any): AxiosPromise<BatchOrderItemV2Response> {
            return localVarFp.deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete(pickedOrderItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1CompanyCategoriesIdDelete(id: number, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.deleteV1CompanyCategoriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1DeliveriesDeliveryDocumentIdDelete(id: number, options?: any): AxiosPromise<DeliveryDocumentResponse> {
            return localVarFp.deleteV1DeliveriesDeliveryDocumentIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1DeliveriesIdDelete(id: number, options?: any): AxiosPromise<DeliveryResponse> {
            return localVarFp.deleteV1DeliveriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1DeliveryRequestsIdDelete(id: number, options?: any): AxiosPromise<DeliveryRequestResponse> {
            return localVarFp.deleteV1DeliveryRequestsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1ItemCategoriesIdDelete(id: number, options?: any): AxiosPromise<ItemCategoryResponse> {
            return localVarFp.deleteV1ItemCategoriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1ItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId: number, linkedItemId: number, options?: any): AxiosPromise<LinkedItemAssociationResponse> {
            return localVarFp.deleteV1ItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId, linkedItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1ItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId: number, similarItemId: number, options?: any): AxiosPromise<SimilarItemAssociationResponse> {
            return localVarFp.deleteV1ItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId, similarItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1PricingPoliciesIdDelete(id: number, options?: any): AxiosPromise<PricingPolicyResponse> {
            return localVarFp.deleteV1PricingPoliciesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Aggregated Item Movements
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [partners] 
         * @param {Array<number>} [warehouses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, options?: any): AxiosPromise<Array<ItemMovementBalance>> {
            return localVarFp.getAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate, toDate, partners, warehouses, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get B2B Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getB2bOrdersV2OrdersB2bOrdersGet(status?: string, companies?: string, warehouses?: string, receivers?: string, legalEntities?: string, fromDeliveryDate?: string, toDeliveryDate?: string, withExecutionPercentage?: boolean, options?: any): AxiosPromise<Array<OrderV2ListResponse>> {
            return localVarFp.getB2bOrdersV2OrdersB2bOrdersGet(status, companies, warehouses, receivers, legalEntities, fromDeliveryDate, toDeliveryDate, withExecutionPercentage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Internal Transfers
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [receivers] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getInternalTransfersV2OrdersInternalTransfersGet(status?: string, companies?: string, warehouses?: string, receivers?: string, fromDeliveryDate?: string, toDeliveryDate?: string, options?: any): AxiosPromise<Array<OrderV2ListResponse>> {
            return localVarFp.getInternalTransfersV2OrdersInternalTransfersGet(status, companies, warehouses, receivers, fromDeliveryDate, toDeliveryDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMeV1MeGet(options?: any): AxiosPromise<UserAccessResponse> {
            return localVarFp.getMeV1MeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Orders For Collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id: number, options?: any): AxiosPromise<LegalEntityOrdersForCollectionAmountResponse> {
            return localVarFp.getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Orders For Distribution Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id: number, options?: any): AxiosPromise<LegalEntityOrdersForDistributionAmountResponse> {
            return localVarFp.getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Overdue Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id: number, options?: any): AxiosPromise<LegalEntityOverduePaymentsAmountResponse> {
            return localVarFp.getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Pending Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id: number, options?: any): AxiosPromise<LegalEntityPendingPaymentsAmountResponse> {
            return localVarFp.getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        healthCheckV1HealthGet(options?: any): AxiosPromise<any> {
            return localVarFp.healthCheckV1HealthGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {boolean} [withItems] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        listOrdersV2OrdersGet(status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options?: any): AxiosPromise<Array<OrderV2Response>> {
            return localVarFp.listOrdersV2OrdersGet(status, companies, warehouses, fromDate, toDate, noInvoiceOnly, withExecutionPercentage, withItems, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Stock Taking Report Items
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStockTakingReportItemsV2StockTakingReportItemsGet(itemId?: number, options?: any): AxiosPromise<Array<StockTakingReportItemResponse>> {
            return localVarFp.listStockTakingReportItemsV2StockTakingReportItemsGet(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Stock Taking Reports
         * @param {string} [fromExecutionDate] 
         * @param {string} [toExecutionDate] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStockTakingReportsV2StockTakingReportsGet(fromExecutionDate?: string, toExecutionDate?: string, userId?: number, options?: any): AxiosPromise<Array<StockTakingReportResponse>> {
            return localVarFp.listStockTakingReportsV2StockTakingReportsGet(fromExecutionDate, toExecutionDate, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Load Info
         * @param {string} vatNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadInfoV1LegalEntitiesLoadInfoPost(vatNumber: string, options?: any): AxiosPromise<CompanyInfo> {
            return localVarFp.loadInfoV1LegalEntitiesLoadInfoPost(vatNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Partner Bonus Quantity
         * @param {number} id 
         * @param {UpdatePartnerBonusQuantityRequest} updatePartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPartnerBonusQuantityV2PromotionsBonusQuantityIdPatch(id: number, updatePartnerBonusQuantityRequest: UpdatePartnerBonusQuantityRequest, options?: any): AxiosPromise<PartnerBonusQuantityResponse> {
            return localVarFp.patchPartnerBonusQuantityV2PromotionsBonusQuantityIdPatch(id, updatePartnerBonusQuantityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pick Order Item
         * @param {number} orderItemId 
         * @param {PickOrderItemRequest} pickOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        pickOrderItemV2OrdersOrderItemOrderItemIdPut(orderItemId: number, pickOrderItemRequest: PickOrderItemRequest, options?: any): AxiosPromise<OrderItemV2Response> {
            return localVarFp.pickOrderItemV2OrdersOrderItemOrderItemIdPut(orderItemId, pickOrderItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Act Of Acceptance By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Act Of Acceptance Items
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId: number, options?: any): AxiosPromise<Array<ActOfAcceptanceItemResponse>> {
            return localVarFp.readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId: number, options?: any): AxiosPromise<ActOfAcceptanceResponse> {
            return localVarFp.readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet(orderId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Acts Of Acceptance
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {string} [users] 
         * @param {string} [orderTypes] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, users?: string, orderTypes?: string, options?: any): AxiosPromise<Array<ActOfAcceptanceResponse>> {
            return localVarFp.readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate, toDate, receivers, legalEntities, companies, users, orderTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All Flat
         * @param {boolean} [leafOnly] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllFlatV1ItemCategoriesFlatGet(leafOnly?: boolean, skip?: number, limit?: number, options?: any): AxiosPromise<Array<ItemCategoryResponse>> {
            return localVarFp.readAllFlatV1ItemCategoriesFlatGet(leafOnly, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All Items
         * @param {number} [itemId] 
         * @param {number} [warehouseId] 
         * @param {boolean} [skipDepleted] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAllItemsV1DeliveriesItemsGet(itemId?: number, warehouseId?: number, skipDepleted?: boolean, skip?: number, limit?: number, options?: any): AxiosPromise<Array<DeliveryItemResponse>> {
            return localVarFp.readAllItemsV1DeliveriesItemsGet(itemId, warehouseId, skipDepleted, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All Units
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllUnitsV1ItemsUnitsGet(options?: any): AxiosPromise<Array<UnitResponse>> {
            return localVarFp.readAllUnitsV1ItemsUnitsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllV1CompanyCategoriesGet(skip?: number, limit?: number, options?: any): AxiosPromise<Array<CompanyCategoryResponse>> {
            return localVarFp.readAllV1CompanyCategoriesGet(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [simple] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllV1ItemCategoriesGet(skip?: number, limit?: number, simple?: boolean, options?: any): AxiosPromise<Array<ItemCategoryResponse>> {
            return localVarFp.readAllV1ItemCategoriesGet(skip, limit, simple, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllV1ItemsItemIdLinkedItemAssociationGet(itemId: number, options?: any): AxiosPromise<Array<LinkedItemAssociationResponse>> {
            return localVarFp.readAllV1ItemsItemIdLinkedItemAssociationGet(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllV1ItemsItemIdSimilarItemAssociationGet(itemId: number, options?: any): AxiosPromise<Array<SimilarItemAssociationResponse>> {
            return localVarFp.readAllV1ItemsItemIdSimilarItemAssociationGet(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All
         * @param {boolean} [showInactive] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAllV1UsersGet(showInactive?: boolean, options?: any): AxiosPromise<Array<UserResponse>> {
            return localVarFp.readAllV1UsersGet(showInactive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Assigned Orders For Picker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAssignedOrdersForPickerV2OrdersPickerGet(options?: any): AxiosPromise<Array<OrderV2Response>> {
            return localVarFp.readAssignedOrdersForPickerV2OrdersPickerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Documents
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDocumentsV1DeliveriesDeliveryDocumentGet(deliveryId: number, options?: any): AxiosPromise<Array<DeliveryDocumentResponse>> {
            return localVarFp.readDeliveryDocumentsV1DeliveriesDeliveryDocumentGet(deliveryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {boolean} [hidePaid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, options?: any): AxiosPromise<Array<ReportDeliveryDocumentResponse>> {
            return localVarFp.readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate, toDate, suppliers, companies, hidePaid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [supplierId] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate?: string, toDate?: string, supplierId?: number, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: any): AxiosPromise<Array<ReportDeliveryItemExtendedResponse>> {
            return localVarFp.readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate, toDate, supplierId, companies, warehouses, itemCategories, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Request Task
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDeliveryRequestTaskV2DeliveryRequestTasksIdGet(id: number, options?: any): AxiosPromise<DeliveryRequestTaskResponse> {
            return localVarFp.readDeliveryRequestTaskV2DeliveryRequestTasksIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Request Tasks By User
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDeliveryRequestTasksByUserV2DeliveryRequestTasksGet(options?: any): AxiosPromise<Array<DeliveryRequestTaskResponse>> {
            return localVarFp.readDeliveryRequestTasksByUserV2DeliveryRequestTasksGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Depleted Amounts With Prices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receviers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate?: string, toDate?: string, receviers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: any): AxiosPromise<Array<ReportDepletedAmountWithPrices>> {
            return localVarFp.readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate, toDate, receviers, companies, warehouses, itemCategories, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoice Credit Notes
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceCreditNotesV2InvoicesInvoiceIdCreditNotesGet(invoiceId: number, options?: any): AxiosPromise<Array<CreditNoteSimpleResponse>> {
            return localVarFp.readInvoiceCreditNotesV2InvoicesInvoiceIdCreditNotesGet(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoice Document
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceDocumentV2InvoicesInvoiceIdDocumentGet(invoiceId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readInvoiceDocumentV2InvoicesInvoiceIdDocumentGet(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoice Document
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet(orderId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoice Orders
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceOrdersV2InvoicesInvoiceIdOrdersGet(invoiceId: number, options?: any): AxiosPromise<Array<OrderV2SimpleResponse>> {
            return localVarFp.readInvoiceOrdersV2InvoicesInvoiceIdOrdersGet(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoice Transactions
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceTransactionsV2InvoicesInvoiceIdTransactionsGet(invoiceId: number, options?: any): AxiosPromise<Array<TransactionSimpleV2Response>> {
            return localVarFp.readInvoiceTransactionsV2InvoicesInvoiceIdTransactionsGet(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoice
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceV2InvoicesInvoiceIdGet(invoiceId: number, options?: any): AxiosPromise<InvoiceResponse> {
            return localVarFp.readInvoiceV2InvoicesInvoiceIdGet(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {boolean} [hideCancelled] 
         * @param {boolean} [hidePaidInFull] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoicesV2InvoicesGet(fromDate?: string, toDate?: string, legalEntities?: string, companies?: string, hideCancelled?: boolean, hidePaidInFull?: boolean, options?: any): AxiosPromise<Array<InvoiceV2Response>> {
            return localVarFp.readInvoicesV2InvoicesGet(fromDate, toDate, legalEntities, companies, hideCancelled, hidePaidInFull, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [hidePaid] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readListV1DeliveriesGet(search?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, hidePaid?: boolean, options?: any): AxiosPromise<Array<DeliveryResponse>> {
            return localVarFp.readListV1DeliveriesGet(search, companies, warehouses, fromDate, toDate, hidePaid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListV1DeliveryRequestsGet(search?: string, suppliers?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<DeliveryRequestResponse>> {
            return localVarFp.readListV1DeliveryRequestsGet(search, suppliers, companies, warehouses, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read List
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListV1PermissionsGet(skip?: number, limit?: number, options?: any): AxiosPromise<Array<PermissionResponse>> {
            return localVarFp.readListV1PermissionsGet(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListV1PricingPoliciesGet(search?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<PricingPolicyResponse>> {
            return localVarFp.readListV1PricingPoliciesGet(search, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order By Order Id
         * @param {number} orderId 
         * @param {boolean} [withLivePrices] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderByOrderIdV2OrdersOrderIdGet(orderId: number, withLivePrices?: boolean, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.readOrderByOrderIdV2OrdersOrderIdGet(orderId, withLivePrices, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId: number, options?: any): AxiosPromise<Array<OrderDocumentV2Response>> {
            return localVarFp.readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId: number, options?: any): AxiosPromise<Array<OrderStatusLogResponse>> {
            return localVarFp.readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Items
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderItemsV1ReportsOrderItemsGet(fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: any): AxiosPromise<Array<ReportOrderItemExtendedResponse>> {
            return localVarFp.readOrderItemsV1ReportsOrderItemsGet(fromDate, toDate, receivers, legalEntities, companies, warehouses, itemCategories, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Transactions
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId: number, options?: any): AxiosPromise<Array<OrderTransactionResponse>> {
            return localVarFp.readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id: number, options?: any): AxiosPromise<OrderingPolicyResponse> {
            return localVarFp.readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Orders Balance
         * @param {string} [statusKeys] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, options?: any): AxiosPromise<Array<ReportOrderBalanceResponse>> {
            return localVarFp.readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys, companies, warehouses, fromDate, toDate, noInvoiceOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Orders For Inspection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrdersForInspectionV2OrdersInspectorGet(options?: any): AxiosPromise<Array<OrderV2Response>> {
            return localVarFp.readOrdersForInspectionV2OrdersInspectorGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Partner Bonus Quantities
         * @param {number} [itemId] 
         * @param {string} [partnerIds] 
         * @param {string} [fromStartDate] 
         * @param {string} [toStartDate] 
         * @param {string} [fromEndDate] 
         * @param {string} [toEndDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnerBonusQuantitiesV2PromotionsBonusQuantityGet(itemId?: number, partnerIds?: string, fromStartDate?: string, toStartDate?: string, fromEndDate?: string, toEndDate?: string, options?: any): AxiosPromise<Array<PartnerBonusQuantityResponse>> {
            return localVarFp.readPartnerBonusQuantitiesV2PromotionsBonusQuantityGet(itemId, partnerIds, fromStartDate, toStartDate, fromEndDate, toEndDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Categories
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id: number, options?: any): AxiosPromise<Array<PricingPolicyItemCategoryResponse>> {
            return localVarFp.readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Category Multipliers
         * @param {string} itemCategories 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories: string, options?: any): AxiosPromise<ReportPricingPolicyItemCategoryMultipliersListResponse> {
            return localVarFp.readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Exceptions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id: number, options?: any): AxiosPromise<Array<PricingPolicyItemExceptionResponse>> {
            return localVarFp.readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Prices
         * @param {string} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items: string, options?: any): AxiosPromise<ReportPricingPolicyItemPricesListResponse> {
            return localVarFp.readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Print Document Queue
         * @param {string} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPrintDocumentQueueV2PrintDocumentQueueGet(state?: string, options?: any): AxiosPromise<Array<PrintDocumentQueueResponse>> {
            return localVarFp.readPrintDocumentQueueV2PrintDocumentQueueGet(state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Quantities To Fill Up Warehouse Reserve
         * @param {number} warehouseId 
         * @param {number} [baseDays] 
         * @param {number} [reserveDays] 
         * @param {number} [supplierId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId: number, baseDays?: number, reserveDays?: number, supplierId?: number, options?: any): AxiosPromise<Array<ReportWarehouseReserveResponse>> {
            return localVarFp.readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId, baseDays, reserveDays, supplierId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stock Receiving Process By Delivery Request Id
         * @param {number} deliveryRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet(deliveryRequestId: number, options?: any): AxiosPromise<StockReceivingProcessResponse> {
            return localVarFp.readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet(deliveryRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stock Taking Report
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockTakingReportV2StockTakingReportsIdGet(id: number, options?: any): AxiosPromise<StockTakingReportResponse> {
            return localVarFp.readStockTakingReportV2StockTakingReportsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stock Taking Tasks By User
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readStockTakingTasksByUserV2StockTakingTaskGet(options?: any): AxiosPromise<Array<StockTakingTaskResponse>> {
            return localVarFp.readStockTakingTasksByUserV2StockTakingTaskGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stocktaking Tasks
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStocktakingTasksV2StocktakingsIdTasksGet(id: number, options?: any): AxiosPromise<Array<StockTakingTaskResponse>> {
            return localVarFp.readStocktakingTasksV2StocktakingsIdTasksGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stocktaking
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStocktakingV2StocktakingsIdGet(id: number, options?: any): AxiosPromise<StocktakingResponse> {
            return localVarFp.readStocktakingV2StocktakingsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stocktakings
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [warehouses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStocktakingsV2StocktakingsGet(fromDate?: string, toDate?: string, warehouses?: string, options?: any): AxiosPromise<Array<StocktakingResponse>> {
            return localVarFp.readStocktakingsV2StocktakingsGet(fromDate, toDate, warehouses, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readV1CompanyCategoriesIdGet(id: number, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.readV1CompanyCategoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readV1DeliveriesIdGet(id: number, options?: any): AxiosPromise<DeliveryResponse> {
            return localVarFp.readV1DeliveriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readV1DeliveryRequestsIdGet(id: number, options?: any): AxiosPromise<DeliveryRequestResponse> {
            return localVarFp.readV1DeliveryRequestsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readV1ItemCategoriesIdGet(id: number, options?: any): AxiosPromise<ItemCategoryResponse> {
            return localVarFp.readV1ItemCategoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId: number, options?: any): AxiosPromise<Array<PartnerItemCategoryResponse>> {
            return localVarFp.readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readV1PricingPoliciesIdGet(id: number, options?: any): AxiosPromise<PricingPolicyResponse> {
            return localVarFp.readV1PricingPoliciesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Warehouse Stock By Orders
         * @param {number} warehouseId 
         * @param {string} toDate 
         * @param {boolean} [hideOrdersWithoutDeliveryDate] 
         * @param {boolean} [hideAvailable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options?: any): AxiosPromise<Array<ReportDeliveryItemByOrdersResponse>> {
            return localVarFp.readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId, toDate, hideOrdersWithoutDeliveryDate, hideAvailable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Warehouse Stock
         * @param {number} [companyId] 
         * @param {number} [warehouseId] 
         * @param {number} [itemId] 
         * @param {number} [manufacturerId] 
         * @param {string} [suppliers] 
         * @param {string} [itemCategories] 
         * @param {boolean} [hideDepleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehouseStockV1ReportsStockGet(companyId?: number, warehouseId?: number, itemId?: number, manufacturerId?: number, suppliers?: string, itemCategories?: string, hideDepleted?: boolean, options?: any): AxiosPromise<Array<ReportDeliveryItemResponse>> {
            return localVarFp.readWarehouseStockV1ReportsStockGet(companyId, warehouseId, itemId, manufacturerId, suppliers, itemCategories, hideDepleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return Items
         * @param {number} orderId 
         * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnItemsV2OrdersOrderIdReturnItemsPut(orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options?: any): AxiosPromise<ReturnOrderItemsV2Response> {
            return localVarFp.returnItemsV2OrdersOrderIdReturnItemsPut(orderId, returnOrderItemV2Request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: any): AxiosPromise<OrderingPolicyResponse> {
            return localVarFp.setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id, setOrderingPolicyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {StartStockReceivingProcessRequest} startStockReceivingProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId: number, startStockReceivingProcessRequest: StartStockReceivingProcessRequest, options?: any): AxiosPromise<void> {
            return localVarFp.startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId, startStockReceivingProcessRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Test Barsy Connection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testBarsyConnectionV1PartnersIdTestBarsyConnectionPost(id: number, options?: any): AxiosPromise<TestConnectionDetailsResponse> {
            return localVarFp.testBarsyConnectionV1PartnersIdTestBarsyConnectionPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Delivery Document
         * @param {UpdateDeliveryDocumentRequest} updateDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryDocumentV1DeliveriesDeliveryDocumentPut(updateDeliveryDocumentRequest: UpdateDeliveryDocumentRequest, options?: any): AxiosPromise<DeliveryDocumentResponse> {
            return localVarFp.updateDeliveryDocumentV1DeliveriesDeliveryDocumentPut(updateDeliveryDocumentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Delivery Request Task Item
         * @param {number} deliveryRequestTaskItemId 
         * @param {UpdateDeliveryRequestTaskItemRequest} updateDeliveryRequestTaskItemRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDeliveryRequestTaskItemV2DeliveryRequestTasksItemDeliveryRequestTaskItemIdPatch(deliveryRequestTaskItemId: number, updateDeliveryRequestTaskItemRequest: UpdateDeliveryRequestTaskItemRequest, options?: any): AxiosPromise<DeliveryRequestTaskItemResponse> {
            return localVarFp.updateDeliveryRequestTaskItemV2DeliveryRequestTasksItemDeliveryRequestTaskItemIdPatch(deliveryRequestTaskItemId, updateDeliveryRequestTaskItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Delivery Request Task
         * @param {number} deliveryRequestTaskId 
         * @param {UpdateDeliveryRequestTaskRequest} updateDeliveryRequestTaskRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDeliveryRequestTaskV2DeliveryRequestTasksDeliveryRequestTaskIdPatch(deliveryRequestTaskId: number, updateDeliveryRequestTaskRequest: UpdateDeliveryRequestTaskRequest, options?: any): AxiosPromise<DeliveryRequestTaskResponse> {
            return localVarFp.updateDeliveryRequestTaskV2DeliveryRequestTasksDeliveryRequestTaskIdPatch(deliveryRequestTaskId, updateDeliveryRequestTaskRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Delivery Request
         * @param {number} deliveryRequestId 
         * @param {UpdateDeliveryRequestRequest} updateDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryRequestV1DeliveryRequestsDeliveryRequestIdPatch(deliveryRequestId: number, updateDeliveryRequestRequest: UpdateDeliveryRequestRequest, options?: any): AxiosPromise<DeliveryRequestResponse> {
            return localVarFp.updateDeliveryRequestV1DeliveryRequestsDeliveryRequestIdPatch(deliveryRequestId, updateDeliveryRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Order Status
         * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest: OrderStatusUpdateRequest, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Order
         * @param {number} orderId 
         * @param {UpdateOrderV2Request} updateOrderV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderV2OrdersOrderIdPatch(orderId: number, updateOrderV2Request: UpdateOrderV2Request, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.updateOrderV2OrdersOrderIdPatch(orderId, updateOrderV2Request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Print Document Queue
         * @param {number} id 
         * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options?: any): AxiosPromise<PrintDocumentQueueResponse> {
            return localVarFp.updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id, updatePrintDocumentQueueRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {Request} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId: number, request: Request, options?: any): AxiosPromise<StockReceivingProcessResponse> {
            return localVarFp.updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Stock Taking Task Item
         * @param {number} stockTakingTaskItemId 
         * @param {UpdateStockTakingTaskItemRequest} updateStockTakingTaskItemRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateStockTakingTaskItemV2StockTakingTaskItemStockTakingTaskItemIdPatch(stockTakingTaskItemId: number, updateStockTakingTaskItemRequest: UpdateStockTakingTaskItemRequest, options?: any): AxiosPromise<StockTakingTaskItemResponse> {
            return localVarFp.updateStockTakingTaskItemV2StockTakingTaskItemStockTakingTaskItemIdPatch(stockTakingTaskItemId, updateStockTakingTaskItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Stock Taking Task
         * @param {number} stockTakingTaskId 
         * @param {UpdateStockTakingTaskRequest} updateStockTakingTaskRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateStockTakingTaskV2StockTakingTaskStockTakingTaskIdPatch(stockTakingTaskId: number, updateStockTakingTaskRequest: UpdateStockTakingTaskRequest, options?: any): AxiosPromise<StockTakingTaskResponse> {
            return localVarFp.updateStockTakingTaskV2StockTakingTaskStockTakingTaskIdPatch(stockTakingTaskId, updateStockTakingTaskRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateV1CompanyCategoriesPut(updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.updateV1CompanyCategoriesPut(updateCompanyCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {UpdateItemCategoryRequest} updateItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateV1ItemCategoriesPut(updateItemCategoryRequest: UpdateItemCategoryRequest, options?: any): AxiosPromise<ItemCategoryResponse> {
            return localVarFp.updateV1ItemCategoriesPut(updateItemCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {UpdateItemRequest} updateItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateV1ItemsPut(updateItemRequest: UpdateItemRequest, options?: any): AxiosPromise<ItemResponse> {
            return localVarFp.updateV1ItemsPut(updateItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {number} partnerId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId: number, requestBody: Array<number>, options?: any): AxiosPromise<Array<PartnerItemCategoryResponse>> {
            return localVarFp.updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {UpdatePricingPolicyRequest} updatePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateV1PricingPoliciesPut(updatePricingPolicyRequest: UpdatePricingPolicyRequest, options?: any): AxiosPromise<PricingPolicyResponse> {
            return localVarFp.updateV1PricingPoliciesPut(updatePricingPolicyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AMSApi - object-oriented interface
 * @export
 * @class AMSApi
 * @extends {BaseAPI}
 */
export class AMSApi extends BaseAPI {
    /**
     * 
     * @summary Bulk Create Partner Bonus Quantity Promotions
     * @param {BulkCreatePartnerBonusQuantityRequest} bulkCreatePartnerBonusQuantityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public bulkCreatePartnerBonusQuantityPromotionsV2PromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest: BulkCreatePartnerBonusQuantityRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).bulkCreatePartnerBonusQuantityPromotionsV2PromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel Invoice By Invoice Id
     * @param {number} invoiceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public cancelInvoiceByInvoiceIdV2InvoicesInvoiceIdPatch(invoiceId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).cancelInvoiceByInvoiceIdV2InvoicesInvoiceIdPatch(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel Invoice Document
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete(orderId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create And Send Email
     * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createAndSendEmailV1DeliveryRequestsSendEmailPost(createDeliveryRequestRequest: CreateDeliveryRequestRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createAndSendEmailV1DeliveryRequestsSendEmailPost(createDeliveryRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Delivery Document
     * @param {CreateDeliveryDocumentRequest} createDeliveryDocumentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createDeliveryDocumentV1DeliveriesDeliveryDocumentPost(createDeliveryDocumentRequest: CreateDeliveryDocumentRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createDeliveryDocumentV1DeliveriesDeliveryDocumentPost(createDeliveryDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Order
     * @param {CreateOrderV2Request} createOrderV2Request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createOrderV2OrdersPost(createOrderV2Request: CreateOrderV2Request, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createOrderV2OrdersPost(createOrderV2Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Print Document Queue
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost(orderId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Stocktaking
     * @param {StocktakingRequest} stocktakingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createStocktakingV2StocktakingsPost(stocktakingRequest: StocktakingRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createStocktakingV2StocktakingsPost(stocktakingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createV1CompanyCategoriesPost(createCompanyCategoryRequest: CreateCompanyCategoryRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createV1CompanyCategoriesPost(createCompanyCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {CreateDeliveryRequest} createDeliveryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createV1DeliveriesPost(createDeliveryRequest: CreateDeliveryRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createV1DeliveriesPost(createDeliveryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createV1DeliveryRequestsPost(createDeliveryRequestRequest: CreateDeliveryRequestRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createV1DeliveryRequestsPost(createDeliveryRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {CreateItemCategoryRequest} createItemCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createV1ItemCategoriesPost(createItemCategoryRequest: CreateItemCategoryRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createV1ItemCategoriesPost(createItemCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {number} itemId 
     * @param {number} linkedItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createV1ItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId: number, linkedItemId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createV1ItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId, linkedItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {number} itemId 
     * @param {number} similarItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createV1ItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId: number, similarItemId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createV1ItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId, similarItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {CreateItemRequest} createItemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createV1ItemsPost(createItemRequest: CreateItemRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createV1ItemsPost(createItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {CreatePricingPolicyRequest} createPricingPolicyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createV1PricingPoliciesPost(createPricingPolicyRequest: CreatePricingPolicyRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createV1PricingPoliciesPost(createPricingPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {CreateInvoiceRequest} createInvoiceRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createV2InvoicesPost(createInvoiceRequest: CreateInvoiceRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createV2InvoicesPost(createInvoiceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Partner Bonus Quantity
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deletePartnerBonusQuantityV2PromotionsBonusQuantityIdDelete(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deletePartnerBonusQuantityV2PromotionsBonusQuantityIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Picked Order Item
     * @param {number} pickedOrderItemId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete(pickedOrderItemId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete(pickedOrderItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deleteV1CompanyCategoriesIdDelete(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deleteV1CompanyCategoriesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deleteV1DeliveriesDeliveryDocumentIdDelete(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deleteV1DeliveriesDeliveryDocumentIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deleteV1DeliveriesIdDelete(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deleteV1DeliveriesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deleteV1DeliveryRequestsIdDelete(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deleteV1DeliveryRequestsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deleteV1ItemCategoriesIdDelete(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deleteV1ItemCategoriesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} itemId 
     * @param {number} linkedItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deleteV1ItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId: number, linkedItemId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deleteV1ItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId, linkedItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} itemId 
     * @param {number} similarItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deleteV1ItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId: number, similarItemId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deleteV1ItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId, similarItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deleteV1PricingPoliciesIdDelete(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deleteV1PricingPoliciesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Aggregated Item Movements
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {Array<number>} [partners] 
     * @param {Array<number>} [warehouses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate, toDate, partners, warehouses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get B2B Orders
     * @param {string} [status] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [receivers] 
     * @param {string} [legalEntities] 
     * @param {string} [fromDeliveryDate] 
     * @param {string} [toDeliveryDate] 
     * @param {boolean} [withExecutionPercentage] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getB2bOrdersV2OrdersB2bOrdersGet(status?: string, companies?: string, warehouses?: string, receivers?: string, legalEntities?: string, fromDeliveryDate?: string, toDeliveryDate?: string, withExecutionPercentage?: boolean, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getB2bOrdersV2OrdersB2bOrdersGet(status, companies, warehouses, receivers, legalEntities, fromDeliveryDate, toDeliveryDate, withExecutionPercentage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Internal Transfers
     * @param {string} [status] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [receivers] 
     * @param {string} [fromDeliveryDate] 
     * @param {string} [toDeliveryDate] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getInternalTransfersV2OrdersInternalTransfersGet(status?: string, companies?: string, warehouses?: string, receivers?: string, fromDeliveryDate?: string, toDeliveryDate?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getInternalTransfersV2OrdersInternalTransfersGet(status, companies, warehouses, receivers, fromDeliveryDate, toDeliveryDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Me
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getMeV1MeGet(options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getMeV1MeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Orders For Collection
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Orders For Distribution Amount
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Overdue Payments Amount
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Pending Payments Amount
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Health Check
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public healthCheckV1HealthGet(options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).healthCheckV1HealthGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Orders
     * @param {string} [status] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {boolean} [noInvoiceOnly] 
     * @param {boolean} [withExecutionPercentage] 
     * @param {boolean} [withItems] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public listOrdersV2OrdersGet(status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).listOrdersV2OrdersGet(status, companies, warehouses, fromDate, toDate, noInvoiceOnly, withExecutionPercentage, withItems, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Stock Taking Report Items
     * @param {number} [itemId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public listStockTakingReportItemsV2StockTakingReportItemsGet(itemId?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).listStockTakingReportItemsV2StockTakingReportItemsGet(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Stock Taking Reports
     * @param {string} [fromExecutionDate] 
     * @param {string} [toExecutionDate] 
     * @param {number} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public listStockTakingReportsV2StockTakingReportsGet(fromExecutionDate?: string, toExecutionDate?: string, userId?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).listStockTakingReportsV2StockTakingReportsGet(fromExecutionDate, toExecutionDate, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Load Info
     * @param {string} vatNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public loadInfoV1LegalEntitiesLoadInfoPost(vatNumber: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).loadInfoV1LegalEntitiesLoadInfoPost(vatNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Partner Bonus Quantity
     * @param {number} id 
     * @param {UpdatePartnerBonusQuantityRequest} updatePartnerBonusQuantityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public patchPartnerBonusQuantityV2PromotionsBonusQuantityIdPatch(id: number, updatePartnerBonusQuantityRequest: UpdatePartnerBonusQuantityRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).patchPartnerBonusQuantityV2PromotionsBonusQuantityIdPatch(id, updatePartnerBonusQuantityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pick Order Item
     * @param {number} orderItemId 
     * @param {PickOrderItemRequest} pickOrderItemRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public pickOrderItemV2OrdersOrderItemOrderItemIdPut(orderItemId: number, pickOrderItemRequest: PickOrderItemRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).pickOrderItemV2OrdersOrderItemOrderItemIdPut(orderItemId, pickOrderItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Act Of Acceptance By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Act Of Acceptance Items
     * @param {number} actOfAcceptanceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Act Of Acceptance
     * @param {number} actOfAcceptanceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Act Of Acceptance
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet(orderId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Acts Of Acceptance
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [receivers] 
     * @param {string} [legalEntities] 
     * @param {string} [companies] 
     * @param {string} [users] 
     * @param {string} [orderTypes] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, users?: string, orderTypes?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate, toDate, receivers, legalEntities, companies, users, orderTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All Flat
     * @param {boolean} [leafOnly] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readAllFlatV1ItemCategoriesFlatGet(leafOnly?: boolean, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readAllFlatV1ItemCategoriesFlatGet(leafOnly, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All Items
     * @param {number} [itemId] 
     * @param {number} [warehouseId] 
     * @param {boolean} [skipDepleted] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readAllItemsV1DeliveriesItemsGet(itemId?: number, warehouseId?: number, skipDepleted?: boolean, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readAllItemsV1DeliveriesItemsGet(itemId, warehouseId, skipDepleted, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All Units
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readAllUnitsV1ItemsUnitsGet(options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readAllUnitsV1ItemsUnitsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readAllV1CompanyCategoriesGet(skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readAllV1CompanyCategoriesGet(skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {boolean} [simple] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readAllV1ItemCategoriesGet(skip?: number, limit?: number, simple?: boolean, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readAllV1ItemCategoriesGet(skip, limit, simple, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readAllV1ItemsItemIdLinkedItemAssociationGet(itemId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readAllV1ItemsItemIdLinkedItemAssociationGet(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readAllV1ItemsItemIdSimilarItemAssociationGet(itemId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readAllV1ItemsItemIdSimilarItemAssociationGet(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All
     * @param {boolean} [showInactive] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readAllV1UsersGet(showInactive?: boolean, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readAllV1UsersGet(showInactive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Assigned Orders For Picker
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readAssignedOrdersForPickerV2OrdersPickerGet(options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readAssignedOrdersForPickerV2OrdersPickerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Batch
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Documents
     * @param {number} deliveryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDeliveryDocumentsV1DeliveriesDeliveryDocumentGet(deliveryId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDeliveryDocumentsV1DeliveriesDeliveryDocumentGet(deliveryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Invoices
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [suppliers] 
     * @param {string} [companies] 
     * @param {boolean} [hidePaid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate, toDate, suppliers, companies, hidePaid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Items
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {number} [supplierId] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [itemCategories] 
     * @param {number} [itemId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate?: string, toDate?: string, supplierId?: number, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate, toDate, supplierId, companies, warehouses, itemCategories, itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Request Task
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDeliveryRequestTaskV2DeliveryRequestTasksIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDeliveryRequestTaskV2DeliveryRequestTasksIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Request Tasks By User
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDeliveryRequestTasksByUserV2DeliveryRequestTasksGet(options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDeliveryRequestTasksByUserV2DeliveryRequestTasksGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Depleted Amounts With Prices
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [receviers] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [itemCategories] 
     * @param {number} [itemId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate?: string, toDate?: string, receviers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate, toDate, receviers, companies, warehouses, itemCategories, itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoice Credit Notes
     * @param {number} invoiceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readInvoiceCreditNotesV2InvoicesInvoiceIdCreditNotesGet(invoiceId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readInvoiceCreditNotesV2InvoicesInvoiceIdCreditNotesGet(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoice Document
     * @param {number} invoiceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readInvoiceDocumentV2InvoicesInvoiceIdDocumentGet(invoiceId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readInvoiceDocumentV2InvoicesInvoiceIdDocumentGet(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoice Document
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet(orderId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoice Orders
     * @param {number} invoiceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readInvoiceOrdersV2InvoicesInvoiceIdOrdersGet(invoiceId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readInvoiceOrdersV2InvoicesInvoiceIdOrdersGet(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoice Transactions
     * @param {number} invoiceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readInvoiceTransactionsV2InvoicesInvoiceIdTransactionsGet(invoiceId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readInvoiceTransactionsV2InvoicesInvoiceIdTransactionsGet(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoice
     * @param {number} invoiceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readInvoiceV2InvoicesInvoiceIdGet(invoiceId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readInvoiceV2InvoicesInvoiceIdGet(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoices
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [legalEntities] 
     * @param {string} [companies] 
     * @param {boolean} [hideCancelled] 
     * @param {boolean} [hidePaidInFull] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readInvoicesV2InvoicesGet(fromDate?: string, toDate?: string, legalEntities?: string, companies?: string, hideCancelled?: boolean, hidePaidInFull?: boolean, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readInvoicesV2InvoicesGet(fromDate, toDate, legalEntities, companies, hideCancelled, hidePaidInFull, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read List
     * @param {string} [search] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {boolean} [hidePaid] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readListV1DeliveriesGet(search?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, hidePaid?: boolean, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readListV1DeliveriesGet(search, companies, warehouses, fromDate, toDate, hidePaid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read List
     * @param {string} [search] 
     * @param {string} [suppliers] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readListV1DeliveryRequestsGet(search?: string, suppliers?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readListV1DeliveryRequestsGet(search, suppliers, companies, warehouses, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read List
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readListV1PermissionsGet(skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readListV1PermissionsGet(skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read List
     * @param {string} [search] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readListV1PricingPoliciesGet(search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readListV1PricingPoliciesGet(search, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order By Order Id
     * @param {number} orderId 
     * @param {boolean} [withLivePrices] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readOrderByOrderIdV2OrdersOrderIdGet(orderId: number, withLivePrices?: boolean, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readOrderByOrderIdV2OrdersOrderIdGet(orderId, withLivePrices, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Documents
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Documents
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Items
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [receivers] 
     * @param {string} [legalEntities] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [itemCategories] 
     * @param {number} [itemId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readOrderItemsV1ReportsOrderItemsGet(fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readOrderItemsV1ReportsOrderItemsGet(fromDate, toDate, receivers, legalEntities, companies, warehouses, itemCategories, itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Transactions
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Ordering Policy
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Orders Balance
     * @param {string} [statusKeys] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {boolean} [noInvoiceOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys, companies, warehouses, fromDate, toDate, noInvoiceOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Orders For Inspection
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readOrdersForInspectionV2OrdersInspectorGet(options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readOrdersForInspectionV2OrdersInspectorGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Partner Bonus Quantities
     * @param {number} [itemId] 
     * @param {string} [partnerIds] 
     * @param {string} [fromStartDate] 
     * @param {string} [toStartDate] 
     * @param {string} [fromEndDate] 
     * @param {string} [toEndDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPartnerBonusQuantitiesV2PromotionsBonusQuantityGet(itemId?: number, partnerIds?: string, fromStartDate?: string, toStartDate?: string, fromEndDate?: string, toEndDate?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPartnerBonusQuantitiesV2PromotionsBonusQuantityGet(itemId, partnerIds, fromStartDate, toStartDate, fromEndDate, toEndDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Categories
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Category Multipliers
     * @param {string} itemCategories 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Exceptions
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Prices
     * @param {string} items 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Print Document Queue
     * @param {string} [state] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPrintDocumentQueueV2PrintDocumentQueueGet(state?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPrintDocumentQueueV2PrintDocumentQueueGet(state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Quantities To Fill Up Warehouse Reserve
     * @param {number} warehouseId 
     * @param {number} [baseDays] 
     * @param {number} [reserveDays] 
     * @param {number} [supplierId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId: number, baseDays?: number, reserveDays?: number, supplierId?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId, baseDays, reserveDays, supplierId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stock Receiving Process By Delivery Request Id
     * @param {number} deliveryRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet(deliveryRequestId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet(deliveryRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stock Taking Report
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readStockTakingReportV2StockTakingReportsIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readStockTakingReportV2StockTakingReportsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stock Taking Tasks By User
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readStockTakingTasksByUserV2StockTakingTaskGet(options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readStockTakingTasksByUserV2StockTakingTaskGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stocktaking Tasks
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readStocktakingTasksV2StocktakingsIdTasksGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readStocktakingTasksV2StocktakingsIdTasksGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stocktaking
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readStocktakingV2StocktakingsIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readStocktakingV2StocktakingsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stocktakings
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [warehouses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readStocktakingsV2StocktakingsGet(fromDate?: string, toDate?: string, warehouses?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readStocktakingsV2StocktakingsGet(fromDate, toDate, warehouses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readV1CompanyCategoriesIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readV1CompanyCategoriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readV1DeliveriesIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readV1DeliveriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readV1DeliveryRequestsIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readV1DeliveryRequestsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readV1ItemCategoriesIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readV1ItemCategoriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} partnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readV1PricingPoliciesIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readV1PricingPoliciesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Warehouse Stock By Orders
     * @param {number} warehouseId 
     * @param {string} toDate 
     * @param {boolean} [hideOrdersWithoutDeliveryDate] 
     * @param {boolean} [hideAvailable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId, toDate, hideOrdersWithoutDeliveryDate, hideAvailable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Warehouse Stock
     * @param {number} [companyId] 
     * @param {number} [warehouseId] 
     * @param {number} [itemId] 
     * @param {number} [manufacturerId] 
     * @param {string} [suppliers] 
     * @param {string} [itemCategories] 
     * @param {boolean} [hideDepleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readWarehouseStockV1ReportsStockGet(companyId?: number, warehouseId?: number, itemId?: number, manufacturerId?: number, suppliers?: string, itemCategories?: string, hideDepleted?: boolean, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readWarehouseStockV1ReportsStockGet(companyId, warehouseId, itemId, manufacturerId, suppliers, itemCategories, hideDepleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return Items
     * @param {number} orderId 
     * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public returnItemsV2OrdersOrderIdReturnItemsPut(orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).returnItemsV2OrdersOrderIdReturnItemsPut(orderId, returnOrderItemV2Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Ordering Policy
     * @param {number} id 
     * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id, setOrderingPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start Stock Receiving Process
     * @param {number} deliveryRequestId 
     * @param {StartStockReceivingProcessRequest} startStockReceivingProcessRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId: number, startStockReceivingProcessRequest: StartStockReceivingProcessRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId, startStockReceivingProcessRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Test Barsy Connection
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public testBarsyConnectionV1PartnersIdTestBarsyConnectionPost(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).testBarsyConnectionV1PartnersIdTestBarsyConnectionPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Delivery Document
     * @param {UpdateDeliveryDocumentRequest} updateDeliveryDocumentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateDeliveryDocumentV1DeliveriesDeliveryDocumentPut(updateDeliveryDocumentRequest: UpdateDeliveryDocumentRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateDeliveryDocumentV1DeliveriesDeliveryDocumentPut(updateDeliveryDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Delivery Request Task Item
     * @param {number} deliveryRequestTaskItemId 
     * @param {UpdateDeliveryRequestTaskItemRequest} updateDeliveryRequestTaskItemRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateDeliveryRequestTaskItemV2DeliveryRequestTasksItemDeliveryRequestTaskItemIdPatch(deliveryRequestTaskItemId: number, updateDeliveryRequestTaskItemRequest: UpdateDeliveryRequestTaskItemRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateDeliveryRequestTaskItemV2DeliveryRequestTasksItemDeliveryRequestTaskItemIdPatch(deliveryRequestTaskItemId, updateDeliveryRequestTaskItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Delivery Request Task
     * @param {number} deliveryRequestTaskId 
     * @param {UpdateDeliveryRequestTaskRequest} updateDeliveryRequestTaskRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateDeliveryRequestTaskV2DeliveryRequestTasksDeliveryRequestTaskIdPatch(deliveryRequestTaskId: number, updateDeliveryRequestTaskRequest: UpdateDeliveryRequestTaskRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateDeliveryRequestTaskV2DeliveryRequestTasksDeliveryRequestTaskIdPatch(deliveryRequestTaskId, updateDeliveryRequestTaskRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Delivery Request
     * @param {number} deliveryRequestId 
     * @param {UpdateDeliveryRequestRequest} updateDeliveryRequestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateDeliveryRequestV1DeliveryRequestsDeliveryRequestIdPatch(deliveryRequestId: number, updateDeliveryRequestRequest: UpdateDeliveryRequestRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateDeliveryRequestV1DeliveryRequestsDeliveryRequestIdPatch(deliveryRequestId, updateDeliveryRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Order Status
     * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest: OrderStatusUpdateRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Order
     * @param {number} orderId 
     * @param {UpdateOrderV2Request} updateOrderV2Request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateOrderV2OrdersOrderIdPatch(orderId: number, updateOrderV2Request: UpdateOrderV2Request, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateOrderV2OrdersOrderIdPatch(orderId, updateOrderV2Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Print Document Queue
     * @param {number} id 
     * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id, updatePrintDocumentQueueRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Stock Receiving Process
     * @param {number} deliveryRequestId 
     * @param {Request} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId: number, request: Request, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Stock Taking Task Item
     * @param {number} stockTakingTaskItemId 
     * @param {UpdateStockTakingTaskItemRequest} updateStockTakingTaskItemRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateStockTakingTaskItemV2StockTakingTaskItemStockTakingTaskItemIdPatch(stockTakingTaskItemId: number, updateStockTakingTaskItemRequest: UpdateStockTakingTaskItemRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateStockTakingTaskItemV2StockTakingTaskItemStockTakingTaskItemIdPatch(stockTakingTaskItemId, updateStockTakingTaskItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Stock Taking Task
     * @param {number} stockTakingTaskId 
     * @param {UpdateStockTakingTaskRequest} updateStockTakingTaskRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateStockTakingTaskV2StockTakingTaskStockTakingTaskIdPatch(stockTakingTaskId: number, updateStockTakingTaskRequest: UpdateStockTakingTaskRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateStockTakingTaskV2StockTakingTaskStockTakingTaskIdPatch(stockTakingTaskId, updateStockTakingTaskRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateV1CompanyCategoriesPut(updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateV1CompanyCategoriesPut(updateCompanyCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {UpdateItemCategoryRequest} updateItemCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateV1ItemCategoriesPut(updateItemCategoryRequest: UpdateItemCategoryRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateV1ItemCategoriesPut(updateItemCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {UpdateItemRequest} updateItemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateV1ItemsPut(updateItemRequest: UpdateItemRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateV1ItemsPut(updateItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {number} partnerId 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId: number, requestBody: Array<number>, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {UpdatePricingPolicyRequest} updatePricingPolicyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateV1PricingPoliciesPut(updatePricingPolicyRequest: UpdatePricingPolicyRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateV1PricingPoliciesPut(updatePricingPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AccessManagementApi - axios parameter creator
 * @export
 */
export const AccessManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Read All
         * @param {boolean} [showInactive] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAllV1UsersGet: async (showInactive?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read List
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListV1PermissionsGet: async (skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccessManagementApi - functional programming interface
 * @export
 */
export const AccessManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccessManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Read All
         * @param {boolean} [showInactive] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readAllV1UsersGet(showInactive?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllV1UsersGet(showInactive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read List
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readListV1PermissionsGet(skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PermissionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readListV1PermissionsGet(skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccessManagementApi - factory interface
 * @export
 */
export const AccessManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccessManagementApiFp(configuration)
    return {
        /**
         * 
         * @summary Read All
         * @param {boolean} [showInactive] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAllV1UsersGet(showInactive?: boolean, options?: any): AxiosPromise<Array<UserResponse>> {
            return localVarFp.readAllV1UsersGet(showInactive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read List
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListV1PermissionsGet(skip?: number, limit?: number, options?: any): AxiosPromise<Array<PermissionResponse>> {
            return localVarFp.readListV1PermissionsGet(skip, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccessManagementApi - object-oriented interface
 * @export
 * @class AccessManagementApi
 * @extends {BaseAPI}
 */
export class AccessManagementApi extends BaseAPI {
    /**
     * 
     * @summary Read All
     * @param {boolean} [showInactive] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AccessManagementApi
     */
    public readAllV1UsersGet(showInactive?: boolean, options?: AxiosRequestConfig) {
        return AccessManagementApiFp(this.configuration).readAllV1UsersGet(showInactive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read List
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessManagementApi
     */
    public readListV1PermissionsGet(skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return AccessManagementApiFp(this.configuration).readListV1PermissionsGet(skip, limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActsOfAcceptanceApi - axios parameter creator
 * @export
 */
export const ActsOfAcceptanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Read Act Of Acceptance By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet', 'id', id)
            const localVarPath = `/v2/acts-of-acceptance/{id}/document`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Act Of Acceptance Items
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet: async (actOfAcceptanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actOfAcceptanceId' is not null or undefined
            assertParamExists('readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet', 'actOfAcceptanceId', actOfAcceptanceId)
            const localVarPath = `/v2/acts-of-acceptance/{act_of_acceptance_id}/items`
                .replace(`{${"act_of_acceptance_id"}}`, encodeURIComponent(String(actOfAcceptanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet: async (actOfAcceptanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actOfAcceptanceId' is not null or undefined
            assertParamExists('readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet', 'actOfAcceptanceId', actOfAcceptanceId)
            const localVarPath = `/v2/acts-of-acceptance/{act_of_acceptance_id}`
                .replace(`{${"act_of_acceptance_id"}}`, encodeURIComponent(String(actOfAcceptanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Acts Of Acceptance
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {string} [users] 
         * @param {string} [orderTypes] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActsOfAcceptanceV2ActsOfAcceptanceGet: async (fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, users?: string, orderTypes?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/acts-of-acceptance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (receivers !== undefined) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (legalEntities !== undefined) {
                localVarQueryParameter['legal_entities'] = legalEntities;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (users !== undefined) {
                localVarQueryParameter['users'] = users;
            }

            if (orderTypes !== undefined) {
                localVarQueryParameter['order_types'] = orderTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActsOfAcceptanceApi - functional programming interface
 * @export
 */
export const ActsOfAcceptanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActsOfAcceptanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Read Act Of Acceptance By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Act Of Acceptance Items
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActOfAcceptanceItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActOfAcceptanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Acts Of Acceptance
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {string} [users] 
         * @param {string} [orderTypes] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, users?: string, orderTypes?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActOfAcceptanceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate, toDate, receivers, legalEntities, companies, users, orderTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActsOfAcceptanceApi - factory interface
 * @export
 */
export const ActsOfAcceptanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActsOfAcceptanceApiFp(configuration)
    return {
        /**
         * 
         * @summary Read Act Of Acceptance By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Act Of Acceptance Items
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId: number, options?: any): AxiosPromise<Array<ActOfAcceptanceItemResponse>> {
            return localVarFp.readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId: number, options?: any): AxiosPromise<ActOfAcceptanceResponse> {
            return localVarFp.readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Acts Of Acceptance
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {string} [users] 
         * @param {string} [orderTypes] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, users?: string, orderTypes?: string, options?: any): AxiosPromise<Array<ActOfAcceptanceResponse>> {
            return localVarFp.readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate, toDate, receivers, legalEntities, companies, users, orderTypes, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActsOfAcceptanceApi - object-oriented interface
 * @export
 * @class ActsOfAcceptanceApi
 * @extends {BaseAPI}
 */
export class ActsOfAcceptanceApi extends BaseAPI {
    /**
     * 
     * @summary Read Act Of Acceptance By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ActsOfAcceptanceApi
     */
    public readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id: number, options?: AxiosRequestConfig) {
        return ActsOfAcceptanceApiFp(this.configuration).readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Act Of Acceptance Items
     * @param {number} actOfAcceptanceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ActsOfAcceptanceApi
     */
    public readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId: number, options?: AxiosRequestConfig) {
        return ActsOfAcceptanceApiFp(this.configuration).readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Act Of Acceptance
     * @param {number} actOfAcceptanceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ActsOfAcceptanceApi
     */
    public readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId: number, options?: AxiosRequestConfig) {
        return ActsOfAcceptanceApiFp(this.configuration).readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Acts Of Acceptance
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [receivers] 
     * @param {string} [legalEntities] 
     * @param {string} [companies] 
     * @param {string} [users] 
     * @param {string} [orderTypes] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ActsOfAcceptanceApi
     */
    public readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, users?: string, orderTypes?: string, options?: AxiosRequestConfig) {
        return ActsOfAcceptanceApiFp(this.configuration).readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate, toDate, receivers, legalEntities, companies, users, orderTypes, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommonApi - axios parameter creator
 * @export
 */
export const CommonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMeV1MeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        healthCheckV1HealthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonApi - functional programming interface
 * @export
 */
export const CommonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getMeV1MeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeV1MeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async healthCheckV1HealthGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCheckV1HealthGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommonApi - factory interface
 * @export
 */
export const CommonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommonApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMeV1MeGet(options?: any): AxiosPromise<UserAccessResponse> {
            return localVarFp.getMeV1MeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        healthCheckV1HealthGet(options?: any): AxiosPromise<any> {
            return localVarFp.healthCheckV1HealthGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommonApi - object-oriented interface
 * @export
 * @class CommonApi
 * @extends {BaseAPI}
 */
export class CommonApi extends BaseAPI {
    /**
     * 
     * @summary Get Me
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public getMeV1MeGet(options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).getMeV1MeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Health Check
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public healthCheckV1HealthGet(options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).healthCheckV1HealthGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginTokenPost: async (username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('loginTokenPost', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('loginTokenPost', 'password', password)
            const localVarPath = `/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (scope !== undefined) { 
                localVarFormParams.set('scope', scope as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginTokenPost(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginTokenPost(username, password, grantType, scope, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginTokenPost(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: any): AxiosPromise<any> {
            return localVarFp.loginTokenPost(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Login
     * @param {string} username 
     * @param {string} password 
     * @param {string} [grantType] 
     * @param {string} [scope] 
     * @param {string} [clientId] 
     * @param {string} [clientSecret] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public loginTokenPost(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).loginTokenPost(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeliveriesApi - axios parameter creator
 * @export
 */
export const DeliveriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create And Send Email
         * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndSendEmailV1DeliveryRequestsSendEmailPost: async (createDeliveryRequestRequest: CreateDeliveryRequestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDeliveryRequestRequest' is not null or undefined
            assertParamExists('createAndSendEmailV1DeliveryRequestsSendEmailPost', 'createDeliveryRequestRequest', createDeliveryRequestRequest)
            const localVarPath = `/v1/delivery-requests/send-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeliveryRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Delivery Document
         * @param {CreateDeliveryDocumentRequest} createDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryDocumentV1DeliveriesDeliveryDocumentPost: async (createDeliveryDocumentRequest: CreateDeliveryDocumentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDeliveryDocumentRequest' is not null or undefined
            assertParamExists('createDeliveryDocumentV1DeliveriesDeliveryDocumentPost', 'createDeliveryDocumentRequest', createDeliveryDocumentRequest)
            const localVarPath = `/v1/deliveries/delivery-document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeliveryDocumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {CreateDeliveryRequest} createDeliveryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1DeliveriesPost: async (createDeliveryRequest: CreateDeliveryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDeliveryRequest' is not null or undefined
            assertParamExists('createV1DeliveriesPost', 'createDeliveryRequest', createDeliveryRequest)
            const localVarPath = `/v1/deliveries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeliveryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1DeliveryRequestsPost: async (createDeliveryRequestRequest: CreateDeliveryRequestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDeliveryRequestRequest' is not null or undefined
            assertParamExists('createV1DeliveryRequestsPost', 'createDeliveryRequestRequest', createDeliveryRequestRequest)
            const localVarPath = `/v1/delivery-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeliveryRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1DeliveriesDeliveryDocumentIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteV1DeliveriesDeliveryDocumentIdDelete', 'id', id)
            const localVarPath = `/v1/deliveries/delivery-document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1DeliveriesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteV1DeliveriesIdDelete', 'id', id)
            const localVarPath = `/v1/deliveries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1DeliveryRequestsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteV1DeliveryRequestsIdDelete', 'id', id)
            const localVarPath = `/v1/delivery-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All Items
         * @param {number} [itemId] 
         * @param {number} [warehouseId] 
         * @param {boolean} [skipDepleted] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAllItemsV1DeliveriesItemsGet: async (itemId?: number, warehouseId?: number, skipDepleted?: boolean, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/deliveries/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouse_id'] = warehouseId;
            }

            if (skipDepleted !== undefined) {
                localVarQueryParameter['skip_depleted'] = skipDepleted;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Documents
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDocumentsV1DeliveriesDeliveryDocumentGet: async (deliveryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryId' is not null or undefined
            assertParamExists('readDeliveryDocumentsV1DeliveriesDeliveryDocumentGet', 'deliveryId', deliveryId)
            const localVarPath = `/v1/deliveries/delivery-document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (deliveryId !== undefined) {
                localVarQueryParameter['delivery_id'] = deliveryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [hidePaid] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readListV1DeliveriesGet: async (search?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, hidePaid?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/deliveries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (hidePaid !== undefined) {
                localVarQueryParameter['hide_paid'] = hidePaid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListV1DeliveryRequestsGet: async (search?: string, suppliers?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/delivery-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (suppliers !== undefined) {
                localVarQueryParameter['suppliers'] = suppliers;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stock Receiving Process By Delivery Request Id
         * @param {number} deliveryRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet: async (deliveryRequestId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryRequestId' is not null or undefined
            assertParamExists('readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet', 'deliveryRequestId', deliveryRequestId)
            const localVarPath = `/v2/delivery-requests/{delivery_request_id}/process`
                .replace(`{${"delivery_request_id"}}`, encodeURIComponent(String(deliveryRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readV1DeliveriesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readV1DeliveriesIdGet', 'id', id)
            const localVarPath = `/v1/deliveries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readV1DeliveryRequestsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readV1DeliveryRequestsIdGet', 'id', id)
            const localVarPath = `/v1/delivery-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {StartStockReceivingProcessRequest} startStockReceivingProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost: async (deliveryRequestId: number, startStockReceivingProcessRequest: StartStockReceivingProcessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryRequestId' is not null or undefined
            assertParamExists('startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost', 'deliveryRequestId', deliveryRequestId)
            // verify required parameter 'startStockReceivingProcessRequest' is not null or undefined
            assertParamExists('startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost', 'startStockReceivingProcessRequest', startStockReceivingProcessRequest)
            const localVarPath = `/v2/delivery-requests/{delivery_request_id}/process`
                .replace(`{${"delivery_request_id"}}`, encodeURIComponent(String(deliveryRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startStockReceivingProcessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Delivery Document
         * @param {UpdateDeliveryDocumentRequest} updateDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryDocumentV1DeliveriesDeliveryDocumentPut: async (updateDeliveryDocumentRequest: UpdateDeliveryDocumentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDeliveryDocumentRequest' is not null or undefined
            assertParamExists('updateDeliveryDocumentV1DeliveriesDeliveryDocumentPut', 'updateDeliveryDocumentRequest', updateDeliveryDocumentRequest)
            const localVarPath = `/v1/deliveries/delivery-document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeliveryDocumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Delivery Request
         * @param {number} deliveryRequestId 
         * @param {UpdateDeliveryRequestRequest} updateDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryRequestV1DeliveryRequestsDeliveryRequestIdPatch: async (deliveryRequestId: number, updateDeliveryRequestRequest: UpdateDeliveryRequestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryRequestId' is not null or undefined
            assertParamExists('updateDeliveryRequestV1DeliveryRequestsDeliveryRequestIdPatch', 'deliveryRequestId', deliveryRequestId)
            // verify required parameter 'updateDeliveryRequestRequest' is not null or undefined
            assertParamExists('updateDeliveryRequestV1DeliveryRequestsDeliveryRequestIdPatch', 'updateDeliveryRequestRequest', updateDeliveryRequestRequest)
            const localVarPath = `/v1/delivery-requests/{delivery_request_id}`
                .replace(`{${"delivery_request_id"}}`, encodeURIComponent(String(deliveryRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeliveryRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {Request} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch: async (deliveryRequestId: number, request: Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryRequestId' is not null or undefined
            assertParamExists('updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch', 'deliveryRequestId', deliveryRequestId)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch', 'request', request)
            const localVarPath = `/v2/delivery-requests/{delivery_request_id}/process`
                .replace(`{${"delivery_request_id"}}`, encodeURIComponent(String(deliveryRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliveriesApi - functional programming interface
 * @export
 */
export const DeliveriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeliveriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create And Send Email
         * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAndSendEmailV1DeliveryRequestsSendEmailPost(createDeliveryRequestRequest: CreateDeliveryRequestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAndSendEmailV1DeliveryRequestsSendEmailPost(createDeliveryRequestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Delivery Document
         * @param {CreateDeliveryDocumentRequest} createDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDeliveryDocumentV1DeliveriesDeliveryDocumentPost(createDeliveryDocumentRequest: CreateDeliveryDocumentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDeliveryDocumentV1DeliveriesDeliveryDocumentPost(createDeliveryDocumentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {CreateDeliveryRequest} createDeliveryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createV1DeliveriesPost(createDeliveryRequest: CreateDeliveryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1DeliveriesPost(createDeliveryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createV1DeliveryRequestsPost(createDeliveryRequestRequest: CreateDeliveryRequestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1DeliveryRequestsPost(createDeliveryRequestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1DeliveriesDeliveryDocumentIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1DeliveriesDeliveryDocumentIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1DeliveriesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1DeliveriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1DeliveryRequestsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1DeliveryRequestsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All Items
         * @param {number} [itemId] 
         * @param {number} [warehouseId] 
         * @param {boolean} [skipDepleted] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readAllItemsV1DeliveriesItemsGet(itemId?: number, warehouseId?: number, skipDepleted?: boolean, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeliveryItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllItemsV1DeliveriesItemsGet(itemId, warehouseId, skipDepleted, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Documents
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryDocumentsV1DeliveriesDeliveryDocumentGet(deliveryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeliveryDocumentResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryDocumentsV1DeliveriesDeliveryDocumentGet(deliveryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [hidePaid] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readListV1DeliveriesGet(search?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, hidePaid?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeliveryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readListV1DeliveriesGet(search, companies, warehouses, fromDate, toDate, hidePaid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readListV1DeliveryRequestsGet(search?: string, suppliers?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeliveryRequestResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readListV1DeliveryRequestsGet(search, suppliers, companies, warehouses, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stock Receiving Process By Delivery Request Id
         * @param {number} deliveryRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet(deliveryRequestId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockReceivingProcessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet(deliveryRequestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readV1DeliveriesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readV1DeliveriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readV1DeliveryRequestsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readV1DeliveryRequestsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {StartStockReceivingProcessRequest} startStockReceivingProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId: number, startStockReceivingProcessRequest: StartStockReceivingProcessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId, startStockReceivingProcessRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Delivery Document
         * @param {UpdateDeliveryDocumentRequest} updateDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeliveryDocumentV1DeliveriesDeliveryDocumentPut(updateDeliveryDocumentRequest: UpdateDeliveryDocumentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeliveryDocumentV1DeliveriesDeliveryDocumentPut(updateDeliveryDocumentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Delivery Request
         * @param {number} deliveryRequestId 
         * @param {UpdateDeliveryRequestRequest} updateDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeliveryRequestV1DeliveryRequestsDeliveryRequestIdPatch(deliveryRequestId: number, updateDeliveryRequestRequest: UpdateDeliveryRequestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeliveryRequestV1DeliveryRequestsDeliveryRequestIdPatch(deliveryRequestId, updateDeliveryRequestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {Request} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId: number, request: Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockReceivingProcessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeliveriesApi - factory interface
 * @export
 */
export const DeliveriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeliveriesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create And Send Email
         * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndSendEmailV1DeliveryRequestsSendEmailPost(createDeliveryRequestRequest: CreateDeliveryRequestRequest, options?: any): AxiosPromise<DeliveryRequestResponse> {
            return localVarFp.createAndSendEmailV1DeliveryRequestsSendEmailPost(createDeliveryRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Delivery Document
         * @param {CreateDeliveryDocumentRequest} createDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryDocumentV1DeliveriesDeliveryDocumentPost(createDeliveryDocumentRequest: CreateDeliveryDocumentRequest, options?: any): AxiosPromise<DeliveryDocumentResponse> {
            return localVarFp.createDeliveryDocumentV1DeliveriesDeliveryDocumentPost(createDeliveryDocumentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {CreateDeliveryRequest} createDeliveryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1DeliveriesPost(createDeliveryRequest: CreateDeliveryRequest, options?: any): AxiosPromise<DeliveryResponse> {
            return localVarFp.createV1DeliveriesPost(createDeliveryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1DeliveryRequestsPost(createDeliveryRequestRequest: CreateDeliveryRequestRequest, options?: any): AxiosPromise<DeliveryRequestResponse> {
            return localVarFp.createV1DeliveryRequestsPost(createDeliveryRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1DeliveriesDeliveryDocumentIdDelete(id: number, options?: any): AxiosPromise<DeliveryDocumentResponse> {
            return localVarFp.deleteV1DeliveriesDeliveryDocumentIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1DeliveriesIdDelete(id: number, options?: any): AxiosPromise<DeliveryResponse> {
            return localVarFp.deleteV1DeliveriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1DeliveryRequestsIdDelete(id: number, options?: any): AxiosPromise<DeliveryRequestResponse> {
            return localVarFp.deleteV1DeliveryRequestsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All Items
         * @param {number} [itemId] 
         * @param {number} [warehouseId] 
         * @param {boolean} [skipDepleted] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAllItemsV1DeliveriesItemsGet(itemId?: number, warehouseId?: number, skipDepleted?: boolean, skip?: number, limit?: number, options?: any): AxiosPromise<Array<DeliveryItemResponse>> {
            return localVarFp.readAllItemsV1DeliveriesItemsGet(itemId, warehouseId, skipDepleted, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Documents
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDocumentsV1DeliveriesDeliveryDocumentGet(deliveryId: number, options?: any): AxiosPromise<Array<DeliveryDocumentResponse>> {
            return localVarFp.readDeliveryDocumentsV1DeliveriesDeliveryDocumentGet(deliveryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [hidePaid] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readListV1DeliveriesGet(search?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, hidePaid?: boolean, options?: any): AxiosPromise<Array<DeliveryResponse>> {
            return localVarFp.readListV1DeliveriesGet(search, companies, warehouses, fromDate, toDate, hidePaid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListV1DeliveryRequestsGet(search?: string, suppliers?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<DeliveryRequestResponse>> {
            return localVarFp.readListV1DeliveryRequestsGet(search, suppliers, companies, warehouses, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stock Receiving Process By Delivery Request Id
         * @param {number} deliveryRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet(deliveryRequestId: number, options?: any): AxiosPromise<StockReceivingProcessResponse> {
            return localVarFp.readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet(deliveryRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readV1DeliveriesIdGet(id: number, options?: any): AxiosPromise<DeliveryResponse> {
            return localVarFp.readV1DeliveriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readV1DeliveryRequestsIdGet(id: number, options?: any): AxiosPromise<DeliveryRequestResponse> {
            return localVarFp.readV1DeliveryRequestsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {StartStockReceivingProcessRequest} startStockReceivingProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId: number, startStockReceivingProcessRequest: StartStockReceivingProcessRequest, options?: any): AxiosPromise<void> {
            return localVarFp.startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId, startStockReceivingProcessRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Delivery Document
         * @param {UpdateDeliveryDocumentRequest} updateDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryDocumentV1DeliveriesDeliveryDocumentPut(updateDeliveryDocumentRequest: UpdateDeliveryDocumentRequest, options?: any): AxiosPromise<DeliveryDocumentResponse> {
            return localVarFp.updateDeliveryDocumentV1DeliveriesDeliveryDocumentPut(updateDeliveryDocumentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Delivery Request
         * @param {number} deliveryRequestId 
         * @param {UpdateDeliveryRequestRequest} updateDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryRequestV1DeliveryRequestsDeliveryRequestIdPatch(deliveryRequestId: number, updateDeliveryRequestRequest: UpdateDeliveryRequestRequest, options?: any): AxiosPromise<DeliveryRequestResponse> {
            return localVarFp.updateDeliveryRequestV1DeliveryRequestsDeliveryRequestIdPatch(deliveryRequestId, updateDeliveryRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {Request} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId: number, request: Request, options?: any): AxiosPromise<StockReceivingProcessResponse> {
            return localVarFp.updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId, request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeliveriesApi - object-oriented interface
 * @export
 * @class DeliveriesApi
 * @extends {BaseAPI}
 */
export class DeliveriesApi extends BaseAPI {
    /**
     * 
     * @summary Create And Send Email
     * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public createAndSendEmailV1DeliveryRequestsSendEmailPost(createDeliveryRequestRequest: CreateDeliveryRequestRequest, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).createAndSendEmailV1DeliveryRequestsSendEmailPost(createDeliveryRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Delivery Document
     * @param {CreateDeliveryDocumentRequest} createDeliveryDocumentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public createDeliveryDocumentV1DeliveriesDeliveryDocumentPost(createDeliveryDocumentRequest: CreateDeliveryDocumentRequest, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).createDeliveryDocumentV1DeliveriesDeliveryDocumentPost(createDeliveryDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {CreateDeliveryRequest} createDeliveryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public createV1DeliveriesPost(createDeliveryRequest: CreateDeliveryRequest, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).createV1DeliveriesPost(createDeliveryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public createV1DeliveryRequestsPost(createDeliveryRequestRequest: CreateDeliveryRequestRequest, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).createV1DeliveryRequestsPost(createDeliveryRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public deleteV1DeliveriesDeliveryDocumentIdDelete(id: number, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).deleteV1DeliveriesDeliveryDocumentIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public deleteV1DeliveriesIdDelete(id: number, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).deleteV1DeliveriesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public deleteV1DeliveryRequestsIdDelete(id: number, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).deleteV1DeliveryRequestsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All Items
     * @param {number} [itemId] 
     * @param {number} [warehouseId] 
     * @param {boolean} [skipDepleted] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public readAllItemsV1DeliveriesItemsGet(itemId?: number, warehouseId?: number, skipDepleted?: boolean, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).readAllItemsV1DeliveriesItemsGet(itemId, warehouseId, skipDepleted, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Documents
     * @param {number} deliveryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public readDeliveryDocumentsV1DeliveriesDeliveryDocumentGet(deliveryId: number, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).readDeliveryDocumentsV1DeliveriesDeliveryDocumentGet(deliveryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read List
     * @param {string} [search] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {boolean} [hidePaid] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public readListV1DeliveriesGet(search?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, hidePaid?: boolean, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).readListV1DeliveriesGet(search, companies, warehouses, fromDate, toDate, hidePaid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read List
     * @param {string} [search] 
     * @param {string} [suppliers] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public readListV1DeliveryRequestsGet(search?: string, suppliers?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).readListV1DeliveryRequestsGet(search, suppliers, companies, warehouses, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stock Receiving Process By Delivery Request Id
     * @param {number} deliveryRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet(deliveryRequestId: number, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet(deliveryRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public readV1DeliveriesIdGet(id: number, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).readV1DeliveriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public readV1DeliveryRequestsIdGet(id: number, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).readV1DeliveryRequestsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start Stock Receiving Process
     * @param {number} deliveryRequestId 
     * @param {StartStockReceivingProcessRequest} startStockReceivingProcessRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId: number, startStockReceivingProcessRequest: StartStockReceivingProcessRequest, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId, startStockReceivingProcessRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Delivery Document
     * @param {UpdateDeliveryDocumentRequest} updateDeliveryDocumentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public updateDeliveryDocumentV1DeliveriesDeliveryDocumentPut(updateDeliveryDocumentRequest: UpdateDeliveryDocumentRequest, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).updateDeliveryDocumentV1DeliveriesDeliveryDocumentPut(updateDeliveryDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Delivery Request
     * @param {number} deliveryRequestId 
     * @param {UpdateDeliveryRequestRequest} updateDeliveryRequestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public updateDeliveryRequestV1DeliveryRequestsDeliveryRequestIdPatch(deliveryRequestId: number, updateDeliveryRequestRequest: UpdateDeliveryRequestRequest, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).updateDeliveryRequestV1DeliveryRequestsDeliveryRequestIdPatch(deliveryRequestId, updateDeliveryRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Stock Receiving Process
     * @param {number} deliveryRequestId 
     * @param {Request} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId: number, request: Request, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId, request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeliveryRequestTasksApi - axios parameter creator
 * @export
 */
export const DeliveryRequestTasksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Read Delivery Request Task
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDeliveryRequestTaskV2DeliveryRequestTasksIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readDeliveryRequestTaskV2DeliveryRequestTasksIdGet', 'id', id)
            const localVarPath = `/v2/delivery-request-tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Request Tasks By User
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDeliveryRequestTasksByUserV2DeliveryRequestTasksGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/delivery-request-tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Delivery Request Task Item
         * @param {number} deliveryRequestTaskItemId 
         * @param {UpdateDeliveryRequestTaskItemRequest} updateDeliveryRequestTaskItemRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDeliveryRequestTaskItemV2DeliveryRequestTasksItemDeliveryRequestTaskItemIdPatch: async (deliveryRequestTaskItemId: number, updateDeliveryRequestTaskItemRequest: UpdateDeliveryRequestTaskItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryRequestTaskItemId' is not null or undefined
            assertParamExists('updateDeliveryRequestTaskItemV2DeliveryRequestTasksItemDeliveryRequestTaskItemIdPatch', 'deliveryRequestTaskItemId', deliveryRequestTaskItemId)
            // verify required parameter 'updateDeliveryRequestTaskItemRequest' is not null or undefined
            assertParamExists('updateDeliveryRequestTaskItemV2DeliveryRequestTasksItemDeliveryRequestTaskItemIdPatch', 'updateDeliveryRequestTaskItemRequest', updateDeliveryRequestTaskItemRequest)
            const localVarPath = `/v2/delivery-request-tasks/item/{delivery_request_task_item_id}`
                .replace(`{${"delivery_request_task_item_id"}}`, encodeURIComponent(String(deliveryRequestTaskItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeliveryRequestTaskItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Delivery Request Task
         * @param {number} deliveryRequestTaskId 
         * @param {UpdateDeliveryRequestTaskRequest} updateDeliveryRequestTaskRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDeliveryRequestTaskV2DeliveryRequestTasksDeliveryRequestTaskIdPatch: async (deliveryRequestTaskId: number, updateDeliveryRequestTaskRequest: UpdateDeliveryRequestTaskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryRequestTaskId' is not null or undefined
            assertParamExists('updateDeliveryRequestTaskV2DeliveryRequestTasksDeliveryRequestTaskIdPatch', 'deliveryRequestTaskId', deliveryRequestTaskId)
            // verify required parameter 'updateDeliveryRequestTaskRequest' is not null or undefined
            assertParamExists('updateDeliveryRequestTaskV2DeliveryRequestTasksDeliveryRequestTaskIdPatch', 'updateDeliveryRequestTaskRequest', updateDeliveryRequestTaskRequest)
            const localVarPath = `/v2/delivery-request-tasks/{delivery_request_task_id}`
                .replace(`{${"delivery_request_task_id"}}`, encodeURIComponent(String(deliveryRequestTaskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeliveryRequestTaskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliveryRequestTasksApi - functional programming interface
 * @export
 */
export const DeliveryRequestTasksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeliveryRequestTasksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Read Delivery Request Task
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readDeliveryRequestTaskV2DeliveryRequestTasksIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryRequestTaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryRequestTaskV2DeliveryRequestTasksIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Request Tasks By User
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readDeliveryRequestTasksByUserV2DeliveryRequestTasksGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeliveryRequestTaskResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryRequestTasksByUserV2DeliveryRequestTasksGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Delivery Request Task Item
         * @param {number} deliveryRequestTaskItemId 
         * @param {UpdateDeliveryRequestTaskItemRequest} updateDeliveryRequestTaskItemRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateDeliveryRequestTaskItemV2DeliveryRequestTasksItemDeliveryRequestTaskItemIdPatch(deliveryRequestTaskItemId: number, updateDeliveryRequestTaskItemRequest: UpdateDeliveryRequestTaskItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryRequestTaskItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeliveryRequestTaskItemV2DeliveryRequestTasksItemDeliveryRequestTaskItemIdPatch(deliveryRequestTaskItemId, updateDeliveryRequestTaskItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Delivery Request Task
         * @param {number} deliveryRequestTaskId 
         * @param {UpdateDeliveryRequestTaskRequest} updateDeliveryRequestTaskRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateDeliveryRequestTaskV2DeliveryRequestTasksDeliveryRequestTaskIdPatch(deliveryRequestTaskId: number, updateDeliveryRequestTaskRequest: UpdateDeliveryRequestTaskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryRequestTaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeliveryRequestTaskV2DeliveryRequestTasksDeliveryRequestTaskIdPatch(deliveryRequestTaskId, updateDeliveryRequestTaskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeliveryRequestTasksApi - factory interface
 * @export
 */
export const DeliveryRequestTasksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeliveryRequestTasksApiFp(configuration)
    return {
        /**
         * 
         * @summary Read Delivery Request Task
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDeliveryRequestTaskV2DeliveryRequestTasksIdGet(id: number, options?: any): AxiosPromise<DeliveryRequestTaskResponse> {
            return localVarFp.readDeliveryRequestTaskV2DeliveryRequestTasksIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Request Tasks By User
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDeliveryRequestTasksByUserV2DeliveryRequestTasksGet(options?: any): AxiosPromise<Array<DeliveryRequestTaskResponse>> {
            return localVarFp.readDeliveryRequestTasksByUserV2DeliveryRequestTasksGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Delivery Request Task Item
         * @param {number} deliveryRequestTaskItemId 
         * @param {UpdateDeliveryRequestTaskItemRequest} updateDeliveryRequestTaskItemRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDeliveryRequestTaskItemV2DeliveryRequestTasksItemDeliveryRequestTaskItemIdPatch(deliveryRequestTaskItemId: number, updateDeliveryRequestTaskItemRequest: UpdateDeliveryRequestTaskItemRequest, options?: any): AxiosPromise<DeliveryRequestTaskItemResponse> {
            return localVarFp.updateDeliveryRequestTaskItemV2DeliveryRequestTasksItemDeliveryRequestTaskItemIdPatch(deliveryRequestTaskItemId, updateDeliveryRequestTaskItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Delivery Request Task
         * @param {number} deliveryRequestTaskId 
         * @param {UpdateDeliveryRequestTaskRequest} updateDeliveryRequestTaskRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDeliveryRequestTaskV2DeliveryRequestTasksDeliveryRequestTaskIdPatch(deliveryRequestTaskId: number, updateDeliveryRequestTaskRequest: UpdateDeliveryRequestTaskRequest, options?: any): AxiosPromise<DeliveryRequestTaskResponse> {
            return localVarFp.updateDeliveryRequestTaskV2DeliveryRequestTasksDeliveryRequestTaskIdPatch(deliveryRequestTaskId, updateDeliveryRequestTaskRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeliveryRequestTasksApi - object-oriented interface
 * @export
 * @class DeliveryRequestTasksApi
 * @extends {BaseAPI}
 */
export class DeliveryRequestTasksApi extends BaseAPI {
    /**
     * 
     * @summary Read Delivery Request Task
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DeliveryRequestTasksApi
     */
    public readDeliveryRequestTaskV2DeliveryRequestTasksIdGet(id: number, options?: AxiosRequestConfig) {
        return DeliveryRequestTasksApiFp(this.configuration).readDeliveryRequestTaskV2DeliveryRequestTasksIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Request Tasks By User
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DeliveryRequestTasksApi
     */
    public readDeliveryRequestTasksByUserV2DeliveryRequestTasksGet(options?: AxiosRequestConfig) {
        return DeliveryRequestTasksApiFp(this.configuration).readDeliveryRequestTasksByUserV2DeliveryRequestTasksGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Delivery Request Task Item
     * @param {number} deliveryRequestTaskItemId 
     * @param {UpdateDeliveryRequestTaskItemRequest} updateDeliveryRequestTaskItemRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DeliveryRequestTasksApi
     */
    public updateDeliveryRequestTaskItemV2DeliveryRequestTasksItemDeliveryRequestTaskItemIdPatch(deliveryRequestTaskItemId: number, updateDeliveryRequestTaskItemRequest: UpdateDeliveryRequestTaskItemRequest, options?: AxiosRequestConfig) {
        return DeliveryRequestTasksApiFp(this.configuration).updateDeliveryRequestTaskItemV2DeliveryRequestTasksItemDeliveryRequestTaskItemIdPatch(deliveryRequestTaskItemId, updateDeliveryRequestTaskItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Delivery Request Task
     * @param {number} deliveryRequestTaskId 
     * @param {UpdateDeliveryRequestTaskRequest} updateDeliveryRequestTaskRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DeliveryRequestTasksApi
     */
    public updateDeliveryRequestTaskV2DeliveryRequestTasksDeliveryRequestTaskIdPatch(deliveryRequestTaskId: number, updateDeliveryRequestTaskRequest: UpdateDeliveryRequestTaskRequest, options?: AxiosRequestConfig) {
        return DeliveryRequestTasksApiFp(this.configuration).updateDeliveryRequestTaskV2DeliveryRequestTasksDeliveryRequestTaskIdPatch(deliveryRequestTaskId, updateDeliveryRequestTaskRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeliveryRequestsApi - axios parameter creator
 * @export
 */
export const DeliveryRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Read Stock Receiving Process By Delivery Request Id
         * @param {number} deliveryRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet: async (deliveryRequestId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryRequestId' is not null or undefined
            assertParamExists('readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet', 'deliveryRequestId', deliveryRequestId)
            const localVarPath = `/v2/delivery-requests/{delivery_request_id}/process`
                .replace(`{${"delivery_request_id"}}`, encodeURIComponent(String(deliveryRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {StartStockReceivingProcessRequest} startStockReceivingProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost: async (deliveryRequestId: number, startStockReceivingProcessRequest: StartStockReceivingProcessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryRequestId' is not null or undefined
            assertParamExists('startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost', 'deliveryRequestId', deliveryRequestId)
            // verify required parameter 'startStockReceivingProcessRequest' is not null or undefined
            assertParamExists('startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost', 'startStockReceivingProcessRequest', startStockReceivingProcessRequest)
            const localVarPath = `/v2/delivery-requests/{delivery_request_id}/process`
                .replace(`{${"delivery_request_id"}}`, encodeURIComponent(String(deliveryRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startStockReceivingProcessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {Request} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch: async (deliveryRequestId: number, request: Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryRequestId' is not null or undefined
            assertParamExists('updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch', 'deliveryRequestId', deliveryRequestId)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch', 'request', request)
            const localVarPath = `/v2/delivery-requests/{delivery_request_id}/process`
                .replace(`{${"delivery_request_id"}}`, encodeURIComponent(String(deliveryRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliveryRequestsApi - functional programming interface
 * @export
 */
export const DeliveryRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeliveryRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Read Stock Receiving Process By Delivery Request Id
         * @param {number} deliveryRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet(deliveryRequestId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockReceivingProcessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet(deliveryRequestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {StartStockReceivingProcessRequest} startStockReceivingProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId: number, startStockReceivingProcessRequest: StartStockReceivingProcessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId, startStockReceivingProcessRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {Request} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId: number, request: Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockReceivingProcessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeliveryRequestsApi - factory interface
 * @export
 */
export const DeliveryRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeliveryRequestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Read Stock Receiving Process By Delivery Request Id
         * @param {number} deliveryRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet(deliveryRequestId: number, options?: any): AxiosPromise<StockReceivingProcessResponse> {
            return localVarFp.readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet(deliveryRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {StartStockReceivingProcessRequest} startStockReceivingProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId: number, startStockReceivingProcessRequest: StartStockReceivingProcessRequest, options?: any): AxiosPromise<void> {
            return localVarFp.startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId, startStockReceivingProcessRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {Request} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId: number, request: Request, options?: any): AxiosPromise<StockReceivingProcessResponse> {
            return localVarFp.updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId, request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeliveryRequestsApi - object-oriented interface
 * @export
 * @class DeliveryRequestsApi
 * @extends {BaseAPI}
 */
export class DeliveryRequestsApi extends BaseAPI {
    /**
     * 
     * @summary Read Stock Receiving Process By Delivery Request Id
     * @param {number} deliveryRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryRequestsApi
     */
    public readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet(deliveryRequestId: number, options?: AxiosRequestConfig) {
        return DeliveryRequestsApiFp(this.configuration).readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet(deliveryRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start Stock Receiving Process
     * @param {number} deliveryRequestId 
     * @param {StartStockReceivingProcessRequest} startStockReceivingProcessRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryRequestsApi
     */
    public startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId: number, startStockReceivingProcessRequest: StartStockReceivingProcessRequest, options?: AxiosRequestConfig) {
        return DeliveryRequestsApiFp(this.configuration).startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId, startStockReceivingProcessRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Stock Receiving Process
     * @param {number} deliveryRequestId 
     * @param {Request} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryRequestsApi
     */
    public updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId: number, request: Request, options?: AxiosRequestConfig) {
        return DeliveryRequestsApiFp(this.configuration).updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId, request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Read Act Of Acceptance By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet', 'id', id)
            const localVarPath = `/v2/acts-of-acceptance/{id}/document`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Act Of Acceptance Items
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet: async (actOfAcceptanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actOfAcceptanceId' is not null or undefined
            assertParamExists('readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet', 'actOfAcceptanceId', actOfAcceptanceId)
            const localVarPath = `/v2/acts-of-acceptance/{act_of_acceptance_id}/items`
                .replace(`{${"act_of_acceptance_id"}}`, encodeURIComponent(String(actOfAcceptanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet: async (actOfAcceptanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actOfAcceptanceId' is not null or undefined
            assertParamExists('readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet', 'actOfAcceptanceId', actOfAcceptanceId)
            const localVarPath = `/v2/acts-of-acceptance/{act_of_acceptance_id}`
                .replace(`{${"act_of_acceptance_id"}}`, encodeURIComponent(String(actOfAcceptanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Acts Of Acceptance
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {string} [users] 
         * @param {string} [orderTypes] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActsOfAcceptanceV2ActsOfAcceptanceGet: async (fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, users?: string, orderTypes?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/acts-of-acceptance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (receivers !== undefined) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (legalEntities !== undefined) {
                localVarQueryParameter['legal_entities'] = legalEntities;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (users !== undefined) {
                localVarQueryParameter['users'] = users;
            }

            if (orderTypes !== undefined) {
                localVarQueryParameter['order_types'] = orderTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Read Act Of Acceptance By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Act Of Acceptance Items
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActOfAcceptanceItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActOfAcceptanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Acts Of Acceptance
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {string} [users] 
         * @param {string} [orderTypes] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, users?: string, orderTypes?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActOfAcceptanceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate, toDate, receivers, legalEntities, companies, users, orderTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Read Act Of Acceptance By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Act Of Acceptance Items
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId: number, options?: any): AxiosPromise<Array<ActOfAcceptanceItemResponse>> {
            return localVarFp.readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId: number, options?: any): AxiosPromise<ActOfAcceptanceResponse> {
            return localVarFp.readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Acts Of Acceptance
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {string} [users] 
         * @param {string} [orderTypes] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, users?: string, orderTypes?: string, options?: any): AxiosPromise<Array<ActOfAcceptanceResponse>> {
            return localVarFp.readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate, toDate, receivers, legalEntities, companies, users, orderTypes, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI {
    /**
     * 
     * @summary Read Act Of Acceptance By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id: number, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Act Of Acceptance Items
     * @param {number} actOfAcceptanceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId: number, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Act Of Acceptance
     * @param {number} actOfAcceptanceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId: number, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Acts Of Acceptance
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [receivers] 
     * @param {string} [legalEntities] 
     * @param {string} [companies] 
     * @param {string} [users] 
     * @param {string} [orderTypes] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, users?: string, orderTypes?: string, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate, toDate, receivers, legalEntities, companies, users, orderTypes, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvoicesApi - axios parameter creator
 * @export
 */
export const InvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel Invoice By Invoice Id
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        cancelInvoiceByInvoiceIdV2InvoicesInvoiceIdPatch: async (invoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('cancelInvoiceByInvoiceIdV2InvoicesInvoiceIdPatch', 'invoiceId', invoiceId)
            const localVarPath = `/v2/invoices/{invoice_id}`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {CreateInvoiceRequest} createInvoiceRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createV2InvoicesPost: async (createInvoiceRequest: CreateInvoiceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createInvoiceRequest' is not null or undefined
            assertParamExists('createV2InvoicesPost', 'createInvoiceRequest', createInvoiceRequest)
            const localVarPath = `/v2/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInvoiceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoice Credit Notes
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceCreditNotesV2InvoicesInvoiceIdCreditNotesGet: async (invoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('readInvoiceCreditNotesV2InvoicesInvoiceIdCreditNotesGet', 'invoiceId', invoiceId)
            const localVarPath = `/v2/invoices/{invoice_id}/credit-notes`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoice Document
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceDocumentV2InvoicesInvoiceIdDocumentGet: async (invoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('readInvoiceDocumentV2InvoicesInvoiceIdDocumentGet', 'invoiceId', invoiceId)
            const localVarPath = `/v2/invoices/{invoice_id}/document`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoice Orders
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceOrdersV2InvoicesInvoiceIdOrdersGet: async (invoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('readInvoiceOrdersV2InvoicesInvoiceIdOrdersGet', 'invoiceId', invoiceId)
            const localVarPath = `/v2/invoices/{invoice_id}/orders`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoice Transactions
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceTransactionsV2InvoicesInvoiceIdTransactionsGet: async (invoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('readInvoiceTransactionsV2InvoicesInvoiceIdTransactionsGet', 'invoiceId', invoiceId)
            const localVarPath = `/v2/invoices/{invoice_id}/transactions`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoice
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceV2InvoicesInvoiceIdGet: async (invoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('readInvoiceV2InvoicesInvoiceIdGet', 'invoiceId', invoiceId)
            const localVarPath = `/v2/invoices/{invoice_id}`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {boolean} [hideCancelled] 
         * @param {boolean} [hidePaidInFull] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoicesV2InvoicesGet: async (fromDate?: string, toDate?: string, legalEntities?: string, companies?: string, hideCancelled?: boolean, hidePaidInFull?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (legalEntities !== undefined) {
                localVarQueryParameter['legal_entities'] = legalEntities;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (hideCancelled !== undefined) {
                localVarQueryParameter['hide_cancelled'] = hideCancelled;
            }

            if (hidePaidInFull !== undefined) {
                localVarQueryParameter['hide_paid_in_full'] = hidePaidInFull;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoicesApi - functional programming interface
 * @export
 */
export const InvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel Invoice By Invoice Id
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async cancelInvoiceByInvoiceIdV2InvoicesInvoiceIdPatch(invoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelInvoiceByInvoiceIdV2InvoicesInvoiceIdPatch(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {CreateInvoiceRequest} createInvoiceRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createV2InvoicesPost(createInvoiceRequest: CreateInvoiceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV2InvoicesPost(createInvoiceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoice Credit Notes
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readInvoiceCreditNotesV2InvoicesInvoiceIdCreditNotesGet(invoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreditNoteSimpleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoiceCreditNotesV2InvoicesInvoiceIdCreditNotesGet(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoice Document
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readInvoiceDocumentV2InvoicesInvoiceIdDocumentGet(invoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoiceDocumentV2InvoicesInvoiceIdDocumentGet(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoice Orders
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readInvoiceOrdersV2InvoicesInvoiceIdOrdersGet(invoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderV2SimpleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoiceOrdersV2InvoicesInvoiceIdOrdersGet(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoice Transactions
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readInvoiceTransactionsV2InvoicesInvoiceIdTransactionsGet(invoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransactionSimpleV2Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoiceTransactionsV2InvoicesInvoiceIdTransactionsGet(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoice
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readInvoiceV2InvoicesInvoiceIdGet(invoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoiceV2InvoicesInvoiceIdGet(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {boolean} [hideCancelled] 
         * @param {boolean} [hidePaidInFull] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readInvoicesV2InvoicesGet(fromDate?: string, toDate?: string, legalEntities?: string, companies?: string, hideCancelled?: boolean, hidePaidInFull?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceV2Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoicesV2InvoicesGet(fromDate, toDate, legalEntities, companies, hideCancelled, hidePaidInFull, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoicesApi - factory interface
 * @export
 */
export const InvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel Invoice By Invoice Id
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        cancelInvoiceByInvoiceIdV2InvoicesInvoiceIdPatch(invoiceId: number, options?: any): AxiosPromise<InvoiceV2Response> {
            return localVarFp.cancelInvoiceByInvoiceIdV2InvoicesInvoiceIdPatch(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {CreateInvoiceRequest} createInvoiceRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createV2InvoicesPost(createInvoiceRequest: CreateInvoiceRequest, options?: any): AxiosPromise<InvoiceResponse> {
            return localVarFp.createV2InvoicesPost(createInvoiceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoice Credit Notes
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceCreditNotesV2InvoicesInvoiceIdCreditNotesGet(invoiceId: number, options?: any): AxiosPromise<Array<CreditNoteSimpleResponse>> {
            return localVarFp.readInvoiceCreditNotesV2InvoicesInvoiceIdCreditNotesGet(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoice Document
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceDocumentV2InvoicesInvoiceIdDocumentGet(invoiceId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readInvoiceDocumentV2InvoicesInvoiceIdDocumentGet(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoice Orders
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceOrdersV2InvoicesInvoiceIdOrdersGet(invoiceId: number, options?: any): AxiosPromise<Array<OrderV2SimpleResponse>> {
            return localVarFp.readInvoiceOrdersV2InvoicesInvoiceIdOrdersGet(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoice Transactions
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceTransactionsV2InvoicesInvoiceIdTransactionsGet(invoiceId: number, options?: any): AxiosPromise<Array<TransactionSimpleV2Response>> {
            return localVarFp.readInvoiceTransactionsV2InvoicesInvoiceIdTransactionsGet(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoice
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceV2InvoicesInvoiceIdGet(invoiceId: number, options?: any): AxiosPromise<InvoiceResponse> {
            return localVarFp.readInvoiceV2InvoicesInvoiceIdGet(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {boolean} [hideCancelled] 
         * @param {boolean} [hidePaidInFull] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoicesV2InvoicesGet(fromDate?: string, toDate?: string, legalEntities?: string, companies?: string, hideCancelled?: boolean, hidePaidInFull?: boolean, options?: any): AxiosPromise<Array<InvoiceV2Response>> {
            return localVarFp.readInvoicesV2InvoicesGet(fromDate, toDate, legalEntities, companies, hideCancelled, hidePaidInFull, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoicesApi - object-oriented interface
 * @export
 * @class InvoicesApi
 * @extends {BaseAPI}
 */
export class InvoicesApi extends BaseAPI {
    /**
     * 
     * @summary Cancel Invoice By Invoice Id
     * @param {number} invoiceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public cancelInvoiceByInvoiceIdV2InvoicesInvoiceIdPatch(invoiceId: number, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).cancelInvoiceByInvoiceIdV2InvoicesInvoiceIdPatch(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {CreateInvoiceRequest} createInvoiceRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public createV2InvoicesPost(createInvoiceRequest: CreateInvoiceRequest, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).createV2InvoicesPost(createInvoiceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoice Credit Notes
     * @param {number} invoiceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public readInvoiceCreditNotesV2InvoicesInvoiceIdCreditNotesGet(invoiceId: number, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).readInvoiceCreditNotesV2InvoicesInvoiceIdCreditNotesGet(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoice Document
     * @param {number} invoiceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public readInvoiceDocumentV2InvoicesInvoiceIdDocumentGet(invoiceId: number, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).readInvoiceDocumentV2InvoicesInvoiceIdDocumentGet(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoice Orders
     * @param {number} invoiceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public readInvoiceOrdersV2InvoicesInvoiceIdOrdersGet(invoiceId: number, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).readInvoiceOrdersV2InvoicesInvoiceIdOrdersGet(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoice Transactions
     * @param {number} invoiceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public readInvoiceTransactionsV2InvoicesInvoiceIdTransactionsGet(invoiceId: number, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).readInvoiceTransactionsV2InvoicesInvoiceIdTransactionsGet(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoice
     * @param {number} invoiceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public readInvoiceV2InvoicesInvoiceIdGet(invoiceId: number, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).readInvoiceV2InvoicesInvoiceIdGet(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoices
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [legalEntities] 
     * @param {string} [companies] 
     * @param {boolean} [hideCancelled] 
     * @param {boolean} [hidePaidInFull] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public readInvoicesV2InvoicesGet(fromDate?: string, toDate?: string, legalEntities?: string, companies?: string, hideCancelled?: boolean, hidePaidInFull?: boolean, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).readInvoicesV2InvoicesGet(fromDate, toDate, legalEntities, companies, hideCancelled, hidePaidInFull, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemsApi - axios parameter creator
 * @export
 */
export const ItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create
         * @param {CreateItemCategoryRequest} createItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1ItemCategoriesPost: async (createItemCategoryRequest: CreateItemCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createItemCategoryRequest' is not null or undefined
            assertParamExists('createV1ItemCategoriesPost', 'createItemCategoryRequest', createItemCategoryRequest)
            const localVarPath = `/v1/item-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createItemCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1ItemsItemIdLinkedItemAssociationLinkedItemIdPost: async (itemId: number, linkedItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('createV1ItemsItemIdLinkedItemAssociationLinkedItemIdPost', 'itemId', itemId)
            // verify required parameter 'linkedItemId' is not null or undefined
            assertParamExists('createV1ItemsItemIdLinkedItemAssociationLinkedItemIdPost', 'linkedItemId', linkedItemId)
            const localVarPath = `/v1/items/{item_id}/linked-item-association/{linked_item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"linked_item_id"}}`, encodeURIComponent(String(linkedItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1ItemsItemIdSimilarItemAssociationSimilarItemIdPost: async (itemId: number, similarItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('createV1ItemsItemIdSimilarItemAssociationSimilarItemIdPost', 'itemId', itemId)
            // verify required parameter 'similarItemId' is not null or undefined
            assertParamExists('createV1ItemsItemIdSimilarItemAssociationSimilarItemIdPost', 'similarItemId', similarItemId)
            const localVarPath = `/v1/items/{item_id}/similar-item-association/{similar_item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"similar_item_id"}}`, encodeURIComponent(String(similarItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {CreateItemRequest} createItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1ItemsPost: async (createItemRequest: CreateItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createItemRequest' is not null or undefined
            assertParamExists('createV1ItemsPost', 'createItemRequest', createItemRequest)
            const localVarPath = `/v1/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1ItemCategoriesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteV1ItemCategoriesIdDelete', 'id', id)
            const localVarPath = `/v1/item-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1ItemsItemIdLinkedItemAssociationLinkedItemIdDelete: async (itemId: number, linkedItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('deleteV1ItemsItemIdLinkedItemAssociationLinkedItemIdDelete', 'itemId', itemId)
            // verify required parameter 'linkedItemId' is not null or undefined
            assertParamExists('deleteV1ItemsItemIdLinkedItemAssociationLinkedItemIdDelete', 'linkedItemId', linkedItemId)
            const localVarPath = `/v1/items/{item_id}/linked-item-association/{linked_item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"linked_item_id"}}`, encodeURIComponent(String(linkedItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1ItemsItemIdSimilarItemAssociationSimilarItemIdDelete: async (itemId: number, similarItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('deleteV1ItemsItemIdSimilarItemAssociationSimilarItemIdDelete', 'itemId', itemId)
            // verify required parameter 'similarItemId' is not null or undefined
            assertParamExists('deleteV1ItemsItemIdSimilarItemAssociationSimilarItemIdDelete', 'similarItemId', similarItemId)
            const localVarPath = `/v1/items/{item_id}/similar-item-association/{similar_item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"similar_item_id"}}`, encodeURIComponent(String(similarItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All Flat
         * @param {boolean} [leafOnly] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllFlatV1ItemCategoriesFlatGet: async (leafOnly?: boolean, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/item-categories/flat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (leafOnly !== undefined) {
                localVarQueryParameter['leaf_only'] = leafOnly;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All Units
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllUnitsV1ItemsUnitsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/items/units`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [simple] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllV1ItemCategoriesGet: async (skip?: number, limit?: number, simple?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/item-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (simple !== undefined) {
                localVarQueryParameter['simple'] = simple;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllV1ItemsItemIdLinkedItemAssociationGet: async (itemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('readAllV1ItemsItemIdLinkedItemAssociationGet', 'itemId', itemId)
            const localVarPath = `/v1/items/{item_id}/linked-item-association`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllV1ItemsItemIdSimilarItemAssociationGet: async (itemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('readAllV1ItemsItemIdSimilarItemAssociationGet', 'itemId', itemId)
            const localVarPath = `/v1/items/{item_id}/similar-item-association`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readV1ItemCategoriesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readV1ItemCategoriesIdGet', 'id', id)
            const localVarPath = `/v1/item-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {UpdateItemCategoryRequest} updateItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateV1ItemCategoriesPut: async (updateItemCategoryRequest: UpdateItemCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateItemCategoryRequest' is not null or undefined
            assertParamExists('updateV1ItemCategoriesPut', 'updateItemCategoryRequest', updateItemCategoryRequest)
            const localVarPath = `/v1/item-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateItemCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {UpdateItemRequest} updateItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateV1ItemsPut: async (updateItemRequest: UpdateItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateItemRequest' is not null or undefined
            assertParamExists('updateV1ItemsPut', 'updateItemRequest', updateItemRequest)
            const localVarPath = `/v1/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsApi - functional programming interface
 * @export
 */
export const ItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {CreateItemCategoryRequest} createItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createV1ItemCategoriesPost(createItemCategoryRequest: CreateItemCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1ItemCategoriesPost(createItemCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createV1ItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId: number, linkedItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkedItemAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1ItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId, linkedItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createV1ItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId: number, similarItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimilarItemAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1ItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId, similarItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {CreateItemRequest} createItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createV1ItemsPost(createItemRequest: CreateItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1ItemsPost(createItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1ItemCategoriesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1ItemCategoriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1ItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId: number, linkedItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkedItemAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1ItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId, linkedItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1ItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId: number, similarItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimilarItemAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1ItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId, similarItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All Flat
         * @param {boolean} [leafOnly] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAllFlatV1ItemCategoriesFlatGet(leafOnly?: boolean, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllFlatV1ItemCategoriesFlatGet(leafOnly, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All Units
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAllUnitsV1ItemsUnitsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UnitResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllUnitsV1ItemsUnitsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [simple] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAllV1ItemCategoriesGet(skip?: number, limit?: number, simple?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllV1ItemCategoriesGet(skip, limit, simple, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAllV1ItemsItemIdLinkedItemAssociationGet(itemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LinkedItemAssociationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllV1ItemsItemIdLinkedItemAssociationGet(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAllV1ItemsItemIdSimilarItemAssociationGet(itemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SimilarItemAssociationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllV1ItemsItemIdSimilarItemAssociationGet(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readV1ItemCategoriesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readV1ItemCategoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {UpdateItemCategoryRequest} updateItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateV1ItemCategoriesPut(updateItemCategoryRequest: UpdateItemCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateV1ItemCategoriesPut(updateItemCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {UpdateItemRequest} updateItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateV1ItemsPut(updateItemRequest: UpdateItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateV1ItemsPut(updateItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsApi - factory interface
 * @export
 */
export const ItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {CreateItemCategoryRequest} createItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1ItemCategoriesPost(createItemCategoryRequest: CreateItemCategoryRequest, options?: any): AxiosPromise<ItemCategoryResponse> {
            return localVarFp.createV1ItemCategoriesPost(createItemCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1ItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId: number, linkedItemId: number, options?: any): AxiosPromise<LinkedItemAssociationResponse> {
            return localVarFp.createV1ItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId, linkedItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1ItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId: number, similarItemId: number, options?: any): AxiosPromise<SimilarItemAssociationResponse> {
            return localVarFp.createV1ItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId, similarItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {CreateItemRequest} createItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1ItemsPost(createItemRequest: CreateItemRequest, options?: any): AxiosPromise<ItemResponse> {
            return localVarFp.createV1ItemsPost(createItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1ItemCategoriesIdDelete(id: number, options?: any): AxiosPromise<ItemCategoryResponse> {
            return localVarFp.deleteV1ItemCategoriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1ItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId: number, linkedItemId: number, options?: any): AxiosPromise<LinkedItemAssociationResponse> {
            return localVarFp.deleteV1ItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId, linkedItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1ItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId: number, similarItemId: number, options?: any): AxiosPromise<SimilarItemAssociationResponse> {
            return localVarFp.deleteV1ItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId, similarItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All Flat
         * @param {boolean} [leafOnly] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllFlatV1ItemCategoriesFlatGet(leafOnly?: boolean, skip?: number, limit?: number, options?: any): AxiosPromise<Array<ItemCategoryResponse>> {
            return localVarFp.readAllFlatV1ItemCategoriesFlatGet(leafOnly, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All Units
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllUnitsV1ItemsUnitsGet(options?: any): AxiosPromise<Array<UnitResponse>> {
            return localVarFp.readAllUnitsV1ItemsUnitsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [simple] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllV1ItemCategoriesGet(skip?: number, limit?: number, simple?: boolean, options?: any): AxiosPromise<Array<ItemCategoryResponse>> {
            return localVarFp.readAllV1ItemCategoriesGet(skip, limit, simple, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllV1ItemsItemIdLinkedItemAssociationGet(itemId: number, options?: any): AxiosPromise<Array<LinkedItemAssociationResponse>> {
            return localVarFp.readAllV1ItemsItemIdLinkedItemAssociationGet(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllV1ItemsItemIdSimilarItemAssociationGet(itemId: number, options?: any): AxiosPromise<Array<SimilarItemAssociationResponse>> {
            return localVarFp.readAllV1ItemsItemIdSimilarItemAssociationGet(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readV1ItemCategoriesIdGet(id: number, options?: any): AxiosPromise<ItemCategoryResponse> {
            return localVarFp.readV1ItemCategoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {UpdateItemCategoryRequest} updateItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateV1ItemCategoriesPut(updateItemCategoryRequest: UpdateItemCategoryRequest, options?: any): AxiosPromise<ItemCategoryResponse> {
            return localVarFp.updateV1ItemCategoriesPut(updateItemCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {UpdateItemRequest} updateItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateV1ItemsPut(updateItemRequest: UpdateItemRequest, options?: any): AxiosPromise<ItemResponse> {
            return localVarFp.updateV1ItemsPut(updateItemRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsApi - object-oriented interface
 * @export
 * @class ItemsApi
 * @extends {BaseAPI}
 */
export class ItemsApi extends BaseAPI {
    /**
     * 
     * @summary Create
     * @param {CreateItemCategoryRequest} createItemCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createV1ItemCategoriesPost(createItemCategoryRequest: CreateItemCategoryRequest, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).createV1ItemCategoriesPost(createItemCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {number} itemId 
     * @param {number} linkedItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createV1ItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId: number, linkedItemId: number, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).createV1ItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId, linkedItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {number} itemId 
     * @param {number} similarItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createV1ItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId: number, similarItemId: number, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).createV1ItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId, similarItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {CreateItemRequest} createItemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createV1ItemsPost(createItemRequest: CreateItemRequest, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).createV1ItemsPost(createItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteV1ItemCategoriesIdDelete(id: number, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).deleteV1ItemCategoriesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} itemId 
     * @param {number} linkedItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteV1ItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId: number, linkedItemId: number, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).deleteV1ItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId, linkedItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} itemId 
     * @param {number} similarItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteV1ItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId: number, similarItemId: number, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).deleteV1ItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId, similarItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All Flat
     * @param {boolean} [leafOnly] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readAllFlatV1ItemCategoriesFlatGet(leafOnly?: boolean, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).readAllFlatV1ItemCategoriesFlatGet(leafOnly, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All Units
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readAllUnitsV1ItemsUnitsGet(options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).readAllUnitsV1ItemsUnitsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {boolean} [simple] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readAllV1ItemCategoriesGet(skip?: number, limit?: number, simple?: boolean, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).readAllV1ItemCategoriesGet(skip, limit, simple, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readAllV1ItemsItemIdLinkedItemAssociationGet(itemId: number, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).readAllV1ItemsItemIdLinkedItemAssociationGet(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readAllV1ItemsItemIdSimilarItemAssociationGet(itemId: number, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).readAllV1ItemsItemIdSimilarItemAssociationGet(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readV1ItemCategoriesIdGet(id: number, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).readV1ItemCategoriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {UpdateItemCategoryRequest} updateItemCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public updateV1ItemCategoriesPut(updateItemCategoryRequest: UpdateItemCategoryRequest, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).updateV1ItemCategoriesPut(updateItemCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {UpdateItemRequest} updateItemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public updateV1ItemsPut(updateItemRequest: UpdateItemRequest, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).updateV1ItemsPut(updateItemRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MobileApi - axios parameter creator
 * @export
 */
export const MobileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel Invoice Document
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/invoice`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderV2Request} createOrderV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderV2OrdersPost: async (createOrderV2Request: CreateOrderV2Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderV2Request' is not null or undefined
            assertParamExists('createOrderV2OrdersPost', 'createOrderV2Request', createOrderV2Request)
            const localVarPath = `/v2/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderV2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Print Document Queue
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/documents/print`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Picked Order Item
         * @param {number} pickedOrderItemId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete: async (pickedOrderItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pickedOrderItemId' is not null or undefined
            assertParamExists('deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete', 'pickedOrderItemId', pickedOrderItemId)
            const localVarPath = `/v2/orders/picked-order-item/{picked_order_item_id}`
                .replace(`{${"picked_order_item_id"}}`, encodeURIComponent(String(pickedOrderItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get B2B Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getB2bOrdersV2OrdersB2bOrdersGet: async (status?: string, companies?: string, warehouses?: string, receivers?: string, legalEntities?: string, fromDeliveryDate?: string, toDeliveryDate?: string, withExecutionPercentage?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orders/b2b-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (receivers !== undefined) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (legalEntities !== undefined) {
                localVarQueryParameter['legal_entities'] = legalEntities;
            }

            if (fromDeliveryDate !== undefined) {
                localVarQueryParameter['from_delivery_date'] = (fromDeliveryDate as any instanceof Date) ?
                    (fromDeliveryDate as any).toISOString() :
                    fromDeliveryDate;
            }

            if (toDeliveryDate !== undefined) {
                localVarQueryParameter['to_delivery_date'] = (toDeliveryDate as any instanceof Date) ?
                    (toDeliveryDate as any).toISOString() :
                    toDeliveryDate;
            }

            if (withExecutionPercentage !== undefined) {
                localVarQueryParameter['with_execution_percentage'] = withExecutionPercentage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Internal Transfers
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [receivers] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getInternalTransfersV2OrdersInternalTransfersGet: async (status?: string, companies?: string, warehouses?: string, receivers?: string, fromDeliveryDate?: string, toDeliveryDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orders/internal-transfers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (receivers !== undefined) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (fromDeliveryDate !== undefined) {
                localVarQueryParameter['from_delivery_date'] = (fromDeliveryDate as any instanceof Date) ?
                    (fromDeliveryDate as any).toISOString() :
                    fromDeliveryDate;
            }

            if (toDeliveryDate !== undefined) {
                localVarQueryParameter['to_delivery_date'] = (toDeliveryDate as any instanceof Date) ?
                    (toDeliveryDate as any).toISOString() :
                    toDeliveryDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {boolean} [withItems] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        listOrdersV2OrdersGet: async (status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (noInvoiceOnly !== undefined) {
                localVarQueryParameter['no_invoice_only'] = noInvoiceOnly;
            }

            if (withExecutionPercentage !== undefined) {
                localVarQueryParameter['with_execution_percentage'] = withExecutionPercentage;
            }

            if (withItems !== undefined) {
                localVarQueryParameter['with_items'] = withItems;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pick Order Item
         * @param {number} orderItemId 
         * @param {PickOrderItemRequest} pickOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        pickOrderItemV2OrdersOrderItemOrderItemIdPut: async (orderItemId: number, pickOrderItemRequest: PickOrderItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderItemId' is not null or undefined
            assertParamExists('pickOrderItemV2OrdersOrderItemOrderItemIdPut', 'orderItemId', orderItemId)
            // verify required parameter 'pickOrderItemRequest' is not null or undefined
            assertParamExists('pickOrderItemV2OrdersOrderItemOrderItemIdPut', 'pickOrderItemRequest', pickOrderItemRequest)
            const localVarPath = `/v2/orders/order-item/{order_item_id}`
                .replace(`{${"order_item_id"}}`, encodeURIComponent(String(orderItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pickOrderItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Act Of Acceptance By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet', 'id', id)
            const localVarPath = `/v2/acts-of-acceptance/{id}/document`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Act Of Acceptance Items
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet: async (actOfAcceptanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actOfAcceptanceId' is not null or undefined
            assertParamExists('readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet', 'actOfAcceptanceId', actOfAcceptanceId)
            const localVarPath = `/v2/acts-of-acceptance/{act_of_acceptance_id}/items`
                .replace(`{${"act_of_acceptance_id"}}`, encodeURIComponent(String(actOfAcceptanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet: async (actOfAcceptanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actOfAcceptanceId' is not null or undefined
            assertParamExists('readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet', 'actOfAcceptanceId', actOfAcceptanceId)
            const localVarPath = `/v2/acts-of-acceptance/{act_of_acceptance_id}`
                .replace(`{${"act_of_acceptance_id"}}`, encodeURIComponent(String(actOfAcceptanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/documents/act-of-acceptance`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Acts Of Acceptance
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {string} [users] 
         * @param {string} [orderTypes] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActsOfAcceptanceV2ActsOfAcceptanceGet: async (fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, users?: string, orderTypes?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/acts-of-acceptance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (receivers !== undefined) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (legalEntities !== undefined) {
                localVarQueryParameter['legal_entities'] = legalEntities;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (users !== undefined) {
                localVarQueryParameter['users'] = users;
            }

            if (orderTypes !== undefined) {
                localVarQueryParameter['order_types'] = orderTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Assigned Orders For Picker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAssignedOrdersForPickerV2OrdersPickerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orders/picker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/documents/delivery-batch`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoice Document
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/documents/invoice`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order By Order Id
         * @param {number} orderId 
         * @param {boolean} [withLivePrices] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderByOrderIdV2OrdersOrderIdGet: async (orderId: number, withLivePrices?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderByOrderIdV2OrdersOrderIdGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (withLivePrices !== undefined) {
                localVarQueryParameter['with_live_prices'] = withLivePrices;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdDocumentsGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderDocumentsV2OrdersOrderIdDocumentsGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/documents`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdStatusLogsGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderDocumentsV2OrdersOrderIdStatusLogsGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/status-logs`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Transactions
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderTransactionsV2OrdersOrderIdTransactionsGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderTransactionsV2OrdersOrderIdTransactionsGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/transactions`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Orders For Inspection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrdersForInspectionV2OrdersInspectorGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orders/inspector`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return Items
         * @param {number} orderId 
         * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnItemsV2OrdersOrderIdReturnItemsPut: async (orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('returnItemsV2OrdersOrderIdReturnItemsPut', 'orderId', orderId)
            // verify required parameter 'returnOrderItemV2Request' is not null or undefined
            assertParamExists('returnItemsV2OrdersOrderIdReturnItemsPut', 'returnOrderItemV2Request', returnOrderItemV2Request)
            const localVarPath = `/v2/orders/{order_id}/return-items`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(returnOrderItemV2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Order Status
         * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderStatusV2OrdersStatusPut: async (orderStatusUpdateRequest: OrderStatusUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderStatusUpdateRequest' is not null or undefined
            assertParamExists('updateOrderStatusV2OrdersStatusPut', 'orderStatusUpdateRequest', orderStatusUpdateRequest)
            const localVarPath = `/v2/orders/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderStatusUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Order
         * @param {number} orderId 
         * @param {UpdateOrderV2Request} updateOrderV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderV2OrdersOrderIdPatch: async (orderId: number, updateOrderV2Request: UpdateOrderV2Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('updateOrderV2OrdersOrderIdPatch', 'orderId', orderId)
            // verify required parameter 'updateOrderV2Request' is not null or undefined
            assertParamExists('updateOrderV2OrdersOrderIdPatch', 'updateOrderV2Request', updateOrderV2Request)
            const localVarPath = `/v2/orders/{order_id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderV2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileApi - functional programming interface
 * @export
 */
export const MobileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel Invoice Document
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderV2Request} createOrderV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrderV2OrdersPost(createOrderV2Request: CreateOrderV2Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrderV2OrdersPost(createOrderV2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Print Document Queue
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Picked Order Item
         * @param {number} pickedOrderItemId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete(pickedOrderItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchOrderItemV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete(pickedOrderItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get B2B Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getB2bOrdersV2OrdersB2bOrdersGet(status?: string, companies?: string, warehouses?: string, receivers?: string, legalEntities?: string, fromDeliveryDate?: string, toDeliveryDate?: string, withExecutionPercentage?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderV2ListResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getB2bOrdersV2OrdersB2bOrdersGet(status, companies, warehouses, receivers, legalEntities, fromDeliveryDate, toDeliveryDate, withExecutionPercentage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Internal Transfers
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [receivers] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getInternalTransfersV2OrdersInternalTransfersGet(status?: string, companies?: string, warehouses?: string, receivers?: string, fromDeliveryDate?: string, toDeliveryDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderV2ListResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInternalTransfersV2OrdersInternalTransfersGet(status, companies, warehouses, receivers, fromDeliveryDate, toDeliveryDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {boolean} [withItems] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async listOrdersV2OrdersGet(status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderV2Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOrdersV2OrdersGet(status, companies, warehouses, fromDate, toDate, noInvoiceOnly, withExecutionPercentage, withItems, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pick Order Item
         * @param {number} orderItemId 
         * @param {PickOrderItemRequest} pickOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async pickOrderItemV2OrdersOrderItemOrderItemIdPut(orderItemId: number, pickOrderItemRequest: PickOrderItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pickOrderItemV2OrdersOrderItemOrderItemIdPut(orderItemId, pickOrderItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Act Of Acceptance By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Act Of Acceptance Items
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActOfAcceptanceItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActOfAcceptanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Acts Of Acceptance
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {string} [users] 
         * @param {string} [orderTypes] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, users?: string, orderTypes?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActOfAcceptanceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate, toDate, receivers, legalEntities, companies, users, orderTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Assigned Orders For Picker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAssignedOrdersForPickerV2OrdersPickerGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderV2Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAssignedOrdersForPickerV2OrdersPickerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoice Document
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order By Order Id
         * @param {number} orderId 
         * @param {boolean} [withLivePrices] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderByOrderIdV2OrdersOrderIdGet(orderId: number, withLivePrices?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderByOrderIdV2OrdersOrderIdGet(orderId, withLivePrices, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderDocumentV2Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderStatusLogResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Transactions
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderTransactionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Orders For Inspection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrdersForInspectionV2OrdersInspectorGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderV2Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrdersForInspectionV2OrdersInspectorGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return Items
         * @param {number} orderId 
         * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async returnItemsV2OrdersOrderIdReturnItemsPut(orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnOrderItemsV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.returnItemsV2OrdersOrderIdReturnItemsPut(orderId, returnOrderItemV2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Order Status
         * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest: OrderStatusUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Order
         * @param {number} orderId 
         * @param {UpdateOrderV2Request} updateOrderV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderV2OrdersOrderIdPatch(orderId: number, updateOrderV2Request: UpdateOrderV2Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderV2OrdersOrderIdPatch(orderId, updateOrderV2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MobileApi - factory interface
 * @export
 */
export const MobileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel Invoice Document
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete(orderId: number, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderV2Request} createOrderV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderV2OrdersPost(createOrderV2Request: CreateOrderV2Request, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.createOrderV2OrdersPost(createOrderV2Request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Print Document Queue
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost(orderId: number, options?: any): AxiosPromise<void> {
            return localVarFp.createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Picked Order Item
         * @param {number} pickedOrderItemId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete(pickedOrderItemId: number, options?: any): AxiosPromise<BatchOrderItemV2Response> {
            return localVarFp.deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete(pickedOrderItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get B2B Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getB2bOrdersV2OrdersB2bOrdersGet(status?: string, companies?: string, warehouses?: string, receivers?: string, legalEntities?: string, fromDeliveryDate?: string, toDeliveryDate?: string, withExecutionPercentage?: boolean, options?: any): AxiosPromise<Array<OrderV2ListResponse>> {
            return localVarFp.getB2bOrdersV2OrdersB2bOrdersGet(status, companies, warehouses, receivers, legalEntities, fromDeliveryDate, toDeliveryDate, withExecutionPercentage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Internal Transfers
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [receivers] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getInternalTransfersV2OrdersInternalTransfersGet(status?: string, companies?: string, warehouses?: string, receivers?: string, fromDeliveryDate?: string, toDeliveryDate?: string, options?: any): AxiosPromise<Array<OrderV2ListResponse>> {
            return localVarFp.getInternalTransfersV2OrdersInternalTransfersGet(status, companies, warehouses, receivers, fromDeliveryDate, toDeliveryDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {boolean} [withItems] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        listOrdersV2OrdersGet(status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options?: any): AxiosPromise<Array<OrderV2Response>> {
            return localVarFp.listOrdersV2OrdersGet(status, companies, warehouses, fromDate, toDate, noInvoiceOnly, withExecutionPercentage, withItems, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pick Order Item
         * @param {number} orderItemId 
         * @param {PickOrderItemRequest} pickOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        pickOrderItemV2OrdersOrderItemOrderItemIdPut(orderItemId: number, pickOrderItemRequest: PickOrderItemRequest, options?: any): AxiosPromise<OrderItemV2Response> {
            return localVarFp.pickOrderItemV2OrdersOrderItemOrderItemIdPut(orderItemId, pickOrderItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Act Of Acceptance By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Act Of Acceptance Items
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId: number, options?: any): AxiosPromise<Array<ActOfAcceptanceItemResponse>> {
            return localVarFp.readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId: number, options?: any): AxiosPromise<ActOfAcceptanceResponse> {
            return localVarFp.readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet(orderId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Acts Of Acceptance
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {string} [users] 
         * @param {string} [orderTypes] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, users?: string, orderTypes?: string, options?: any): AxiosPromise<Array<ActOfAcceptanceResponse>> {
            return localVarFp.readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate, toDate, receivers, legalEntities, companies, users, orderTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Assigned Orders For Picker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAssignedOrdersForPickerV2OrdersPickerGet(options?: any): AxiosPromise<Array<OrderV2Response>> {
            return localVarFp.readAssignedOrdersForPickerV2OrdersPickerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoice Document
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet(orderId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order By Order Id
         * @param {number} orderId 
         * @param {boolean} [withLivePrices] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderByOrderIdV2OrdersOrderIdGet(orderId: number, withLivePrices?: boolean, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.readOrderByOrderIdV2OrdersOrderIdGet(orderId, withLivePrices, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId: number, options?: any): AxiosPromise<Array<OrderDocumentV2Response>> {
            return localVarFp.readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId: number, options?: any): AxiosPromise<Array<OrderStatusLogResponse>> {
            return localVarFp.readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Transactions
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId: number, options?: any): AxiosPromise<Array<OrderTransactionResponse>> {
            return localVarFp.readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Orders For Inspection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrdersForInspectionV2OrdersInspectorGet(options?: any): AxiosPromise<Array<OrderV2Response>> {
            return localVarFp.readOrdersForInspectionV2OrdersInspectorGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return Items
         * @param {number} orderId 
         * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnItemsV2OrdersOrderIdReturnItemsPut(orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options?: any): AxiosPromise<ReturnOrderItemsV2Response> {
            return localVarFp.returnItemsV2OrdersOrderIdReturnItemsPut(orderId, returnOrderItemV2Request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Order Status
         * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest: OrderStatusUpdateRequest, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Order
         * @param {number} orderId 
         * @param {UpdateOrderV2Request} updateOrderV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderV2OrdersOrderIdPatch(orderId: number, updateOrderV2Request: UpdateOrderV2Request, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.updateOrderV2OrdersOrderIdPatch(orderId, updateOrderV2Request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MobileApi - object-oriented interface
 * @export
 * @class MobileApi
 * @extends {BaseAPI}
 */
export class MobileApi extends BaseAPI {
    /**
     * 
     * @summary Cancel Invoice Document
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete(orderId: number, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Order
     * @param {CreateOrderV2Request} createOrderV2Request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public createOrderV2OrdersPost(createOrderV2Request: CreateOrderV2Request, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).createOrderV2OrdersPost(createOrderV2Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Print Document Queue
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost(orderId: number, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Picked Order Item
     * @param {number} pickedOrderItemId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete(pickedOrderItemId: number, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete(pickedOrderItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get B2B Orders
     * @param {string} [status] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [receivers] 
     * @param {string} [legalEntities] 
     * @param {string} [fromDeliveryDate] 
     * @param {string} [toDeliveryDate] 
     * @param {boolean} [withExecutionPercentage] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public getB2bOrdersV2OrdersB2bOrdersGet(status?: string, companies?: string, warehouses?: string, receivers?: string, legalEntities?: string, fromDeliveryDate?: string, toDeliveryDate?: string, withExecutionPercentage?: boolean, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).getB2bOrdersV2OrdersB2bOrdersGet(status, companies, warehouses, receivers, legalEntities, fromDeliveryDate, toDeliveryDate, withExecutionPercentage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Internal Transfers
     * @param {string} [status] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [receivers] 
     * @param {string} [fromDeliveryDate] 
     * @param {string} [toDeliveryDate] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public getInternalTransfersV2OrdersInternalTransfersGet(status?: string, companies?: string, warehouses?: string, receivers?: string, fromDeliveryDate?: string, toDeliveryDate?: string, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).getInternalTransfersV2OrdersInternalTransfersGet(status, companies, warehouses, receivers, fromDeliveryDate, toDeliveryDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Orders
     * @param {string} [status] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {boolean} [noInvoiceOnly] 
     * @param {boolean} [withExecutionPercentage] 
     * @param {boolean} [withItems] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public listOrdersV2OrdersGet(status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).listOrdersV2OrdersGet(status, companies, warehouses, fromDate, toDate, noInvoiceOnly, withExecutionPercentage, withItems, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pick Order Item
     * @param {number} orderItemId 
     * @param {PickOrderItemRequest} pickOrderItemRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public pickOrderItemV2OrdersOrderItemOrderItemIdPut(orderItemId: number, pickOrderItemRequest: PickOrderItemRequest, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).pickOrderItemV2OrdersOrderItemOrderItemIdPut(orderItemId, pickOrderItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Act Of Acceptance By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id: number, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Act Of Acceptance Items
     * @param {number} actOfAcceptanceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId: number, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Act Of Acceptance
     * @param {number} actOfAcceptanceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId: number, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Act Of Acceptance
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet(orderId: number, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Acts Of Acceptance
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [receivers] 
     * @param {string} [legalEntities] 
     * @param {string} [companies] 
     * @param {string} [users] 
     * @param {string} [orderTypes] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, users?: string, orderTypes?: string, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate, toDate, receivers, legalEntities, companies, users, orderTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Assigned Orders For Picker
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public readAssignedOrdersForPickerV2OrdersPickerGet(options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).readAssignedOrdersForPickerV2OrdersPickerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Batch
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoice Document
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet(orderId: number, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order By Order Id
     * @param {number} orderId 
     * @param {boolean} [withLivePrices] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public readOrderByOrderIdV2OrdersOrderIdGet(orderId: number, withLivePrices?: boolean, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).readOrderByOrderIdV2OrdersOrderIdGet(orderId, withLivePrices, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Documents
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId: number, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Documents
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId: number, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Transactions
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId: number, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Orders For Inspection
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public readOrdersForInspectionV2OrdersInspectorGet(options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).readOrdersForInspectionV2OrdersInspectorGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return Items
     * @param {number} orderId 
     * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public returnItemsV2OrdersOrderIdReturnItemsPut(orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).returnItemsV2OrdersOrderIdReturnItemsPut(orderId, returnOrderItemV2Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Order Status
     * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest: OrderStatusUpdateRequest, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Order
     * @param {number} orderId 
     * @param {UpdateOrderV2Request} updateOrderV2Request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public updateOrderV2OrdersOrderIdPatch(orderId: number, updateOrderV2Request: UpdateOrderV2Request, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).updateOrderV2OrdersOrderIdPatch(orderId, updateOrderV2Request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel Invoice Document
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/invoice`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderV2Request} createOrderV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderV2OrdersPost: async (createOrderV2Request: CreateOrderV2Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderV2Request' is not null or undefined
            assertParamExists('createOrderV2OrdersPost', 'createOrderV2Request', createOrderV2Request)
            const localVarPath = `/v2/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderV2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Print Document Queue
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/documents/print`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Picked Order Item
         * @param {number} pickedOrderItemId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete: async (pickedOrderItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pickedOrderItemId' is not null or undefined
            assertParamExists('deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete', 'pickedOrderItemId', pickedOrderItemId)
            const localVarPath = `/v2/orders/picked-order-item/{picked_order_item_id}`
                .replace(`{${"picked_order_item_id"}}`, encodeURIComponent(String(pickedOrderItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get B2B Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getB2bOrdersV2OrdersB2bOrdersGet: async (status?: string, companies?: string, warehouses?: string, receivers?: string, legalEntities?: string, fromDeliveryDate?: string, toDeliveryDate?: string, withExecutionPercentage?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orders/b2b-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (receivers !== undefined) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (legalEntities !== undefined) {
                localVarQueryParameter['legal_entities'] = legalEntities;
            }

            if (fromDeliveryDate !== undefined) {
                localVarQueryParameter['from_delivery_date'] = (fromDeliveryDate as any instanceof Date) ?
                    (fromDeliveryDate as any).toISOString() :
                    fromDeliveryDate;
            }

            if (toDeliveryDate !== undefined) {
                localVarQueryParameter['to_delivery_date'] = (toDeliveryDate as any instanceof Date) ?
                    (toDeliveryDate as any).toISOString() :
                    toDeliveryDate;
            }

            if (withExecutionPercentage !== undefined) {
                localVarQueryParameter['with_execution_percentage'] = withExecutionPercentage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Internal Transfers
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [receivers] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getInternalTransfersV2OrdersInternalTransfersGet: async (status?: string, companies?: string, warehouses?: string, receivers?: string, fromDeliveryDate?: string, toDeliveryDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orders/internal-transfers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (receivers !== undefined) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (fromDeliveryDate !== undefined) {
                localVarQueryParameter['from_delivery_date'] = (fromDeliveryDate as any instanceof Date) ?
                    (fromDeliveryDate as any).toISOString() :
                    fromDeliveryDate;
            }

            if (toDeliveryDate !== undefined) {
                localVarQueryParameter['to_delivery_date'] = (toDeliveryDate as any instanceof Date) ?
                    (toDeliveryDate as any).toISOString() :
                    toDeliveryDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {boolean} [withItems] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        listOrdersV2OrdersGet: async (status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (noInvoiceOnly !== undefined) {
                localVarQueryParameter['no_invoice_only'] = noInvoiceOnly;
            }

            if (withExecutionPercentage !== undefined) {
                localVarQueryParameter['with_execution_percentage'] = withExecutionPercentage;
            }

            if (withItems !== undefined) {
                localVarQueryParameter['with_items'] = withItems;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pick Order Item
         * @param {number} orderItemId 
         * @param {PickOrderItemRequest} pickOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        pickOrderItemV2OrdersOrderItemOrderItemIdPut: async (orderItemId: number, pickOrderItemRequest: PickOrderItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderItemId' is not null or undefined
            assertParamExists('pickOrderItemV2OrdersOrderItemOrderItemIdPut', 'orderItemId', orderItemId)
            // verify required parameter 'pickOrderItemRequest' is not null or undefined
            assertParamExists('pickOrderItemV2OrdersOrderItemOrderItemIdPut', 'pickOrderItemRequest', pickOrderItemRequest)
            const localVarPath = `/v2/orders/order-item/{order_item_id}`
                .replace(`{${"order_item_id"}}`, encodeURIComponent(String(orderItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pickOrderItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Act Of Acceptance By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet', 'id', id)
            const localVarPath = `/v2/acts-of-acceptance/{id}/document`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Act Of Acceptance Items
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet: async (actOfAcceptanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actOfAcceptanceId' is not null or undefined
            assertParamExists('readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet', 'actOfAcceptanceId', actOfAcceptanceId)
            const localVarPath = `/v2/acts-of-acceptance/{act_of_acceptance_id}/items`
                .replace(`{${"act_of_acceptance_id"}}`, encodeURIComponent(String(actOfAcceptanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet: async (actOfAcceptanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actOfAcceptanceId' is not null or undefined
            assertParamExists('readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet', 'actOfAcceptanceId', actOfAcceptanceId)
            const localVarPath = `/v2/acts-of-acceptance/{act_of_acceptance_id}`
                .replace(`{${"act_of_acceptance_id"}}`, encodeURIComponent(String(actOfAcceptanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/documents/act-of-acceptance`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Acts Of Acceptance
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {string} [users] 
         * @param {string} [orderTypes] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActsOfAcceptanceV2ActsOfAcceptanceGet: async (fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, users?: string, orderTypes?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/acts-of-acceptance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (receivers !== undefined) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (legalEntities !== undefined) {
                localVarQueryParameter['legal_entities'] = legalEntities;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (users !== undefined) {
                localVarQueryParameter['users'] = users;
            }

            if (orderTypes !== undefined) {
                localVarQueryParameter['order_types'] = orderTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Assigned Orders For Picker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAssignedOrdersForPickerV2OrdersPickerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orders/picker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/documents/delivery-batch`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoice Document
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/documents/invoice`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order By Order Id
         * @param {number} orderId 
         * @param {boolean} [withLivePrices] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderByOrderIdV2OrdersOrderIdGet: async (orderId: number, withLivePrices?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderByOrderIdV2OrdersOrderIdGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (withLivePrices !== undefined) {
                localVarQueryParameter['with_live_prices'] = withLivePrices;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdDocumentsGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderDocumentsV2OrdersOrderIdDocumentsGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/documents`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdStatusLogsGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderDocumentsV2OrdersOrderIdStatusLogsGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/status-logs`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Transactions
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderTransactionsV2OrdersOrderIdTransactionsGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderTransactionsV2OrdersOrderIdTransactionsGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/transactions`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Orders For Inspection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrdersForInspectionV2OrdersInspectorGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orders/inspector`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return Items
         * @param {number} orderId 
         * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnItemsV2OrdersOrderIdReturnItemsPut: async (orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('returnItemsV2OrdersOrderIdReturnItemsPut', 'orderId', orderId)
            // verify required parameter 'returnOrderItemV2Request' is not null or undefined
            assertParamExists('returnItemsV2OrdersOrderIdReturnItemsPut', 'returnOrderItemV2Request', returnOrderItemV2Request)
            const localVarPath = `/v2/orders/{order_id}/return-items`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(returnOrderItemV2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Order Status
         * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderStatusV2OrdersStatusPut: async (orderStatusUpdateRequest: OrderStatusUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderStatusUpdateRequest' is not null or undefined
            assertParamExists('updateOrderStatusV2OrdersStatusPut', 'orderStatusUpdateRequest', orderStatusUpdateRequest)
            const localVarPath = `/v2/orders/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderStatusUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Order
         * @param {number} orderId 
         * @param {UpdateOrderV2Request} updateOrderV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderV2OrdersOrderIdPatch: async (orderId: number, updateOrderV2Request: UpdateOrderV2Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('updateOrderV2OrdersOrderIdPatch', 'orderId', orderId)
            // verify required parameter 'updateOrderV2Request' is not null or undefined
            assertParamExists('updateOrderV2OrdersOrderIdPatch', 'updateOrderV2Request', updateOrderV2Request)
            const localVarPath = `/v2/orders/{order_id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderV2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel Invoice Document
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderV2Request} createOrderV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrderV2OrdersPost(createOrderV2Request: CreateOrderV2Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrderV2OrdersPost(createOrderV2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Print Document Queue
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Picked Order Item
         * @param {number} pickedOrderItemId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete(pickedOrderItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchOrderItemV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete(pickedOrderItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get B2B Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getB2bOrdersV2OrdersB2bOrdersGet(status?: string, companies?: string, warehouses?: string, receivers?: string, legalEntities?: string, fromDeliveryDate?: string, toDeliveryDate?: string, withExecutionPercentage?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderV2ListResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getB2bOrdersV2OrdersB2bOrdersGet(status, companies, warehouses, receivers, legalEntities, fromDeliveryDate, toDeliveryDate, withExecutionPercentage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Internal Transfers
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [receivers] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getInternalTransfersV2OrdersInternalTransfersGet(status?: string, companies?: string, warehouses?: string, receivers?: string, fromDeliveryDate?: string, toDeliveryDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderV2ListResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInternalTransfersV2OrdersInternalTransfersGet(status, companies, warehouses, receivers, fromDeliveryDate, toDeliveryDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {boolean} [withItems] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async listOrdersV2OrdersGet(status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderV2Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOrdersV2OrdersGet(status, companies, warehouses, fromDate, toDate, noInvoiceOnly, withExecutionPercentage, withItems, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pick Order Item
         * @param {number} orderItemId 
         * @param {PickOrderItemRequest} pickOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async pickOrderItemV2OrdersOrderItemOrderItemIdPut(orderItemId: number, pickOrderItemRequest: PickOrderItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pickOrderItemV2OrdersOrderItemOrderItemIdPut(orderItemId, pickOrderItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Act Of Acceptance By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Act Of Acceptance Items
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActOfAcceptanceItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActOfAcceptanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Acts Of Acceptance
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {string} [users] 
         * @param {string} [orderTypes] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, users?: string, orderTypes?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActOfAcceptanceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate, toDate, receivers, legalEntities, companies, users, orderTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Assigned Orders For Picker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAssignedOrdersForPickerV2OrdersPickerGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderV2Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAssignedOrdersForPickerV2OrdersPickerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoice Document
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order By Order Id
         * @param {number} orderId 
         * @param {boolean} [withLivePrices] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderByOrderIdV2OrdersOrderIdGet(orderId: number, withLivePrices?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderByOrderIdV2OrdersOrderIdGet(orderId, withLivePrices, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderDocumentV2Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderStatusLogResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Transactions
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderTransactionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Orders For Inspection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrdersForInspectionV2OrdersInspectorGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderV2Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrdersForInspectionV2OrdersInspectorGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return Items
         * @param {number} orderId 
         * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async returnItemsV2OrdersOrderIdReturnItemsPut(orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnOrderItemsV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.returnItemsV2OrdersOrderIdReturnItemsPut(orderId, returnOrderItemV2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Order Status
         * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest: OrderStatusUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Order
         * @param {number} orderId 
         * @param {UpdateOrderV2Request} updateOrderV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderV2OrdersOrderIdPatch(orderId: number, updateOrderV2Request: UpdateOrderV2Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderV2OrdersOrderIdPatch(orderId, updateOrderV2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel Invoice Document
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete(orderId: number, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderV2Request} createOrderV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderV2OrdersPost(createOrderV2Request: CreateOrderV2Request, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.createOrderV2OrdersPost(createOrderV2Request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Print Document Queue
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost(orderId: number, options?: any): AxiosPromise<void> {
            return localVarFp.createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Picked Order Item
         * @param {number} pickedOrderItemId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete(pickedOrderItemId: number, options?: any): AxiosPromise<BatchOrderItemV2Response> {
            return localVarFp.deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete(pickedOrderItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get B2B Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getB2bOrdersV2OrdersB2bOrdersGet(status?: string, companies?: string, warehouses?: string, receivers?: string, legalEntities?: string, fromDeliveryDate?: string, toDeliveryDate?: string, withExecutionPercentage?: boolean, options?: any): AxiosPromise<Array<OrderV2ListResponse>> {
            return localVarFp.getB2bOrdersV2OrdersB2bOrdersGet(status, companies, warehouses, receivers, legalEntities, fromDeliveryDate, toDeliveryDate, withExecutionPercentage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Internal Transfers
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [receivers] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getInternalTransfersV2OrdersInternalTransfersGet(status?: string, companies?: string, warehouses?: string, receivers?: string, fromDeliveryDate?: string, toDeliveryDate?: string, options?: any): AxiosPromise<Array<OrderV2ListResponse>> {
            return localVarFp.getInternalTransfersV2OrdersInternalTransfersGet(status, companies, warehouses, receivers, fromDeliveryDate, toDeliveryDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {boolean} [withItems] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        listOrdersV2OrdersGet(status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options?: any): AxiosPromise<Array<OrderV2Response>> {
            return localVarFp.listOrdersV2OrdersGet(status, companies, warehouses, fromDate, toDate, noInvoiceOnly, withExecutionPercentage, withItems, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pick Order Item
         * @param {number} orderItemId 
         * @param {PickOrderItemRequest} pickOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        pickOrderItemV2OrdersOrderItemOrderItemIdPut(orderItemId: number, pickOrderItemRequest: PickOrderItemRequest, options?: any): AxiosPromise<OrderItemV2Response> {
            return localVarFp.pickOrderItemV2OrdersOrderItemOrderItemIdPut(orderItemId, pickOrderItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Act Of Acceptance By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Act Of Acceptance Items
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId: number, options?: any): AxiosPromise<Array<ActOfAcceptanceItemResponse>> {
            return localVarFp.readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId: number, options?: any): AxiosPromise<ActOfAcceptanceResponse> {
            return localVarFp.readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet(orderId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Acts Of Acceptance
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {string} [users] 
         * @param {string} [orderTypes] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, users?: string, orderTypes?: string, options?: any): AxiosPromise<Array<ActOfAcceptanceResponse>> {
            return localVarFp.readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate, toDate, receivers, legalEntities, companies, users, orderTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Assigned Orders For Picker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAssignedOrdersForPickerV2OrdersPickerGet(options?: any): AxiosPromise<Array<OrderV2Response>> {
            return localVarFp.readAssignedOrdersForPickerV2OrdersPickerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoice Document
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet(orderId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order By Order Id
         * @param {number} orderId 
         * @param {boolean} [withLivePrices] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderByOrderIdV2OrdersOrderIdGet(orderId: number, withLivePrices?: boolean, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.readOrderByOrderIdV2OrdersOrderIdGet(orderId, withLivePrices, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId: number, options?: any): AxiosPromise<Array<OrderDocumentV2Response>> {
            return localVarFp.readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId: number, options?: any): AxiosPromise<Array<OrderStatusLogResponse>> {
            return localVarFp.readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Transactions
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId: number, options?: any): AxiosPromise<Array<OrderTransactionResponse>> {
            return localVarFp.readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Orders For Inspection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrdersForInspectionV2OrdersInspectorGet(options?: any): AxiosPromise<Array<OrderV2Response>> {
            return localVarFp.readOrdersForInspectionV2OrdersInspectorGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return Items
         * @param {number} orderId 
         * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnItemsV2OrdersOrderIdReturnItemsPut(orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options?: any): AxiosPromise<ReturnOrderItemsV2Response> {
            return localVarFp.returnItemsV2OrdersOrderIdReturnItemsPut(orderId, returnOrderItemV2Request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Order Status
         * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest: OrderStatusUpdateRequest, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Order
         * @param {number} orderId 
         * @param {UpdateOrderV2Request} updateOrderV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderV2OrdersOrderIdPatch(orderId: number, updateOrderV2Request: UpdateOrderV2Request, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.updateOrderV2OrdersOrderIdPatch(orderId, updateOrderV2Request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
    /**
     * 
     * @summary Cancel Invoice Document
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete(orderId: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).cancelInvoiceDocumentV2OrdersOrderIdInvoiceDelete(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Order
     * @param {CreateOrderV2Request} createOrderV2Request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public createOrderV2OrdersPost(createOrderV2Request: CreateOrderV2Request, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).createOrderV2OrdersPost(createOrderV2Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Print Document Queue
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost(orderId: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).createPrintDocumentQueueV2OrdersOrderIdDocumentsPrintPost(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Picked Order Item
     * @param {number} pickedOrderItemId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete(pickedOrderItemId: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).deletePickedOrderItemV2OrdersPickedOrderItemPickedOrderItemIdDelete(pickedOrderItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get B2B Orders
     * @param {string} [status] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [receivers] 
     * @param {string} [legalEntities] 
     * @param {string} [fromDeliveryDate] 
     * @param {string} [toDeliveryDate] 
     * @param {boolean} [withExecutionPercentage] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getB2bOrdersV2OrdersB2bOrdersGet(status?: string, companies?: string, warehouses?: string, receivers?: string, legalEntities?: string, fromDeliveryDate?: string, toDeliveryDate?: string, withExecutionPercentage?: boolean, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getB2bOrdersV2OrdersB2bOrdersGet(status, companies, warehouses, receivers, legalEntities, fromDeliveryDate, toDeliveryDate, withExecutionPercentage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Internal Transfers
     * @param {string} [status] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [receivers] 
     * @param {string} [fromDeliveryDate] 
     * @param {string} [toDeliveryDate] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getInternalTransfersV2OrdersInternalTransfersGet(status?: string, companies?: string, warehouses?: string, receivers?: string, fromDeliveryDate?: string, toDeliveryDate?: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getInternalTransfersV2OrdersInternalTransfersGet(status, companies, warehouses, receivers, fromDeliveryDate, toDeliveryDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Orders
     * @param {string} [status] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {boolean} [noInvoiceOnly] 
     * @param {boolean} [withExecutionPercentage] 
     * @param {boolean} [withItems] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public listOrdersV2OrdersGet(status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).listOrdersV2OrdersGet(status, companies, warehouses, fromDate, toDate, noInvoiceOnly, withExecutionPercentage, withItems, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pick Order Item
     * @param {number} orderItemId 
     * @param {PickOrderItemRequest} pickOrderItemRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public pickOrderItemV2OrdersOrderItemOrderItemIdPut(orderItemId: number, pickOrderItemRequest: PickOrderItemRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).pickOrderItemV2OrdersOrderItemOrderItemIdPut(orderItemId, pickOrderItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Act Of Acceptance By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readActOfAcceptanceByIdV2ActsOfAcceptanceIdDocumentGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Act Of Acceptance Items
     * @param {number} actOfAcceptanceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readActOfAcceptanceItemsV2ActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Act Of Acceptance
     * @param {number} actOfAcceptanceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readActOfAcceptanceV2ActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Act Of Acceptance
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet(orderId: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readActOfAcceptanceV2OrdersOrderIdDocumentsActOfAcceptanceGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Acts Of Acceptance
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [receivers] 
     * @param {string} [legalEntities] 
     * @param {string} [companies] 
     * @param {string} [users] 
     * @param {string} [orderTypes] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, users?: string, orderTypes?: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readActsOfAcceptanceV2ActsOfAcceptanceGet(fromDate, toDate, receivers, legalEntities, companies, users, orderTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Assigned Orders For Picker
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readAssignedOrdersForPickerV2OrdersPickerGet(options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readAssignedOrdersForPickerV2OrdersPickerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Batch
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoice Document
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet(orderId: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readInvoiceDocumentV2OrdersOrderIdDocumentsInvoiceGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order By Order Id
     * @param {number} orderId 
     * @param {boolean} [withLivePrices] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readOrderByOrderIdV2OrdersOrderIdGet(orderId: number, withLivePrices?: boolean, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readOrderByOrderIdV2OrdersOrderIdGet(orderId, withLivePrices, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Documents
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Documents
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Transactions
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Orders For Inspection
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readOrdersForInspectionV2OrdersInspectorGet(options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readOrdersForInspectionV2OrdersInspectorGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return Items
     * @param {number} orderId 
     * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public returnItemsV2OrdersOrderIdReturnItemsPut(orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).returnItemsV2OrdersOrderIdReturnItemsPut(orderId, returnOrderItemV2Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Order Status
     * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest: OrderStatusUpdateRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Order
     * @param {number} orderId 
     * @param {UpdateOrderV2Request} updateOrderV2Request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public updateOrderV2OrdersOrderIdPatch(orderId: number, updateOrderV2Request: UpdateOrderV2Request, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).updateOrderV2OrdersOrderIdPatch(orderId, updateOrderV2Request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnersApi - axios parameter creator
 * @export
 */
export const PartnersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create
         * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1CompanyCategoriesPost: async (createCompanyCategoryRequest: CreateCompanyCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyCategoryRequest' is not null or undefined
            assertParamExists('createV1CompanyCategoriesPost', 'createCompanyCategoryRequest', createCompanyCategoryRequest)
            const localVarPath = `/v1/company-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1CompanyCategoriesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteV1CompanyCategoriesIdDelete', 'id', id)
            const localVarPath = `/v1/company-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Orders For Collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet', 'id', id)
            const localVarPath = `/v2/legal-entities/{id}/orders-for-collection/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Orders For Distribution Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet', 'id', id)
            const localVarPath = `/v2/legal-entities/{id}/orders-for-distribution/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Overdue Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet', 'id', id)
            const localVarPath = `/v2/legal-entities/{id}/overdue-payments/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Pending Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet', 'id', id)
            const localVarPath = `/v2/legal-entities/{id}/pending-payments/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Load Info
         * @param {string} vatNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadInfoV1LegalEntitiesLoadInfoPost: async (vatNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vatNumber' is not null or undefined
            assertParamExists('loadInfoV1LegalEntitiesLoadInfoPost', 'vatNumber', vatNumber)
            const localVarPath = `/v1/legal-entities/load-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (vatNumber !== undefined) {
                localVarQueryParameter['vat_number'] = vatNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllV1CompanyCategoriesGet: async (skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/company-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet', 'id', id)
            const localVarPath = `/v2/legal-entities/{id}/ordering-policy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readV1CompanyCategoriesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readV1CompanyCategoriesIdGet', 'id', id)
            const localVarPath = `/v1/company-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readV1PartnersPartnerIdItemCategoryAssociationGet: async (partnerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('readV1PartnersPartnerIdItemCategoryAssociationGet', 'partnerId', partnerId)
            const localVarPath = `/v1/partners/{partner_id}/item-category-association`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut: async (id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut', 'id', id)
            // verify required parameter 'setOrderingPolicyRequest' is not null or undefined
            assertParamExists('setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut', 'setOrderingPolicyRequest', setOrderingPolicyRequest)
            const localVarPath = `/v2/legal-entities/{id}/ordering-policy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setOrderingPolicyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test Barsy Connection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testBarsyConnectionV1PartnersIdTestBarsyConnectionPost: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('testBarsyConnectionV1PartnersIdTestBarsyConnectionPost', 'id', id)
            const localVarPath = `/v1/partners/{id}/test-barsy-connection`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateV1CompanyCategoriesPut: async (updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCompanyCategoryRequest' is not null or undefined
            assertParamExists('updateV1CompanyCategoriesPut', 'updateCompanyCategoryRequest', updateCompanyCategoryRequest)
            const localVarPath = `/v1/company-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {number} partnerId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateV1PartnersPartnerIdItemCategoryAssociationPost: async (partnerId: number, requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('updateV1PartnersPartnerIdItemCategoryAssociationPost', 'partnerId', partnerId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateV1PartnersPartnerIdItemCategoryAssociationPost', 'requestBody', requestBody)
            const localVarPath = `/v1/partners/{partner_id}/item-category-association`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnersApi - functional programming interface
 * @export
 */
export const PartnersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createV1CompanyCategoriesPost(createCompanyCategoryRequest: CreateCompanyCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1CompanyCategoriesPost(createCompanyCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1CompanyCategoriesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1CompanyCategoriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Orders For Collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntityOrdersForCollectionAmountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Orders For Distribution Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntityOrdersForDistributionAmountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Overdue Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntityOverduePaymentsAmountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Pending Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntityPendingPaymentsAmountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Load Info
         * @param {string} vatNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadInfoV1LegalEntitiesLoadInfoPost(vatNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadInfoV1LegalEntitiesLoadInfoPost(vatNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAllV1CompanyCategoriesGet(skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllV1CompanyCategoriesGet(skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readV1CompanyCategoriesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readV1CompanyCategoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id, setOrderingPolicyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Test Barsy Connection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testBarsyConnectionV1PartnersIdTestBarsyConnectionPost(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestConnectionDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testBarsyConnectionV1PartnersIdTestBarsyConnectionPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateV1CompanyCategoriesPut(updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateV1CompanyCategoriesPut(updateCompanyCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {number} partnerId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId: number, requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnersApi - factory interface
 * @export
 */
export const PartnersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1CompanyCategoriesPost(createCompanyCategoryRequest: CreateCompanyCategoryRequest, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.createV1CompanyCategoriesPost(createCompanyCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1CompanyCategoriesIdDelete(id: number, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.deleteV1CompanyCategoriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Orders For Collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id: number, options?: any): AxiosPromise<LegalEntityOrdersForCollectionAmountResponse> {
            return localVarFp.getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Orders For Distribution Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id: number, options?: any): AxiosPromise<LegalEntityOrdersForDistributionAmountResponse> {
            return localVarFp.getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Overdue Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id: number, options?: any): AxiosPromise<LegalEntityOverduePaymentsAmountResponse> {
            return localVarFp.getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Pending Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id: number, options?: any): AxiosPromise<LegalEntityPendingPaymentsAmountResponse> {
            return localVarFp.getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Load Info
         * @param {string} vatNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadInfoV1LegalEntitiesLoadInfoPost(vatNumber: string, options?: any): AxiosPromise<CompanyInfo> {
            return localVarFp.loadInfoV1LegalEntitiesLoadInfoPost(vatNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllV1CompanyCategoriesGet(skip?: number, limit?: number, options?: any): AxiosPromise<Array<CompanyCategoryResponse>> {
            return localVarFp.readAllV1CompanyCategoriesGet(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id: number, options?: any): AxiosPromise<OrderingPolicyResponse> {
            return localVarFp.readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readV1CompanyCategoriesIdGet(id: number, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.readV1CompanyCategoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId: number, options?: any): AxiosPromise<Array<PartnerItemCategoryResponse>> {
            return localVarFp.readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: any): AxiosPromise<OrderingPolicyResponse> {
            return localVarFp.setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id, setOrderingPolicyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Test Barsy Connection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testBarsyConnectionV1PartnersIdTestBarsyConnectionPost(id: number, options?: any): AxiosPromise<TestConnectionDetailsResponse> {
            return localVarFp.testBarsyConnectionV1PartnersIdTestBarsyConnectionPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateV1CompanyCategoriesPut(updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.updateV1CompanyCategoriesPut(updateCompanyCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {number} partnerId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId: number, requestBody: Array<number>, options?: any): AxiosPromise<Array<PartnerItemCategoryResponse>> {
            return localVarFp.updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnersApi - object-oriented interface
 * @export
 * @class PartnersApi
 * @extends {BaseAPI}
 */
export class PartnersApi extends BaseAPI {
    /**
     * 
     * @summary Create
     * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public createV1CompanyCategoriesPost(createCompanyCategoryRequest: CreateCompanyCategoryRequest, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).createV1CompanyCategoriesPost(createCompanyCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public deleteV1CompanyCategoriesIdDelete(id: number, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).deleteV1CompanyCategoriesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Orders For Collection
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id: number, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Orders For Distribution Amount
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id: number, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Overdue Payments Amount
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id: number, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Pending Payments Amount
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id: number, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Load Info
     * @param {string} vatNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public loadInfoV1LegalEntitiesLoadInfoPost(vatNumber: string, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).loadInfoV1LegalEntitiesLoadInfoPost(vatNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public readAllV1CompanyCategoriesGet(skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).readAllV1CompanyCategoriesGet(skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Ordering Policy
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id: number, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public readV1CompanyCategoriesIdGet(id: number, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).readV1CompanyCategoriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} partnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId: number, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Ordering Policy
     * @param {number} id 
     * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id, setOrderingPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Test Barsy Connection
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public testBarsyConnectionV1PartnersIdTestBarsyConnectionPost(id: number, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).testBarsyConnectionV1PartnersIdTestBarsyConnectionPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public updateV1CompanyCategoriesPut(updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).updateV1CompanyCategoriesPut(updateCompanyCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {number} partnerId 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId: number, requestBody: Array<number>, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PricingPoliciesApi - axios parameter creator
 * @export
 */
export const PricingPoliciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create
         * @param {CreatePricingPolicyRequest} createPricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1PricingPoliciesPost: async (createPricingPolicyRequest: CreatePricingPolicyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPricingPolicyRequest' is not null or undefined
            assertParamExists('createV1PricingPoliciesPost', 'createPricingPolicyRequest', createPricingPolicyRequest)
            const localVarPath = `/v1/pricing-policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPricingPolicyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1PricingPoliciesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteV1PricingPoliciesIdDelete', 'id', id)
            const localVarPath = `/v1/pricing-policies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListV1PricingPoliciesGet: async (search?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/pricing-policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Categories
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet', 'id', id)
            const localVarPath = `/v1/pricing-policies/{id}/item-categories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Exceptions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet', 'id', id)
            const localVarPath = `/v1/pricing-policies/{id}/item-exceptions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readV1PricingPoliciesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readV1PricingPoliciesIdGet', 'id', id)
            const localVarPath = `/v1/pricing-policies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {UpdatePricingPolicyRequest} updatePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateV1PricingPoliciesPut: async (updatePricingPolicyRequest: UpdatePricingPolicyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePricingPolicyRequest' is not null or undefined
            assertParamExists('updateV1PricingPoliciesPut', 'updatePricingPolicyRequest', updatePricingPolicyRequest)
            const localVarPath = `/v1/pricing-policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePricingPolicyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PricingPoliciesApi - functional programming interface
 * @export
 */
export const PricingPoliciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PricingPoliciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {CreatePricingPolicyRequest} createPricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createV1PricingPoliciesPost(createPricingPolicyRequest: CreatePricingPolicyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1PricingPoliciesPost(createPricingPolicyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1PricingPoliciesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1PricingPoliciesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readListV1PricingPoliciesGet(search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PricingPolicyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readListV1PricingPoliciesGet(search, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Categories
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PricingPolicyItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Exceptions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PricingPolicyItemExceptionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readV1PricingPoliciesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readV1PricingPoliciesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {UpdatePricingPolicyRequest} updatePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateV1PricingPoliciesPut(updatePricingPolicyRequest: UpdatePricingPolicyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateV1PricingPoliciesPut(updatePricingPolicyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PricingPoliciesApi - factory interface
 * @export
 */
export const PricingPoliciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PricingPoliciesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {CreatePricingPolicyRequest} createPricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1PricingPoliciesPost(createPricingPolicyRequest: CreatePricingPolicyRequest, options?: any): AxiosPromise<PricingPolicyResponse> {
            return localVarFp.createV1PricingPoliciesPost(createPricingPolicyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1PricingPoliciesIdDelete(id: number, options?: any): AxiosPromise<PricingPolicyResponse> {
            return localVarFp.deleteV1PricingPoliciesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListV1PricingPoliciesGet(search?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<PricingPolicyResponse>> {
            return localVarFp.readListV1PricingPoliciesGet(search, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Categories
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id: number, options?: any): AxiosPromise<Array<PricingPolicyItemCategoryResponse>> {
            return localVarFp.readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Exceptions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id: number, options?: any): AxiosPromise<Array<PricingPolicyItemExceptionResponse>> {
            return localVarFp.readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readV1PricingPoliciesIdGet(id: number, options?: any): AxiosPromise<PricingPolicyResponse> {
            return localVarFp.readV1PricingPoliciesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {UpdatePricingPolicyRequest} updatePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateV1PricingPoliciesPut(updatePricingPolicyRequest: UpdatePricingPolicyRequest, options?: any): AxiosPromise<PricingPolicyResponse> {
            return localVarFp.updateV1PricingPoliciesPut(updatePricingPolicyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PricingPoliciesApi - object-oriented interface
 * @export
 * @class PricingPoliciesApi
 * @extends {BaseAPI}
 */
export class PricingPoliciesApi extends BaseAPI {
    /**
     * 
     * @summary Create
     * @param {CreatePricingPolicyRequest} createPricingPolicyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingPoliciesApi
     */
    public createV1PricingPoliciesPost(createPricingPolicyRequest: CreatePricingPolicyRequest, options?: AxiosRequestConfig) {
        return PricingPoliciesApiFp(this.configuration).createV1PricingPoliciesPost(createPricingPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingPoliciesApi
     */
    public deleteV1PricingPoliciesIdDelete(id: number, options?: AxiosRequestConfig) {
        return PricingPoliciesApiFp(this.configuration).deleteV1PricingPoliciesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read List
     * @param {string} [search] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingPoliciesApi
     */
    public readListV1PricingPoliciesGet(search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return PricingPoliciesApiFp(this.configuration).readListV1PricingPoliciesGet(search, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Categories
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingPoliciesApi
     */
    public readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id: number, options?: AxiosRequestConfig) {
        return PricingPoliciesApiFp(this.configuration).readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Exceptions
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingPoliciesApi
     */
    public readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id: number, options?: AxiosRequestConfig) {
        return PricingPoliciesApiFp(this.configuration).readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingPoliciesApi
     */
    public readV1PricingPoliciesIdGet(id: number, options?: AxiosRequestConfig) {
        return PricingPoliciesApiFp(this.configuration).readV1PricingPoliciesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {UpdatePricingPolicyRequest} updatePricingPolicyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingPoliciesApi
     */
    public updateV1PricingPoliciesPut(updatePricingPolicyRequest: UpdatePricingPolicyRequest, options?: AxiosRequestConfig) {
        return PricingPoliciesApiFp(this.configuration).updateV1PricingPoliciesPut(updatePricingPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PrintingApi - axios parameter creator
 * @export
 */
export const PrintingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Read Print Document Queue
         * @param {string} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPrintDocumentQueueV2PrintDocumentQueueGet: async (state?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/print-document-queue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Print Document Queue
         * @param {number} id 
         * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrintDocumentQueueV2PrintDocumentQueueIdPatch: async (id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePrintDocumentQueueV2PrintDocumentQueueIdPatch', 'id', id)
            // verify required parameter 'updatePrintDocumentQueueRequest' is not null or undefined
            assertParamExists('updatePrintDocumentQueueV2PrintDocumentQueueIdPatch', 'updatePrintDocumentQueueRequest', updatePrintDocumentQueueRequest)
            const localVarPath = `/v2/print-document-queue/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePrintDocumentQueueRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrintingApi - functional programming interface
 * @export
 */
export const PrintingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrintingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Read Print Document Queue
         * @param {string} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPrintDocumentQueueV2PrintDocumentQueueGet(state?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrintDocumentQueueResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPrintDocumentQueueV2PrintDocumentQueueGet(state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Print Document Queue
         * @param {number} id 
         * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrintDocumentQueueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id, updatePrintDocumentQueueRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrintingApi - factory interface
 * @export
 */
export const PrintingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrintingApiFp(configuration)
    return {
        /**
         * 
         * @summary Read Print Document Queue
         * @param {string} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPrintDocumentQueueV2PrintDocumentQueueGet(state?: string, options?: any): AxiosPromise<Array<PrintDocumentQueueResponse>> {
            return localVarFp.readPrintDocumentQueueV2PrintDocumentQueueGet(state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Print Document Queue
         * @param {number} id 
         * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options?: any): AxiosPromise<PrintDocumentQueueResponse> {
            return localVarFp.updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id, updatePrintDocumentQueueRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PrintingApi - object-oriented interface
 * @export
 * @class PrintingApi
 * @extends {BaseAPI}
 */
export class PrintingApi extends BaseAPI {
    /**
     * 
     * @summary Read Print Document Queue
     * @param {string} [state] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintingApi
     */
    public readPrintDocumentQueueV2PrintDocumentQueueGet(state?: string, options?: AxiosRequestConfig) {
        return PrintingApiFp(this.configuration).readPrintDocumentQueueV2PrintDocumentQueueGet(state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Print Document Queue
     * @param {number} id 
     * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintingApi
     */
    public updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options?: AxiosRequestConfig) {
        return PrintingApiFp(this.configuration).updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id, updatePrintDocumentQueueRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PromotionsApi - axios parameter creator
 * @export
 */
export const PromotionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Bulk Create Partner Bonus Quantity Promotions
         * @param {BulkCreatePartnerBonusQuantityRequest} bulkCreatePartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCreatePartnerBonusQuantityPromotionsV2PromotionsBonusQuantityPost: async (bulkCreatePartnerBonusQuantityRequest: BulkCreatePartnerBonusQuantityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkCreatePartnerBonusQuantityRequest' is not null or undefined
            assertParamExists('bulkCreatePartnerBonusQuantityPromotionsV2PromotionsBonusQuantityPost', 'bulkCreatePartnerBonusQuantityRequest', bulkCreatePartnerBonusQuantityRequest)
            const localVarPath = `/v2/promotions/bonus-quantity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkCreatePartnerBonusQuantityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Partner Bonus Quantity
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerBonusQuantityV2PromotionsBonusQuantityIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePartnerBonusQuantityV2PromotionsBonusQuantityIdDelete', 'id', id)
            const localVarPath = `/v2/promotions/bonus-quantity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Partner Bonus Quantity
         * @param {number} id 
         * @param {UpdatePartnerBonusQuantityRequest} updatePartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPartnerBonusQuantityV2PromotionsBonusQuantityIdPatch: async (id: number, updatePartnerBonusQuantityRequest: UpdatePartnerBonusQuantityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchPartnerBonusQuantityV2PromotionsBonusQuantityIdPatch', 'id', id)
            // verify required parameter 'updatePartnerBonusQuantityRequest' is not null or undefined
            assertParamExists('patchPartnerBonusQuantityV2PromotionsBonusQuantityIdPatch', 'updatePartnerBonusQuantityRequest', updatePartnerBonusQuantityRequest)
            const localVarPath = `/v2/promotions/bonus-quantity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePartnerBonusQuantityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Partner Bonus Quantities
         * @param {number} [itemId] 
         * @param {string} [partnerIds] 
         * @param {string} [fromStartDate] 
         * @param {string} [toStartDate] 
         * @param {string} [fromEndDate] 
         * @param {string} [toEndDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnerBonusQuantitiesV2PromotionsBonusQuantityGet: async (itemId?: number, partnerIds?: string, fromStartDate?: string, toStartDate?: string, fromEndDate?: string, toEndDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/promotions/bonus-quantity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (partnerIds !== undefined) {
                localVarQueryParameter['partner_ids'] = partnerIds;
            }

            if (fromStartDate !== undefined) {
                localVarQueryParameter['from_start_date'] = (fromStartDate as any instanceof Date) ?
                    (fromStartDate as any).toISOString().substr(0,10) :
                    fromStartDate;
            }

            if (toStartDate !== undefined) {
                localVarQueryParameter['to_start_date'] = (toStartDate as any instanceof Date) ?
                    (toStartDate as any).toISOString().substr(0,10) :
                    toStartDate;
            }

            if (fromEndDate !== undefined) {
                localVarQueryParameter['from_end_date'] = (fromEndDate as any instanceof Date) ?
                    (fromEndDate as any).toISOString().substr(0,10) :
                    fromEndDate;
            }

            if (toEndDate !== undefined) {
                localVarQueryParameter['to_end_date'] = (toEndDate as any instanceof Date) ?
                    (toEndDate as any).toISOString().substr(0,10) :
                    toEndDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotionsApi - functional programming interface
 * @export
 */
export const PromotionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromotionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Bulk Create Partner Bonus Quantity Promotions
         * @param {BulkCreatePartnerBonusQuantityRequest} bulkCreatePartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkCreatePartnerBonusQuantityPromotionsV2PromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest: BulkCreatePartnerBonusQuantityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerBonusQuantityResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCreatePartnerBonusQuantityPromotionsV2PromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Partner Bonus Quantity
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePartnerBonusQuantityV2PromotionsBonusQuantityIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerBonusQuantityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePartnerBonusQuantityV2PromotionsBonusQuantityIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Partner Bonus Quantity
         * @param {number} id 
         * @param {UpdatePartnerBonusQuantityRequest} updatePartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchPartnerBonusQuantityV2PromotionsBonusQuantityIdPatch(id: number, updatePartnerBonusQuantityRequest: UpdatePartnerBonusQuantityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerBonusQuantityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchPartnerBonusQuantityV2PromotionsBonusQuantityIdPatch(id, updatePartnerBonusQuantityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Partner Bonus Quantities
         * @param {number} [itemId] 
         * @param {string} [partnerIds] 
         * @param {string} [fromStartDate] 
         * @param {string} [toStartDate] 
         * @param {string} [fromEndDate] 
         * @param {string} [toEndDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPartnerBonusQuantitiesV2PromotionsBonusQuantityGet(itemId?: number, partnerIds?: string, fromStartDate?: string, toStartDate?: string, fromEndDate?: string, toEndDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerBonusQuantityResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPartnerBonusQuantitiesV2PromotionsBonusQuantityGet(itemId, partnerIds, fromStartDate, toStartDate, fromEndDate, toEndDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromotionsApi - factory interface
 * @export
 */
export const PromotionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromotionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Bulk Create Partner Bonus Quantity Promotions
         * @param {BulkCreatePartnerBonusQuantityRequest} bulkCreatePartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCreatePartnerBonusQuantityPromotionsV2PromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest: BulkCreatePartnerBonusQuantityRequest, options?: any): AxiosPromise<Array<PartnerBonusQuantityResponse>> {
            return localVarFp.bulkCreatePartnerBonusQuantityPromotionsV2PromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Partner Bonus Quantity
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerBonusQuantityV2PromotionsBonusQuantityIdDelete(id: number, options?: any): AxiosPromise<PartnerBonusQuantityResponse> {
            return localVarFp.deletePartnerBonusQuantityV2PromotionsBonusQuantityIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Partner Bonus Quantity
         * @param {number} id 
         * @param {UpdatePartnerBonusQuantityRequest} updatePartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPartnerBonusQuantityV2PromotionsBonusQuantityIdPatch(id: number, updatePartnerBonusQuantityRequest: UpdatePartnerBonusQuantityRequest, options?: any): AxiosPromise<PartnerBonusQuantityResponse> {
            return localVarFp.patchPartnerBonusQuantityV2PromotionsBonusQuantityIdPatch(id, updatePartnerBonusQuantityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Partner Bonus Quantities
         * @param {number} [itemId] 
         * @param {string} [partnerIds] 
         * @param {string} [fromStartDate] 
         * @param {string} [toStartDate] 
         * @param {string} [fromEndDate] 
         * @param {string} [toEndDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnerBonusQuantitiesV2PromotionsBonusQuantityGet(itemId?: number, partnerIds?: string, fromStartDate?: string, toStartDate?: string, fromEndDate?: string, toEndDate?: string, options?: any): AxiosPromise<Array<PartnerBonusQuantityResponse>> {
            return localVarFp.readPartnerBonusQuantitiesV2PromotionsBonusQuantityGet(itemId, partnerIds, fromStartDate, toStartDate, fromEndDate, toEndDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromotionsApi - object-oriented interface
 * @export
 * @class PromotionsApi
 * @extends {BaseAPI}
 */
export class PromotionsApi extends BaseAPI {
    /**
     * 
     * @summary Bulk Create Partner Bonus Quantity Promotions
     * @param {BulkCreatePartnerBonusQuantityRequest} bulkCreatePartnerBonusQuantityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public bulkCreatePartnerBonusQuantityPromotionsV2PromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest: BulkCreatePartnerBonusQuantityRequest, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).bulkCreatePartnerBonusQuantityPromotionsV2PromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Partner Bonus Quantity
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public deletePartnerBonusQuantityV2PromotionsBonusQuantityIdDelete(id: number, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).deletePartnerBonusQuantityV2PromotionsBonusQuantityIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Partner Bonus Quantity
     * @param {number} id 
     * @param {UpdatePartnerBonusQuantityRequest} updatePartnerBonusQuantityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public patchPartnerBonusQuantityV2PromotionsBonusQuantityIdPatch(id: number, updatePartnerBonusQuantityRequest: UpdatePartnerBonusQuantityRequest, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).patchPartnerBonusQuantityV2PromotionsBonusQuantityIdPatch(id, updatePartnerBonusQuantityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Partner Bonus Quantities
     * @param {number} [itemId] 
     * @param {string} [partnerIds] 
     * @param {string} [fromStartDate] 
     * @param {string} [toStartDate] 
     * @param {string} [fromEndDate] 
     * @param {string} [toEndDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public readPartnerBonusQuantitiesV2PromotionsBonusQuantityGet(itemId?: number, partnerIds?: string, fromStartDate?: string, toStartDate?: string, fromEndDate?: string, toEndDate?: string, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).readPartnerBonusQuantitiesV2PromotionsBonusQuantityGet(itemId, partnerIds, fromStartDate, toStartDate, fromEndDate, toEndDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Aggregated Item Movements
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [partners] 
         * @param {Array<number>} [warehouses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatedItemMovementsV2ReportsItemMovementsBalanceGet: async (fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/reports/item-movements-balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (partners) {
                localVarQueryParameter['partners'] = partners;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {boolean} [hidePaid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryInvoicesV1ReportsDeliveryInvoiceGet: async (fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/delivery-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (suppliers !== undefined) {
                localVarQueryParameter['suppliers'] = suppliers;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (hidePaid !== undefined) {
                localVarQueryParameter['hide_paid'] = hidePaid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [supplierId] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryItemsV1ReportsDeliveryItemsGet: async (fromDate?: string, toDate?: string, supplierId?: number, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/delivery-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (supplierId !== undefined) {
                localVarQueryParameter['supplier_id'] = supplierId;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (itemCategories !== undefined) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Depleted Amounts With Prices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receviers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet: async (fromDate?: string, toDate?: string, receviers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/depleted-amounts-with-prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (receviers !== undefined) {
                localVarQueryParameter['receviers'] = receviers;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (itemCategories !== undefined) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Items
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderItemsV1ReportsOrderItemsGet: async (fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/order-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (receivers !== undefined) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (legalEntities !== undefined) {
                localVarQueryParameter['legal_entities'] = legalEntities;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (itemCategories !== undefined) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Orders Balance
         * @param {string} [statusKeys] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrdersBalanceV1ReportsOrdersBalanceGet: async (statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/orders-balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (statusKeys !== undefined) {
                localVarQueryParameter['status_keys'] = statusKeys;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (noInvoiceOnly !== undefined) {
                localVarQueryParameter['no_invoice_only'] = noInvoiceOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Category Multipliers
         * @param {string} itemCategories 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet: async (itemCategories: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemCategories' is not null or undefined
            assertParamExists('readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet', 'itemCategories', itemCategories)
            const localVarPath = `/v1/reports/pricing-policy-item-category-multipliers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (itemCategories !== undefined) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Prices
         * @param {string} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet: async (items: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'items' is not null or undefined
            assertParamExists('readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet', 'items', items)
            const localVarPath = `/v1/reports/pricing-policy-item-prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Quantities To Fill Up Warehouse Reserve
         * @param {number} warehouseId 
         * @param {number} [baseDays] 
         * @param {number} [reserveDays] 
         * @param {number} [supplierId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet: async (warehouseId: number, baseDays?: number, reserveDays?: number, supplierId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet', 'warehouseId', warehouseId)
            const localVarPath = `/v1/reports/warehouse-reserve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouse_id'] = warehouseId;
            }

            if (baseDays !== undefined) {
                localVarQueryParameter['base_days'] = baseDays;
            }

            if (reserveDays !== undefined) {
                localVarQueryParameter['reserve_days'] = reserveDays;
            }

            if (supplierId !== undefined) {
                localVarQueryParameter['supplier_id'] = supplierId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Warehouse Stock By Orders
         * @param {number} warehouseId 
         * @param {string} toDate 
         * @param {boolean} [hideOrdersWithoutDeliveryDate] 
         * @param {boolean} [hideAvailable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehouseStockByOrdersV1ReportsStockByOrdersGet: async (warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('readWarehouseStockByOrdersV1ReportsStockByOrdersGet', 'warehouseId', warehouseId)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('readWarehouseStockByOrdersV1ReportsStockByOrdersGet', 'toDate', toDate)
            const localVarPath = `/v1/reports/stock-by-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouse_id'] = warehouseId;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (hideOrdersWithoutDeliveryDate !== undefined) {
                localVarQueryParameter['hide_orders_without_delivery_date'] = hideOrdersWithoutDeliveryDate;
            }

            if (hideAvailable !== undefined) {
                localVarQueryParameter['hide_available'] = hideAvailable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Warehouse Stock
         * @param {number} [companyId] 
         * @param {number} [warehouseId] 
         * @param {number} [itemId] 
         * @param {number} [manufacturerId] 
         * @param {string} [suppliers] 
         * @param {string} [itemCategories] 
         * @param {boolean} [hideDepleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehouseStockV1ReportsStockGet: async (companyId?: number, warehouseId?: number, itemId?: number, manufacturerId?: number, suppliers?: string, itemCategories?: string, hideDepleted?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/stock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['company_id'] = companyId;
            }

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouse_id'] = warehouseId;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturer_id'] = manufacturerId;
            }

            if (suppliers !== undefined) {
                localVarQueryParameter['suppliers'] = suppliers;
            }

            if (itemCategories !== undefined) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }

            if (hideDepleted !== undefined) {
                localVarQueryParameter['hide_depleted'] = hideDepleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Aggregated Item Movements
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [partners] 
         * @param {Array<number>} [warehouses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ItemMovementBalance>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate, toDate, partners, warehouses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {boolean} [hidePaid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportDeliveryDocumentResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate, toDate, suppliers, companies, hidePaid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [supplierId] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate?: string, toDate?: string, supplierId?: number, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportDeliveryItemExtendedResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate, toDate, supplierId, companies, warehouses, itemCategories, itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Depleted Amounts With Prices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receviers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate?: string, toDate?: string, receviers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportDepletedAmountWithPrices>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate, toDate, receviers, companies, warehouses, itemCategories, itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Items
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderItemsV1ReportsOrderItemsGet(fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportOrderItemExtendedResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderItemsV1ReportsOrderItemsGet(fromDate, toDate, receivers, legalEntities, companies, warehouses, itemCategories, itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Orders Balance
         * @param {string} [statusKeys] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportOrderBalanceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys, companies, warehouses, fromDate, toDate, noInvoiceOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Category Multipliers
         * @param {string} itemCategories 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportPricingPolicyItemCategoryMultipliersListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Prices
         * @param {string} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportPricingPolicyItemPricesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Quantities To Fill Up Warehouse Reserve
         * @param {number} warehouseId 
         * @param {number} [baseDays] 
         * @param {number} [reserveDays] 
         * @param {number} [supplierId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId: number, baseDays?: number, reserveDays?: number, supplierId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportWarehouseReserveResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId, baseDays, reserveDays, supplierId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Warehouse Stock By Orders
         * @param {number} warehouseId 
         * @param {string} toDate 
         * @param {boolean} [hideOrdersWithoutDeliveryDate] 
         * @param {boolean} [hideAvailable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportDeliveryItemByOrdersResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId, toDate, hideOrdersWithoutDeliveryDate, hideAvailable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Warehouse Stock
         * @param {number} [companyId] 
         * @param {number} [warehouseId] 
         * @param {number} [itemId] 
         * @param {number} [manufacturerId] 
         * @param {string} [suppliers] 
         * @param {string} [itemCategories] 
         * @param {boolean} [hideDepleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readWarehouseStockV1ReportsStockGet(companyId?: number, warehouseId?: number, itemId?: number, manufacturerId?: number, suppliers?: string, itemCategories?: string, hideDepleted?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportDeliveryItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readWarehouseStockV1ReportsStockGet(companyId, warehouseId, itemId, manufacturerId, suppliers, itemCategories, hideDepleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Aggregated Item Movements
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [partners] 
         * @param {Array<number>} [warehouses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, options?: any): AxiosPromise<Array<ItemMovementBalance>> {
            return localVarFp.getAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate, toDate, partners, warehouses, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {boolean} [hidePaid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, options?: any): AxiosPromise<Array<ReportDeliveryDocumentResponse>> {
            return localVarFp.readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate, toDate, suppliers, companies, hidePaid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [supplierId] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate?: string, toDate?: string, supplierId?: number, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: any): AxiosPromise<Array<ReportDeliveryItemExtendedResponse>> {
            return localVarFp.readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate, toDate, supplierId, companies, warehouses, itemCategories, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Depleted Amounts With Prices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receviers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate?: string, toDate?: string, receviers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: any): AxiosPromise<Array<ReportDepletedAmountWithPrices>> {
            return localVarFp.readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate, toDate, receviers, companies, warehouses, itemCategories, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Items
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [legalEntities] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderItemsV1ReportsOrderItemsGet(fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: any): AxiosPromise<Array<ReportOrderItemExtendedResponse>> {
            return localVarFp.readOrderItemsV1ReportsOrderItemsGet(fromDate, toDate, receivers, legalEntities, companies, warehouses, itemCategories, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Orders Balance
         * @param {string} [statusKeys] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, options?: any): AxiosPromise<Array<ReportOrderBalanceResponse>> {
            return localVarFp.readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys, companies, warehouses, fromDate, toDate, noInvoiceOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Category Multipliers
         * @param {string} itemCategories 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories: string, options?: any): AxiosPromise<ReportPricingPolicyItemCategoryMultipliersListResponse> {
            return localVarFp.readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Prices
         * @param {string} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items: string, options?: any): AxiosPromise<ReportPricingPolicyItemPricesListResponse> {
            return localVarFp.readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Quantities To Fill Up Warehouse Reserve
         * @param {number} warehouseId 
         * @param {number} [baseDays] 
         * @param {number} [reserveDays] 
         * @param {number} [supplierId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId: number, baseDays?: number, reserveDays?: number, supplierId?: number, options?: any): AxiosPromise<Array<ReportWarehouseReserveResponse>> {
            return localVarFp.readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId, baseDays, reserveDays, supplierId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Warehouse Stock By Orders
         * @param {number} warehouseId 
         * @param {string} toDate 
         * @param {boolean} [hideOrdersWithoutDeliveryDate] 
         * @param {boolean} [hideAvailable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options?: any): AxiosPromise<Array<ReportDeliveryItemByOrdersResponse>> {
            return localVarFp.readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId, toDate, hideOrdersWithoutDeliveryDate, hideAvailable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Warehouse Stock
         * @param {number} [companyId] 
         * @param {number} [warehouseId] 
         * @param {number} [itemId] 
         * @param {number} [manufacturerId] 
         * @param {string} [suppliers] 
         * @param {string} [itemCategories] 
         * @param {boolean} [hideDepleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehouseStockV1ReportsStockGet(companyId?: number, warehouseId?: number, itemId?: number, manufacturerId?: number, suppliers?: string, itemCategories?: string, hideDepleted?: boolean, options?: any): AxiosPromise<Array<ReportDeliveryItemResponse>> {
            return localVarFp.readWarehouseStockV1ReportsStockGet(companyId, warehouseId, itemId, manufacturerId, suppliers, itemCategories, hideDepleted, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @summary Get Aggregated Item Movements
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {Array<number>} [partners] 
     * @param {Array<number>} [warehouses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate, toDate, partners, warehouses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Invoices
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [suppliers] 
     * @param {string} [companies] 
     * @param {boolean} [hidePaid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate, toDate, suppliers, companies, hidePaid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Items
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {number} [supplierId] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [itemCategories] 
     * @param {number} [itemId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate?: string, toDate?: string, supplierId?: number, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate, toDate, supplierId, companies, warehouses, itemCategories, itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Depleted Amounts With Prices
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [receviers] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [itemCategories] 
     * @param {number} [itemId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate?: string, toDate?: string, receviers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate, toDate, receviers, companies, warehouses, itemCategories, itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Items
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [receivers] 
     * @param {string} [legalEntities] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [itemCategories] 
     * @param {number} [itemId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readOrderItemsV1ReportsOrderItemsGet(fromDate?: string, toDate?: string, receivers?: string, legalEntities?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readOrderItemsV1ReportsOrderItemsGet(fromDate, toDate, receivers, legalEntities, companies, warehouses, itemCategories, itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Orders Balance
     * @param {string} [statusKeys] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {boolean} [noInvoiceOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys, companies, warehouses, fromDate, toDate, noInvoiceOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Category Multipliers
     * @param {string} itemCategories 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Prices
     * @param {string} items 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Quantities To Fill Up Warehouse Reserve
     * @param {number} warehouseId 
     * @param {number} [baseDays] 
     * @param {number} [reserveDays] 
     * @param {number} [supplierId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId: number, baseDays?: number, reserveDays?: number, supplierId?: number, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId, baseDays, reserveDays, supplierId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Warehouse Stock By Orders
     * @param {number} warehouseId 
     * @param {string} toDate 
     * @param {boolean} [hideOrdersWithoutDeliveryDate] 
     * @param {boolean} [hideAvailable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId, toDate, hideOrdersWithoutDeliveryDate, hideAvailable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Warehouse Stock
     * @param {number} [companyId] 
     * @param {number} [warehouseId] 
     * @param {number} [itemId] 
     * @param {number} [manufacturerId] 
     * @param {string} [suppliers] 
     * @param {string} [itemCategories] 
     * @param {boolean} [hideDepleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readWarehouseStockV1ReportsStockGet(companyId?: number, warehouseId?: number, itemId?: number, manufacturerId?: number, suppliers?: string, itemCategories?: string, hideDepleted?: boolean, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readWarehouseStockV1ReportsStockGet(companyId, warehouseId, itemId, manufacturerId, suppliers, itemCategories, hideDepleted, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StockTakingReportsApi - axios parameter creator
 * @export
 */
export const StockTakingReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List Stock Taking Report Items
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStockTakingReportItemsV2StockTakingReportItemsGet: async (itemId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/stock-taking-report-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Stock Taking Reports
         * @param {string} [fromExecutionDate] 
         * @param {string} [toExecutionDate] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStockTakingReportsV2StockTakingReportsGet: async (fromExecutionDate?: string, toExecutionDate?: string, userId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/stock-taking-reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromExecutionDate !== undefined) {
                localVarQueryParameter['from_execution_date'] = fromExecutionDate;
            }

            if (toExecutionDate !== undefined) {
                localVarQueryParameter['to_execution_date'] = toExecutionDate;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stock Taking Report
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockTakingReportV2StockTakingReportsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readStockTakingReportV2StockTakingReportsIdGet', 'id', id)
            const localVarPath = `/v2/stock-taking-reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StockTakingReportsApi - functional programming interface
 * @export
 */
export const StockTakingReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StockTakingReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List Stock Taking Report Items
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStockTakingReportItemsV2StockTakingReportItemsGet(itemId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockTakingReportItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStockTakingReportItemsV2StockTakingReportItemsGet(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Stock Taking Reports
         * @param {string} [fromExecutionDate] 
         * @param {string} [toExecutionDate] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStockTakingReportsV2StockTakingReportsGet(fromExecutionDate?: string, toExecutionDate?: string, userId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockTakingReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStockTakingReportsV2StockTakingReportsGet(fromExecutionDate, toExecutionDate, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stock Taking Report
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStockTakingReportV2StockTakingReportsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockTakingReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStockTakingReportV2StockTakingReportsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StockTakingReportsApi - factory interface
 * @export
 */
export const StockTakingReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StockTakingReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary List Stock Taking Report Items
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStockTakingReportItemsV2StockTakingReportItemsGet(itemId?: number, options?: any): AxiosPromise<Array<StockTakingReportItemResponse>> {
            return localVarFp.listStockTakingReportItemsV2StockTakingReportItemsGet(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Stock Taking Reports
         * @param {string} [fromExecutionDate] 
         * @param {string} [toExecutionDate] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStockTakingReportsV2StockTakingReportsGet(fromExecutionDate?: string, toExecutionDate?: string, userId?: number, options?: any): AxiosPromise<Array<StockTakingReportResponse>> {
            return localVarFp.listStockTakingReportsV2StockTakingReportsGet(fromExecutionDate, toExecutionDate, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stock Taking Report
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockTakingReportV2StockTakingReportsIdGet(id: number, options?: any): AxiosPromise<StockTakingReportResponse> {
            return localVarFp.readStockTakingReportV2StockTakingReportsIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StockTakingReportsApi - object-oriented interface
 * @export
 * @class StockTakingReportsApi
 * @extends {BaseAPI}
 */
export class StockTakingReportsApi extends BaseAPI {
    /**
     * 
     * @summary List Stock Taking Report Items
     * @param {number} [itemId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockTakingReportsApi
     */
    public listStockTakingReportItemsV2StockTakingReportItemsGet(itemId?: number, options?: AxiosRequestConfig) {
        return StockTakingReportsApiFp(this.configuration).listStockTakingReportItemsV2StockTakingReportItemsGet(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Stock Taking Reports
     * @param {string} [fromExecutionDate] 
     * @param {string} [toExecutionDate] 
     * @param {number} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockTakingReportsApi
     */
    public listStockTakingReportsV2StockTakingReportsGet(fromExecutionDate?: string, toExecutionDate?: string, userId?: number, options?: AxiosRequestConfig) {
        return StockTakingReportsApiFp(this.configuration).listStockTakingReportsV2StockTakingReportsGet(fromExecutionDate, toExecutionDate, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stock Taking Report
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockTakingReportsApi
     */
    public readStockTakingReportV2StockTakingReportsIdGet(id: number, options?: AxiosRequestConfig) {
        return StockTakingReportsApiFp(this.configuration).readStockTakingReportV2StockTakingReportsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StocktakingApi - axios parameter creator
 * @export
 */
export const StocktakingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Stocktaking
         * @param {StocktakingRequest} stocktakingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStocktakingV2StocktakingsPost: async (stocktakingRequest: StocktakingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stocktakingRequest' is not null or undefined
            assertParamExists('createStocktakingV2StocktakingsPost', 'stocktakingRequest', stocktakingRequest)
            const localVarPath = `/v2/stocktakings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stocktakingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stock Taking Tasks By User
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readStockTakingTasksByUserV2StockTakingTaskGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/stock-taking/task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stocktaking Tasks
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStocktakingTasksV2StocktakingsIdTasksGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readStocktakingTasksV2StocktakingsIdTasksGet', 'id', id)
            const localVarPath = `/v2/stocktakings/{id}/tasks`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stocktaking
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStocktakingV2StocktakingsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readStocktakingV2StocktakingsIdGet', 'id', id)
            const localVarPath = `/v2/stocktakings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stocktakings
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [warehouses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStocktakingsV2StocktakingsGet: async (fromDate?: string, toDate?: string, warehouses?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/stocktakings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Stock Taking Task Item
         * @param {number} stockTakingTaskItemId 
         * @param {UpdateStockTakingTaskItemRequest} updateStockTakingTaskItemRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateStockTakingTaskItemV2StockTakingTaskItemStockTakingTaskItemIdPatch: async (stockTakingTaskItemId: number, updateStockTakingTaskItemRequest: UpdateStockTakingTaskItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stockTakingTaskItemId' is not null or undefined
            assertParamExists('updateStockTakingTaskItemV2StockTakingTaskItemStockTakingTaskItemIdPatch', 'stockTakingTaskItemId', stockTakingTaskItemId)
            // verify required parameter 'updateStockTakingTaskItemRequest' is not null or undefined
            assertParamExists('updateStockTakingTaskItemV2StockTakingTaskItemStockTakingTaskItemIdPatch', 'updateStockTakingTaskItemRequest', updateStockTakingTaskItemRequest)
            const localVarPath = `/v2/stock-taking/task-item/{stock_taking_task_item_id}`
                .replace(`{${"stock_taking_task_item_id"}}`, encodeURIComponent(String(stockTakingTaskItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStockTakingTaskItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Stock Taking Task
         * @param {number} stockTakingTaskId 
         * @param {UpdateStockTakingTaskRequest} updateStockTakingTaskRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateStockTakingTaskV2StockTakingTaskStockTakingTaskIdPatch: async (stockTakingTaskId: number, updateStockTakingTaskRequest: UpdateStockTakingTaskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stockTakingTaskId' is not null or undefined
            assertParamExists('updateStockTakingTaskV2StockTakingTaskStockTakingTaskIdPatch', 'stockTakingTaskId', stockTakingTaskId)
            // verify required parameter 'updateStockTakingTaskRequest' is not null or undefined
            assertParamExists('updateStockTakingTaskV2StockTakingTaskStockTakingTaskIdPatch', 'updateStockTakingTaskRequest', updateStockTakingTaskRequest)
            const localVarPath = `/v2/stock-taking/task/{stock_taking_task_id}`
                .replace(`{${"stock_taking_task_id"}}`, encodeURIComponent(String(stockTakingTaskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStockTakingTaskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StocktakingApi - functional programming interface
 * @export
 */
export const StocktakingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StocktakingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Stocktaking
         * @param {StocktakingRequest} stocktakingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStocktakingV2StocktakingsPost(stocktakingRequest: StocktakingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StocktakingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStocktakingV2StocktakingsPost(stocktakingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stock Taking Tasks By User
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readStockTakingTasksByUserV2StockTakingTaskGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockTakingTaskResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStockTakingTasksByUserV2StockTakingTaskGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stocktaking Tasks
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStocktakingTasksV2StocktakingsIdTasksGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockTakingTaskResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStocktakingTasksV2StocktakingsIdTasksGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stocktaking
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStocktakingV2StocktakingsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StocktakingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStocktakingV2StocktakingsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stocktakings
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [warehouses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStocktakingsV2StocktakingsGet(fromDate?: string, toDate?: string, warehouses?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StocktakingResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStocktakingsV2StocktakingsGet(fromDate, toDate, warehouses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Stock Taking Task Item
         * @param {number} stockTakingTaskItemId 
         * @param {UpdateStockTakingTaskItemRequest} updateStockTakingTaskItemRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateStockTakingTaskItemV2StockTakingTaskItemStockTakingTaskItemIdPatch(stockTakingTaskItemId: number, updateStockTakingTaskItemRequest: UpdateStockTakingTaskItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockTakingTaskItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStockTakingTaskItemV2StockTakingTaskItemStockTakingTaskItemIdPatch(stockTakingTaskItemId, updateStockTakingTaskItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Stock Taking Task
         * @param {number} stockTakingTaskId 
         * @param {UpdateStockTakingTaskRequest} updateStockTakingTaskRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateStockTakingTaskV2StockTakingTaskStockTakingTaskIdPatch(stockTakingTaskId: number, updateStockTakingTaskRequest: UpdateStockTakingTaskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockTakingTaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStockTakingTaskV2StockTakingTaskStockTakingTaskIdPatch(stockTakingTaskId, updateStockTakingTaskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StocktakingApi - factory interface
 * @export
 */
export const StocktakingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StocktakingApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Stocktaking
         * @param {StocktakingRequest} stocktakingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStocktakingV2StocktakingsPost(stocktakingRequest: StocktakingRequest, options?: any): AxiosPromise<StocktakingResponse> {
            return localVarFp.createStocktakingV2StocktakingsPost(stocktakingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stock Taking Tasks By User
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readStockTakingTasksByUserV2StockTakingTaskGet(options?: any): AxiosPromise<Array<StockTakingTaskResponse>> {
            return localVarFp.readStockTakingTasksByUserV2StockTakingTaskGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stocktaking Tasks
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStocktakingTasksV2StocktakingsIdTasksGet(id: number, options?: any): AxiosPromise<Array<StockTakingTaskResponse>> {
            return localVarFp.readStocktakingTasksV2StocktakingsIdTasksGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stocktaking
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStocktakingV2StocktakingsIdGet(id: number, options?: any): AxiosPromise<StocktakingResponse> {
            return localVarFp.readStocktakingV2StocktakingsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stocktakings
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [warehouses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStocktakingsV2StocktakingsGet(fromDate?: string, toDate?: string, warehouses?: string, options?: any): AxiosPromise<Array<StocktakingResponse>> {
            return localVarFp.readStocktakingsV2StocktakingsGet(fromDate, toDate, warehouses, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Stock Taking Task Item
         * @param {number} stockTakingTaskItemId 
         * @param {UpdateStockTakingTaskItemRequest} updateStockTakingTaskItemRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateStockTakingTaskItemV2StockTakingTaskItemStockTakingTaskItemIdPatch(stockTakingTaskItemId: number, updateStockTakingTaskItemRequest: UpdateStockTakingTaskItemRequest, options?: any): AxiosPromise<StockTakingTaskItemResponse> {
            return localVarFp.updateStockTakingTaskItemV2StockTakingTaskItemStockTakingTaskItemIdPatch(stockTakingTaskItemId, updateStockTakingTaskItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Stock Taking Task
         * @param {number} stockTakingTaskId 
         * @param {UpdateStockTakingTaskRequest} updateStockTakingTaskRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateStockTakingTaskV2StockTakingTaskStockTakingTaskIdPatch(stockTakingTaskId: number, updateStockTakingTaskRequest: UpdateStockTakingTaskRequest, options?: any): AxiosPromise<StockTakingTaskResponse> {
            return localVarFp.updateStockTakingTaskV2StockTakingTaskStockTakingTaskIdPatch(stockTakingTaskId, updateStockTakingTaskRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StocktakingApi - object-oriented interface
 * @export
 * @class StocktakingApi
 * @extends {BaseAPI}
 */
export class StocktakingApi extends BaseAPI {
    /**
     * 
     * @summary Create Stocktaking
     * @param {StocktakingRequest} stocktakingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StocktakingApi
     */
    public createStocktakingV2StocktakingsPost(stocktakingRequest: StocktakingRequest, options?: AxiosRequestConfig) {
        return StocktakingApiFp(this.configuration).createStocktakingV2StocktakingsPost(stocktakingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stock Taking Tasks By User
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof StocktakingApi
     */
    public readStockTakingTasksByUserV2StockTakingTaskGet(options?: AxiosRequestConfig) {
        return StocktakingApiFp(this.configuration).readStockTakingTasksByUserV2StockTakingTaskGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stocktaking Tasks
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StocktakingApi
     */
    public readStocktakingTasksV2StocktakingsIdTasksGet(id: number, options?: AxiosRequestConfig) {
        return StocktakingApiFp(this.configuration).readStocktakingTasksV2StocktakingsIdTasksGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stocktaking
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StocktakingApi
     */
    public readStocktakingV2StocktakingsIdGet(id: number, options?: AxiosRequestConfig) {
        return StocktakingApiFp(this.configuration).readStocktakingV2StocktakingsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stocktakings
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [warehouses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StocktakingApi
     */
    public readStocktakingsV2StocktakingsGet(fromDate?: string, toDate?: string, warehouses?: string, options?: AxiosRequestConfig) {
        return StocktakingApiFp(this.configuration).readStocktakingsV2StocktakingsGet(fromDate, toDate, warehouses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Stock Taking Task Item
     * @param {number} stockTakingTaskItemId 
     * @param {UpdateStockTakingTaskItemRequest} updateStockTakingTaskItemRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof StocktakingApi
     */
    public updateStockTakingTaskItemV2StockTakingTaskItemStockTakingTaskItemIdPatch(stockTakingTaskItemId: number, updateStockTakingTaskItemRequest: UpdateStockTakingTaskItemRequest, options?: AxiosRequestConfig) {
        return StocktakingApiFp(this.configuration).updateStockTakingTaskItemV2StockTakingTaskItemStockTakingTaskItemIdPatch(stockTakingTaskItemId, updateStockTakingTaskItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Stock Taking Task
     * @param {number} stockTakingTaskId 
     * @param {UpdateStockTakingTaskRequest} updateStockTakingTaskRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof StocktakingApi
     */
    public updateStockTakingTaskV2StockTakingTaskStockTakingTaskIdPatch(stockTakingTaskId: number, updateStockTakingTaskRequest: UpdateStockTakingTaskRequest, options?: AxiosRequestConfig) {
        return StocktakingApiFp(this.configuration).updateStockTakingTaskV2StockTakingTaskStockTakingTaskIdPatch(stockTakingTaskId, updateStockTakingTaskRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SuppliersApi - axios parameter creator
 * @export
 */
export const SuppliersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create
         * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1CompanyCategoriesPost: async (createCompanyCategoryRequest: CreateCompanyCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyCategoryRequest' is not null or undefined
            assertParamExists('createV1CompanyCategoriesPost', 'createCompanyCategoryRequest', createCompanyCategoryRequest)
            const localVarPath = `/v1/company-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1CompanyCategoriesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteV1CompanyCategoriesIdDelete', 'id', id)
            const localVarPath = `/v1/company-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Load Info
         * @param {string} vatNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadInfoV1LegalEntitiesLoadInfoPost: async (vatNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vatNumber' is not null or undefined
            assertParamExists('loadInfoV1LegalEntitiesLoadInfoPost', 'vatNumber', vatNumber)
            const localVarPath = `/v1/legal-entities/load-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (vatNumber !== undefined) {
                localVarQueryParameter['vat_number'] = vatNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllV1CompanyCategoriesGet: async (skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/company-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readV1CompanyCategoriesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readV1CompanyCategoriesIdGet', 'id', id)
            const localVarPath = `/v1/company-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateV1CompanyCategoriesPut: async (updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCompanyCategoryRequest' is not null or undefined
            assertParamExists('updateV1CompanyCategoriesPut', 'updateCompanyCategoryRequest', updateCompanyCategoryRequest)
            const localVarPath = `/v1/company-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SuppliersApi - functional programming interface
 * @export
 */
export const SuppliersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SuppliersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createV1CompanyCategoriesPost(createCompanyCategoryRequest: CreateCompanyCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1CompanyCategoriesPost(createCompanyCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1CompanyCategoriesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1CompanyCategoriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Load Info
         * @param {string} vatNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadInfoV1LegalEntitiesLoadInfoPost(vatNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadInfoV1LegalEntitiesLoadInfoPost(vatNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAllV1CompanyCategoriesGet(skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllV1CompanyCategoriesGet(skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readV1CompanyCategoriesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readV1CompanyCategoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateV1CompanyCategoriesPut(updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateV1CompanyCategoriesPut(updateCompanyCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SuppliersApi - factory interface
 * @export
 */
export const SuppliersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SuppliersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV1CompanyCategoriesPost(createCompanyCategoryRequest: CreateCompanyCategoryRequest, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.createV1CompanyCategoriesPost(createCompanyCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1CompanyCategoriesIdDelete(id: number, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.deleteV1CompanyCategoriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Load Info
         * @param {string} vatNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadInfoV1LegalEntitiesLoadInfoPost(vatNumber: string, options?: any): AxiosPromise<CompanyInfo> {
            return localVarFp.loadInfoV1LegalEntitiesLoadInfoPost(vatNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllV1CompanyCategoriesGet(skip?: number, limit?: number, options?: any): AxiosPromise<Array<CompanyCategoryResponse>> {
            return localVarFp.readAllV1CompanyCategoriesGet(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readV1CompanyCategoriesIdGet(id: number, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.readV1CompanyCategoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateV1CompanyCategoriesPut(updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.updateV1CompanyCategoriesPut(updateCompanyCategoryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SuppliersApi - object-oriented interface
 * @export
 * @class SuppliersApi
 * @extends {BaseAPI}
 */
export class SuppliersApi extends BaseAPI {
    /**
     * 
     * @summary Create
     * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApi
     */
    public createV1CompanyCategoriesPost(createCompanyCategoryRequest: CreateCompanyCategoryRequest, options?: AxiosRequestConfig) {
        return SuppliersApiFp(this.configuration).createV1CompanyCategoriesPost(createCompanyCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApi
     */
    public deleteV1CompanyCategoriesIdDelete(id: number, options?: AxiosRequestConfig) {
        return SuppliersApiFp(this.configuration).deleteV1CompanyCategoriesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Load Info
     * @param {string} vatNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApi
     */
    public loadInfoV1LegalEntitiesLoadInfoPost(vatNumber: string, options?: AxiosRequestConfig) {
        return SuppliersApiFp(this.configuration).loadInfoV1LegalEntitiesLoadInfoPost(vatNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApi
     */
    public readAllV1CompanyCategoriesGet(skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return SuppliersApiFp(this.configuration).readAllV1CompanyCategoriesGet(skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApi
     */
    public readV1CompanyCategoriesIdGet(id: number, options?: AxiosRequestConfig) {
        return SuppliersApiFp(this.configuration).readV1CompanyCategoriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApi
     */
    public updateV1CompanyCategoriesPut(updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options?: AxiosRequestConfig) {
        return SuppliersApiFp(this.configuration).updateV1CompanyCategoriesPut(updateCompanyCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


