import { AMSTable } from '../../helpers/ui';
import { ActOfAcceptanceItemResponse } from '../../services/api';
import React from 'react';
import { useActOfAcceptanceItems } from '../../helpers/hooks';

interface ActOfAcceptanceItemsPanelProps {
  id: number;
}

const ActOfAcceptanceItemsPanel = ({ id }: ActOfAcceptanceItemsPanelProps) => {
  const { actOfAcceptanceItems, loading } = useActOfAcceptanceItems(id);

  return (
    <AMSTable
      title={'Списък с артикули'}
      columns={[
        { title: 'Арт.ном', field: 'itemArtNo', cellStyle: { width: '10%' } },
        {
          title: 'Наименование на артикула',
          field: 'itemName',
          cellStyle: { width: '10%' }
        },
        {
          title: 'Баркод',
          field: 'itemBarcodeValue',
          cellStyle: { width: '10%' }
        },
        {
          title: 'Партида',
          field: 'deliveryItemBatchNumber',
          cellStyle: { width: '10%' }
        },
        {
          title: 'Срок на годност',
          field: 'deliveryItemExpirationDate',
          cellStyle: { width: '10%' }
        },
        {
          title: 'Държава',
          field: 'deliveryItemCountry',
          cellStyle: { width: '10%' }
        },
        {
          title: 'Тип опак.',
          field: 'itemUnitName',
          cellStyle: { width: '10%' }
        },
        {
          title: 'Бройки в кашон',
          field: 'itemPackageQuantityDelivery',
          align: 'right',
          type: 'numeric',
          cellStyle: { width: '10%' }
        },
        {
          title: 'Количество',
          field: 'quantity',
          cellStyle: { width: '10%' },
          align: 'right',
          render: (item: ActOfAcceptanceItemResponse) => (+item.quantity).toFixed(3)
        },
        {
          title: 'Ед. цена (без ДДС)',
          field: 'orderItemNetPriceForOne',
          cellStyle: { width: '10%' },
          align: 'right',
          type: 'currency',
          currencySetting: {
            locale: 'bg',
            currencyCode: 'bgn',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }
        },
        {
          title: 'Сума',
          field: 'netTotal',
          cellStyle: { width: '10%' },
          type: 'currency',
          currencySetting: {
            locale: 'bg',
            currencyCode: 'bgn',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }
        }
      ]}
      pageSize={10}
      search={true}
      data={actOfAcceptanceItems}
      isLoading={loading}
    />
  );
};

export default ActOfAcceptanceItemsPanel;
