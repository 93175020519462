import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { ActOfAcceptanceResponse } from '../../services/api';
import { actsOfAcceptanceService } from '../../services/services';

export interface IActOfAcceptance<T> extends IAsyncType<T> {
    actOfAcceptance: T;
    setActOfAcceptance: Dispatch<SetStateAction<T>>;
}

const useActOfAcceptance = (
    id: number,
): IActOfAcceptance<ActOfAcceptanceResponse | null> => {
    const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
        ActOfAcceptanceResponse | null
    >(actsOfAcceptanceService.getActOfAcceptance, null, [id]);
    return {
        actOfAcceptance: data,
        setActOfAcceptance: setData,
        loading,
        setLoading,
        parameters,
        setParameters
    };
};

export default useActOfAcceptance;
