import { IconButton, TableCell, TableRow, Typography, makeStyles } from '@material-ui/core';
import Item, { SimilarItemAssociation } from '../../../models/item';
import React, { useEffect, useMemo, useState } from 'react';
import { accessManagementService, itemsService } from '../../../services/services';

import AMSAsyncAutocomplete from '../../../helpers/ui/AMSAsyncAutocomplete/AMSAsyncAutocomplete';
import AMSTable from '../../../helpers/ui/AMSTable/AMSTable';
import { AccessPermissions } from '../../../services/accessManagementService';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DateFnsUtils from '@date-io/date-fns';
import { ItemResponse } from '../../../services/api-v3';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import compareWithUndefined from '../../../helpers/compareWithUndefined';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  }
}));

interface SimilarItemsContainerProps {
  id: number;
}

const SimilarItemsContainer = (props: SimilarItemsContainerProps) => {
  const { id } = props;
  const classes = useStyles();

  const [item, setItem] = useState<any>(null);
  const [similarItems, setSimilarItems] = useState<SimilarItemAssociation[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      if (id) {
        const resp = await itemsService.getSimilarItems(id);
        setSimilarItems(resp.data);
      } else {
        setSimilarItems([]);
      }
      setLoading(false);
    };
    loadData();
  }, [id]);

  const [columns] = useState([
    {
      title: 'Арт №',
      field: 'similarItemId',
      render: (row: SimilarItemAssociation) => row.similarItemId,
      cellStyle: { width: '8%' },
      customSort: (i1: SimilarItemAssociation, i2: SimilarItemAssociation) =>
        i1.similarItemId - i2.similarItemId
    },
    {
      title: 'Име',
      field: 'similarItemName',
      render: (row: SimilarItemAssociation) => row.similarItemName,
      cellStyle: { width: '92%' },
      customSort: (i1: SimilarItemAssociation, i2: SimilarItemAssociation) =>
        compareWithUndefined(i1.similarItemName, i2.similarItemName)
    }
  ]);
  const disabled = useMemo(
    () =>
      !accessManagementService.hasPermission(AccessPermissions.CAN_CREATE_ITEM) &&
      !accessManagementService.hasPermission(AccessPermissions.CAN_UPDATE_ITEM),
    []
  );

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <AMSTable
          title="Аналози"
          columns={columns}
          isLoading={loading}
          data={similarItems}
          components={{
            EditRow: (props: any) => {
              return (
                <TableRow>
                  <TableCell colSpan={2}>
                    {props.mode === 'add' ? (
                      <AMSAsyncAutocomplete
                        label="Артикул"
                        onChange={(item: ItemResponse | null) => setItem(item)}
                        value={item}
                        minChar={3}
                      />
                    ) : (
                      <Typography component="h1" variant="h6">
                        Наистина ли искате да изтриете този запис?
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <div className={classes.flex}>
                      <IconButton
                        onClick={() =>
                          props.onEditingApproved(
                            props.mode,
                            props.mode === 'add' ? item : props.data,
                            props.mode === 'add' ? item : props.data
                          )
                        }
                      >
                        <CheckIcon />
                      </IconButton>
                      <IconButton onClick={() => props.onEditingCanceled(props.mode, props.data)}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              );
            }
          }}
          inlineActions={true}
          pageSize={8}
          onAdd={
            !disabled
              ? async (similarItem: Item) => {
                  if (item && item.id) {
                    setLoading(true);
                    const resp = await itemsService.addSimilarItem(item.id, similarItem);
                    setSimilarItems([...similarItems, resp.data]);
                    setLoading(false);
                  }
                }
              : undefined
          }
          onDelete={
            !disabled
              ? async (similarItem: any) => {
                  if (similarItem && similarItem.itemId && similarItem.similarItemId) {
                    setLoading(true);
                    await itemsService.deleteSimilarItem(
                      similarItem.itemId,
                      similarItem.similarItemId
                    );
                    const data = [...similarItems];
                    data.splice(similarItem.tableData.id, 1);
                    setSimilarItems(data);
                    setLoading(false);
                  }
                }
              : undefined
          }
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

export default SimilarItemsContainer;
