import { IconButton, TableCell, TableRow, Typography, makeStyles } from '@material-ui/core';
import Item, { LinkedItemAssociation } from '../../../models/item';
import React, { useEffect, useMemo, useState } from 'react';
import { accessManagementService, itemsService } from '../../../services/services';

import AMSAsyncAutocomplete from '../../../helpers/ui/AMSAsyncAutocomplete/AMSAsyncAutocomplete';
import AMSTable from '../../../helpers/ui/AMSTable/AMSTable';
import { AccessPermissions } from '../../../services/accessManagementService';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DateFnsUtils from '@date-io/date-fns';
import { ItemResponse } from '../../../services/api-v3';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import compareWithUndefined from '../../../helpers/compareWithUndefined';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  }
}));

interface LinkedItemsContainerProps {
  id: number;
}

const LinkedItemsContainer = (props: LinkedItemsContainerProps) => {
  const { id } = props;
  const classes = useStyles();

  const [item, setItem] = useState<any>(null);
  const [linkedItems, setLinkedItems] = useState<LinkedItemAssociation[]>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      if (id) {
        const resp = await itemsService.getLinkedItems(id);
        setLinkedItems(resp.data);
      } else {
        setLinkedItems([]);
      }
      setLoading(false);
    };
    loadData();
  }, [id]);

  const [columns] = useState([
    {
      title: 'Арт №',
      field: 'item',
      render: (row: LinkedItemAssociation) => row.linkedItemId,
      cellStyle: { width: '8%' },
      customSort: (i1: LinkedItemAssociation, i2: LinkedItemAssociation) =>
        i1.linkedItemId - i2.linkedItemId
    },
    {
      title: 'Име',
      field: 'item',
      render: (row: LinkedItemAssociation) => row.linkedItemName,
      cellStyle: { width: '92%' },
      customSort: (i1: LinkedItemAssociation, i2: LinkedItemAssociation) =>
        compareWithUndefined(i1.linkedItemName, i2.linkedItemName)
    }
  ]);
  const disabled = useMemo(
    () =>
      !accessManagementService.hasPermission(AccessPermissions.CAN_CREATE_ITEM) &&
      !accessManagementService.hasPermission(AccessPermissions.CAN_UPDATE_ITEM),
    []
  );

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <AMSTable
          title="Свързани продукти"
          columns={columns}
          data={linkedItems}
          components={{
            EditRow: (props: any) => {
              return (
                <TableRow>
                  <TableCell colSpan={2}>
                    {props.mode === 'add' ? (
                      <AMSAsyncAutocomplete
                        label="Артикул"
                        onChange={(item: ItemResponse | null) => setItem(item)}
                        value={item}
                        minChar={3}
                      />
                    ) : (
                      <Typography component="h1" variant="h6">
                        Наистина ли искате да изтриете този запис?
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <div className={classes.flex}>
                      <IconButton
                        onClick={() =>
                          props.onEditingApproved(
                            props.mode,
                            props.mode === 'add' ? item : props.data,
                            props.mode === 'add' ? item : props.data
                          )
                        }
                      >
                        <CheckIcon />
                      </IconButton>
                      <IconButton onClick={() => props.onEditingCanceled(props.mode, props.data)}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              );
            }
          }}
          inlineActions={true}
          pageSize={8}
          isLoading={isLoading}
          onAdd={
            !disabled
              ? async (relatedItem: Item) => {
                  if (item && item.id) {
                    setLoading(true);
                    const resp = await itemsService.addLinkedItem(item.id, relatedItem);
                    if (resp) {
                      setLinkedItems([...linkedItems, resp.data]);
                    }
                    setLoading(false);
                  }
                }
              : undefined
          }
          onDelete={
            !disabled
              ? async (linkedItemAssociation: any) => {
                  if (
                    linkedItemAssociation &&
                    linkedItemAssociation.itemId &&
                    linkedItemAssociation.linkedItemId
                  ) {
                    setLoading(true);
                    await itemsService.deleteLinkedItem(
                      linkedItemAssociation.itemId,
                      linkedItemAssociation.linkedItemId
                    );
                    const data = [...linkedItems];
                    data.splice(linkedItemAssociation.tableData.id, 1);
                    setLinkedItems(data);
                    setLoading(false);
                  }
                }
              : undefined
          }
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

export default LinkedItemsContainer;
