import {
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip
} from '@material-ui/core';
import {
  CompanyListResponse,
  ItemResponse,
  SupplierListResponse,
  WarehouseListResponse
} from '../../../services/api-v3';
import { ItemCategoryResponse, ReportDeliveryItemResponse } from '../../../services/api';
import Lookup, {
  companyToLookup,
  itemCategoryToLookup,
  itemToLookup,
  supplierToLookup,
  warehouseToLookup
} from '../../../models/lookup';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { categoriesService, cookiesService, reportsService } from '../../../services/services';
import { useCompanies, useStyles, useSuppliers, useWarehouses } from '../../../helpers/hooks';

import AMSAsyncAutocomplete from '../../../helpers/ui/AMSAsyncAutocomplete/AMSAsyncAutocomplete';
import AMSAutocomplete from '../../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import AMSButton from '../../../helpers/ui/AMSButton/AMSButton';
import AMSConfirmDialog from '../../../helpers/ui/AMSConfirmDialog/AMSConfirmDialog';
import AMSTable from '../../../helpers/ui/AMSTable/AMSTable';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { getExtendedCompanyId } from '../../../helpers/utils';
import { parseFilter } from '../../../helpers/url';
import { useHistory } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ReportStockComponent = ({ location }: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { companyIds, warehouseIds, itemId, itemCategoryIds, supplierIds, hideDepleted } = useMemo(
    () => parseFilter(location.search),
    [location.search]
  );
  const filter = useMemo(() => cookiesService.getReportStockFilter(), []);
  const [loading, setLoading] = useState(false);
  const [stock, setStock] = useState<ReportDeliveryItemResponse[]>([]);
  const { companies } = useCompanies();
  const { warehouses } = useWarehouses();
  const { suppliers } = useSuppliers();
  const [company, setCompany] = useState<Lookup | null>(filter.filterCompany ?? null);
  const [warehouse, setWarehouse] = useState<Lookup | null>(filter.filterWarehouse ?? null);
  const [item, setItem] = useState<Lookup | null>(filter.filterItem ?? null);
  const [categories, setCategories] = useState<ItemCategoryResponse[]>([]);
  const [filterCategories, setFilterCategories] = useState<Lookup[]>([]);
  const [filterSuppliers, setFilterSuppliers] = useState<Lookup[]>([]);
  const [filterCompanyId, setFilterCompanyId] = useState<number | undefined>(
    companyIds && companyIds.length > 0 ? companyIds[0] : undefined
  );
  const [filterWarehouseId, setFilterWarehouseId] = useState<number | undefined>(
    warehouseIds && warehouseIds.length > 0 ? warehouseIds[0] : undefined
  );
  const [filterItemId, setFilterItemId] = useState<number | undefined>(itemId ? itemId : undefined);
  const [filterHideDepleted, setFilterHideDepleted] = useState<boolean>(
    hideDepleted !== null ? hideDepleted : filter.filterHideDepleted ?? true
  );

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  useEffect(() => {
    const loadData = async () => {
      const [categoriesResp] = await Promise.all([categoriesService.getCategoriesFlat(true)]);
      setCategories(categoriesResp ? categoriesResp.data : []);
    };
    loadData();
  }, []);

  useEffect(() => {
    const { filterCompanies } = cookiesService.getReportStockFilter();

    if (companies) {
      const expectedCompaniesFilter = companyIds
        ? companies
            .filter((c: CompanyListResponse) => companyIds.includes(c.id))
            .map(companyToLookup)
        : filterCompanies ?? [];
      setFilterCompanyId(
        expectedCompaniesFilter.length > 0 ? expectedCompaniesFilter[0].id : undefined
      );
      setCompany(expectedCompaniesFilter.length > 0 ? expectedCompaniesFilter[0] : null);
    }
  }, [companyIds, companies]);

  useEffect(() => {
    const { filterHideDepleted } = cookiesService.getReportStockFilter();
    setFilterHideDepleted(filterHideDepleted ?? true);
  }, []);

  useEffect(() => {
    const { filterWarehouses } = cookiesService.getReportStockFilter();

    if (warehouses) {
      const expectedWarehousesFilter = warehouseIds
        ? warehouses
            .filter((w: WarehouseListResponse) => warehouseIds.includes(w.id))
            .map(warehouseToLookup)
        : filterWarehouses ?? [];
      setFilterWarehouseId(
        expectedWarehousesFilter.length > 0 ? expectedWarehousesFilter[0].id : undefined
      );
      setWarehouse(expectedWarehousesFilter.length > 0 ? expectedWarehousesFilter[0] : null);
    }
  }, [warehouseIds, warehouses]);

  useEffect(() => {
    const { filterSuppliers } = cookiesService.getReportStockFilter();

    if (suppliers) {
      setFilterSuppliers(
        supplierIds
          ? suppliers
              .filter((s: SupplierListResponse) => supplierIds.includes(s.id))
              .map(supplierToLookup)
          : filterSuppliers ?? []
      );
    }
  }, [supplierIds, suppliers]);

  useEffect(() => {
    const { filterCategories } = cookiesService.getReportStockFilter();

    if (categories) {
      setFilterCategories(
        itemCategoryIds
          ? categories
              .filter((i: ItemCategoryResponse) => itemCategoryIds.includes(i.id))
              .map(itemCategoryToLookup)
          : filterCategories ?? []
      );
    }
  }, [itemCategoryIds, categories]);

  const loadStock = useCallback(() => {
    const loadStockReport = async () => {
      setLoading(true);
      const stockResponse = await reportsService.getStock(
        filterCompanyId,
        filterWarehouseId,
        filterItemId,
        hideDepleted ?? filterHideDepleted,
        filterSuppliers.map((supplier: Lookup) => supplier.id).join(','),
        filterCategories.map((category: Lookup) => category.id).join(',')
      );
      cookiesService.setReportStockFilter({
        filterCompanies: company ? [company] : undefined,
        filterCompanyId,
        filterWarehouses: warehouse ? [warehouse] : warehouse,
        filterWarehouseId,
        filterItemId,
        filterHideDepleted,
        filterSuppliers,
        filterCategories
      });
      let params: any = {};
      if (filterCompanyId) {
        params.companyIds = [filterCompanyId];
      }
      if (filterWarehouseId) {
        params.warehouseIds = [filterWarehouseId];
      }
      if (filterItemId) {
        params.itemId = filterItemId;
      }
      params.hideDepleted = filterHideDepleted;
      if (filterSuppliers?.length > 0) {
        params.supplierIds = filterSuppliers.map((s) => s.id).join(',');
      }
      if (filterCategories?.length > 0) {
        params.itemCategoryIds = filterCategories.map((c) => c.id).join(',');
      }
      history.push({
        pathname: '/reports-stock',
        search: new URLSearchParams(params).toString()
      });
      if (stockResponse) {
        setStock(stockResponse.data);
      }
      setLoading(false);
    };
    loadStockReport();
  }, [
    company,
    filterCategories,
    filterCompanyId,
    filterHideDepleted,
    filterItemId,
    filterSuppliers,
    filterWarehouseId,
    hideDepleted,
    history,
    warehouse
  ]);

  return (
    <>
      <div className="text-align-left">
        <Container maxWidth="xl">
          <Grid container spacing={1}>
            <Grid item lg={4} md={3} sm={6} xs={12}>
              <AMSAutocomplete
                label="Фирма"
                options={companies
                  .map((c: CompanyListResponse) => ({
                    id: c.id,
                    value: `${getExtendedCompanyId(c.id)} ${c.note} (${c.name})`
                  }))
                  .sort((as1, as2) => as2.id - as1.id)}
                value={company ? company : null}
                onChange={(selectedValue: Lookup | null) => {
                  setCompany(selectedValue);
                  setFilterCompanyId(selectedValue?.id);
                  if (selectedValue) {
                    setFilterCompanyId(selectedValue.id);
                    if (
                      warehouses.filter(
                        (wh: WarehouseListResponse) => wh.companyId === filterCompanyId
                      )
                    ) {
                      setWarehouse(null);
                      setFilterWarehouseId(undefined);
                    }
                  } else {
                    setFilterCompanyId(undefined);
                  }
                }}
                minChar={0}
              />
            </Grid>
            <Grid item lg={4} md={3} sm={6} xs={12}>
              <AMSAutocomplete
                label="Склад"
                options={(filterCompanyId
                  ? warehouses.filter(
                      (wh: WarehouseListResponse) => wh.companyId === filterCompanyId
                    )
                  : warehouses
                )
                  .map(warehouseToLookup)
                  .sort((as1, as2) => as2.id - as1.id)}
                value={warehouse ? warehouse : null}
                onChange={(selectedValue: Lookup | null) => {
                  setWarehouse(selectedValue);
                  if (selectedValue) {
                    setFilterWarehouseId(selectedValue.id);
                  } else {
                    setFilterWarehouseId(undefined);
                  }
                }}
                minChar={0}
              />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <AMSAsyncAutocomplete
                label="Артикул"
                onChange={(item: ItemResponse | null) => {
                  if (item) {
                    setFilterItemId(item?.id);
                    setItem(itemToLookup(item));
                  } else {
                    setFilterItemId(undefined);
                    setItem(null);
                  }
                }}
                value={item?.id}
                minChar={3}
              />
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <AMSAutocomplete
                multiple
                minChar={0}
                limitTags={3}
                options={categories.map(itemCategoryToLookup)}
                disableCloseOnSelect
                getOptionLabel={(option: Lookup) => option.value}
                renderOption={(option: Lookup, { selected }: any) => (
                  <Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      className={classes.checkbox}
                      checked={selected}
                      color="primary"
                    />
                    {option.value}
                  </Fragment>
                )}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Артикулна група"
                    placeholder=""
                    margin="dense"
                    fullWidth
                  />
                )}
                value={filterCategories}
                onChange={(values) => setFilterCategories(values)}
                groupBy={(g) => g.group}
              />
            </Grid>
            <Grid item lg={4} md={3} sm={12} xs={12}>
              <AMSAutocomplete
                multiple
                minChar={0}
                limitTags={3}
                options={suppliers.map(supplierToLookup)}
                disableCloseOnSelect
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      className={classes.checkbox}
                      checked={selected}
                      color="primary"
                    />
                    {option.value}
                  </Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Доставчици"
                    placeholder=""
                    margin="dense"
                    fullWidth
                  />
                )}
                value={filterSuppliers}
                onChange={setFilterSuppliers}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={12} xs={12}>
              <FormControlLabel
                style={{ paddingTop: 6 }}
                control={
                  <Checkbox
                    checked={filterHideDepleted}
                    onChange={() => {
                      setFilterHideDepleted(!filterHideDepleted);
                    }}
                  />
                }
                label="Скриване на изчерпаните"
                labelPlacement="end"
              />
            </Grid>
            <Grid item lg={2} md={1} sm={12} xs={12}>
              {!filterCompanyId &&
              !filterWarehouseId &&
              !filterItemId &&
              (!filterCategories || filterCategories.length === 0) &&
              (!filterSuppliers || filterSuppliers.length === 0) ? (
                <Tooltip
                  title={
                    <div style={{ fontSize: 14 }}>
                      Търсенето без филтри може да доведе до забавяне на системата
                    </div>
                  }
                  placement="top"
                  style={{ fontSize: 24 }}
                >
                  <div>
                    <AMSButton
                      color="primary"
                      variant="contained"
                      text="Търсене"
                      loading={loading}
                      disabled={false}
                      onClick={() => setOpenConfirmDialog(true)}
                      style={{
                        marginTop: 8,
                        backgroundColor: '#ff7b00',
                        float: 'right'
                      }}
                    />
                  </div>
                </Tooltip>
              ) : (
                <AMSButton
                  color="primary"
                  variant="contained"
                  text="Търсене"
                  loading={loading}
                  disabled={false}
                  onClick={loadStock}
                  style={{
                    marginTop: 8,
                    float: 'right'
                  }}
                />
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {loading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <AMSTable
                  title="Наличности"
                  columns={[
                    {
                      title: 'Компания',
                      field: 'companyNote',
                      cellStyle: { width: '20%' },
                      render: (stock: ReportDeliveryItemResponse) =>
                        `${stock.companyNote} (${stock.companyName})`
                    },
                    {
                      title: 'Склад',
                      field: 'warehouseName',
                      cellStyle: { width: '20%' }
                    },
                    {
                      title: 'Арт №',
                      field: 'itemArtNo',
                      cellStyle: { width: '8%' }
                    },
                    {
                      title: 'Артикул',
                      field: 'itemName',
                      cellStyle: { width: '20%' }
                    },
                    {
                      title: 'Срок на годност',
                      field: 'itemExpirationDate',
                      cellStyle: { width: '10%' }
                    },
                    {
                      title: 'Партида',
                      field: 'itemBatchNumber',
                      cellStyle: { width: '8%' }
                    },
                    {
                      title: 'Държава',
                      field: 'itemCountry',
                      cellStyle: { width: '8%' }
                    },
                    {
                      title: 'Наличност',
                      field: 'itemAvailableQuantity',
                      cellStyle: { width: '8%' },
                      align: 'right',
                      type: 'numeric'
                    }
                  ]}
                  paging={false}
                  overflowY="scroll"
                  minBodyHeight="65vh"
                  maxBodyHeight="65vh"
                  data={stock}
                />
              )}
            </Grid>
          </Grid>
        </Container>
        <AMSConfirmDialog
          open={openConfirmDialog}
          onConfirm={() => {
            loadStock();
            setOpenConfirmDialog(false);
          }}
          onClose={() => setOpenConfirmDialog(false)}
          title={'Търсене без филтри!'}
          message={
            'Търсенето без филтри може да доведе до забавяне на системата. Искате да продължите?'
          }
        />
      </div>
    </>
  );
};

export default ReportStockComponent;
