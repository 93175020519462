import {
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import {
  CompanyListResponse,
  ItemResponse,
  SupplierListResponse,
  WarehouseListResponse
} from '../../../services/api-v3';
import { ItemCategoryResponse, ReportDeliveryItemExtendedResponse } from '../../../services/api';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Lookup, { itemToLookup, warehouseToLookup } from '../../../models/lookup';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { cookiesService, reportsService } from '../../../services/services';
import {
  getExtendedCompanyId,
  getExtendedDeliveryId,
  getExtendedItemCategoryId,
  getExtendedSupplierId
} from '../../../helpers/utils';
import { ignoreOffset, isValidDate, toEndOfDay, toStartOfDay } from '../../../helpers/date-helper';
import {
  useCompanies,
  useItemCategories,
  useSuppliers,
  useWarehouses
} from '../../../helpers/hooks';

import AMSAsyncAutocomplete from '../../../helpers/ui/AMSAsyncAutocomplete/AMSAsyncAutocomplete';
import AMSAutocomplete from '../../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import AMSConfirmDialog from '../../../helpers/ui/AMSConfirmDialog/AMSConfirmDialog';
import AMSLink from '../../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../../helpers/ui/AMSTable/AMSTable';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DateFnsUtils from '@date-io/date-fns';
import { SupplierResponse } from '../../../services/api';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  checkbox: {
    marginRight: 8
  },
  searchButton: {
    marginTop: 10,
    marginLeft: 10
  }
}));

const ReportDeliveryItemsComponent = ({ location }: any) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [deliveryItems, setDeliveryItems] = useState<ReportDeliveryItemExtendedResponse[]>([]);

  const filter = cookiesService.getReportDeliveryItemsFilter();
  const [filterFromDate, setFilterFromDate] = useState<any>(
    filter.filterFromDate
      ? ignoreOffset(new Date(filter.filterFromDate))
      : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [filterToDate, setFilterToDate] = useState<any>(
    filter.filterToDate ? ignoreOffset(new Date(filter.filterToDate)) : new Date()
  );
  const [filterCompanyIds, setFilterCompanyIds] = useState<any[]>(
    filter.filterCompanyIds ? filter.filterCompanyIds : []
  );
  const [filterWarehouseIds, setFilterWarehouseIds] = useState<any[]>(
    filter.filterWarehouseIds ? filter.filterWarehouseIds : []
  );
  const [filterSupplierId, setFilterSupplierId] = useState<number | undefined>(
    filter.filterSupplierId ? filter.filterSupplierId : undefined
  );
  const [filterCategories, setFilterCategories] = useState<ItemCategoryResponse[]>(
    filter.filterCategories ? filter.filterCategories : []
  );
  const [filterItemId, setFilterItemId] = useState<number>();

  const { suppliers } = useSuppliers();
  const { companies } = useCompanies();
  const { warehouses } = useWarehouses();
  const { categories } = useItemCategories();

  const [supplier, setSupplier] = useState<SupplierResponse | null>(null);
  const [item, setItem] = useState<Lookup | null>(null);

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const loadDeliveryItems = useCallback(async () => {
    setLoading(true);
    const fromDate = isValidDate(filterFromDate) ? toStartOfDay(filterFromDate) : undefined;
    const toDate = isValidDate(filterToDate) ? toEndOfDay(filterToDate) : undefined;
    const deliveryItemsResponse = await reportsService.getDeliveryItems(
      fromDate,
      toDate,
      filterSupplierId ? filterSupplierId : undefined,
      filterCompanyIds.map((company: CompanyListResponse) => company.id).join(','),
      filterWarehouseIds.map((warehouse: WarehouseListResponse) => warehouse.id).join(','),
      filterCategories.map((itemCategory: ItemCategoryResponse) => itemCategory.id).join(','),
      filterItemId ? filterItemId : undefined
    );
    if (deliveryItemsResponse) {
      setDeliveryItems(deliveryItemsResponse.data);
    }
    setLoading(false);
  }, [
    filterFromDate,
    filterToDate,
    filterCompanyIds,
    filterWarehouseIds,
    filterSupplierId,
    filterCategories,
    filterItemId
  ]);

  useEffect(() => {
    cookiesService.setReportDeliveryItemsFilter({
      filterFromDate: filterFromDate ? toStartOfDay(filterFromDate) : undefined,
      filterToDate: filterToDate ? toEndOfDay(filterToDate) : undefined,
      filterCompanyIds: filterCompanyIds,
      filterWarehouseIds: filterWarehouseIds,
      filterSupplierId: filterSupplierId,
      filterCategories: filterCategories,
      filterItemId: filterItemId
    });
    loadDeliveryItems();
  }, [
    loadDeliveryItems,
    filterFromDate,
    filterToDate,
    filterCompanyIds,
    filterWarehouseIds,
    filterSupplierId,
    filterCategories,
    filterItemId
  ]);

  const suppliersMap = useMemo(
    () =>
      suppliers.reduce((res: any, s: SupplierListResponse) => {
        res[s.name] = s.id;
        return res;
      }, {}),
    [suppliers]
  );

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="text-align-left">
          <Container maxWidth="xl">
            <Grid container spacing={1}>
              <Grid item lg={3} md={6} sm={12} xs={12}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format="dd/MM/yy"
                  margin="dense"
                  label="От"
                  helperText={''}
                  value={filterFromDate ? filterFromDate : null}
                  onChange={(value: Date | null) => {
                    if (value) {
                      if (isValidDate(value)) {
                        setFilterFromDate(ignoreOffset(value));
                      }
                    } else {
                      setFilterFromDate(null);
                    }
                  }}
                  inputVariant="outlined"
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  maxDate={filterToDate}
                />
              </Grid>
              <Grid item lg={3} md={6} sm={12} xs={12}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format="dd/MM/yy"
                  margin="dense"
                  label="До"
                  helperText={''}
                  value={filterToDate ? filterToDate : null}
                  onChange={(value: Date | null) => {
                    if (value) {
                      if (isValidDate(value)) {
                        setFilterToDate(ignoreOffset(value));
                      }
                    } else {
                      setFilterToDate(null);
                    }
                  }}
                  inputVariant="outlined"
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  minDate={filterFromDate}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <AMSAutocomplete
                  label="Доставчик"
                  options={suppliers
                    .map((supplier: SupplierListResponse) => ({
                      id: supplier.id,
                      value: `${getExtendedSupplierId(supplier.id)} ${supplier.name}`
                    }))
                    .sort((as1, as2) => as2.id - as1.id)}
                  value={supplier ? supplier : null}
                  onChange={(selectedValue: SupplierResponse | null) => {
                    setSupplier(selectedValue);
                    if (selectedValue) {
                      setFilterSupplierId(selectedValue.id);
                    } else {
                      setFilterSupplierId(undefined);
                    }
                  }}
                  minChar={0}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={companies.map((le) => ({
                    id: le.id,
                    value: `${getExtendedCompanyId(le.id)} ${le.note}`,
                    group: le.name
                  }))}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Компания"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterCompanyIds}
                  onChange={(values) => setFilterCompanyIds(values)}
                  groupBy={(g) => (g.group ? g.group : '')}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={(filterCompanyIds.length > 0
                    ? warehouses.filter((wh: WarehouseListResponse) =>
                        filterCompanyIds
                          .map((company: CompanyListResponse) => company.id)
                          .includes(wh.companyId)
                      )
                    : warehouses
                  ).map(warehouseToLookup)}
                  sortOptions={false}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Складове"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterWarehouseIds}
                  onChange={(values) => setFilterWarehouseIds(values)}
                  groupBy={(g) => (g.group ? g.group : '')}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={categories.map((c) => ({
                    id: c.id,
                    value: `${getExtendedItemCategoryId(c.id)} ${c.name}`,
                    group: `${!!c.parentId ? `${c.parentName}` : 'Основни'}`
                  }))}
                  disableCloseOnSelect
                  getOptionLabel={(option: Lookup) => option.value}
                  renderOption={(option: Lookup, { selected }: any) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Категория"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterCategories}
                  onChange={(values) => setFilterCategories(values)}
                  groupBy={(g) => g.group}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <AMSAsyncAutocomplete
                  label="Артикул"
                  onChange={(item: ItemResponse | null) => {
                    if (item) {
                      setFilterItemId(item?.id);
                      setItem(itemToLookup(item));
                    } else {
                      setFilterItemId(undefined);
                      setItem(null);
                    }
                  }}
                  value={item}
                  minChar={3}
                />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <AMSTable
                    title="Доставки на артикули"
                    columns={[
                      {
                        title: 'Дата',
                        field: 'deliveryDate',
                        type: 'date',
                        cellStyle: { width: '8%' }
                      },
                      {
                        title: 'Доставка №',
                        field: 'deliveryId',
                        cellStyle: { width: '10%' },
                        render: (deliveryItem: ReportDeliveryItemExtendedResponse) => (
                          <AMSLink href={`/delivery?id=${deliveryItem.deliveryId}`}>
                            {getExtendedDeliveryId(deliveryItem.deliveryId)}
                          </AMSLink>
                        )
                      },
                      {
                        title: 'Доставчик',
                        field: 'supplierName',
                        render: (deliveryItem: ReportDeliveryItemExtendedResponse) =>
                          suppliersMap[deliveryItem.supplierName] ? (
                            <AMSLink
                              href={`/supplier?id=${suppliersMap[deliveryItem.supplierName]}`}
                            >
                              {deliveryItem.supplierName}
                            </AMSLink>
                          ) : (
                            deliveryItem.supplierName
                          ),
                        cellStyle: { width: '16%' }
                      },
                      {
                        title: 'Склад',
                        field: 'warehouseName',
                        cellStyle: { width: '16%' }
                      },
                      {
                        title: 'Група',
                        field: 'itemCategoryName',
                        cellStyle: { width: '20%' }
                      },
                      {
                        title: 'Артикул',
                        field: 'itemName',
                        cellStyle: { width: '20%' }
                      },
                      {
                        title: 'Ед. цена',
                        field: 'itemPrice',
                        cellStyle: { width: '6%' },
                        type: 'currency',
                        currencySetting: {
                          locale: 'bg',
                          currencyCode: 'bgn',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }
                      },
                      {
                        title: 'Срок на годност',
                        field: 'itemExpirationDate',
                        cellStyle: { width: '8%' }
                      },
                      {
                        title: 'Партида',
                        field: 'itemBatchNumber',
                        cellStyle: { width: '6%' }
                      },
                      {
                        title: 'Държава',
                        field: 'itemCountry',
                        cellStyle: { width: '6%' }
                      },
                      {
                        title: 'Количество',
                        field: 'itemQuantity',
                        cellStyle: { width: '6%' },
                        align: 'right',
                        type: 'numeric'
                      }
                    ]}
                    paging={false}
                    overflowY="scroll"
                    minBodyHeight="55vh"
                    maxBodyHeight="55vh"
                    data={deliveryItems}
                  />
                )}
              </Grid>
            </Grid>
          </Container>
          <AMSConfirmDialog
            open={openConfirmDialog}
            onConfirm={() => {
              loadDeliveryItems();
              setOpenConfirmDialog(false);
            }}
            onClose={() => setOpenConfirmDialog(false)}
            title={'Търсене без филтри!'}
            message={
              'Търсенето без филтри може да доведе до забавяне на системата. Искате да продължите?'
            }
          />
        </div>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default ReportDeliveryItemsComponent;
