import { AMSDatePicker, AMSTextField } from '../../../helpers/ui';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core';
import {
  PartnerBonusQuantityResponse,
  UpdatePartnerBonusQuantityRequest
} from '../../../services/api';
import React, { useCallback, useMemo, useState } from 'react';
import { ignoreOffset, isValidDate, toDateString } from '../../../helpers/date-helper';

import { promotionsService } from '../../../services/services';

export interface EditQuantityPromotionDialogProps {
  promotion: PartnerBonusQuantityResponse;
  open: boolean;
  onSave: (promotion: PartnerBonusQuantityResponse) => void;
  onClose: () => void;
}

const EditQuantityPromotionDialog = ({
  promotion,
  open,
  onSave,
  onClose
}: EditQuantityPromotionDialogProps) => {
  const [base, setBase] = useState<string>(promotion.baseQuantity);
  const [bonus, setBonus] = useState<string>(promotion.bonusQuantity);
  const [startDate, setStartDate] = useState<Date | null>(new Date(promotion.startDate));
  const [endDate, setEndDate] = useState<Date | null>(new Date(promotion.endDate));

  const baseValue = useMemo(() => (base && base.length > 0 ? +base : 0), [base]);
  const bonusValue = useMemo(() => (bonus && bonus.length > 0 ? +bonus : 0), [bonus]);

  const handleSave = useCallback(async () => {
    const request: UpdatePartnerBonusQuantityRequest = {
      baseQuantity: `${baseValue}`,
      bonusQuantity: `${bonusValue}`,
      startDate: toDateString(startDate!),
      endDate: toDateString(endDate!)
    };
    const resp = await promotionsService.patchPromotion(promotion.id, request);
    if (resp && resp.data) {
      onSave(resp.data);
    }
  }, [promotion, baseValue, bonusValue, startDate, endDate, onSave]);

  const hasQuantityError = useMemo(
    () => baseValue <= bonusValue && baseValue > 0 && bonusValue > 0,
    [baseValue, bonusValue]
  );

  const hasPeriodError = useMemo(
    () => (startDate && endDate && startDate > endDate) ?? false,
    [startDate, endDate]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
    >
      <DialogTitle>Количествена промоция</DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={1}>
          <Grid item lg={3} sm={12}>
            <AMSTextField
              label="Основа"
              value={base ?? ''}
              onChange={(value) => setBase(value ?? '')}
              error={hasQuantityError}
              required
            />
          </Grid>
          <Grid item lg={3} sm={12}>
            <AMSTextField
              label="Бонус"
              value={bonus ?? ''}
              onChange={(value) => setBonus(value ?? '')}
              error={hasQuantityError}
              required
            />
          </Grid>
          <Grid item lg={3} sm={12}>
            <AMSDatePicker
              label="От"
              value={startDate ? startDate : null}
              onChange={(value: Date | null) => {
                if (value) {
                  if (isValidDate(value)) {
                    setStartDate(ignoreOffset(value));
                  }
                } else {
                  setStartDate(null);
                }
              }}
              minDate={new Date()}
              maxDate={endDate ?? undefined}
              error={hasPeriodError}
            />
          </Grid>
          <Grid item lg={3} sm={12}>
            <AMSDatePicker
              label="До"
              value={endDate ? endDate : null}
              onChange={(value: Date | null) => {
                if (value) {
                  if (isValidDate(value)) {
                    setEndDate(ignoreOffset(value));
                  }
                } else {
                  setEndDate(null);
                }
              }}
              minDate={startDate ?? undefined}
              error={hasPeriodError}
            />
          </Grid>
          <Grid item lg={12} sm={12}>
            <Typography
              style={{
                visibility: hasQuantityError ? undefined : 'hidden',
                color: '#FD9728'
              }}
              align="right"
            >
              Бонусът трябва да е по-малък от основата
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">
          Отказ
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={hasPeriodError || hasQuantityError}
        >
          Добре
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditQuantityPromotionDialog;
