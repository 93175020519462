import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { UnitEnum, unitEnumLocalizations } from '../../../../../services/AMSV3Service';

import AMSAsyncAutocomplete from '../../../../../helpers/ui/AMSAsyncAutocomplete/AMSAsyncAutocomplete';
import AMSViewOnlyTextField from '../../../../../helpers/ui/AMSViewOnlyTextField/AMSViewOnlyTextField';
import { ItemResponse } from '../../../../../services/api-v3';
import { PartnerBonusQuantityResponse } from '../../../../../services/api';
import { s3ImagesUrl } from '../../../../../helpers/openapi-axios-helper';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold'
  },
  imageWrapper: {
    width: '100%',
    height: 256,
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    width: 256,
    height: 256
  },
  label: {
    padding: 6
  },
  addNewCheckboxWrapper: {
    paddingTop: 8
  }
}));

export interface AddOrderItemDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (request: any, addNew: boolean) => void;
  partnerId?: number;
  hasActiveIntegration?: boolean;
  promotions?: PartnerBonusQuantityResponse[];
}

const AddOrderItemDialog = ({
  open,
  onClose,
  onSave,
  partnerId,
  hasActiveIntegration,
  promotions
}: AddOrderItemDialogProps) => {
  const classes = useStyles();

  const [item, setItem] = useState<ItemResponse | null>(null);
  const [quantity, setQuantity] = useState<string>('1');
  const [bonusQuantity, setBonusQuantity] = useState<string>('0');
  const [promotion, setPromotion] = useState<PartnerBonusQuantityResponse | null>(null);

  const [addNew, setAddNew] = useState<boolean>(false);

  useEffect(() => {
    setItem(null);
    setQuantity('1');
  }, [open]);

  const promotionsByItemId = useMemo(
    () =>
      promotions?.reduce((res: any, promotion: PartnerBonusQuantityResponse) => {
        if (!res) {
          res = {};
        }
        res[promotion.itemId] = promotion;
        return res;
      }, {}) ?? {},
    [promotions]
  );

  useEffect(() => {
    if (item && promotionsByItemId[item?.id ?? -1] && promotions) {
      const promo = promotionsByItemId[item?.id ?? -1] ?? null;
      if (promo) {
        setPromotion(promo);
        const multiplier = Math.floor(+quantity / +promo.baseQuantity);
        setBonusQuantity(`${multiplier * +promo.bonusQuantity}`);
      } else {
        setPromotion(null);
        setBonusQuantity('0');
      }
    } else {
      setPromotion(null);
      setBonusQuantity('0');
    }
  }, [promotionsByItemId, item, setBonusQuantity, promotions, quantity]);

  useEffect(() => {
    if (promotion) {
      const multiplier = Math.floor(+quantity / +promotion.baseQuantity);
      setBonusQuantity(`${multiplier * +promotion.bonusQuantity}`);
    }
  }, [promotion, quantity]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
    >
      <DialogTitle>
        <div className={classes.title}>Добавяне на артикул</div>
      </DialogTitle>
      <DialogContent dividers={true}>
        <div className={classes.imageWrapper}>
          {!!item && (
            <img
              src={`${s3ImagesUrl}/items/${item.artNo}.png`}
              onError={(ev: any) => (ev.target.src = require('../../../../../images/fallback.png'))}
              alt="-"
              className={classes.image}
            />
          )}
          {!item && (
            <img
              src={require('../../../../../images/fallback.png')}
              alt="-"
              className={classes.image}
            />
          )}
        </div>
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <AMSAsyncAutocomplete
              label="Търсене"
              onChange={(value: ItemResponse | null) => {
                setItem(value);
              }}
              value={item}
              minChar={3}
              partnerId={hasActiveIntegration ? partnerId : undefined}
            />
          </Grid>
          <Grid item lg={8}>
            <AMSViewOnlyTextField label="Име" value={item ? item.name : ''} />
          </Grid>
          <Grid item lg={2}>
            <TextField
              label="Брой"
              margin="dense"
              variant="outlined"
              fullWidth
              value={quantity}
              type="number"
              onChange={(event) => setQuantity(event.target.value)}
            />
          </Grid>
          <Grid item lg={2}>
            <TextField
              label="Бонус"
              margin="dense"
              variant="outlined"
              fullWidth
              value={bonusQuantity}
              type="number"
              onChange={(event) => setBonusQuantity(event.target.value)}
              disabled
            />
          </Grid>
          <Grid item lg={6}>
            <AMSViewOnlyTextField
              label="Артикулен номер"
              value={item && item.artNo ? item.artNo : ''}
            />
          </Grid>
          <Grid item lg={6}>
            <AMSViewOnlyTextField
              label="Баркод"
              value={item && item.barcodeValue ? item.barcodeValue : ''}
            />
          </Grid>
          <Grid item lg={6}>
            <AMSViewOnlyTextField
              label="Мерна единица"
              value={
                item
                  ? `${unitEnumLocalizations[item.unitId as UnitEnum]}${
                      item.packageQty && item.packageQty > 1 ? ` от ${item.packageQty}` : ''
                    }`
                  : ''
              }
            />
          </Grid>
          <Grid item lg={3}>
            <AMSViewOnlyTextField
              label="Единична цена (без ДДС)"
              value={item ? item.basePrice : '0.00'}
            />
          </Grid>
          <Grid item lg={3}>
            <AMSViewOnlyTextField
              label="Обща цена (без ДДС)"
              value={item ? (+item.basePrice * +quantity).toFixed(2) : '0.00'}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.addNewCheckboxWrapper}>
          <FormControlLabel
            className={classes.label}
            control={<Checkbox checked={addNew} onChange={() => setAddNew(!addNew)} />}
            label="Добави нов след запис"
            labelPlacement="end"
          />
        </div>
        <Button onClick={onClose} color="secondary" variant="contained">
          Отказ
        </Button>
        <Button
          onClick={() => {
            if (item) {
              onSave(
                {
                  ...item,
                  itemId: item.id,
                  quantity: +quantity,
                  orderedQuantity: (+quantity).toFixed(3),
                  bonusQuantity: (+bonusQuantity).toFixed(3),
                  price: item.basePrice,
                  totalPrice: +item.basePrice * +quantity,
                  liveTotalPrice: +item.basePrice * +quantity,
                  collected: '0.000',
                  unitName: unitEnumLocalizations[item.unitId as UnitEnum]
                },
                addNew
              );
              if (addNew) {
                setItem(null);
                setQuantity('1');
              }
            }
          }}
          color="primary"
          variant="contained"
        >
          Добре
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOrderItemDialog;
