import {
  ActiveOrderItemResponse,
  ManufacturerResponse,
  SupplierListResponse
} from '../../../services/api-v3';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Slider,
  TextField,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import {
  CreateItemRequest,
  ItemCategoryResponse,
  SupplierResponse,
  UnitResponse,
  UpdateItemRequest
} from '../../../services/api';
import Lookup, { manufacturerToLookup } from '../../../models/lookup';
import React, { ChangeEvent, Fragment, useEffect, useMemo, useState } from 'react';
import { UnitEnum, unitEnumLocalizations, unitLookups } from '../../../services/AMSV3Service';
import {
  accessManagementService,
  amsV3Service,
  itemsService,
  snackbarService
} from '../../../services/services';
import {
  getExtendedItemCategoryId,
  getExtendedManufacturerId,
  getExtendedOrderId,
  getExtendedSupplierId
} from '../../../helpers/utils';

import AMSAutocomplete from '../../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import AMSButton from '../../../helpers/ui/AMSButton/AMSButton';
import AMSCurrencyAutocomplete from '../../../helpers/ui/AMSCurrencyAutocomplete/AMSCurrencyAutocomplete';
import AMSLink from '../../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../../helpers/ui/AMSTable/AMSTable';
import { AMSTextField } from '../../../helpers/ui';
import { AccessPermissions } from '../../../services/accessManagementService';
import Category from '../../../models/category';
import Currency from '../../../models/currency';
import { Line } from 'react-chartjs-2';
import OutlinedDiv from '../../../helpers/ui/OutlinedDiv';
import { findCurrency } from '../../../helpers/currency-helper';
import { useHistory } from 'react-router-dom';
import { useLookup } from '../../../helpers/hooks';
import useManufacturers from '../../../helpers/hooks/useManufacturers';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 20
  },
  label: {
    padding: 6
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

interface ItemContainerProps {
  item: any;
  onChange: (field: string, value: any) => void;
  units: UnitResponse[];
  categories: ItemCategoryResponse[];
  suppliers: SupplierListResponse[];
  reload: () => void;
  stockData?: any;
  activeOrderItems?: ActiveOrderItemResponse[];
}

const ItemContainer = (props: ItemContainerProps) => {
  const {
    item = { height: 0, width: 0, depth: 0 },
    units,
    categories,
    suppliers,
    onChange,
    reload,
    stockData,
    activeOrderItems
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState<Currency | null>(null);
  const [unit, setUnit] = useState<any | null>(null);
  const [deliveryRequestUnit, setDeliveryRequestUnit] = useState<Lookup | null>(null);
  const [category, setCategory] = useState<any>(null);
  const [supplier, setSupplier] = useState<any>(null);
  const { manufacturers } = useManufacturers();
  const [manufacturer, setManufacturer] = useLookup(null);
  const [lastDeliveryPrice, setLastDeliveryPrice] = useState<number | null>(null);

  const [sliderValue, setSliderValue] = useState<string>(
    item && item.minimumRequiredPartOfFactoryDaysOfExpirationForDelivery
      ? item.minimumRequiredPartOfFactoryDaysOfExpirationForDelivery
      : '75.00'
  );

  const [addNew, setAddNew] = useState<boolean>(false);

  const disabled = useMemo(
    () =>
      !accessManagementService.hasPermission(AccessPermissions.CAN_CREATE_ITEM) &&
      !accessManagementService.hasPermission(AccessPermissions.CAN_UPDATE_ITEM),
    []
  );

  const handleSliderChange = (event: ChangeEvent<{}>, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setSliderValue(newValue.toFixed(2));
    } else {
      setSliderValue(newValue[0].toFixed(2));
    }
  };

  const handleInputChange = (value?: string) => {
    setSliderValue(value ?? '');
  };

  const handleBlur = () => {
    if (+sliderValue < 0) {
      setSliderValue('0.00');
    } else if (+sliderValue > 100) {
      setSliderValue('100.00');
    } else {
      setSliderValue((+sliderValue).toFixed(2));
    }
  };

  useEffect(() => {
    const currency = findCurrency(item.currency);
    if (currency) {
      setCurrency(currency);
    } else {
      setCurrency(null);
    }
    const unit: any = units.find((u) => item.unitId === u.id);
    if (unit) {
      setUnit({ id: unit.id, value: unit.name });
    } else {
      setUnit(null);
    }
    const itemCategory: any = categories.find((ic) => item.itemCategoryId === ic.id);
    if (itemCategory) {
      setCategory({
        id: itemCategory.id,
        value: `${getExtendedItemCategoryId(itemCategory.id)} ${itemCategory.name}`
      });
    } else {
      setCategory(null);
    }
    const itemSupplier: SupplierListResponse | undefined = suppliers.find(
      (s) => item.supplierId === s.id
    );
    if (itemSupplier) {
      setSupplier({
        id: itemSupplier.id,
        value: `${getExtendedSupplierId(itemSupplier.id)} ${itemSupplier.name}`
      });
    } else {
      setSupplier(null);
    }
    const itemManufacturer: ManufacturerResponse | undefined = manufacturers.find(
      (m) => item.manufacturerId === m.id
    );
    if (itemManufacturer) {
      setManufacturer({
        id: itemManufacturer.id,
        value: `${getExtendedManufacturerId(itemManufacturer.id)} ${itemManufacturer.name}`
      });
    } else {
      setManufacturer(null);
    }
    if (item.deliveryRequestUnit) {
      setDeliveryRequestUnit({
        id: item.deliveryRequestUnit,
        value: unitEnumLocalizations[item.deliveryRequestUnit as UnitEnum]
      });
    }
  }, [item, units, categories, suppliers, manufacturers, setManufacturer]);

  useEffect(() => {
    if (item && item.id) {
      amsV3Service
        .getItemLastDeliveryPrice(item.id)
        .then((response: any) => setLastDeliveryPrice(response.data))
        .catch(() => setLastDeliveryPrice(null));
    }
  }, [item]);

  const isValidItem = () =>
    item &&
    item.name &&
    item.basePrice &&
    item.costPrice &&
    item.vat &&
    item.packageQty &&
    item.packageQtyDelivery &&
    item.unitId &&
    item.supplierId &&
    item.itemCategoryId &&
    item.weight &&
    currency;

  return (
    <Paper elevation={2} className={classes.paper}>
      <Grid container spacing={1}>
        <Grid item lg={6} xs={12}>
          <OutlinedDiv label="Основна информация">
            <Grid container spacing={1}>
              <Grid item container lg={3} md={4} sm={12} xs={12} alignContent="flex-start">
                <FormControlLabel
                  className={classes.label}
                  control={
                    <Checkbox
                      checked={item.isActive}
                      onChange={() => onChange('isActive', !item.isActive)}
                    />
                  }
                  disabled={!item || !item.id}
                  label="Активен"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item lg={9} md={8} sm={12} xs={12}>
                <TextField
                  label="Име"
                  variant="outlined"
                  required
                  fullWidth
                  margin="dense"
                  value={item && item.name ? item.name : ''}
                  onChange={(event) => onChange('name', event?.target.value)}
                  disabled={disabled || !item.isActive}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <TextField
                  label="Артикулен номер"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  value={item && item.artNo ? item.artNo : ''}
                  onChange={(event) => onChange('artNo', event?.target.value)}
                  disabled={disabled || !item.isActive}
                  required
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <TextField
                  label="Баркод"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  value={item && item.barcodeValue ? item.barcodeValue : ''}
                  onChange={(event) => onChange('barcodeValue', event?.target.value)}
                  disabled={disabled || !item.isActive}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <TextField
                  label="Алтернативен баркод"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  value={item && item.additionalBarcodeValue ? item.additionalBarcodeValue : ''}
                  onChange={(event) => onChange('additionalBarcodeValue', event?.target.value)}
                  disabled={disabled || !item.isActive}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <AMSAutocomplete
                  label="Категория"
                  options={categories.map((c) => {
                    return {
                      id: c.id,
                      value: `${getExtendedItemCategoryId(c.id)} ${c.name}`,
                      group: `${!!c.parentId ? `${c.parentName}` : 'Основни'}`
                    };
                  })}
                  value={category}
                  onChange={(selectedValue: Category | null) => {
                    setCategory(selectedValue);
                    if (selectedValue) {
                      onChange('itemCategoryId', selectedValue.id);
                    } else {
                      onChange('itemCategoryId', null);
                    }
                  }}
                  groupBy={(g) => g.group}
                  minChar={0}
                  limit={0}
                  disabled={disabled || !item.isActive}
                  required
                />
              </Grid>
              <Grid item lg={12}>
                <AMSAutocomplete
                  label="Доставчик"
                  options={suppliers
                    .map((supplier: SupplierListResponse) => ({
                      id: supplier.id,
                      value: `${getExtendedSupplierId(supplier.id)} ${supplier.name}`
                    }))
                    .sort((as1, as2) => as2.id - as1.id)}
                  value={supplier ? supplier : null}
                  onChange={(selectedValue: SupplierResponse | null) => {
                    setSupplier(selectedValue);
                    if (selectedValue) {
                      onChange('supplierId', selectedValue.id);
                    } else {
                      onChange('supplierId', null);
                    }
                  }}
                  minChar={0}
                  disabled={disabled || !item.isActive}
                  required
                />
              </Grid>
              <Grid item lg={12}>
                <AMSAutocomplete
                  label="Производител"
                  options={manufacturers.map(manufacturerToLookup)}
                  value={manufacturer ? manufacturer : null}
                  onChange={setManufacturer}
                  minChar={0}
                  disabled={disabled || !item.isActive}
                />
              </Grid>
              <Grid item lg={12}>
                <TextField
                  label="Допълнителна информация"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  multiline
                  rows={4}
                  rowsMax={4}
                  value={item && item.description ? item.description : ''}
                  onChange={(event) => onChange('description', event?.target.value)}
                  disabled={disabled || !item.isActive}
                />
              </Grid>
            </Grid>
          </OutlinedDiv>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Grid container spacing={1}>
            <Grid item lg={12} xs={12}>
              <OutlinedDiv label="Цени">
                <Grid container spacing={1}>
                  <Grid item lg={4} xs={12}>
                    <TextField
                      label="Базова"
                      variant="outlined"
                      required
                      fullWidth
                      margin="dense"
                      type="number"
                      value={item && item.basePrice ? item.basePrice : ''}
                      onChange={(event) => onChange('basePrice', event?.target.value)}
                      disabled={disabled || !item.isActive}
                    />
                  </Grid>
                  <Grid item lg={4} xs={12}>
                    <TextField
                      label="Последна доставна"
                      variant="outlined"
                      disabled
                      fullWidth
                      margin="dense"
                      value={lastDeliveryPrice ? lastDeliveryPrice : '---'}
                    />
                  </Grid>
                  <Grid item lg={4} xs={12}>
                    <TextField
                      label="По себестойност"
                      variant="outlined"
                      required
                      fullWidth
                      margin="dense"
                      type="number"
                      value={item && item.costPrice ? item.costPrice : ''}
                      onChange={(event) => onChange('costPrice', event?.target.value)}
                      disabled={disabled || !item.isActive}
                    />
                  </Grid>
                  <Grid item lg={8} xs={12}>
                    <AMSCurrencyAutocomplete
                      currency={currency}
                      onChange={(_, value: any) => {
                        if (value) {
                          onChange('currency', value.alpha3);
                        } else {
                          onChange('currency', null);
                        }
                      }}
                      required={true}
                      disabled={disabled || !item.isActive}
                    />
                  </Grid>
                  <Grid item lg={4} xs={12}>
                    <TextField
                      label="ДДС"
                      variant="outlined"
                      required
                      fullWidth
                      margin="dense"
                      type="number"
                      value={item && (item.vat || item.vat === 0) ? item.vat : ''}
                      onChange={(event) => onChange('vat', event?.target.value)}
                      InputProps={{
                        endAdornment: <Fragment>%</Fragment>
                      }}
                      disabled={disabled || !item.isActive}
                    />
                  </Grid>
                </Grid>
              </OutlinedDiv>
            </Grid>
            <Grid item lg={12} xs={12}>
              <OutlinedDiv label="Складови движения">
                <Grid container spacing={1}>
                  <Grid item lg={6} xs={12}>
                    <Tooltip title="Максимален срок на забрана за изписване">
                      <TextField
                        label="Период срок на годност"
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        type="number"
                        value={
                          item && item.minimumAllowedDaysToExpiration
                            ? item.minimumAllowedDaysToExpiration
                            : ''
                        }
                        onChange={(event) =>
                          onChange('minimumAllowedDaysToExpiration', event?.target.value)
                        }
                        disabled={disabled || !item.isActive}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <Tooltip title="Стандартен срок на годност според начина на производство">
                      <TextField
                        label="Стандартен срок на годност"
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        type="number"
                        value={
                          item && item.factoryDaysToExpiration ? item.factoryDaysToExpiration : ''
                        }
                        onChange={(event) =>
                          onChange('factoryDaysToExpiration', event?.target.value)
                        }
                        disabled={disabled || !item.isActive}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Tooltip title="Максимален срок на забрана заприхождаване">
                      <Grid container spacing={2}>
                        <Grid item lg={9} xs={6}>
                          <Slider
                            aria-label="Максимален срок на забрана за заприхождаване"
                            value={+sliderValue}
                            onChange={handleSliderChange}
                            aria-labelledby="input-slider"
                            step={5}
                            marks
                            min={0}
                            max={100}
                            disabled={disabled || !item.isActive}
                            style={{ marginTop: 10 }}
                          />
                        </Grid>
                        <Grid item lg={3} xs={6}>
                          <AMSTextField
                            label="%"
                            value={`${sliderValue}`}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            inputProps={{
                              step: 10,
                              min: 0,
                              max: 100,
                              type: 'number',
                              'aria-labelledby': 'input-slider'
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Tooltip>
                  </Grid>
                </Grid>
              </OutlinedDiv>
            </Grid>
            <Grid item lg={4} xs={12}>
              <OutlinedDiv label="Заявки">
                <Grid container spacing={1}>
                  <Grid item lg={12} xs={12}>
                    <AMSAutocomplete
                      label="Доставна МЕ"
                      options={unitLookups}
                      value={deliveryRequestUnit}
                      onChange={(value) => {
                        setDeliveryRequestUnit(value);
                        if (value) {
                          onChange('deliveryRequestUnit', value.id);
                        } else {
                          onChange('deliveryRequestUnit', null);
                        }
                      }}
                      required={true}
                      minChar={0}
                      disabled={disabled || !item.isActive}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      label="Бройки в МЕ"
                      variant="outlined"
                      required
                      fullWidth
                      margin="dense"
                      placeholder="брой"
                      value={
                        item && (item.packageQty || item.packageQty === 0) ? item.packageQty : ''
                      }
                      onChange={(event) => onChange('packageQty', event?.target.value)}
                      disabled={disabled || !item.isActive}
                    />
                  </Grid>
                </Grid>
              </OutlinedDiv>
            </Grid>
            <Grid item lg={8} xs={12}>
              <OutlinedDiv label="Поръчки">
                <Grid container spacing={1}>
                  <Grid item lg={6} xs={12}>
                    <AMSAutocomplete
                      label="Мерна единица"
                      options={units
                        .sort((u1: UnitResponse, u2: UnitResponse) => u2.id - u1.id)
                        .map((c) => ({
                          id: c.id,
                          value: c.name
                        }))}
                      value={unit}
                      onChange={(value) => {
                        setUnit(unit);
                        if (value) {
                          onChange('unitId', value.id);
                        } else {
                          onChange('unitId', null);
                        }
                      }}
                      required={true}
                      minChar={0}
                      disabled={disabled || !item.isActive}
                    />
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <TextField
                      label="В"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      type="number"
                      value={item && (item.height || item.height === 0) ? item.height : ''}
                      onChange={(event) => onChange('height', event?.target.value)}
                      InputProps={{
                        endAdornment: <Fragment>см</Fragment>
                      }}
                      disabled={disabled || !item.isActive}
                    />
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <TextField
                      label="Ш"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      type="number"
                      value={item && (item.width || item.width === 0) ? item.width : ''}
                      onChange={(event) => onChange('width', event?.target.value)}
                      InputProps={{
                        endAdornment: <Fragment>см</Fragment>
                      }}
                      disabled={disabled || !item.isActive}
                    />
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <TextField
                      label="Бройки в МЕ"
                      variant="outlined"
                      required
                      fullWidth
                      margin="dense"
                      placeholder="брой"
                      value={item && item.packageQtyDelivery ? item.packageQtyDelivery : ''}
                      onChange={(event) => onChange('packageQtyDelivery', event?.target.value)}
                      disabled={disabled || !item.isActive}
                    />
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <TextField
                      label="Тегло"
                      variant="outlined"
                      required
                      fullWidth
                      margin="dense"
                      type="number"
                      value={item && item.weight ? item.weight : ''}
                      onChange={(event) => onChange('weight', event?.target.value)}
                      InputProps={{
                        endAdornment: <Fragment>кг</Fragment>
                      }}
                      disabled={disabled || !item.isActive}
                    />
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <TextField
                      label="Д"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      type="number"
                      value={item && (item.depth || item.depth === 0) ? item.depth : ''}
                      onChange={(event) => onChange('depth', event?.target.value)}
                      InputProps={{
                        endAdornment: <Fragment>см</Fragment>
                      }}
                      disabled={disabled || !item.isActive}
                    />
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <TextField
                      label="Обем"
                      variant="outlined"
                      disabled
                      fullWidth
                      margin="dense"
                      value={
                        item &&
                        (item.height || item.height === 0) &&
                        (item.width || item.width === 0) &&
                        (item.depth || item.depth === 0)
                          ? ((item.height * item.width * item.depth) / 1000000).toFixed(4)
                          : ''
                      }
                      InputProps={{
                        endAdornment: (
                          <Fragment>
                            м<sup>3</sup>
                          </Fragment>
                        )
                      }}
                    />
                  </Grid>
                </Grid>
              </OutlinedDiv>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={12} xs={12}>
          <Grid container>
            <Grid item lg={12} xs={12}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormControlLabel
                  className={classes.label}
                  control={
                    <Checkbox
                      checked={item && item.deliveryOnDemand}
                      onChange={() => onChange('deliveryOnDemand', !item.deliveryOnDemand)}
                    />
                  }
                  label="Доставка при поръчка"
                  labelPlacement="end"
                  disabled={disabled || !item.isActive}
                />
                <FormControlLabel
                  className={classes.label}
                  control={
                    <Checkbox
                      checked={item && item.maintainStock}
                      onChange={() => onChange('maintainStock', !item.maintainStock)}
                    />
                  }
                  label="Поддръжка на наличност"
                  labelPlacement="end"
                  disabled={disabled || !item.isActive}
                />
                <FormControlLabel
                  className={classes.label}
                  control={
                    <Checkbox
                      checked={item && item.noStorage}
                      onChange={() => onChange('noStorage', !item.noStorage)}
                    />
                  }
                  label="Без склад"
                  labelPlacement="end"
                  disabled={disabled || !item.isActive}
                />
                <FormControlLabel
                  className={classes.label}
                  control={
                    <Checkbox
                      checked={item && item.isOwnedByUs}
                      onChange={() => onChange('isOwnedByUs', !item.isOwnedByUs)}
                    />
                  }
                  label="Наша собственост"
                  labelPlacement="end"
                  disabled={disabled || !item.isActive}
                />
                <FormControlLabel
                  className={classes.label}
                  control={
                    <Checkbox
                      checked={item && item.promo}
                      onChange={() => onChange('promo', !item.promo)}
                    />
                  }
                  label="Промо"
                  labelPlacement="end"
                  disabled={disabled || !item.isActive}
                />
                <FormControlLabel
                  className={classes.label}
                  control={
                    <Checkbox
                      checked={item && item.mlm}
                      onChange={() => onChange('mlm', !item.mlm)}
                    />
                  }
                  label="MLM"
                  labelPlacement="end"
                  disabled={disabled || !item.isActive}
                />
                <FormControlLabel
                  className={classes.label}
                  control={
                    <Checkbox
                      checked={item && item.stockSelectable}
                      onChange={() => onChange('stockSelectable', !item.stockSelectable)}
                    />
                  }
                  label="Задължителна партида"
                  labelPlacement="end"
                  disabled={disabled || !item.isActive}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container>
            {!disabled && (
              <Grid lg={10} xs={10} container justify="flex-end">
                <FormControlLabel
                  className={classes.label}
                  control={<Checkbox checked={addNew} onChange={() => setAddNew(!addNew)} />}
                  label="Добави нов след запис"
                  labelPlacement="end"
                />
              </Grid>
            )}
            {!disabled && (
              <Grid lg={2} xs={2} container justify="flex-end">
                <AMSButton
                  text={addNew ? 'Запиши и добави нов' : 'Запиши'}
                  color="primary"
                  variant="contained"
                  onClick={async () => {
                    setLoading(true);
                    let resp = null;
                    if (item && item.id) {
                      if (item.isActive) {
                        const request: UpdateItemRequest = {
                          ...item,
                          minimumRequiredPartOfFactoryDaysOfExpirationForDelivery: sliderValue
                        };
                        resp = await itemsService.updateItem(request);
                      } else {
                        const request: UpdateItemRequest = {
                          id: item.id,
                          supplierId: item.supplierId,
                          itemCategoryId: item.itemCategoryId,
                          minimumRequiredPartOfFactoryDaysOfExpirationForDelivery: sliderValue,
                          isActive: false
                        };
                        resp = await itemsService.updateItem(request);
                      }
                    } else {
                      const request: CreateItemRequest = {
                        ...item,
                        minimumRequiredPartOfFactoryDaysOfExpirationForDelivery: sliderValue
                      };
                      resp = await itemsService.createItem(request);
                    }
                    if (resp) {
                      snackbarService.setSnackbarOpen(true);
                      if (addNew) {
                        reload();
                      } else {
                        history.replace(`item?${resp.data.id ? `id=${resp.data.id}` : ''}&tabId=0`);
                      }
                    }
                    setLoading(false);
                  }}
                  disabled={loading || !isValidItem()}
                  loading={loading}
                />
              </Grid>
            )}
          </Grid>
          <Grid container>
            <Grid item lg={12} xs={12}>
              <Line data={stockData} height={50} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={12} xs={12}>
              <AMSTable
                data={activeOrderItems}
                paging={false}
                components={{
                  Container: (props: any) => (
                    <Paper {...props} elevation={0} className="padding-0" />
                  )
                }}
                columns={[
                  {
                    title: 'Поръчка №',
                    field: 'orderId',
                    render: (orderItem: ActiveOrderItemResponse) => (
                      <AMSLink href={`/order?id=${orderItem.orderId}`}>
                        {getExtendedOrderId(orderItem.orderId)}
                      </AMSLink>
                    ),
                    cellStyle: { width: '25%' }
                  },
                  {
                    title: 'Дата за доставка',
                    field: 'orderDeliveryDate',
                    type: 'datetime',
                    cellStyle: { width: '25%' }
                  },
                  {
                    title: 'Количество',
                    field: 'quantity',
                    cellStyle: { width: '10%' }
                  },
                  {
                    title: 'Събрано',
                    field: 'collected',
                    cellStyle: { width: '10%' }
                  },
                  {
                    title: 'Цена',
                    field: 'price',
                    cellStyle: { width: '20%' },
                    align: 'right',
                    type: 'currency',
                    currencySetting: {
                      locale: 'bg',
                      currencyCode: 'bgn',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }
                  }
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ItemContainer;
