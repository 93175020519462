import React, { useCallback, useState } from 'react';
import { promotionsService, snackbarService } from '../../../../services/services';
import { useFlag, usePermissions, usePromotions } from '../../../../helpers/hooks';

import AMSLink from '../../../../helpers/ui/AMSLink/AMSLink';
import { AMSTable } from '../../../../helpers/ui';
import { AccessPermissions } from '../../../../services/accessManagementService';
import EditQuantityPromotionDialog from '../../../Promotions/EditQuantityPromotionDialog/EditQuantityPromotionDialog';
import { PartnerBonusQuantityResponse } from '../../../../services/api';
import QuantityPromotionDialog from '../../../Promotions/QuantityPromotionDialog/QuantityPromotionDialog';

interface PartnerQuantityPromotionsComponentProps {
  partnerId: number;
}

const PartnerQuantityPromotionsComponent = ({
  partnerId
}: PartnerQuantityPromotionsComponentProps) => {
  const { promotions, setPromotions } = usePromotions(undefined, [partnerId]);
  const [openCreateDialog, setOpenCreateDialog] = useFlag(false);
  const [openEditDialog, setOpenEditDialog] = useFlag(false);
  const [selectedPromotion, setSelectedPromotion] = useState<PartnerBonusQuantityResponse | null>(
    null
  );

  const [canCreate, canUpdate, canDelete] = usePermissions([
    AccessPermissions.CAN_CREATE_PROMOTION,
    AccessPermissions.CAN_UPDATE_PROMOTION,
    AccessPermissions.CAN_DELETE_PROMOTION
  ]);

  const handleAdd = useCallback(() => {
    setOpenCreateDialog(true);
  }, [setOpenCreateDialog]);

  const handleOnSave = useCallback(
    (newPromotions: PartnerBonusQuantityResponse[]) => {
      setPromotions([...promotions, ...newPromotions]);
      setOpenCreateDialog(false);
      snackbarService.setSnackbarOpen(true);
    },
    [promotions, setPromotions, setOpenCreateDialog]
  );

  const handleEdit = useCallback(
    (promotion: PartnerBonusQuantityResponse) => {
      setSelectedPromotion(promotion);
      setOpenEditDialog(true);
    },
    [setOpenEditDialog]
  );

  const handleUpdate = useCallback(
    (promotion: PartnerBonusQuantityResponse) => {
      setPromotions(promotions.map((p) => (p.id === promotion.id ? promotion : p)));
      setOpenEditDialog(false);
      setSelectedPromotion(null);
      snackbarService.setSnackbarOpen(true);
    },
    [promotions, setPromotions, setOpenEditDialog]
  );

  const handleDelete = useCallback(
    async (promotion: PartnerBonusQuantityResponse) => {
      await promotionsService.deletePromotion(promotion.id);
      setPromotions(promotions.filter((p) => p.id !== promotion.id));
      snackbarService.setSnackbarOpen(true);
    },
    [promotions, setPromotions]
  );

  return (
    <>
      <AMSTable
        columns={[
          {
            title: 'Артикул',
            field: 'itemName',
            cellStyle: { width: '60%' },
            render: (rowData: any) => (
              <AMSLink href={`/item?id=${rowData.itemId}`}>{rowData.itemName}</AMSLink>
            )
          },
          {
            title: 'Основа',
            field: 'baseQuantity',
            cellStyle: { width: '10%' }
          },
          {
            title: 'Бонус',
            field: 'bonusQuantity',
            cellStyle: { width: '10%' }
          },
          {
            title: 'От',
            field: 'startDate',
            type: 'date',
            cellStyle: { width: '10%' }
          },
          {
            title: 'До',
            field: 'endDate',
            type: 'date',
            cellStyle: { width: '10%' }
          }
        ]}
        data={promotions}
        onAdd={canCreate ? handleAdd : undefined}
        onEdit={canUpdate ? handleEdit : undefined}
        onDelete={canDelete ? handleDelete : undefined}
      />
      <QuantityPromotionDialog
        partnerId={partnerId}
        open={openCreateDialog}
        onSave={handleOnSave}
        onClose={() => setOpenCreateDialog(false)}
      />
      {selectedPromotion && (
        <EditQuantityPromotionDialog
          promotion={selectedPromotion}
          open={openEditDialog}
          onSave={handleUpdate}
          onClose={() => setOpenEditDialog(false)}
        />
      )}
    </>
  );
};
export default PartnerQuantityPromotionsComponent;
