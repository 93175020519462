import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { PartnerBonusQuantityResponse } from '../../services/api';
import { promotionsService } from '../../services/services';

export interface IPromotions<T> extends IAsyncType<T> {
  promotions: T;
  setPromotions: Dispatch<SetStateAction<T>>;
}

const usePromotions = (
  itemId?: number,
  partnerIds?: number[],
  fromStartDate?: Date,
  toStartDate?: Date,
  fromEndDate?: Date,
  toEndDate?: Date
): IPromotions<PartnerBonusQuantityResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    PartnerBonusQuantityResponse[]
  >(
    promotionsService.getPromotions,
    [],
    [itemId, partnerIds, fromStartDate, toStartDate, fromEndDate, toEndDate]
  );
  return {
    promotions: data,
    setPromotions: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default usePromotions;
