import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { ChangeEvent, Fragment, useState } from 'react';
import Currency from '../../../models/currency';
import { currenciesData } from '../../currency-helper';

interface Props {
  currency: Currency | null;
  disabled?: boolean;
  required?: boolean;
  onChange: (event: ChangeEvent<{}>, currency: Currency | null) => void;
}

const AMSCurrencyAutocomplete = ({
  currency,
  disabled = false,
  required = false,
  onChange
}: Props) => {
  const [currencies] = useState<Currency[]>(currenciesData);

  return (
    <Autocomplete
      options={currencies}
      renderOption={(option: any) => (
        <Fragment key={option.alpha3}>{`(${option.alpha3}) ${option.name}`}</Fragment>
      )}
      getOptionLabel={(option: any) => `(${option.alpha3}) ${option.name}`}
      getOptionSelected={(option, value) => option.alpha3 === value.alpha3}
      fullWidth
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Валута"
          margin="dense"
          variant="outlined"
          disabled={disabled}
          required={required}
        />
      )}
      onChange={onChange}
      value={currency}
    />
  );
};

export default AMSCurrencyAutocomplete;
