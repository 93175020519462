import { AccessPermissions } from '../../../services/accessManagementService';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ActOfAcceptanceComponent from '../../../components/ActOfAcceptanceComponent/ActOfAcceptanceComponent';
import ActsOfAcceptanceComponent from '../../../components/ActsOfAcceptanceComponent/ActsOfAcceptanceComponent';
import AddIcon from '@material-ui/icons/Add';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AvailabilityComponent from '../../../components/Reports/AvailabilityComponent/AvailabilityComponent';
import B2BOrdersComponent from '../../../components/OrdersComponent/B2BOrdersComponent/B2BOrdersComponent';
import B2COrdersComponent from '../../../components/OrdersComponent/B2COrdersComponent/B2COrdersComponent';
import { Badge } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import BusinessIcon from '@material-ui/icons/Business';
import Categories from '../../../components/Categories/Categories';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import ClientInfoComponent from '../../../components/PartnerComponent/Tabs/PartnerClientsComponent/ClientInfoComponent';
import ClientsComponent from '../../../components/ClientsComponent/ClientsComponent';
import CompanyCategories from '../../../components/CompanyCategories/CompanyCategories';
import Deliveries from '../../../components/DeliveriesComponent/DeliveriesComponent';
import DeliveryComponent from '../../../components/DeliveryComponent/DeliveryComponent';
import DeliveryRequestComponent from '../../../components/DeliveryRequestComponent/DeliveryRequestComponent';
import DeliveryRequestsComponent from '../../../components/DeliveryRequestsComponent/DeliveryRequestsComponent';
import FinanceDashboardComponent from '../../../components/FinanceDashboardComponent/FinanceDashboardComponent';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ImportItemsComponent from '../../../components/Settings/ImportItemsComponent/ImportItemsComponent';
import InternalTransfersComponent from '../../../components/OrdersComponent/InternalTransfersComponent/InternalTransfersComponent';
import InvoiceComponent from '../../../components/InvoiceComponent/InvoiceComponent';
import InvoicesComponent from '../../../components/InvoicesComponent/InvoicesComponent';
import ItemDeliverySubscriptionComponent from '../../../components/Settings/ItemDeliverySubscriptionComponent/ItemDeliverySubscriptionComponent';
import ItemDeliverySubscriptionsComponent from '../../../components/Settings/ItemDeliverySubscriptionsComponent/ItemDeliverySubscriptionsComponent';
import ItemProfileComponent from '../../../components/ItemProfileComponent/ItemProfileComponent';
import Items from '../../../components/Items/Items';
import LegalEntitiesComponent from '../../../components/LegalEntitiesComponent/LegalEntitiesComponent';
import LegalEntityComponent from '../../../components/LegalEntityComponent/LegalEntityComponent';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import OrderComponent from '../../../components/OrderComponent/OrderComponent';
import OrdersComponent from '../../../components/OrdersComponent/OrdersComponent';
import PartnerComponent from '../../../components/PartnerComponent/PartnerComponent';
import PartnersComponent from '../../../components/PartnersComponent/PartnersComponent';
import PricingPoliciesComponent from '../../../components/PricingPoliciesComponent/PricingPoliciesComponent';
import PricingPolicyComponent from '../../../components/PricingPolicyComponent/PricingPolicyComponent';
import Promotions from '../../../components/Promotions/Promotions';
import React from 'react';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ReportDeliveryDocumentsComponent from '../../../components/Reports/ReportDeliveryDocumentsComponent/ReportDeliveryDocumentsComponent';
import ReportDeliveryItemsComponent from '../../../components/Reports/ReportDeliveryItemsComponent/ReportDeliveryItemsComponent';
import ReportDetailedTurnoverComponent from '../../../components/Reports/ReportDetailedTurnoverComponent/ReportDetailedTurnoverComponent';
import ReportItemMovementsComponent from '../../../components/Reports/ReportItemMovementsComponent/ReportItemMovementsComponent';
import ReportOrderBalanceComponent from '../../../components/Reports/ReportOrdersBalanceComponent/ReportOrdersBalanceComponent';
import ReportOrderItemsComponent from '../../../components/Reports/ReportOrderItemsComponent/ReportOrderItemsComponent';
import ReportOrderQuantitiesComponent from '../../../components/Reports/ReportOrderQuantitiesComponent/ReportOrderQuantitiesComponent';
import ReportPricingComponent from '../../../components/Reports/ReportPricingComponent/ReportPricingComponent';
import ReportStockComponent from '../../../components/Reports/ReportStockComponent/ReportStockComponent';
import ReportsComponent from '../../../components/Reports/ReportsComponent';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import StockTakingReportComponent from '../../../components/StockTaking/StockTakingReportComponent/StockTakingReportComponent';
import StockTakingReportsComponent from '../../../components/StockTaking/StockTakingReportsComponent/StockTakingReportsComponent';
import StocktakingComponent from '../../../components/StockTaking/Stocktaking/StocktakingComponent';
import StocktakingsComponent from '../../../components/StockTaking/Stocktakings/StocktakingsComponent';
import StoreIcon from '@material-ui/icons/Store';
import SupplierComponent from '../../../components/SupplierComponent/SupplierComponent';
import SupplierReserveSubscriptionComponent from '../../../components/Settings/SupplierReserveSubscriptionComponent/SupplierReserveSubscriptionComponent';
import SupplierReserveSubscriptionsComponent from '../../../components/Settings/SupplierReserveSubscriptionsComponent/SupplierReserveSubscriptionsComponent';
import SuppliersComponent from '../../../components/SuppliersComponent/SuppliersComponent';
import TransactionsComponent from '../../../components/TransactionsComponent/TransactionsComponent';
import { isB2B } from '../../../helpers/openapi-axios-helper';

// import HailIcon from '@material-ui/icons/HailIcon';

// import InternalTransfersComponent from '../../../components/OrdersComponent/InternalTransfersComponent/InternalTransfersComponent';

export interface MenuItem {
  label?: string;
  component: any;
  route: string;
  icon: any;
  permissions: AccessPermissions[];
  children?: MenuItem[];
}

export const mainMenuItems: MenuItem[] = [
  {
    label: 'Нов артикул',
    component: ItemProfileComponent,
    route: '/item',
    icon: (
      <Badge
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        badgeContent={<AddIcon className="small-icon-style" />}
      >
        <ShoppingBasketIcon className="icon-style" />
      </Badge>
    ),
    permissions: [AccessPermissions.CAN_CREATE_ITEM]
  },
  {
    label: 'Нов контрагент',
    component: PartnerComponent,
    route: '/partner',
    icon: (
      <Badge
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        badgeContent={<AddIcon className="small-icon-style" />}
      >
        <LocalBarIcon className="icon-style" />
      </Badge>
    ),
    permissions: [AccessPermissions.CAN_CREATE_PARTNER]
  },
  {
    label: 'Нов доставчик',
    component: SupplierComponent,
    route: '/supplier',
    icon: (
      <Badge
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        badgeContent={<AddIcon className="small-icon-style" />}
      >
        <LocalShippingIcon className="icon-style" />
      </Badge>
    ),
    permissions: [AccessPermissions.CAN_CREATE_SUPPLIER]
  },
  {
    label: 'Нова доставка',
    component: DeliveryComponent,
    route: '/delivery',
    icon: (
      <Badge
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        badgeContent={<AddIcon className="small-icon-style" />}
      >
        <ListAltIcon className="icon-style" />
      </Badge>
    ),
    permissions: [AccessPermissions.CAN_CREATE_DELIVERY]
  },
  {
    label: 'Нова поръчка',
    component: OrderComponent,
    route: '/order',
    icon: (
      <Badge
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        badgeContent={<AddIcon className="small-icon-style" />}
      >
        <ShoppingCartIcon className="icon-style" />
      </Badge>
    ),
    permissions: [AccessPermissions.CAN_CREATE_ORDER]
  },
  {
    label: 'Нова фактура',
    component: InvoiceComponent,
    route: '/invoice',
    icon: (
      <Badge
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        badgeContent={<AddIcon className="small-icon-style" />}
      >
        <ReceiptIcon className="icon-style" />
      </Badge>
    ),
    permissions: [AccessPermissions.CAN_CREATE_INVOICE]
  }
];

export const secondaryMenuItems: MenuItem[] = [
  {
    label: 'Артикули',
    component: Items,
    route: '/items',
    icon: <ShoppingBasketIcon className="icon-style" />,
    permissions: [AccessPermissions.CAN_READ_ITEMS]
  },
  {
    label: 'Промоции',
    component: Promotions,
    route: '/promotions',
    icon: <LoyaltyIcon className="icon-style" />,
    permissions: [AccessPermissions.CAN_READ_PROMOTIONS]
  },
  {
    label: isB2B ? 'Поръчки B2B' : 'Поръчки B2C',
    component: isB2B ? B2BOrdersComponent : B2COrdersComponent,
    route: isB2B ? '/b2b-orders' : '/b2c-orders',
    icon: (
      <Badge
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        badgeContent={<LocalBarIcon className="small-icon-style" />}
      >
        <LocalShippingIcon className="icon-style" style={{ transform: 'scaleX(-1)' }} />
      </Badge>
    ),
    permissions: [AccessPermissions.CAN_READ_ORDERS]
  },
  {
    label: 'Вътрешни трансфери',
    component: InternalTransfersComponent,
    route: '/internal-transfers',
    icon: (
      <Badge
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        badgeContent={<HomeWorkIcon className="small-icon-style" />}
      >
        <LocalShippingIcon className="icon-style" style={{ transform: 'scaleX(-1)' }} />
      </Badge>
    ),
    permissions: [AccessPermissions.CAN_READ_ORDERS]
  },
  {
    label: 'Протоколи',
    component: ActsOfAcceptanceComponent,
    route: '/acts-of-acceptance',
    icon: <AssignmentIndIcon className="icon-style" />,
    permissions: [AccessPermissions.CAN_READ_ORDERS]
  },
  {
    label: 'Фактури',
    component: InvoicesComponent,
    route: '/invoices',
    icon: <ReceiptIcon className="icon-style" />,
    permissions: [AccessPermissions.CAN_READ_INVOICES]
  },
  {
    label: 'Трансакции',
    component: TransactionsComponent,
    route: '/transactions',
    icon: <LocalAtmIcon className="icon-style" />,
    permissions: [AccessPermissions.CAN_VIEW_FINANCE_DASHBOARD]
  },
  {
    label: 'Доставчици',
    component: SuppliersComponent,
    route: '/suppliers',
    icon: <LocalShippingIcon className="icon-style" />,
    permissions: [AccessPermissions.CAN_READ_SUPPLIERS]
  },
  {
    label: 'Заявки',
    component: DeliveryRequestsComponent,
    route: '/delivery-requests',
    icon: (
      <Badge
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        badgeContent={<ListAltIcon className="small-icon-style" />}
      >
        <LocalShippingIcon className="icon-style" />
      </Badge>
    ),
    permissions: [AccessPermissions.CAN_READ_DELIVERIES]
  },
  {
    label: 'Доставки',
    component: Deliveries,
    route: '/deliveries',
    icon: <ListAltIcon className="icon-style" />,
    permissions: [AccessPermissions.CAN_READ_DELIVERIES]
  },
  {
    label: 'Документи по доставки',
    component: ReportDeliveryDocumentsComponent,
    route: '/reports-delivery-invoice',
    icon: <ListAltIcon className="icon-style" />,
    permissions: [AccessPermissions.CAN_READ_REPORTS_DELIVERY_INVOICE]
  },
  {
    label: 'Юридически лица',
    component: LegalEntitiesComponent,
    route: '/legal-entities',
    icon: <BusinessIcon className="icon-style" />,
    permissions: [AccessPermissions.CAN_READ_LEGAL_ENTITIES]
  },
  {
    label: 'Контрагенти',
    component: PartnersComponent,
    route: '/partners',
    icon: <LocalBarIcon className="icon-style" />,
    permissions: [AccessPermissions.CAN_READ_PARTNERS]
  },
  {
    label: 'Клиенти',
    component: ClientsComponent,
    route: '/clients',
    icon: <AccountCircleIcon className="icon-style" />,
    permissions: [AccessPermissions.CAN_READ_PARTNERS]
  },
  {
    label: 'Ценови политики',
    component: PricingPoliciesComponent,
    route: '/pricing-policies',
    icon: <LocalOfferIcon className="icon-style" />,
    permissions: [AccessPermissions.CAN_READ_PRICING_POLICIES]
  },
  {
    label: 'Финанси',
    component: FinanceDashboardComponent,
    route: '/finance-dashboard',
    icon: <AccountBalanceOutlinedIcon className="icon-style" />,
    permissions: [AccessPermissions.CAN_VIEW_FINANCE_DASHBOARD]
  },
  {
    label: 'Ревизии',
    component: StocktakingsComponent,
    route: '/stocktakings',
    icon: <AssignmentTurnedInIcon className="icon-style" />,
    permissions: [AccessPermissions.CAN_READ_STOCKTAKING_REPORTS]
  },
  {
    label: 'Протоколи за ревизия',
    component: StockTakingReportsComponent,
    route: '/stock-taking-reports',
    icon: <AssignmentIcon className="icon-style" />,
    permissions: [AccessPermissions.CAN_READ_STOCKTAKING_REPORTS]
  },
  {
    label: 'Критични количества',
    component: ItemDeliverySubscriptionsComponent,
    route: '/item-delivery-subscriptions',
    icon: <GroupWorkIcon className="icon-style" />,
    permissions: [AccessPermissions.CAN_MANAGE_ITEM_DELIVERY_SUBSCRIPTION]
  },
  {
    label: 'Складов резерв',
    component: SupplierReserveSubscriptionsComponent,
    route: '/supplier-reserve-subscriptions',
    icon: <GroupWorkIcon className="icon-style" />,
    permissions: [AccessPermissions.CAN_MANAGE_SUPPLIER_RESERVE_SUBSCRIPTION]
  },
  {
    label: 'Справки',
    route: '/reports',
    icon: <BarChartIcon className="icon-style" />,
    component: ReportsComponent,
    children: [
      {
        label: 'Наличности',
        component: ReportStockComponent,
        route: '/reports-stock',
        icon: <HomeWorkIcon className="icon-style" />,
        permissions: [AccessPermissions.CAN_READ_REPORTS_STOCK]
      },
      {
        label: 'Наличности (нов)',
        component: AvailabilityComponent,
        route: '/availabilities',
        icon: <HomeWorkIcon className="icon-style" />,
        permissions: [AccessPermissions.CAN_READ_REPORTS_STOCK]
      },
      {
        label: 'Поръчки',
        component: ReportOrderQuantitiesComponent,
        route: '/reports-order-quantities',
        icon: <ShoppingCartIcon className="icon-style" />,
        permissions: [AccessPermissions.CAN_READ_REPORTS_ORDER_QUANTITIES]
      },
      {
        label: 'Доставки',
        component: ReportDeliveryItemsComponent,
        route: '/reports-delivery-item',
        icon: <ListAltIcon className="icon-style" />,
        permissions: [AccessPermissions.CAN_READ_REPORTS_DELIVERY_ITEM]
      },
      {
        label: 'Приходи',
        component: ReportOrderBalanceComponent,
        route: '/reports-order-balance',
        icon: (
          <Badge
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            badgeContent={<MonetizationOnIcon className="small-icon-style" />}
          >
            <AccountBalanceOutlinedIcon className="icon-style" />
          </Badge>
        ),
        permissions: [AccessPermissions.CAN_READ_REPORTS_ORDER_BALANCE]
      },
      {
        label: 'Продажби',
        component: ReportOrderItemsComponent,
        route: '/reports-order-item',
        icon: (
          <Badge
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            badgeContent={<MonetizationOnIcon className="small-icon-style" />}
          >
            <ShoppingCartIcon className="icon-style" />
          </Badge>
        ),
        permissions: [AccessPermissions.CAN_READ_REPORTS_ORDER_ITEM]
      },
      {
        label: 'Печалба',
        component: ReportDetailedTurnoverComponent,
        route: '/reports-detailed-turnover',
        icon: <MonetizationOnIcon className="icon-style" />,
        permissions: [AccessPermissions.CAN_READ_REPORTS_ORDER_ITEM]
      },
      {
        label: 'Цени',
        component: ReportPricingComponent,
        route: '/reports-pricing-policy',
        icon: (
          <Badge
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            badgeContent={<MonetizationOnIcon className="small-icon-style" />}
          >
            <StoreIcon className="icon-style" />
          </Badge>
        ),
        permissions: [AccessPermissions.CAN_READ_REPORTS_PRICING_POLICY]
      },
      {
        label: 'Движения',
        component: ReportItemMovementsComponent,
        route: '/item-movements-balance',
        icon: <LocalShippingIcon className="icon-style" />,
        permissions: [AccessPermissions.CAN_READ_REPORTS_STOCK]
      }
    ],
    permissions: [
      AccessPermissions.CAN_READ_REPORTS,
      AccessPermissions.CAN_READ_REPORTS_DELIVERY_INVOICE,
      AccessPermissions.CAN_READ_REPORTS_DELIVERY_ITEM,
      AccessPermissions.CAN_READ_INVOICES,
      AccessPermissions.CAN_READ_REPORTS_ORDER_BALANCE,
      AccessPermissions.CAN_READ_REPORTS_ORDER_ITEM,
      AccessPermissions.CAN_READ_REPORTS_ORDER_QUANTITIES,
      AccessPermissions.CAN_READ_REPORTS_PRICING_POLICY,
      AccessPermissions.CAN_READ_REPORTS_STOCK,
      AccessPermissions.CAN_READ_REPORTS_WAREHOUSE_RESERVE
    ]
  },
  {
    label: 'Артикулни групи',
    component: Categories,
    route: '/item-categories',
    icon: <GroupWorkIcon className="icon-style" />,
    permissions: [AccessPermissions.CAN_READ_ITEM_CATEGORIES]
  },
  {
    label: 'Категории',
    component: CompanyCategories,
    route: '/company-categories',
    icon: <CategoryOutlinedIcon className="icon-style" />,
    permissions: [AccessPermissions.CAN_READ_COMPANY_CATEGORIES]
  },
  {
    label: 'Поръчки (стар)',
    component: OrdersComponent,
    route: '/orders',
    icon: <ShoppingCartIcon className="icon-style" />,
    permissions: [AccessPermissions.CAN_READ_ORDERS]
  },
  {
    label: 'Импорт на артикули',
    component: ImportItemsComponent,
    route: '/import-items',
    icon: <ImportExportIcon className="icon-style" />,
    permissions: [AccessPermissions.CAN_READ_ITEMS]
  }
];

export const hiddenMenuItems: MenuItem[] = [
  {
    component: ActOfAcceptanceComponent,
    route: '/act-of-acceptance',
    icon: null,
    permissions: [AccessPermissions.CAN_READ_ORDER]
  },
  {
    component: PricingPolicyComponent,
    route: '/pricing-policy',
    icon: null,
    permissions: [
      AccessPermissions.CAN_READ_PRICING_POLICY,
      AccessPermissions.CAN_CREATE_PRICING_POLICY,
      AccessPermissions.CAN_UPDATE_PRICING_POLICY
    ]
  },
  {
    component: LegalEntityComponent,
    route: '/legal-entity',
    icon: null,
    permissions: [
      AccessPermissions.CAN_READ_LEGAL_ENTITY,
      AccessPermissions.CAN_CREATE_LEGAL_ENTITY,
      AccessPermissions.CAN_UPDATE_LEGAL_ENTITY
    ]
  },
  {
    component: DeliveryRequestComponent,
    route: '/delivery-request',
    icon: null,
    permissions: [
      AccessPermissions.CAN_READ_DELIVERY,
      AccessPermissions.CAN_CREATE_DELIVERY,
      AccessPermissions.CAN_UPDATE_DELIVERY
    ]
  },
  {
    component: ItemProfileComponent,
    route: '/item',
    icon: null,
    permissions: [AccessPermissions.CAN_READ_ITEM, AccessPermissions.CAN_UPDATE_ITEM]
  },
  {
    component: PartnerComponent,
    route: '/partner',
    icon: null,
    permissions: [AccessPermissions.CAN_READ_PARTNER, AccessPermissions.CAN_UPDATE_PARTNER]
  },
  {
    component: SupplierComponent,
    route: '/supplier',
    icon: null,
    permissions: [AccessPermissions.CAN_READ_SUPPLIER, AccessPermissions.CAN_UPDATE_SUPPLIER]
  },
  {
    component: DeliveryComponent,
    route: '/delivery',
    icon: null,
    permissions: [AccessPermissions.CAN_READ_DELIVERY, AccessPermissions.CAN_UPDATE_DELIVERY]
  },
  {
    component: OrderComponent,
    route: '/order',
    icon: null,
    permissions: [AccessPermissions.CAN_READ_ORDER, AccessPermissions.CAN_UPDATE_ORDER]
  },
  {
    component: ItemDeliverySubscriptionComponent,
    route: '/item-delivery-subscription',
    icon: null,
    permissions: [AccessPermissions.CAN_MANAGE_ITEM_DELIVERY_SUBSCRIPTION]
  },
  {
    component: SupplierReserveSubscriptionComponent,
    route: '/supplier-reserve-subscription',
    icon: null,
    permissions: [AccessPermissions.CAN_MANAGE_SUPPLIER_RESERVE_SUBSCRIPTION]
  },
  {
    component: StockTakingReportComponent,
    route: '/stock-taking-report',
    icon: null,
    permissions: [AccessPermissions.CAN_READ_STOCKTAKING_REPORTS]
  },
  {
    component: StocktakingComponent,
    route: '/stocktaking',
    icon: null,
    permissions: [AccessPermissions.CAN_READ_STOCKTAKING_REPORTS]
  },
  {
    component: ClientInfoComponent,
    route: '/client-info',
    icon: null,
    permissions: [AccessPermissions.CAN_READ_PARTNER]
  }
];

export const deprecatedMenuItems: MenuItem[] = [];

export const visibleMenuItems = [...mainMenuItems, ...secondaryMenuItems, ...deprecatedMenuItems];
