import { AMSAutocomplete, AMSPdfViewerDialog, AMSTable } from '../../helpers/ui';
import { Checkbox, Grid, TextField, Typography } from '@material-ui/core';
import {
  CompanyListResponse,
  LegalEntityListResponse,
  PartnerListResponse,
  UserListResponse
} from '../../services/api-v3';
import Lookup, {
  companyToLookup,
  legalEntityToLookup,
  partnerToLookup,
  userToLookup
} from '../../models/lookup';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { getOrderTypeLabelById, orderTypeLookups } from '../../services/ordersService';
import {
  ignoreOffset,
  isValidDate,
  toDateString,
  toEndOfDay,
  toStartOfDay
} from '../../helpers/date-helper';
import {
  useActsOfAcceptance,
  useCompanies,
  useLegalEntities,
  usePartners,
  usePermissions,
  useStyles,
  useUsers
} from '../../helpers/hooks';

import AMSButton from '../../helpers/ui/AMSButton/AMSButton';
import AMSLink from '../../helpers/ui/AMSLink/AMSLink';
import { AccessPermissions } from '../../services/accessManagementService';
import { ActOfAcceptanceResponse } from '../../services/api';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DescriptionIcon from '@material-ui/icons/Description';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { cookiesService } from '../../services/services';
import { getActOfAcceptanceUrl } from '../../helpers/utils';
import { parseFilter } from '../../helpers/url';
import { useHistory } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ActsOfAcceptanceComponent = ({ location }: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { fromDate, toDate, companyIds, userIds, partnerIds, legalEntityIds, typeIds } = useMemo(
    () => parseFilter(location.search),
    [location.search]
  );

  const [title, setTitle] = useState('');
  const [openActOfAcceptancePreview, setOpenActOfAcceptancePreview] = useState(false);
  const [url, setUrl] = useState('');

  const filter = useMemo(() => cookiesService.getActOfAcceptanceFilter(), []);

  const [filterUsers, setFilterUsers] = useState<Lookup[]>([]);
  const [filterCompanies, setFilterCompanies] = useState<Lookup[]>([]);
  const [filterLegalEntities, setFilterLegalEntities] = useState<Lookup[]>([]);
  const [filterPartners, setFilterPartners] = useState<Lookup[]>([]);
  const [filterType, setFilterType] = useState<Lookup[]>(
    typeIds ? typeIds : filter.filterType ? filter.filterType : []
  );
  const [filterFromDate, setFilterFromDate] = useState<Date | null>(
    fromDate
      ? fromDate
      : filter.filterFromDate
      ? ignoreOffset(new Date(filter.filterFromDate))
      : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [filterToDate, setFilterToDate] = useState<Date | null>(
    toDate ? toDate : filter.filterToDate ? ignoreOffset(new Date(filter.filterToDate)) : new Date()
  );

  const { actsOfAcceptance, loading, setParameters } = useActsOfAcceptance(
    filterFromDate,
    filterToDate,
    partnerIds?.join(',') ?? filterPartners.map((p: Lookup) => p.id).join(','),
    legalEntityIds?.join(',') ?? filterLegalEntities.map((l: Lookup) => l.id).join(','),
    companyIds?.join(',') ?? filterCompanies.map((c: Lookup) => c.id).join(','),
    userIds?.join(',') ?? filterUsers.map((u: Lookup) => u.id).join(','),
    typeIds?.join(',') ?? filterType.map((t: Lookup) => t.id).join(',')
  );
  const { users } = useUsers();
  const { companies } = useCompanies();
  const { legalEntities } = useLegalEntities();
  const { partners } = usePartners();

  const [canRead] = usePermissions([AccessPermissions.CAN_READ_ORDER]);

  useEffect(() => {
    const { filterUsers } = cookiesService.getActOfAcceptanceFilter();
    setFilterUsers(
      userIds && users
        ? users.filter((u: UserListResponse) => userIds.includes(u.id)).map(userToLookup)
        : filterUsers ?? []
    );
  }, [userIds, users]);

  useEffect(() => {
    const { filterCompanies } = cookiesService.getActOfAcceptanceFilter();

    setFilterCompanies(
      companyIds && companies
        ? companies
            .filter((c: CompanyListResponse) => companyIds.includes(c.id))
            .map(companyToLookup)
        : filterCompanies ?? []
    );
  }, [companies, companyIds]);

  useEffect(() => {
    const { filterLegalEntities } = cookiesService.getActOfAcceptanceFilter();

    setFilterLegalEntities(
      legalEntityIds && legalEntities
        ? legalEntities
            .filter((le: LegalEntityListResponse) => legalEntityIds.includes(le.id))
            .map(legalEntityToLookup)
        : filterLegalEntities ?? []
    );
  }, [legalEntityIds, legalEntities]);

  useEffect(() => {
    const { filterPartners } = cookiesService.getActOfAcceptanceFilter();
    setFilterPartners(
      partnerIds && partners
        ? partners
            .filter((p: PartnerListResponse) => partnerIds.includes(p.id))
            .map(partnerToLookup)
        : filterPartners ?? []
    );
  }, [partners, partnerIds]);

  useEffect(() => {
    const { filterType } = cookiesService.getActOfAcceptanceFilter();

    setFilterType(
      typeIds ? orderTypeLookups.filter((ot: Lookup) => typeIds.includes(ot.id)) : filterType ?? []
    );
  }, [typeIds]);

  const onSearch = useCallback(() => {
    const fromDate = isValidDate(filterFromDate) ? new Date(toStartOfDay(filterFromDate!)) : null;
    const toDate = isValidDate(filterToDate) ? new Date(toEndOfDay(filterToDate!)) : null;
    cookiesService.setActOfAcceptanceFilter({
      filterFromDate: fromDate,
      filterToDate: toDate,
      filterUsers,
      filterCompanies,
      filterLegalEntities,
      filterPartners,
      filterType
    });
    let params: any = {};
    if (filterFromDate) {
      params.fromDate = toDateString(filterFromDate);
    }
    if (filterToDate) {
      params.toDate = toDateString(filterToDate);
    }
    if (filterCompanies?.length > 0) {
      params.companyIds = filterCompanies.map((c) => c.id).join(',');
    }
    if (filterLegalEntities?.length > 0) {
      params.legalEntitiesIds = filterLegalEntities.map((l) => l.id).join(',');
    }
    if (filterPartners?.length > 0) {
      params.partnerIds = filterPartners.map((p) => p.id).join(',');
    }
    if (filterUsers?.length > 0) {
      params.userIds = filterUsers.map((u) => u.id).join(',');
    }
    if (filterType?.length > 0) {
      params.typeIds = filterType.map((type) => type.id).join(',');
    }
    history.push({
      pathname: '/acts-of-acceptance',
      search: new URLSearchParams(params).toString()
    });
    setParameters([
      fromDate,
      toDate,
      filterPartners.map((p: Lookup) => p.id).join(','),
      filterLegalEntities.map((l: Lookup) => l.id).join(','),
      filterCompanies.map((c: Lookup) => c.id).join(','),
      filterUsers.map((u: Lookup) => u.id).join(','),
      filterType.map((t: Lookup) => t.id).join(',')
    ]);
  }, [
    filterFromDate,
    filterToDate,
    filterUsers,
    filterCompanies,
    filterLegalEntities,
    filterPartners,
    filterType,
    history,
    setParameters
  ]);

  const paymentOptionDistribution: any = useMemo(
    () =>
      actsOfAcceptance.reduce((res: any, aoa: ActOfAcceptanceResponse) => {
        if (!res) {
          res = {};
        }
        const key =
          aoa.orderPaymentOption?.replace('Начин на плащане: <b>', '').replace('</b>', '') ?? 'Без';
        if (!res[key]) {
          res[key] = 0;
        }
        res[key] = res[key] + aoa.totalIncludingVat;
        return res;
      }, {}),
    [actsOfAcceptance]
  );

  return (
    <Grid container spacing={1}>
      <Grid item lg={2} md={6} sm={12} xs={12}>
        <KeyboardDatePicker
          disableToolbar
          autoOk={true}
          variant="inline"
          format="dd/MM/yy"
          margin="dense"
          label="От"
          helperText={''}
          value={filterFromDate ? filterFromDate : null}
          onChange={(value: Date | null) => {
            if (value) {
              if (isValidDate(value)) {
                setFilterFromDate(ignoreOffset(value));
              }
            } else {
              setFilterFromDate(null);
            }
          }}
          inputVariant="outlined"
          fullWidth
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          maxDate={filterToDate}
        />
      </Grid>
      <Grid item lg={2} md={6} sm={12} xs={12}>
        <KeyboardDatePicker
          disableToolbar
          autoOk={true}
          variant="inline"
          format="dd/MM/yy"
          margin="dense"
          label="До"
          helperText={''}
          value={filterToDate ? filterToDate : null}
          onChange={(value: Date | null) => {
            if (value) {
              if (isValidDate(value)) {
                setFilterToDate(ignoreOffset(value));
              }
            } else {
              setFilterToDate(null);
            }
          }}
          inputVariant="outlined"
          fullWidth
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          minDate={filterFromDate}
        />
      </Grid>
      <Grid item lg={4} md={6} sm={12} xs={12}>
        <AMSAutocomplete
          label="Потребители"
          options={users.map(userToLookup)}
          value={filterUsers}
          onChange={setFilterUsers}
          minChar={0}
          disableCloseOnSelect
          renderOption={(option, { selected }) => (
            <Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                className={classes.checkbox}
                checked={selected}
                color="primary"
              />
              {option.value}
            </Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Потребители"
              placeholder=""
              margin="dense"
              fullWidth
            />
          )}
          multiple
          required
        />
      </Grid>
      <Grid item lg={4} md={6} sm={12} xs={12}>
        <AMSAutocomplete
          label="Юридически лица"
          options={legalEntities.map(legalEntityToLookup)}
          value={filterLegalEntities}
          onChange={setFilterLegalEntities}
          minChar={0}
          disableCloseOnSelect
          renderOption={(option, { selected }) => (
            <Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                className={classes.checkbox}
                checked={selected}
                color="primary"
              />
              {option.value}
            </Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Юридически лица"
              placeholder=""
              margin="dense"
              fullWidth
            />
          )}
          multiple
          required
        />
      </Grid>
      <Grid item lg={4} md={6} sm={12} xs={12}>
        <AMSAutocomplete
          label="Компании"
          options={companies.map(companyToLookup)}
          value={filterCompanies}
          onChange={setFilterCompanies}
          minChar={0}
          disableCloseOnSelect
          renderOption={(option, { selected }) => (
            <Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                className={classes.checkbox}
                checked={selected}
                color="primary"
              />
              {option.value}
            </Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Компании"
              placeholder=""
              margin="dense"
              fullWidth
            />
          )}
          multiple
          required
        />
      </Grid>

      <Grid item lg={4} md={6} sm={12} xs={12}>
        <AMSAutocomplete
          label="Получатели"
          options={partners.map(partnerToLookup)}
          value={filterPartners}
          onChange={setFilterPartners}
          minChar={0}
          disableCloseOnSelect
          renderOption={(option, { selected }) => (
            <Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                className={classes.checkbox}
                checked={selected}
                color="primary"
              />
              {option.value}
            </Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Получатели"
              placeholder=""
              margin="dense"
              fullWidth
            />
          )}
          multiple
          required
        />
      </Grid>
      <Grid item lg={3} md={6} sm={12} xs={12}>
        <AMSAutocomplete
          multiple
          minChar={0}
          limitTags={3}
          options={orderTypeLookups}
          sortOptions={false}
          disableCloseOnSelect
          renderOption={(option, { selected }) => (
            <Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                className={classes.checkbox}
                checked={selected}
                color="primary"
              />
              {option.value}
            </Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Тип"
              placeholder=""
              margin="dense"
              fullWidth
            />
          )}
          value={filterType}
          onChange={(values) => setFilterType(values)}
        />
      </Grid>
      <Grid item lg={1} md={1} sm={12} xs={12}>
        <AMSButton
          color="primary"
          variant="contained"
          text="Търсене"
          loading={loading}
          disabled={false}
          onClick={onSearch}
          style={{
            marginTop: 8
          }}
        />
      </Grid>
      <Grid item lg={12}>
        <AMSTable
          title={
            <Grid container spacing={2}>
              <Grid item lg={4}>
                <Typography style={{ float: 'left' }}>Списък с протоколи</Typography>
              </Grid>
              {paymentOptionDistribution && (
                <Grid item lg={8}>
                  <Grid container spacing={1}>
                    {Object.keys(paymentOptionDistribution).map((key: string) => (
                      <Fragment key={key}>
                        <Grid item lg={9}>
                          <Typography style={{ float: 'right' }}>{key}:</Typography>
                        </Grid>
                        <Grid item lg={3}>
                          <Typography style={{ float: 'right' }}>
                            {`${paymentOptionDistribution[key].toFixed(2)} лв.`}
                          </Typography>
                        </Grid>
                      </Fragment>
                    ))}
                  </Grid>
                </Grid>
              )}
            </Grid>
          }
          columns={[
            {
              title: '№',
              field: 'number',
              cellStyle: { width: '10%' },
              render: (rowData: ActOfAcceptanceResponse) => (
                <AMSLink href={`/act-of-acceptance?id=${rowData.id}`}>{rowData.number}</AMSLink>
              )
            },
            { title: 'Дата на създаване', field: 'createdAt', cellStyle: { width: '10%' } },
            {
              title: 'Компания',
              field: 'companyName',
              cellStyle: { width: '15%' }
            },
            {
              title: 'Получател',
              field: 'receiverName',
              cellStyle: { width: '15%' },
              render: (rowData: ActOfAcceptanceResponse) => (
                <AMSLink href={`/partner?id=${rowData.receiverId}`}>{rowData.receiverName}</AMSLink>
              )
            },
            {
              title: 'Юридическо лице',
              field: 'receiverLegalEntityName',
              cellStyle: { width: '15%' },
              render: (rowData: ActOfAcceptanceResponse) => (
                <AMSLink href={`/legal-entity?id=${rowData.receiverLegalEntityId}`}>
                  {rowData.receiverLegalEntityName}
                </AMSLink>
              )
            },
            {
              title: 'Потребител',
              field: 'createdByName',
              cellStyle: { width: '15%' }
            },
            {
              title: 'Тип на поръчката',
              field: 'orderType',
              cellStyle: { width: '15%' },
              render: (rowData: any) => getOrderTypeLabelById(rowData.orderType)
            },
            {
              title: 'Сума',
              field: 'totalIncludingVat',
              cellStyle: { width: '15%' },
              align: 'right',
              type: 'currency',
              currencySetting: {
                locale: 'bg',
                currencyCode: 'bgn',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }
            }
          ]}
          customAction={
            canRead
              ? {
                  icon: () => (
                    <DescriptionIcon
                      style={{
                        color: '#252525 !important'
                      }}
                    />
                  ),
                  tooltip: 'Преглед на документ',
                  onClick: (event: Event, actOfAcceptance: ActOfAcceptanceResponse) => {
                    setTitle(actOfAcceptance.number);
                    setUrl(getActOfAcceptanceUrl(actOfAcceptance.id));
                    setOpenActOfAcceptancePreview(true);
                  }
                }
              : undefined
          }
          pageSize={10}
          search={true}
          data={actsOfAcceptance}
          isLoading={loading}
        />
      </Grid>
      <AMSPdfViewerDialog
        title={title}
        url={url}
        open={openActOfAcceptancePreview}
        onClose={() => setOpenActOfAcceptancePreview(false)}
      />
    </Grid>
  );
};

export default ActsOfAcceptanceComponent;
