import { BulkCreatePartnerBonusQuantityRequest, UpdatePartnerBonusQuantityRequest } from './api';

import { AMSApi } from '../helpers/openapi-axios-helper';
import { toDateString } from '../helpers/date-helper';

export class PromotionsService {
  getPromotions = async (
    itemId?: number,
    partnerIds?: number[],
    fromStartDate?: Date,
    toStartDate?: Date,
    fromEndDate?: Date,
    toEndDate?: Date
  ) => {
    const axios = await AMSApi();
    return await axios.readPartnerBonusQuantitiesV2PromotionsBonusQuantityGet(
      itemId,
      partnerIds?.join(','),
      fromStartDate ? toDateString(fromStartDate) : undefined,
      toStartDate ? toDateString(toStartDate) : undefined,
      fromEndDate ? toDateString(fromEndDate) : undefined,
      toEndDate ? toDateString(toEndDate) : undefined
    );
  };

  createPromotions = async (request: BulkCreatePartnerBonusQuantityRequest) => {
    const axios = await AMSApi();
    return await axios.bulkCreatePartnerBonusQuantityPromotionsV2PromotionsBonusQuantityPost(
      request
    );
  };

  patchPromotion = async (id: number, request: UpdatePartnerBonusQuantityRequest) => {
    const axios = await AMSApi();
    return await axios.patchPartnerBonusQuantityV2PromotionsBonusQuantityIdPatch(id, request);
  };

  deletePromotion = async (id: number) => {
    const axios = await AMSApi();
    return await axios.deletePartnerBonusQuantityV2PromotionsBonusQuantityIdDelete(id);
  };
}
