import { CreateItemRequest, UpdateItemRequest } from './api';

import { AMSApi } from '../helpers/openapi-axios-helper';
import Item from '../models/item';

export class ItemsService {
  createItem = async (item: CreateItemRequest) => {
    const axios = await AMSApi();
    return await axios.createV1ItemsPost(item);
  };

  updateItem = async (item: UpdateItemRequest) => {
    const axios = await AMSApi();
    return await axios.updateV1ItemsPut(item);
  };

  getUnits = async () => {
    const axios = await AMSApi();
    return await axios.readAllUnitsV1ItemsUnitsGet();
  };

  getLinkedItems = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readAllV1ItemsItemIdLinkedItemAssociationGet(id);
  };

  addLinkedItem = async (itemId: number, item: Item) => {
    const axios = await AMSApi();
    return await axios.createV1ItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId, item.id);
  };

  deleteLinkedItem = async (itemId: number, id: number) => {
    const axios = await AMSApi();
    return await axios.deleteV1ItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId, id);
  };

  getSimilarItems = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readAllV1ItemsItemIdSimilarItemAssociationGet(id);
  };

  addSimilarItem = async (itemId: number, item: Item) => {
    const axios = await AMSApi();
    return await axios.createV1ItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId, item.id);
  };

  deleteSimilarItem = async (itemId: number, id: number) => {
    const axios = await AMSApi();
    return await axios.deleteV1ItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId, id);
  };
}
