import {
  AMSAsyncAutocomplete,
  AMSAutocomplete,
  AMSDatePicker,
  AMSTextField
} from '../../../helpers/ui';
import {
  BulkCreatePartnerBonusQuantityRequest,
  PartnerBonusQuantityResponse
} from '../../../services/api';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import Lookup, { partnerToLookup } from '../../../models/lookup';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { ignoreOffset, isValidDate, toDateString } from '../../../helpers/date-helper';
import { usePartners, useStyles } from '../../../helpers/hooks';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { ItemResponse } from '../../../services/api-v3';
import { promotionsService } from '../../../services/services';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface QuantityPromotionDialogProps {
  open: boolean;
  onSave: (promotions: PartnerBonusQuantityResponse[]) => void;
  onClose: () => void;
  itemId?: number;
  partnerId?: number;
}

const QuantityPromotionDialog = ({
  open,
  onSave,
  onClose,
  itemId,
  partnerId
}: QuantityPromotionDialogProps) => {
  const classes = useStyles();
  const [base, setBase] = useState<string | null>(null);
  const [bonus, setBonus] = useState<string | null>(null);
  const [item, setItem] = useState<ItemResponse | null>(null);
  const [addNew, setAddNew] = useState<boolean>(false);
  const { partners } = usePartners();
  const [selectedPartners, setSelectedPartners] = useState<Lookup[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const baseValue = useMemo(() => (base && base.length > 0 ? +base : 0), [base]);
  const bonusValue = useMemo(() => (bonus && bonus.length > 0 ? +bonus : 0), [bonus]);

  const handleSave = useCallback(async () => {
    const request: BulkCreatePartnerBonusQuantityRequest = {
      baseQuantity: `${baseValue}`,
      bonusQuantity: `${bonusValue}`,
      itemId: item?.id ?? itemId ?? -1,
      partnerIds: selectedPartners.length > 0 ? selectedPartners.map((p) => p.id) : [partnerId],
      startDate: toDateString(startDate!),
      endDate: toDateString(endDate!)
    };
    const resp = await promotionsService.createPromotions(request);
    if (resp && resp.data) {
      onSave(resp.data);
    }
  }, [
    itemId,
    partnerId,
    baseValue,
    bonusValue,
    item,
    onSave,
    selectedPartners,
    startDate,
    endDate
  ]);

  const hasQuantityError = useMemo(
    () => baseValue <= bonusValue && baseValue > 0 && bonusValue > 0,
    [baseValue, bonusValue]
  );

  const hasPeriodError = useMemo(
    () => (startDate && endDate && startDate > endDate) ?? false,
    [startDate, endDate]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
    >
      <DialogTitle>Количествена промоция</DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <Grid container spacing={1}>
              {!itemId && (
                <Grid item lg={12} sm={12}>
                  <AMSAsyncAutocomplete
                    label="Артикул"
                    value={item}
                    onChange={(value: ItemResponse | null) => {
                      setItem(value);
                    }}
                    getItemLabel={(item: any) => `${item.artNo} ${item.name}`}
                    required
                  />
                </Grid>
              )}
              {!partnerId && (
                <Grid item lg={12} sm={12}>
                  <AMSAutocomplete
                    multiple
                    minChar={0}
                    limitTags={10}
                    options={partners.map(partnerToLookup)}
                    disableCloseOnSelect
                    renderOption={(option, { selected }) => (
                      <Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          className={classes.checkbox}
                          checked={selected}
                          color="primary"
                        />
                        {option.value}
                      </Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Контрагенти"
                        placeholder=""
                        margin="dense"
                        fullWidth
                        required
                      />
                    )}
                    value={selectedPartners}
                    onChange={setSelectedPartners}
                    groupBy={(g) => (g.group ? g.group : '')}
                    required
                  />
                </Grid>
              )}
              <Grid item lg={3} sm={12}>
                <AMSTextField
                  label="Основа"
                  value={base ?? ''}
                  onChange={(value) => setBase(value ?? '')}
                  error={hasQuantityError}
                  required
                />
              </Grid>
              <Grid item lg={3} sm={12}>
                <AMSTextField
                  label="Бонус"
                  value={bonus ?? ''}
                  onChange={(value) => setBonus(value ?? '')}
                  error={hasQuantityError}
                  required
                />
              </Grid>
              <Grid item lg={3} sm={12}>
                <AMSDatePicker
                  label="От"
                  value={startDate ? startDate : null}
                  onChange={(value: Date | null) => {
                    if (value) {
                      if (isValidDate(value)) {
                        setStartDate(ignoreOffset(value));
                      }
                    } else {
                      setStartDate(null);
                    }
                  }}
                  minDate={new Date()}
                  maxDate={endDate ?? undefined}
                  error={hasPeriodError}
                />
              </Grid>
              <Grid item lg={3} sm={12}>
                <AMSDatePicker
                  label="До"
                  value={endDate ? endDate : null}
                  onChange={(value: Date | null) => {
                    if (value) {
                      if (isValidDate(value)) {
                        setEndDate(ignoreOffset(value));
                      }
                    } else {
                      setEndDate(null);
                    }
                  }}
                  minDate={startDate ?? undefined}
                  error={hasPeriodError}
                />
              </Grid>
              <Grid item lg={12} sm={12}>
                <Typography
                  style={{
                    visibility: hasQuantityError ? undefined : 'hidden',
                    color: '#FD9728'
                  }}
                  align="right"
                >
                  Бонусът трябва да е по-малък от основата
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.addNewCheckboxWrapper}>
          <FormControlLabel
            className={classes.label}
            control={<Checkbox checked={addNew} onChange={() => setAddNew(!addNew)} />}
            label="Добави нов след запис"
            labelPlacement="end"
          />
        </div>
        <Button onClick={onClose} color="secondary" variant="contained">
          Отказ
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={hasPeriodError || hasQuantityError}
        >
          Добре
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuantityPromotionDialog;
