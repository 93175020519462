import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { ActOfAcceptanceResponse } from '../../services/api';
import { actsOfAcceptanceService } from '../../services/services';

export interface IActsOfAcceptance<T> extends IAsyncType<T> {
  actsOfAcceptance: T;
  setActsOfAcceptance: Dispatch<SetStateAction<T>>;
}

const useActsOfAcceptance = (
  fromDate: Date | null,
  toDate: Date | null,
  receivers?: string,
  legalEntities?: string,
  companies?: string,
  users?: string,
  orderTypes?: string,
): IActsOfAcceptance<ActOfAcceptanceResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    ActOfAcceptanceResponse[]
  >(actsOfAcceptanceService.getActsOfAcceptance, [],
    [
      fromDate,
      toDate,
      receivers,
      legalEntities,
      companies,
      users,
      orderTypes,
    ]);
  return {
    actsOfAcceptance: data,
    setActsOfAcceptance: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useActsOfAcceptance;
