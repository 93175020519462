import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  CompanyListResponse,
  LegalEntityListResponse,
  OrderListResponse,
  PaymentOptionResponse
} from '../../services/api-v3';
import {
  DocumentTypes,
  OrderStatusKey,
  OrderStatusKeys,
  getOrderStatusByKey,
  getOrderStatusLabelByKey
} from '../../services/ordersService';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Lookup, { paymentOptionToLookup } from '../../models/lookup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { amsV3Service, ordersService } from '../../services/services';
import {
  getExtendedCompanyId,
  getExtendedLegalEntityId,
  getExtendedOrderId,
  getInvoiceUrl
} from '../../helpers/utils';
import { ignoreOffset, isValidDate, toEndOfDay, toStartOfDay } from '../../helpers/date-helper';
import {
  useCompanies,
  useLegalEntities,
  usePartners,
  usePaymentOptions,
  useWarehouses
} from '../../helpers/hooks';

import AMSAutocomplete from '../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import AMSButton from '../../helpers/ui/AMSButton/AMSButton';
import AMSLink from '../../helpers/ui/AMSLink/AMSLink';
import AMSPdfViewerDialog from '../../helpers/ui/AMSPdfViewerDialog/AMSPdfViewerDialog';
import AMSTable from '../../helpers/ui/AMSTable/AMSTable';
import DateFnsUtils from '@date-io/date-fns';
import InvoiceCreditNotesComponent from './Tabs/InvoiceCreditNotesComponent/InvoiceCreditNotesComponent';
import InvoiceOrdersComponent from './Tabs/InvoiceOrdersComponent/InvoiceOrdersComponent';
import InvoiceTransactionsComponent from './Tabs/InvoiceTransactionsComponent/InvoiceTransactionsComponent';
import TabPanel from '../../helpers/ui/TabPanel/TabPanel';
import { parseQuery } from '../../helpers/url';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    marginRight: 8
  },
  container: {
    margin: 0
  },
  root: {
    padding: 20
  },
  saveButton: {
    marginTop: 10
  },
  statusIndicator: {
    borderRadius: 24,
    width: 24,
    height: 24,
    minHeight: 24,
    minWidth: 24,
    maxHeight: 24,
    maxWidth: 24,
    marginRight: 8,
    border: '1px solid #737373'
  },
  statusText: {
    paddingTop: 2
  },
  expense: {
    color: '#F03901'
  },
  income: {
    color: '#93AB3B'
  }
}));

const InvoiceComponent = ({ location }: { location: any }) => {
  const { id, tabId } = parseQuery(location.search);

  const classes = useStyles();
  const history = useHistory();

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedInvoices, setSelectedInvoices] = useState<number[]>([]);
  const [paymentOption, setPaymentOption] = useState<Lookup | null>(null);

  const [invoice, setInvoice] = useState<any>({});
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [openInvoicePreview, setOpenInvoicePreview] = useState(false);
  const [filterFromDate, setFilterFromDate] = useState<any>(
    new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [filterToDate, setFilterToDate] = useState<any>(new Date());
  const [filterLegalEntity, setFilterLegalEntity] = useState<LegalEntityListResponse | null>(null);
  const [filterCompany, setFilterCompany] = useState<CompanyListResponse | null>(null);

  const [orders, setOrders] = useState<OrderListResponse[]>([]);
  const { companies } = useCompanies();
  const { legalEntities } = useLegalEntities();
  const { paymentOptions } = usePaymentOptions();
  const { partners } = usePartners();
  const { warehouses } = useWarehouses();

  useEffect(() => {
    if (tabId) {
      if (id) {
        setSelectedTab(+tabId);
      } else {
        setSelectedTab(0);
        history.push(`invoice?${id ? `id=${id}` : ''}&tabId=0`);
      }
    } else {
      setSelectedTab(0);
    }
  }, [tabId, id, history]);

  useEffect(() => {
    const loadInvoice = async () => {
      setLoading(true);
      if (id) {
        const invoiceResp = await amsV3Service.getInvoice(id);
        setInvoice(invoiceResp ? invoiceResp.data : {});
      } else {
        setInvoice({});
      }
      setLoading(false);
    };
    loadInvoice();
  }, [id]);

  const search = useCallback(async () => {
    setLoading(true);
    const loadOrders = async () => {
      if (
        (filterFromDate === null || isValidDate(filterFromDate)) &&
        (filterToDate === null || isValidDate(filterToDate)) &&
        filterLegalEntity
      ) {
        const fromDate = isValidDate(filterFromDate) ? toStartOfDay(filterFromDate) : undefined;
        const toDate = isValidDate(filterToDate) ? toEndOfDay(filterToDate) : undefined;
        const ordersResp = await amsV3Service.getOrders(
          [
            OrderStatusKeys.FOR_SHIPPING,
            OrderStatusKeys.SHIPPED,
            OrderStatusKeys.DELIVERED,
            OrderStatusKeys.AWAITING_PAYMENT,
            OrderStatusKeys.FINISHED_SUCCESSFULLY,
            OrderStatusKeys.FINISHED_UNSUCCESSFULLY
          ],
          [filterCompany?.id ?? -1],
          [],
          [],
          [filterLegalEntity.id],
          fromDate,
          toDate,
          false
        );
        if (ordersResp) {
          setOrders(
            ordersResp.data.data.filter(
              (order: OrderListResponse) => order.billableNetTotal && !order.invoiceId
            )
          );
        }
      }
      setLoading(false);
    };
    loadOrders();
  }, [filterCompany, filterFromDate, filterLegalEntity, filterToDate]);

  useEffect(() => {
    search();
  }, [search, filterFromDate, filterToDate, filterLegalEntity, filterCompany]);

  const handleChange = (event: React.ChangeEvent<{}>, newSelectedTab: number) => {
    setSelectedTab(newSelectedTab);
    history.replace(`invoice?${id ? `id=${id}` : ''}&tabId=${newSelectedTab}`);
  };

  const a11yProps = (index: any) => {
    return {
      id: `invoice-tab-${index}`,
      'aria-controls': `invoice-tabpanel-${index}`
    };
  };

  const shouldBeDisabled = useMemo(
    () => selectedInvoices.length === 0 || !paymentOption,
    [selectedInvoices, paymentOption]
  );

  const transactionDocuments = useMemo(
    () => [
      invoice.invoiceId
        ? {
            id: invoice.id,
            value: `${invoice.number} ${ordersService.getOrderDocumentTypeAlias(
              DocumentTypes.CREDIT_NOTE
            )}`,
            type: DocumentTypes.CREDIT_NOTE,
            remainingAmount: -invoice.remainingAmount
          }
        : {
            id: invoice.id,
            value: `${invoice.number} ${ordersService.getOrderDocumentTypeAlias(
              DocumentTypes.INVOICE
            )}`,
            type: DocumentTypes.INVOICE,
            remainingAmount: invoice.remainingAmount
          }
    ],
    [invoice]
  );

  const warehouseNamesById = useMemo(
    () =>
      warehouses.reduce((res: any, warehouse) => {
        if (!res) {
          res = {};
        }
        res[warehouse.id] = warehouse.name;
        return res;
      }, {}),
    [warehouses]
  );

  const partnerNamesById = useMemo(
    () =>
      partners.reduce((res: any, partner) => {
        if (!res) {
          res = {};
        }
        res[partner.id] = partner.name;
        return res;
      }, {}),
    [partners]
  );

  return id && invoice.id && !loading ? (
    <Container maxWidth="xl" className={classes.container}>
      <Paper elevation={0} className={classes.root}>
        <Grid container>
          <Grid item lg={12} container justify="center">
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setTitle(invoice.number);
                setUrl(getInvoiceUrl(invoice.id));
                setOpenInvoicePreview(true);
              }}
              style={{ marginRight: '16px' }}
            >
              <Typography component="h5" variant="h5">
                {id && invoice.number ? invoice.number : ''}
              </Typography>
            </Button>
            {invoice.invoiceId ? (
              <AMSLink href={`/invoice?id=${invoice.invoiceId}`}>
                <Button color="primary" variant="outlined">
                  <Typography component="h5" variant="h5" display="inline">
                    Фактура
                  </Typography>
                </Button>
              </AMSLink>
            ) : undefined}
          </Grid>
          <Grid item lg={12}>
            <Tabs value={selectedTab} onChange={handleChange}>
              <Tab label="Поръчки" {...a11yProps(0)} />
              {!invoice.invoiceId && (
                <Tab
                  disabled={!invoice && !invoice.invoiceId}
                  label="Кредитни известия"
                  {...a11yProps(1)}
                />
              )}
              <Tab disabled={!id} label="Трансакции" {...a11yProps(2)} />
            </Tabs>
          </Grid>
          <Grid item lg={12}>
            <TabPanel value={selectedTab} index={0}>
              <InvoiceOrdersComponent id={id} />
            </TabPanel>
            {!invoice.invoiceId && (
              <TabPanel value={selectedTab} index={1}>
                <InvoiceCreditNotesComponent id={id} />
              </TabPanel>
            )}
            {id && (
              <TabPanel value={selectedTab} index={!invoice.invoiceId ? 2 : 1}>
                <InvoiceTransactionsComponent
                  id={id}
                  invoice={invoice}
                  documentsOptions={transactionDocuments}
                />
              </TabPanel>
            )}
          </Grid>
        </Grid>
      </Paper>
      <AMSPdfViewerDialog
        title={title}
        url={url}
        open={openInvoicePreview}
        onClose={() => setOpenInvoicePreview(false)}
      />
    </Container>
  ) : id ? (
    <CircularProgress />
  ) : (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Container maxWidth="xl" className={classes.container}>
        <Paper elevation={0} className={classes.root}>
          <Grid container spacing={1}>
            <Grid item lg={2} md={6} sm={12} xs={12}>
              <KeyboardDatePicker
                disableToolbar
                autoOk={true}
                variant="inline"
                format="dd/MM/yy"
                margin="dense"
                label="От дата"
                helperText={''}
                value={filterFromDate ? filterFromDate : null}
                onChange={(value: Date | null) => {
                  if (value) {
                    if (isValidDate(value)) {
                      setFilterFromDate(ignoreOffset(value));
                    }
                  } else {
                    setFilterFromDate(null);
                  }
                }}
                inputVariant="outlined"
                fullWidth
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                maxDate={filterToDate}
                required
              />
            </Grid>
            <Grid item lg={2} md={6} sm={12} xs={12}>
              <KeyboardDatePicker
                disableToolbar
                autoOk={true}
                variant="inline"
                format="dd/MM/yy"
                margin="dense"
                label="До дата"
                helperText={''}
                value={filterToDate ? filterToDate : null}
                onChange={(value: Date | null) => {
                  if (value) {
                    if (isValidDate(value)) {
                      setFilterToDate(ignoreOffset(value));
                    }
                  } else {
                    setFilterToDate(null);
                  }
                }}
                inputVariant="outlined"
                fullWidth
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                minDate={filterFromDate}
                required
              />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <AMSAutocomplete
                minChar={0}
                options={companies.map((c) => ({
                  id: c.id,
                  value: `${getExtendedCompanyId(c.id)} ${c.note}`,
                  group: c.name
                }))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Компания"
                    placeholder=""
                    margin="dense"
                    fullWidth
                    required
                  />
                )}
                value={filterCompany}
                onChange={(value) => setFilterCompany(value)}
                groupBy={(g) => (g.group ? g.group : '')}
              />
            </Grid>
            <Grid item lg={2} md={12} sm={12} xs={12}>
              <AMSButton
                text="Създаване"
                color="primary"
                variant="contained"
                onClick={async () => {
                  setActionLoading(true);
                  if (paymentOption) {
                    const invoiceResp = await amsV3Service.createInvoice(
                      selectedInvoices,
                      paymentOption.id
                    );
                    history.push(
                      `invoice?${invoiceResp.data ? `id=${invoiceResp.data.id}` : ''}&tabId=0`
                    );
                  }
                  setActionLoading(false);
                }}
                disabled={shouldBeDisabled}
                loading={actionLoading}
                className={classes.saveButton}
              />
            </Grid>
            <Grid item lg={5} md={12} sm={12} xs={12}>
              <AMSAutocomplete
                minChar={0}
                options={legalEntities.map((le) => ({
                  id: le.id,
                  value: `${getExtendedLegalEntityId(le.id)} ${le.name}`
                }))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Юридическо лице"
                    placeholder=""
                    margin="dense"
                    fullWidth
                    required
                  />
                )}
                value={filterLegalEntity}
                onChange={(value) => {
                  setFilterLegalEntity(value);
                  if (value) {
                    const legalEntity = legalEntities.find(
                      (le: LegalEntityListResponse) => value.id === le.id
                    );
                    const paymentOption = paymentOptions.find(
                      (po: PaymentOptionResponse) => legalEntity?.paymentOptionId === po.id
                    );
                    if (legalEntity && legalEntity.paymentOptionId && paymentOption) {
                      setPaymentOption(paymentOptionToLookup(paymentOption));
                    }
                  }
                }}
              />
            </Grid>
            <Grid item lg={5} md={12}>
              <AMSAutocomplete
                options={paymentOptions.map(paymentOptionToLookup)}
                value={paymentOption}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Начин на плащане"
                    placeholder=""
                    margin="dense"
                    fullWidth
                    required
                  />
                )}
                onChange={(value: any) => {
                  setPaymentOption(value);
                }}
                minChar={0}
                required
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <AMSTable
                title="Поръчки"
                columns={[
                  {
                    title: '№',
                    field: 'id',
                    render: (rowData: OrderListResponse) => getExtendedOrderId(rowData.id),
                    cellStyle: { width: '5%' }
                  },
                  {
                    title: 'Статус',
                    field: 'status',
                    render: (rowData: OrderListResponse) => (
                      <Tooltip title={getOrderStatusLabelByKey(rowData.status as OrderStatusKey)}>
                        <div className="d-flex">
                          <div
                            className={classes.statusIndicator}
                            style={{
                              backgroundColor: getOrderStatusByKey(rowData.status as OrderStatusKey)
                                .color
                            }}
                          />
                          <div className={classes.statusText}>
                            {getOrderStatusLabelByKey(rowData.status as OrderStatusKey)}
                          </div>
                        </div>
                      </Tooltip>
                    ),
                    cellStyle: { width: '15%' }
                  },
                  {
                    title: 'Дата за доставка',
                    field: 'deliveryDate',
                    type: 'datetime',
                    cellStyle: { width: '10%' }
                  },
                  {
                    title: 'Получател',
                    field: 'receiverId',
                    render: (rowData: OrderListResponse) => (
                      <AMSLink href={`/partner?id=${rowData.receiverId}`}>
                        {partnerNamesById[rowData.receiverId ?? '']}
                      </AMSLink>
                    ),
                    cellStyle: { width: '30%' }
                  },
                  {
                    title: 'Склад',
                    field: 'warehouseId',
                    render: (rowData: OrderListResponse) => (
                      <AMSLink href={`/warehouse?id=${rowData.warehouseId}`}>
                        {warehouseNamesById[rowData.warehouseId ?? '']}
                      </AMSLink>
                    ),
                    cellStyle: { width: '30%' }
                  },
                  {
                    title: 'Сума',
                    field: 'billableNetTotal',
                    cellStyle: { width: '10%' },
                    type: 'currency',
                    currencySetting: {
                      locale: 'bg',
                      currencyCode: 'bgn',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }
                  }
                ]}
                paging={false}
                data={orders}
                isLoading={loading}
                selection={true}
                onSelectionChange={(data) => setSelectedInvoices(data.map((row) => row.id) ?? [])}
              />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </MuiPickersUtilsProvider>
  );
};

export default InvoiceComponent;
