import { ActiveOrderItemResponse, ItemResponse } from '../../../../../services/api-v3';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  makeStyles
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { UnitEnum, unitEnumLocalizations } from '../../../../../services/AMSV3Service';
import { ignoreOffset, toDateString, toYYYYMMDD } from '../../../../../helpers/date-helper';

import AMSAsyncAutocomplete from '../../../../../helpers/ui/AMSAsyncAutocomplete/AMSAsyncAutocomplete';
import AMSCountryAutocomplete from '../../../../../helpers/ui/AMSCountryAutocomplete/AMSCountryAutocomplete';
import AMSTable from '../../../../../helpers/ui/AMSTable/AMSTable';
import AMSViewOnlyTextField from '../../../../../helpers/ui/AMSViewOnlyTextField/AMSViewOnlyTextField';
import Country from '../../../../../models/country';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Lookup from '../../../../../models/lookup';
import { amsV3Service } from '../../../../../services/services';
import { findCountry } from '../../../../../helpers/country-helper';
import { getExtendedOrderId } from '../../../../../helpers/utils';
import { s3ImagesUrl } from '../../../../../helpers/openapi-axios-helper';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold'
  },
  imageWrapper: {
    width: '100%',
    height: 256,
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    width: 256,
    height: 256
  },
  label: {
    padding: 6
  },
  button: {
    marginTop: 8
  },
  addNewCheckboxWrapper: {
    paddingTop: 8
  }
}));

export interface AddOrderItemDialogProps {
  supplier: Lookup | null;
  open: boolean;
  onClose: () => void;
  onSave: (request: any, addNew: boolean) => void;
}

const AddOrderItemDialog = ({ supplier, open, onClose, onSave }: AddOrderItemDialogProps) => {
  const classes = useStyles();

  const [item, setItem] = useState<ItemResponse | null>(null);
  const [quantity, setQuantity] = useState<string>('1');
  const [price, setPrice] = useState<string>('');
  const [batchNumber, setBatchNumber] = useState<string | null>(null);
  const [expirationDate, setExpirationDate] = useState<Date | null>(null);
  const [country, setCountry] = useState<Country | null>(null);
  const [activeOrderItems, setActiveOrderItems] = useState<ActiveOrderItemResponse[]>([]);

  const [addNew, setAddNew] = useState<boolean>(false);

  useEffect(() => {
    setItem(null);
    setQuantity('1');
  }, [open]);

  useEffect(() => {
    if (item && item.lastDeliveryCountry) {
      const countryObject: Country | undefined = findCountry(item.lastDeliveryCountry);
      if (countryObject) {
        setCountry(countryObject);
      }
    } else {
      setCountry(null);
    }
    if (item && item.lastKnownPrice) {
      setPrice(item.lastKnownPrice.toString());
    } else {
      setPrice('');
    }
    if (item?.id) {
      amsV3Service
        .getItemActiveOrderItems(item.id)
        .then((response) => setActiveOrderItems(response.data.data));
    }
  }, [item]);

  const handleAddStock = async () => {
    if (supplier && item && !!price && !!quantity && +price > 0 && +quantity > 0) {
      const newItemStock = {
        itemId: item.id,
        itemName: item.name,
        itemBarcodeValue: item.barcodeValue,
        quantity: +quantity,
        price: +price,
        batchNumber: batchNumber ? batchNumber : `${supplier.id}_${toYYYYMMDD(new Date())}`,
        expirationDate: expirationDate
          ? toDateString(expirationDate)
          : toDateString(new Date(new Date().getTime() + 20 * 365 * 24 * 60 * 60 * 1000)),
        country: country ? country.alpha3 : 'BGR',
        itemVat: item.vat,
        priceIncludingVat: ((100 + item.vat) / 100) * +price,
        totalWithoutVat: +quantity * +price,
        totalIncludingVat: ((100 + item.vat) / 100) * +quantity * +price
      };
      onSave(newItemStock, addNew);
      setItem(null);
      setBatchNumber(null);
      setExpirationDate(null);
      setCountry(null);
      setQuantity('');
      setPrice('');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth={activeOrderItems.length > 0 ? 'xl' : 'md'}
    >
      <DialogTitle>
        <div className={classes.title}>Добавяне на артикул</div>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={1}>
          <Grid item sm={activeOrderItems.length > 0 ? 8 : 12}>
            <div className={classes.imageWrapper}>
              {!!item && (
                <img
                  src={`${s3ImagesUrl}/items/${item.artNo}.png`}
                  onError={(ev: any) =>
                    (ev.target.src = require('../../../../../images/fallback.png'))
                  }
                  alt="-"
                  className={classes.image}
                />
              )}
              {!item && (
                <img
                  src={require('../../../../../images/fallback.png')}
                  alt="-"
                  className={classes.image}
                />
              )}
            </div>
            <Grid container spacing={1}>
              <Grid item lg={12}>
                <AMSAsyncAutocomplete
                  label="Търсене"
                  onChange={(value: ItemResponse | null) => {
                    setItem(value);
                  }}
                  value={item}
                  minChar={3}
                  forDelivery={true}
                />
              </Grid>
              <Grid item lg={9}>
                <AMSViewOnlyTextField label="Име" value={item ? item.name : ''} />
              </Grid>
              <Grid item lg={3}>
                <AMSViewOnlyTextField
                  label="Артикулен номер"
                  value={item && item.artNo ? item.artNo : ''}
                />
              </Grid>
              <Grid item lg={6}>
                <AMSViewOnlyTextField
                  label="Баркод"
                  value={item && item.barcodeValue ? item.barcodeValue : ''}
                />
              </Grid>
              <Grid item lg={6}>
                <AMSViewOnlyTextField
                  label="Мерна единица"
                  value={
                    item
                      ? `${unitEnumLocalizations[item.unitId as UnitEnum]}${
                          item.packageQty && item.packageQty > 1 ? ` от ${item.packageQty}` : ''
                        }`
                      : ''
                  }
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  label="Брой"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  value={quantity}
                  type="number"
                  onChange={(event) => setQuantity(event.target.value)}
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  label="Единична цена (без ДДС)"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  type="number"
                  value={price}
                  onChange={(event) => setPrice(event.target.value)}
                />
              </Grid>
              <Grid item lg={4}>
                <AMSViewOnlyTextField
                  label="Обща цена (без ДДС)"
                  value={item ? (+price * +quantity).toFixed(2) : '0.00'}
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  label="Партида"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  value={batchNumber ? batchNumber : ''}
                  onChange={(e) => setBatchNumber(e.target.value)}
                  placeholder={`по подр. ${supplier ? supplier.id : 'X'}_${toYYYYMMDD(new Date())}`}
                />
              </Grid>
              <Grid item sm={4}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format="dd/MM/yy"
                  margin="dense"
                  label="Срок на годност"
                  helperText={''}
                  placeholder={`по подр. ${toDateString(
                    new Date(new Date().getTime() + 20 * 365 * 24 * 60 * 60 * 1000)
                  )}`}
                  value={expirationDate ? expirationDate : null}
                  onChange={(value: Date | null) => {
                    if (value) {
                      setExpirationDate(ignoreOffset(value));
                    } else {
                      setExpirationDate(null);
                    }
                  }}
                  inputVariant="outlined"
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
              </Grid>
              <Grid item sm={4}>
                <AMSCountryAutocomplete
                  country={country}
                  onChange={(_, value: Country | null) => {
                    setCountry(value);
                  }}
                  placeholder={`по подр. България`}
                />
              </Grid>
            </Grid>
          </Grid>
          {activeOrderItems.length > 0 && (
            <Grid item sm={4}>
              <AMSTable
                title="Активни поръчки"
                data={activeOrderItems}
                components={{
                  Container: (props: any) => (
                    <Paper {...props} elevation={0} className="padding-0" />
                  )
                }}
                columns={[
                  {
                    title: 'Поръчка №',
                    field: 'orderId',
                    render: (orderItem: ActiveOrderItemResponse) =>
                      getExtendedOrderId(orderItem.orderId),
                    cellStyle: { width: '20%' }
                  },
                  {
                    title: 'Дата за доставка',
                    field: 'orderDeliveryDate',
                    type: 'date',
                    cellStyle: { width: '20%' }
                  },
                  {
                    title: 'Количество',
                    field: 'quantity',
                    type: 'numeric',
                    cellStyle: { width: '20%' }
                  },
                  {
                    title: 'Събрани',
                    field: 'collected',
                    type: 'numeric',
                    cellStyle: { width: '20%' }
                  },
                  {
                    title: 'Цена',
                    field: 'price',
                    type: 'currency',
                    currencySetting: {
                      locale: 'bg',
                      currencyCode: 'bgn',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    },
                    cellStyle: { width: '20%' }
                  }
                ]}
                paging={false}
                exportButton={false}
                minBodyHeight="40vh"
                maxBodyHeight="40vh"
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.addNewCheckboxWrapper}>
          <FormControlLabel
            className={classes.label}
            control={<Checkbox checked={addNew} onChange={() => setAddNew(!addNew)} />}
            label="Добави нов след запис"
            labelPlacement="end"
          />
        </div>
        <Button onClick={onClose} color="secondary" variant="contained">
          Отказ
        </Button>
        <Button
          onClick={handleAddStock}
          disabled={!item || !!!price || !!!quantity || +price <= 0 || +quantity <= 0}
          color="primary"
          variant="contained"
        >
          Добре
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOrderItemDialog;
